import firstImage from '../../images/pages/sugar-factories/firstImage.jpg';
import secondImage from '../../images/pages/sugar-factories/secondImage.jpg';
import {PageTemplates} from "../../pages/standartPages/enum";

export const sugarFactories = {
    url:'/sugar-factories',
    title:'Цукрові заводи',
    firstImage,
    secondImage,
    type: PageTemplates.TwoImagesTwoTextsType,
    alsoSeeIndex: 8,
    buttons: [{
        text: 'Мийний комплекс',
        url: '/washing-complex'
    },{
        text: 'Сушарський комплекс для жому',
        url: '/zhomodushilny-complex'
    },{
        text: 'Дифузійне відділення',
        url: '/diffusion-department'
    },{
        text: 'Продуктове відділення',
        url: '/grocery-department'
    },{
        text: 'Кристалізаційне відділення',
        url: '/crystallization-department'
    },{
        text: 'Відділення вапняково-випалювальне',
        url: '/lime-burning-department'
    },{
        text: 'Сушарський комплекс для цукру',
        url: '/sugar-drying-complex'
    },{
        text: 'Відділення переробки буряка',
        url: '/beet-processing-department'
    },{
        text: 'Випарна станція',
        url: '/evaporation-station'
    },{
        text: 'Станція фільтрування',
        url: '/filtration-station'
    },{
        text: 'Станція дефекосатурації (сокоочистний відділ)',
        url: '/juice-department'
    }],
    keywords: 'будівництво цукрового заводу,монтаж обладнання цукрового заводу,будівництво цеху',
    description: 'Виконаємо роботи пов\'язані з будівництвом заводу з переробки цукру. Будівництво нового цеху клеровки цукру-сирця. Монтаж компресорів. Монтаж з трубною та транспортною обв\'язкою мішалок, бункерів',
    firstText: {
        text: `## Наша компанія виконає роботи пов'язані з будівництвом заводу з переробки цукру.

###### Монтаж обладнання цукрового заводу може включати:

- Виготовлення відвалів, переходів та їх монтаж;

- Монтаж запірної арматури;

- Монтаж трьохпродуктової схеми;

- Монтаж з трубною та транспортною обв'язкою мішалок, бункерів;

- Будівництво нового цеху клеровки цукру-сирця;

- Демонтаж зношених мішалок, бункерів, утфелераспределителей;

- Монтаж системи пожежогасіння цеху безтарного зберігання цукру;

- Монтаж змішувача утфелю та меласи;

- Встановлення збірника сиропу після сульфітації в конденсатному господарстві;

- Монтаж турбогенератора;

- Монтаж компресорів;

- Монтаж парової турбіни в комплекті з генератором;

- Будівельно-монтажні роботи заміни парових комунікацій випарних апаратів;

- Монтаж грабельного транспортера;

- Монтаж насосного парку з трубною обв'язкою;

- Заміна технологічних трубопроводів;

- Демонтаж і монтаж ємності для зберігання меласи об'ємом;

- Демонтаж і монтаж цукроохолоджувальних установок;

- Монтаж теплообмінників;

- Будівництво швидкозбірних складів для зберігання цукру;

- Монтаж арочних ферм складу гранульованого жому;

- Монтаж та введення в експлуатацію турбоагрегата;

- Монтаж системи аспірації жомосушильного відділення;

- Монтаж системи вентиляції вапнякового відділення та газової печі;

- Встановлення різного великогабаритного технологічного обладнання;

- Виготовлення і монтаж шаф управління, пультів та їх підключення;`
    },
    secondText: {
        text: `#### Реконструкція цукрового заводу:

- Виготовлення і монтаж технологічного обладнання нестандартного типу;

- Виготовлення і монтаж металоконструкцій;

- Капітальний ремонт дифузійних апаратів (А1 ПДС 20, ДС-12, ПДС-20);

- Капітальний ремонт бурякорізок (свеклорізок);

- Ремонт Центрифуг;

- Виготовлення і монтаж мішалок, бункерів, утфелераспределителей;

- Заміна зношених технологічних трубопроводів;

- Заміна трубок пароперегрівача;

- Капітальний ремонт БУМів;

- Виготовлення і монтаж сатураторів, дефекаторів їх трубна обв'язка;

- Капітальний ремонт свекломийки з виготовленням соломоботволовушок;

- Реконструкція трубопроводів пара, води та соку, трубних комунікацій включаючи трубне обладнання;

- Ремонт водяних і сокових насосів;

- Ремонт і налагодження вакуумних фільтрів (БсхШУ);

- Ремонт тракта подачі і мийки свекли;

- Ремонт транспортера;

- Капітальний ремонт гідротранспортера;

- Ремонт жомосушильних барабанів;

- Реконструкція теплової схеми заводу;

- Реконструкція станції теплообмінників;

- Реконструкція вапняково-випалювальної печі;

- Реконструкція фільтрів 1-го та 2-го сатурації ФіЛС;

- Реконструкція вакуум-конденсаційних установок та контурів охолодження оборотної води;

- Реконструкція теплообмінників, конденсатора;

- Модернізація відділення сушки та охолодження цукру;

- Модернізація охолоджувача з киплячим шаром цукру;

- Реконструкція випарної станції (Заміна поверхні нагріву всіх корпусів);

- Модернізація жомосушильного відділення з переходом на газ двох жомосушок;

- Реконструкція вакуум-апаратів;

- Ремонт лобового сита;

- Виготовлення черпачного колеса;

- Наплавка котика;

- Демонтаж шнеків з заміною віток та виготовленням нових спиць;

- Реконструкція парового котла (ГМ-50/14, HURTS, ДКВР-10, STANDART-KESSEL, БКЗ-75-39, БГМ 35, ДКВР 17/13, ДЕ25, ТС-35, ОГО-50, ДЕ 25/14);

- Будівельно-монтажні роботи заміни комунікацій конденсаторного господарства;

- Ремонт з виготовленням і заміною зношеного обладнання на станції свеклоподачі та мийки свекли;

- Виготовлення і монтаж з обв'язкою технологічними трубопроводами мішалки стандарт-сиропа;

- Заміна ковшових ваг з обв'язкою трубопроводами і демонтажем мішалки;

- Заміна інсталяції редукторів та насоса з обв'язкою технологічними трубопроводами;

- Виготовлення шаф управління.
`
    }};
