import firstImage from '../../images/pages/filtration-station/1.jpg';
import {PageTemplates} from "../../pages/standartPages/enum";

export const filtrationStation = {
    url: '/filtration-station',
    title: 'Монтаж оборудования станции фильтрования',
    firstImage,
    keywords: 'монтаж станции фильтрования,демонтаж станции фильтрования,ремонт станции фильтрования',
    type: PageTemplates.OneImageTwoTextsSmallType,
    firstText: {
        text: `Выполним монтаж и замену/демонтаж оборудования станции фильтрования и другого промышленного оборудования на профессиональном уровне. На все виды работ организация имеет необходимые разрешения и свидетельства.`
    },
    description: 'Работы по монтажу и модернизации станции фильтрации сахарного завода.Монтаж пресс фильтров Рutsch, Sismat, КФ. Монтаж свечных фильтров (ФСБУ-110, ФСБУ-90, ФСБУ-110). Монтаж сиропных фильтра ФСП, ТФС. Монтаж и обвязка патронных фильтров сиропа (ТК-ФПИ)',
    secondText: {
        text: `Наша компания выполнит работы по монтажу и модернизации станции фильтрации сахарного завода.

##### В список работ могут входить:

- Монтаж пресс фильтров Рutsch, Sismat, КФ;

- Установка комплектов фильтровальных элементов для фильтров ТF;

- Монтаж свечных фильтров (ФСБУ-110, ФСБУ-90, ФСБУ-110);

- Монтаж и обвязка патронных фильтров сиропа (ТК-ФПИ);

- Монтаж сиропных фильтра ФСП, ТФС.
`}};


