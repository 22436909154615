import firstImage from '../../images/pages/oil-cleaning-line-installation/firstImage.jpg';
import secondImage from '../../images/pages/oil-cleaning-line-installation/secondImage.jpg';
import {PageTemplates} from "../../pages/standartPages/enum";

export const oilCleaningLineInstallation = {
    url: '/oil-cleaning-line-installation',
    title: 'Монтаж линии очистки масла',
    firstImage,
    secondImage,
    keywords: 'ремонт линии очистки масла,линия очистки масла',
    type: PageTemplates.TwoImagesOneTextType,
    description: 'Реконструкция маслоэкстракционного завода. Строительно-монтажные работы. Возведении фундаментов под емкости и резервуар. Монтаж технологических сетей и коммуникаций',
    text: `# Реконструкция маслоэкстракционного завода:
        
- Строительно-монтажные работы;

- Изготовление и монтаж металлоконструкций;

- Возведении фундаментов под емкости и резервуары;

- Зашивка сэндвич-панелями помещений и стен зданий;

- Демонтаж устаревшего оборудования;

- Монтаж технологических сетей и коммуникаций;

- Реконструкция и строительство зданий и сооружений;

- Реконструкция технологической линии цеха экстракции;

- Реконструкция станции разгрузки автомобилей;

- Изготовление емкостей из нержавеющей стали;

- Изготовление Бункеров;

- Ремонт технологических трубопроводов;

- Ремон норий;

- Ремонт конвейеров;

- Удленение транспортера;

- Сопровождение пуско-наладочных работ.`,
};