import firstImage from '../../images/pages/washing-complex/1.jpg';
import {PageTemplates} from "../../pages/standartPages/enum";

export const washingComplex = {
    url: '/washing-complex',
    title: 'Монтаж оборудования моечного комплекса сахарного завода',
    firstImage,
    type: PageTemplates.OneImageTwoTextsSmallType,
    keywords: 'монтаж,моечный комплекс сахарного завода,оборудование моечного комплекса,монтаж моечного комплекс сахарного завода',
    firstText: {
        text: `ООО «СИТАМ» Выполним монтаж и замену/демонтаж оборудования моечного комплекса и другого промышленного оборудования на профессиональном уровне. На все виды работ организация имеет необходимые разрешения и свидетельства.`
    },
    description: 'Выполним реконструкцию и монтаж оборудования тракта подачи и моечного комплекса сахарного завода для повышением производительности или технического переоснащения (модернизации). Монтаж свекломойка с ополаскивателями и водоотделителями (Ш1-ПМД, СДК, DRW). Установка вертикальных отстойников-сгустителей ВОУ, Ш1-ПОС',
    secondText: {
        text: `Наша компания выполнит реконструкцию и монтаж оборудования тракта подачи и моечного комплекса сахарного завода для повышением производительности или технического переоснащения (модернизации).

#### Монтаж и ввод в эксплуатацию моечного комплекса может включать:

- Монтаж свекломойка с ополаскивателями и водоотделителями (Ш1-ПМД, СДК, DRW);

- Монтаж классификатора двухвалкового;

- Установка вертикальных отстойников-сгустителей ВОУ, Ш1-ПОС;

- Установка по очистке транспортерно-моечной воды;

- Монтаж сепаратора воды (барабанный);

- Монтаж водоотделителя ТМА-ВДФ;

- Монтаж улавливателя легких примесей УЛП;

- Монтаж сетчатого транспортера ТМА-КС;

- Монтаж камнеловушки и соломоботволовушки (TSA);

- Монтаж барабанной гравиеловушки ТКА;

- Монтаж разделительно-ленточного конвейера TRB;

- Монтаж фильтра транспортерно-моечных вод SWF;

- Монтаж насосного парка;

- Изготовление и замена оборудования;
`}
};