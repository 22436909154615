import secondImage from '../../images/pages/mold-repair/2.jpg';
import firstImage from '../../images/pages/mold-repair/1.png';
import {PageTemplates} from "../../pages/standartPages/enum";

export const moldRepair = {
        url: '/mold-repair',
        title: 'Ремонт кристаллизатора',
        firstImage,
        secondImage,
        keywords: 'ремонт кристаллизатора,монтаж кристаллизатора,демонтаж кристаллизатора',
        manufacturers: {
            text: 'ТОВ «СІТАМ» має великий досвід ремонту кристалізаторів найрізноманітніших марок і конфігурацій. У списку обладнання, з яким ми працюємо, знаходяться агрегати наступних виробників: Alfa Laval, Bernardini, Andreotti, Westfalia та інших виробників, включаючи вітчизняних.',
        },
        underTitle: `ТОВ «СІТАМ» Ремонтуємо кристалізатори та інше промислове обладнання на професійному рівні. На всі види робіт організація має необхідні дозволи та свідоцтва.`,
        type: PageTemplates.TwoImagesOneTextBigType,
        description: 'Ремонт кристалізаторів маслоекстракційних заводів та інших виробничих ліній, лінії вінтеризації (виморожування). У списку обладнання, з яким ми працюємо, знаходяться агрегати наступних виробників: Alfa Laval, Bernardini, Andreotti, Westfalia та інших виробників, включаючи вітчизняних.',
        text: `Виконаємо ремонт кристалізаторів маслоекстракційних заводів та інших виробничих ліній, наприклад: лінії вінтеризації (виморожування).

Ремонт кристалізатора може включати:

- Натягання горизонтальної анкерної лінії;

- Закріплення, зняття розкачок [отяжок] на висоті;

- Демонтаж мотор-редуктора з лобовиною;

- Демонтаж/монтаж металевих перекриттів і кришок для проємів (кришок кристалізаторів);

- Фіксація центрального вала;

- Очищення поверхні змійовиків;

- Демонтаж змійовиків;

- Монтаж кріплень під змійовики (швеллер);

- Монтаж (зварка) змійовиків;

- Демонтаж і монтаж днища;

- Демонтаж і монтаж труб теплообмінника;

- Постановка високопрочних болтів;

- Розфіксація вала;

- Монтаж мотор-редуктора;

- Очищення внутрішньої поверхні апарата;

- Футерування внутрішньої поверхні апарата нержавіючим листом;

- Гідравлічне випробування трубопроводів, систем опалення, водопроводу та гарячого водопостачання;
`,
    };


