import firstImage from '../../images/pages/beet-processing-department/1.jpg';
import {PageTemplates} from "../../pages/standartPages/enum";

export const beetProcessingDepartment = {
    url: '/beet-processing-department',
    title: 'Монтаж обладнання свеклопереробного відділення',
    firstImage,
    type: PageTemplates.OneImageTwoTextsSmallType,
    description: 'Монтаж обладнання, свеклопереробне відділення, монтаж дифузійних апаратів. Ремонт нахилених дифузійних установок. Встановлення пульполовушок дифузійного соку. Монтаж ошпарювача стружки',
    keywords: 'монтаж обладнання, свеклопереробне відділення, монтаж дифузійних апаратів',
    firstText: {
        text: `Виконаємо монтаж і заміну/демонтаж обладнання свеклопереробного відділення та іншого промислового обладнання на професійному рівні. На всі види робіт організація має необхідні дозволи та свідоцтва.`
    },
    secondText: {
        text: `Наша компанія виконає роботи з монтажу і реконструкції (модернізації) свеклопереробного відділення цукрового заводу.

##### Список можливих робіт:

- Демонтаж і монтаж дифузійних апаратів (КДА, ЕКА, ПДС, DDS, DC, Ж4-ПДБ-3, КД 2-А30, А1-ПД2-С20, DC-8, C-17);

- Ремонт нахилених дифузійних установок;

- Встановлення пульполовушок дифузійного соку і жомувальної води ОВ 63-200;

- Монтаж ошпарювача стружки Р3-ПОД, ОС-20/30М, ПНА-2;

- Встановлення піноочисника (БМА);

- Монтаж тракту подачі свекли;

- Ремонт електрообладнання;
`}};
