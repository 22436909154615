import firstImage from '../../images/pages/installation-and-dismantling-of-car-scales/firstImage.jpg';
import secondImage from '../../images/pages/installation-and-dismantling-of-car-scales/secondImage.jpg';
import {PageTemplates} from "../../pages/standartPages/enum";

export const installationAndDismantlingOfCarScales = {
    url: '/installation-and-dismantling-of-car-scales',
    title: 'Монтаж и демонтаж вагонных весов',
    type: PageTemplates.TwoImagesOneTextBigType,
    keywords: 'монтаж вагонных весов,демонтаж вагонных весов',
    manufacturers: {
        text: 'ООО «СИТАМ» имеет большой опыт монтажа весов самых разных марок и конфигураций. В списке оборудования, с которым мы работаем, числятся агрегаты следующих производителей: Весоизмерительные системы, Техноваги, Булат, Кода, Мика, ЕТС, ТОМ, Сведа, Элва, Укрэстмаркинвест, Метровес, НПП Грамм, СоюзЦветМетАвтоматика, Пульсар, Schenck, НПВ-150т, ВВЕТ-150, ТВВ-150 и других производителей',
    },
    description: 'Ремонтируем промышленные весы и другое весовое оборудование на профессиональном уровне. Предлагаем услуги по установке платформенных тензометрические вагонных весов различной конструкции под ключ',
    underTitle: `ООО «СИТАМ» Ремонтируем промышленные весы и другое весовое оборудование на профессиональном уровне. На все виды работ организация имеет необходимые разрешения и свидетельства.
    Предлагаем услуги по установке платформенных тензометрические вагонных весов различной конструкции под ключ. Так же демонтаж или замену физически и морально устаревшего весового оборудования на вашем предприятии. Выполним все строительные работы или быстро установим на готовом фундаменте, шпалах и т.д`,
    firstImage,
    secondImage,
        text: `### Выполним следующие строительно монтажные работы:

###### Разработка котлована под приямок;
- Обустройство основы под ж/д колею;

###### Монтаж ж/д путей;
- Устройство железобетонных фундаментов;
- Балластировка пути на железо- бетонных шпалах;

- Установку-демонтаж опалубки;
- Обустройство выравнивающей цементной стяжки;
- Засыпка и утрамбовка траншей, котлованов и ям;

- Установка металлоконструкций и закладных деталей;

- Монтаж контура заземления весов;
- Грунтовака и покраска металлических поверхностей;

- Гидроизоляция стен, фундамен- та;

- Погрузка и перевозка мусо- ра;

- Демонтаж рельсовых путей;
- Демонтаж и утилизация бетонного покрытия;

- Демонтаж асфальтного покрытия;
- Демонтаж платформы весов, весовой системы, балок;

- Демонтаж бетонных стенок фундамента;
`};
