import firstImage from '../../images/pages/modernization-of-the-hydraulic-press/1.jpg';
import secondImage from '../../images/pages/modernization-of-the-hydraulic-press/2.jpg';
import {PageTemplates} from "../../pages/standartPages/enum";

export const modernizationOfTheHydraulicPress = {
        url: '/modernization-of-the-hydraulic-press',
        type: PageTemplates.TwoImagesTwoTextsType,
        keywords: 'модернізація преса,реконструкція преса,модернізація гідравлічного преса',
        manufacturers: {
            text: 'ТОВ «СІТАМ» має великий досвід модернізації та ремонту рамних і колонних гідравлічних пресів найрізноманітніших марок і конфігурацій. У списку обладнання, з яким ми працюємо, знаходяться агрегати наступних виробників: LAEIS (Лайс) HPF, ITALPRESSE, Beckwood Press, glomac, П-6332Б, ONA-PRESS, Jelsingrad Smeral, дг2434а, «БЛИСС», «Schloemann», П237, Б6234, П3236, ДО242 та інших виробників, включаючи вітчизняних.',
        },
        title: 'Модернізація гідравлічного преса',
        underTitle: 'ТОВ «СІТАМ» Виконаємо ремонт та модернізацію гідравлічного преса та іншого промислового кузнечно-пресового обладнання на професійному рівні. На всі види робіт організація має необхідні дозволи та свідоцтва',
        firstImage,
        secondImage,
        description: 'Виконаємо роботи з ремонту та модернізації гідравлічного преса. модернізації та ремонту рамних і колонних гідравлічних пресів найрізноманітніших марок і конфігурацій. У списку обладнання, з яким ми працюємо, знаходяться агрегати наступних виробників: LAEIS (Лайс) HPF, ITALPRESSE, Beckwood Press, glomac, П-6332Б, ONA-PRESS, Jelsingrad Smeral, дг2434а, «БЛИСС», «Schloemann», П237, Б6234, П3236, ДО242 та інших виробників, включаючи вітчизняних',
        firstText: {
            text: `##### Модернізація гідравлічної частини:

Діагностика гідравлічної системи;

Заміна насосів (насосних груп);

Заміна існуючої фільтровальної системи;

Заміна гідроапаратури на сучасні клапанні групи;

Установка нових гідророзподільників та манометрів на гідростанцію;

Заміна всіх РТІ на нові;

Модернізація гідроприводу;

Заміна трубопроводів гідросистеми.

##### Модернізація електричної частини (електрообладнання):

Установка ел. двигунів;

Установка системи КІПіА;

Установка комплекту датчиків;

Шафа управління;

Модернізація, установка панелі управління;

Заміна існуючих кінцевих та аварійних вимикачів;

Заміна релеєвих систем управління.
`
        },
        secondText: {
            text: `Наша компанія виконає роботи з модернізації та ремонту гідравлічного преса, встановлених в технологічних лініях різних галузей промисловості (металургійної, машинобудівної, деревообробної, бавовнопрядильної, коксохімічної тощо), що використовуються для:

Виробництва вогнетривів, пластмаси;

Запресування колісних пар;

Листкової штамповки;

Гарячої штамповки (гарячого витягання днищ);

Пакування та брикетування стружки чорних металів;

Виробництва графітових електродів;

Виробництва алюмінієвого профілю;

Шагової формовки труб;

Виробництва ДВП;

Кування, штампування, запресування/випресування виробів з металу та ін.
У результаті модернізації обладнання оснащується сучасною розподільною та силовою апаратурою, а також системами управління, що підвищує його надійність і ремонтопридатність.

Модернізація механічної частини:

Розкладка та зварка тріщин на станині;

Фрезерування робочої поверхні основи;

Ремонт, заміна циліндрів;

Заміна ущільнень циліндрів;

Ремонт штоку циліндрів (шліфування задирів, наплавка виробки);

Відновлення посадочних отворів циліндрів;

Виготовлення нових бронзових втулок;

Відновлення з'єднувальних отворів чавунних траверс;

Заміна рухомої траверси;

Відновлення архітрава;

Ремонт колони;

Термозатяжка гаєк колони;

Установка, ревізія системи змащення;

Заміна гвинтів і гайки преса
`}
    };

