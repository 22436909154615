import firstImage from '../../images/pages/zhomodushilny-complex/1.jpg';
import { PageTemplates } from "../../pages/standartPages/enum";

export const zhomodushilnyComplex = {
    url: '/zhomodushilny-complex',
    title: 'Монтаж оборудования жомосушильного отделения',
    firstImage,
    keywords: 'монтаж оборудования жомосушильного отделения, оборудование жомосушильного отделения, монтаж жомосушек',
    type: PageTemplates.OneImageTwoTextsSmallType,
    maxSize: true,
    firstText: {
        text: `ТОВ «СІТАМ» Виконаємо монтаж та заміну/демонтаж обладнання жомосушильного відділення та іншого промислового обладнання на професійному рівні. На всі види робіт організація має необхідні дозволи та свідоцтва.`,
    },
    description: 'Виконаємо роботи з будівництва та монтажу обладнання жомопресового, жомосушильного та грануляційного відділень цукрового заводу. Монтаж жомосушильного комплексу (жомосушок). Монтаж шнека-розподільника над пресами глибокого віджиму. Монтаж циклона жомосушильного барабана',
    secondText: {
        text: `Наша компанія виконає роботи з будівництва та монтажу обладнання жомопресового, жомосушильного та грануляційного відділень цукрового заводу.

##### Список можливих робіт:

- Виготовлення фундаментів, технологічних площадок;

- Монтаж жомосушильного комплексу (жомосушок);

- Монтаж жомопресів глибокого віджиму Babbini, GH-2 Sangerhausen;

- Монтаж шнека-розподільника над пресами глибокого віджиму;

- Ремонт жомо-віджимних пресів;

- Монтаж жомосушильного барабана, камера вивантаження, топки, норії;

- Монтаж циклона жомосушильного барабана;

- Монтаж охолоджувальної колонки;

- Виготовлення вічка колеса;

- Монтаж грануляторів CPM;

- Монтаж складів гранульованого жому;

- Монтаж транспортерів;

- Виготовлення нових самотеків, бункерів;

- Виготовлення циклонів та камера вивантаження;

- Монтаж схеми пастеризації жомо-віджимної води;

- Заміна теплоізоляції;

- Монтаж силового електрообладнання та системи освітлення жомосушильного та жомогрануляційного відділень;

- Електромонтажні та пуско-налагоджувальні роботи в відділенні пресів глибокого віджиму та жомосушильному відділенні.`,
    },
};
