import secondImage from '../../images/pages/guillotine-press-shears/1.jpg';
import firstImage from '../../images/pages/guillotine-press-shears/2.jpg';
import {PageTemplates} from "../../pages/standartPages/enum";

export const guillotinePressShears = {
    url: '/guillotine-press-shears',
    title: 'Ремонт гильотинных ножниц',
    firstImage,
    secondImage,
    keywords: 'неисправности гильотины,ремонт гильотинных ножниц,ремонт гильотины,замена гильотинных ножниц',
    manufacturers: {
        text: 'ООО «СИТАМ» имеет большой опыт ремонта гильотинных ножниц самых разных марок и конфигураций. В списке оборудования, с которым мы работаем, числятся агрегаты следующих производителей: Akros, Yangli QC12, NC E200 и других производителей (в т.ч. отечественных)',
    },
    underTitle: `ООО «СИТАМ» Ремонтируем гильотины, пресс ножницы и гидравлику на профессиональном уровне. На все виды работ организация имеет необходимые разрешения и свидетельства`,
    description: 'Ремонтируем гильотины, пресс ножницы и гидравлику на профессиональном уровне. В списке оборудования, с которым мы работаем, числятся агрегаты следующих производителей: Akros, Yangli QC12, NC E200 и других производителей (в т.ч. отечественных)',
    type: PageTemplates.TwoImagesOneTextType,
    text: `#### Неисправности гильотинных ножниц:

- Двигатель не запускается;

- Режущая балка не движется;

- Странный шум, производимый насосом;

- Не устанавливается максимальное давление;

- Плохое качество реза.

Неисправности заставляют работать систему менее эффективно, с меньшей производительностью вызывая отказы в работе которые ведут к выходу агрегата из строя, увеличению брака и затратам в связи с остановкой производственного цикла.

##### Ремонт гильотины для металла включает: 

В зависимости от результатов диагностики перечень работ по текущему ремонту гильотинных ножниц может включать ремонт, восстановление или полную замену следующих элементов гильотинных ножниц.

- Замена отдельных узлов или элементов системы;

- Устранение дефектов деталей наплавкой и расточкой;

- Полный демонтаж всех узлов и разборка;

- Чистка, промывка и протирка деталей;

- Обработка стола, станины, механизма реза, и др. базовых деталей;

- Изготовление бронзовых втулок и вкладышей;

- Замена подшипников качения и скольжения;

- Замена системы смазки;

- Изготовление эл.шкафа и разводка электрооборудования;

- Выставка норм точности и зазора между ножами;

- Обкатка ножниц на холостом ходу;

- Проверка работы на заготовке;

- Окраска ножниц;

- Ремонт автоматики;

- Пусконаладка и монтаж гильотинных ножниц.

Ремонт и модернизация позволяет получить агрегат по своим характеристикам полностью соответствующий новому, при затратах не более 35- 40% от стоимости нового оборудования`,
};

