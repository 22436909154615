import firstImage from '../../images/pages/repair-and-installation-of-oil-extraction-plant-equipment/firstImage.png';
import secondImage from '../../images/pages/repair-and-installation-of-oil-extraction-plant-equipment/secondImage.png';
import {PageTemplates} from "../../pages/standartPages/enum";

export const repairAndInstallationOfOilExtractionPlantEquipment = {
    url: '/repair-and-installation-of-oil-extraction-plant-equipment',
    alsoSeeIndex: 3,
    buttons: [{
        text: 'Монтаж линии прессования масла',
        url: '/oil-pressing-line-installation'
    },{
        text: 'Монтаж линии экстракции масла',
        url: '/oil-extraction-line-installation'
    },{
        text: 'Монтаж линии очистки масла',
        url: '/oil-cleaning-line-installation'
    },{
        text: 'Монтаж линии гидратации и нейтрализации масла',
        url: '/installation-of-oil-hydration-and-neutralization-line'
    },{
        text: 'Монтаж линии адсорбции (отбеливания) масла',
        url: '/installation-of-oil-adsorption-bleaching-line'
    },{
        text: 'Монтаж линии винтеризации масла (вымораживания)',
        url: '/installation-of-oil-winterization-freezing-line'
    },{
        text: 'Монтаж линии рафинации и дезодорации масла',
        url: '/installation-of-oil-refining-and-deodorization-line'
    },{
        text: 'Монтаж линии сушки фосфатидов',
        url: '/installation-of-a-phosphatide-drying-line'
    }],
    type: PageTemplates.TwoImagesTwoTextsType,
    keywords: 'реконструкция маслоэкстракционного завода,ремонт завода,ремонт конвейера',
    manufacturers: {
        text: 'ООО «СИТАМ» имеет большой опыт монтажа и демонтажа конвейеров (транспортеров) самых разных марок и конфигураций. В списке оборудования, с которым мы работаем, числятся агрегаты следующих производителей: ТСЦм, TЦС, КСЦ, КЦ-С, ТрЦС, К4-УТФ, КС, ТКНЦ и других производителей в т.ч. отечественных.',
    },
    title: 'Ремонт и монтаж оборудования маслоэкстракционного завода',
    underTitle: 'ООО «СИТАМ» Выполним ремонтные и монтажные работы оборудования маслоэкстракционного завода и другого промышленного оборудования на профессиональном уровне.\n' +
    ' На все виды работ организация имеет необходимые разрешения и свидетельства.',
    firstImage,
    secondImage,
    description: 'Выполним ремонтные и монтажные работы оборудования маслоэкстракционного завода. В списке оборудования, с которым мы работаем, числятся агрегаты следующих производителей: ТСЦм, TЦС, КСЦ, КЦ-С, ТрЦС, К4-УТФ, КС, ТКНЦ и других производителей в т.ч. отечественных',
    firstText: {
        text: `Реконструкция маслоэкстракционного завода:
        
- Строительно-монтажные работы;

- Изготовление и монтаж металлоконструкций;

- Возведении фундаментов под емкости и резервуары;

- Зашивка сэндвич-панелями помещений и стен зданий;

- Демонтаж устаревшего оборудования;

- Монтаж технологических сетей и коммуникаций;

- Реконструкция и строительство зданий и сооружений;

- Реконструкция технологической линии цеха экстракции;

- Реконструкция станции разгрузки автомобилей;

- Изготовление емкостей из нержавеющей стали;

- Изготовление Бункеров;

- Ремонт технологических трубопроводов;

- Ремон норий;

- Ремонт конвейеров;

- Удленнение транспортера;

- Сопровождение пуско-наладочных работ.`
    },
    secondText: {
        text: `Наша компания выполняет все виды общестроительных работ, работ по изготовлению и монтажу связанных со строительством и реконструкцией масло-экстракционных заводов.
        
- Монтаж оборудования маслоэкстракционного завода может включать:

- Монтаж скребковых и ленточных транспортеров (конвейеров);

- Монтаж экстрактора;

- Монтаж тостера;

- Монтаж рекуператора;

- Монтаж конденсатора;

- Монтаж дистиллятора;

- Монтаж центробежных насосов;

- Монтаж технологических трубопроводов (воды, пара, масла, мисцеллы и растворителя);

- Монтаж пневмотранспорта;

- Монтаж вальцовых станков;

- Монтаж жаровни;

- Монтаж испарителя;

- Монтаж сепаратора;

- Монтаж гранулятора;

- Монтаж защитных ограждений;

- Монтаж грузоподъемного оборудования;

- Монтаж самотечного оборудования;

- Монтаж пересыпных устройств;

- Монтаж норий;

- Монтаж силосов;

- Монтаж технологического оборудования лузговой котельной;

- Установка оборудования охладителя гранул;

- Монтаж оборудования подготовительного цеха;

- Монтаж оборудования цеха маслопрессового отделения;

- Монтаж системы аспирации;

- Монтаж вентиляции;

- Электромонтажные работы.
`
}};
