import firstImage from '../../images/pages/repair-of-a-car-dumper/1.jpg';
import secondImage from '../../images/pages/repair-of-a-car-dumper/2.jpg';
import {PageTemplates} from "../../pages/standartPages/enum";

export const repairOfaCarDumper = {
    url: '/repair-of-a-car-dumper',
    type: PageTemplates.TwoImagesTwoTextsType,
    underTitle: 'ООО «СИТАМ» Ремонтируем вагоноопрокидыватели и другое промышленное оборудование на профессиональном уровне. На все виды работ организация имеет необходимые разрешения и свидетельства',
    title: 'Ремонт и обслуживание вентиляторной градирни',
    keywords: 'ремонт вентиляторной градирни,обслуживание вентиляторной градирни,обслуживание вагоноопрокидывателей,ремонт вагоноопрокидывателей',
    firstImage,
    secondImage,
    description: 'Сервис и техническое обслуживание вагоноопрокидывателей и сопутствующего оборудования. Ремонт металлоконструкций оборудования вагоноопрокидывателей. Пусконаладочные работы',
    firstText: {
        text: `##### Сервис и техническое обслуживание вагоноопрокидывателей и сопутствующего оборудования.
Регламент обслуживания вагоноопрокидывателя: 3 года эксплуатации — средний ремонт, 6 лет эксплуатации – капитальный ремонт. Так же стоит обратить внимание на квалифицированную диагностику технического состояния вагоноопрокидывателя. Как показывает опыт о ремонте задумываются при постоянных сбоев или после выхода из строя. Последствия такого отношения бывают достаточно дорогостоящими. Во избежание этого наша компания может предложить следующие сервисные услуги:

##### Технический аудит

- обследование и диагностика технического состояния с выдачей рекомендаций по ремонту или модернизации оборудования;

- приведение оборудования вагоноопрокидывателей, вагонотолкателей, дробильно-фрезерных машин в проектное положение;

- разработка рекомендаций по продлению срока службы вагоноопрокидывателя.

##### Пусконаладочные работы

- ревизия (дефектовка) после монтажа (ремонта), наладка, пуск, регулирование и обкатка механической части оборудования;

- испытания, наладка и регулирование электрической части оборудования;

Модернизацию оборудования разгрузочных комплексов

- модернизацию механической и электрической части вагоноопрокидывателей, дробильно-фрезерных машин, вагонотолкателей;

##### Производство и ремонт

- выполнение капитальных ремонтов оборудования разгрузочного комплекса «под ключ».

- изготовление и поставка запасных частей к вагоноопрокидывателю, вагонотолкателю, дробильно-фрезерной машине, питателю.
`
    },
    secondText: {
        text: `Вагоноопрокидыватели типа ВРС-75, ВРС-93(-110) применяют на горно-обогатительных фабриках, коксохимических заводах, электростанциях, пунктах опрокидывания, металлургических комбинатах, балкерных и угольных терминалах ТЭЦ для повышения производительности разгрузки сыпучих материалов.

Сложный характер нагружения вагоноопрокидывателя, нарушение условий эксплуатации, нештатные ситуации в работе зубчатого сцепления, возможные конструктивные недостатки, ошибки при монтаже часто приводят к разрушению деталей, поломкам и как следствие простоям оборудования.

##### Ремонт металлоконструкций оборудования вагоноопрокидывателей может включать следующее:

- ремонт пластинчатого питателя;

- ремонт стационарного ленточного конвейера;

- ремонт вагоноопрокидывателя и течек в корпусе;

- ремонт вагонотолкателей;

- ремонт дробильно-фрезерных машин (ДФМ);

- ремонт приемных бункеров и над бункерных решеток;

- демонтаж и резка демонтированного вагоноопрокидывателя.

##### Капитальный ремонт вагоноопрокидывателя роторного стационарного может включать:

- Демонтаж и монтаж катковротора с приведение в проектное положение;

- Ревизия и ремонт ротора;

- Демонтаж, ремонт и монтаж вибраторов ротора;

- Замена плит и прокладок рам вибраторов;

- Монтаж, демонтаж и изготовление рассекателей ротора;

- Выставка вагоноопрокидывателя в проектное положение;

- Изготовление, монтаж и усиление металлоконструкций;

- Монтаж и демонтаж роликов;

- Замена роликов цапф в сборе;

- Ремонт роликоопор;

- Восстановление сварочных швов;

- Ремонт привалочных стенок и буферных устройств с заменой осей и пружин;

- Ремонт, замена секций бандажа и их креплений;

- Демонтаж, ремонт и монтаж амортизаторов (платформ, буферов, рам);

- Демонтаж старой и монтаж новой резиновой футеровки с изготовлением креплений;

- Выставления подводящих и отводящих рельсовых путей;

- Замена рельс (лекальных);

- Ревизия и ремонт привода вагоноопрокидывателя;

- Изготовление и монтаж настила, кожухов;

- Регулировка и восстановление проектных зазоров;

- Установка фотодатчиков с подачи вагонов;

- Ремонт тяг платформы с заменой втулок;

- Демонтаж и монтаж восстановленных тяг;

- Замена стаканов;

- Замена вставок;

- Замена осей траверс;

- Замена и установка торцевого упора;

- Замена ограничителей с восстановлением и регулировкой;

- Ремонт опорных балок;

- Замена бонок;

- Ревизия с заменой уплотнений редукторов;

- Восстановление чугунных крышек редукторов;

- Замена тормозов;

- Замена шкивов;

- Замена обойм;

- Замена втулок;

- Замена муфт;

- Ремонт шестерней, замена вала шестерни;

- Ремонт рам и надежное закрепление на фундаментах;

- Смазка оборудования согласно карте смазывания;

- Общая выверка и наладка оборудования. Запуск в работу вагоноопрокидывателя.
`}};



