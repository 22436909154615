import firstImage from '../../images/pages/elevator-assembly-and-replacement/firstImage.png';
import secondImage from '../../images/pages/elevator-assembly-and-replacement/secondImage.png';
import {PageTemplates} from "../../pages/standartPages/enum";

export const elevatorAssemblyAndReplacement = {
        url: '/elevator-assembly-and-replacement',
        title: 'Монтаж / демонтаж норії',
        firstImage,
        secondImage,
        alsoSeeIndex: 10,
        keywords: 'монтаж норії,демонтаж норії,заміна норії',
        manufacturers: {
            text: 'компанія ТОВ «СІТАМ» має великий досвід монтажу норій різних марок і конфігурацій. У списку обладнання, з яким ми працюємо, числяться агрегати таких виробників: Fratelli Pedrotti, Agrex, Mecmar, Metalmont, Frame, Bonfanti, Ravaro, Scolari, Strahl, Petcus, Araj, Chelik, Mepu, Neuero, Riela, Ozsu, ДСП та інших виробників, в тому числі вітчизняних.'
        },
        type: PageTemplates.ThreeTwoImagesTwoTextsType,
        description: 'Виконаємо установку та демонтаж норії стрічкового ковшового елеватора шроту, лінії ЦДМ. Монтаж складових частин норії. Вирізка настилу на технологічних площадках під розміри норії. Монтаж башмака норії',
        firstText: {
            text: `Компанія ТОВ «СІТАМ» Встановимо норії та інше промислове обладнання на професійному рівні. На всі види робіт організація має необхідні дозволи та свідоцтва.

##### Виконаємо установку та демонтаж норії стрічкового ковшового елеватора шроту, лінії ЦДМ і т.д. Виконаємо такі роботи заміни норії:

Монтаж складових частин норії:

Виготовлення та заливка фундаменту;

Пробивка (розширення) бетону в перекриттях у місцях існуючих проходів;

Вирізка настилу на технологічних площадках під розміри норії;

Переустановки та посилення існуючих конструкцій;

Переміщення на місце установки всіх необхідних матеріалів та обладнання;

Скорочення транспортера та зсув рами натяжної станції;

Монтаж башмака норії;

Монтаж проміжних секцій;

Монтаж головки норії;

Монтаж стрічки, ковшів;

Реконструкція та монтаж нової рами (стійки) під привід норії;

Монтаж та виставлення приводу норії на раму (стійку);

Монтаж редуктора та ел. двигуна;

Монтаж муфти;

Врізка повітропроводів аспіраційної системи;

Врізка самотеків;

Реконструкція та монтаж нового завантажувального самотека на фланцевих з'єднаннях від вихідного фланцевого отвору транспортера до вхідного фланцевого отвору норії;

Реконструкція та монтаж нового завантажувального самотека на фланцевих з'єднаннях від вихідного фланцевого отвору норії до вхідного фланцевого отвору транспортера;

Зашиття (облаштування) настилу на технологічних площадках в місцях проходів змонтованої норії;

Монтаж вибуховідвідника до головки норії з виходом на дах;

Порядкування робочого місця;

Облаштування проходу для норії (бетонування);

Пусконалагоджувальні роботи.`
        },
        secondText: {
            text: `#### Демонтаж складових частин норії:

Від'єднання головної частини норії від фундаменту;

Демонтаж фундаменту мотор-редуктора;

Демонтаж анкерних болтів кріплення натяжної станції;

Від'єднання натяжної станції та її виведення;

Демонтаж приводу норії;

Демонтаж та зниження привідного редуктора та електродвигуна;

Роз'єднання напівмуфти редуктора та привідного вала;

Демонтаж напівмуфти з вала, опорних підшипників, привідного вала, зірочок;

Демонтаж повітропроводів аспіраційної системи;

Демонтаж ковшів, стрічки;

Демонтаж головки норії;

Демонтаж секцій шляхом поступового зниження валовища та від'єднання секцій;

Демонтаж короба;

Демонтаж захисного кожуха норії головної частини;

Роз'єднання ланцюга та вийняття з башмака шахти норії;

Демонтаж башмака норії;

Демонтаж рами (стійки) під привід норії;

Демонтаж розвантажувального та завантажувального самотеків;

Демонтаж ложки норії;

Демонтаж ревізійних вибуховідвідних люків;

Демонтаж вибуховідвідника.`
        }
    };
