import secondImage from '../../images/pages/compressor-installation/1.jpg';
import firstImage from '../../images/pages/compressor-installation/2.jpg';
import {PageTemplates} from "../../pages/standartPages/enum";

export const compressorInstallation = {
        url: '/compressor-installation',
        title: 'Монтаж компресора',
        firstImage,
        secondImage,
        keywords: 'монтаж компресора,ремонт винтового,заміна компресора,ремонт промислового обладнання',
        manufacturers: {
            text: 'ТОВ «СІТАМ» має великий досвід монтажу та демонтажу компресорів найрізноманітніших марок і конфігурацій. У списку обладнання, з яким ми працюємо, числяться агрегати наступних виробників: Bock, Bitzer, Copeland, Maneurop, DVK та інших виробників в т.ч. вітчизняних',
        },
        underTitle: `ТОВ «СІТАМ» Виконаємо монтаж компресора та іншого промислового холодильного обладнання на професійному рівні. На всі види робіт організація має необхідні дозволи та свідоцтва`,
        type: PageTemplates.TwoImagesOneTextType,
        description: 'Виконаємо монтаж та заміну (демонтаж) винтового та поршневого компресора та супутнього промислового холодильного обладнання. Обладнання, з яким ми працюємо: Bock, Bitzer, Copeland, Maneurop, DVK та інших виробників в т.ч. вітчизняних',
        text: `Виконаємо монтаж та заміну (демонтаж) винтового та поршневого компресора та супутнього промислового холодильного обладнання.

Етапи установки компресора можуть включати:

Підготовка фундаменту;

Розвантаження та транспортування компресора до місця установки;

Монтаж та закріплення компресора на фундаменті;

Підключення обладнання до комунікацій (електроенергія, стиснений повітря, вода, та каналізація);

Встановлення повітряного фільтра, ресивера, осушувача;

Підключення лінії відведення конденсату;

Контроль якості робочих рідин;

Контроль герметичності пневматичних та гідравлічних контурів;

Контроль кріплення кожухів;

Контроль наявності гострих країв;

Контроль змащення в місцях для її нанесення;

Достатній доступ до обладнання`,
    };
