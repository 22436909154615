import firstImage from '../../images/pages/boiler-room-installation-repair/1.jpeg';
import secondImage from '../../images/pages/boiler-room-installation-repair/2.jpg';
import {PageTemplates} from "../../pages/standartPages/enum";

export const boilerEoomInstallationRepair = {
    url: '/boiler-room-installation-repair',
    title: 'Монтаж и ремонт котельной',
    type: PageTemplates.TwoImagesTwoTextsType,
    keywords: 'ремонт котельной,монтаж котельной',
    manufacturers: {
        text: 'ООО «СИТАМ» имеет большой опыт работы по монтажа различных типов промышленных котельных: газовых, дизельных, электрических или твердотопливных, различных марок и конфигураций (PROTHERM, VAILLANT, BUDERUS, BOSCH, BAXI, KENTATSU FURST, VIESSMANN, NAVIEN, STROPUVA, LOOS, ICI, FERROLI, OILON, WEISHAUPT, SAACKE, BROEN, NAVAL, ADL, NEMEN, VEXVE, GRUNDFOS, WILO, KSB, KRAL, ALLWEILER, SEPARTEC, JURBY WATER TECH, HENGINEERING, НВР, BWT, SWEP, ALFA-LAVAL POTOK, ETSS, UNICAL, CIB UNIGAS, ELCO и др.)',
    },
    underTitle: 'ООО «СИТАМ» Выполним монтаж технологических линий, конвейеров, транспортеров, инженерных и технологических систем, и другого технологического оборудования промышленного производства на профессиональном уровне. На все виды работ организация имеет необходимые разрешения и свидетельства.',
    firstImage,
    secondImage,
    description: 'Монтажа различных типов промышленных котельных: газовых, дизельных, электрических или твердотопливных, различных марок и конфигураций (PROTHERM, VAILLANT, BUDERUS, BOSCH, BAXI, KENTATSU FURST, VIESSMANN, NAVIEN, STROPUVA, LOOS, ICI, FERROLI, OILON, WEISHAUPT, SAACKE, BROEN, NAVAL, ADL, NEMEN, VEXVE, GRUNDFOS, WILO, KSB, KRAL, ALLWEILER, SEPARTEC, JURBY WATER TECH, HENGINEERING, НВР, BWT, SWEP, ALFA-LAVAL POTOK, ETSS, UNICAL, CIB UNIGAS, ELCO и др.)',
    firstText: {
        text: `### Монтаж модульных котельных и стационарных
Монтаж является важным этапом строительства котельной. От его качества зависит работа котельной и успешность пусконаладочных работ котельного оборудования.

Выполним монтаж любых видов котельных: паровых, водяных, комбинированных. На различных промышленных объектах (заводы, производственные помещения, склады, торговые центры, объекты коммунального хозяйства и т.д)

По способу монтажа котельные делятся на:

Стационарного типа:

Отдельностоящие – располагаются в отдельно расположенном здании;

Пристроенные – с непосредственным прилеганием к объекту;

Встроенные – размещаются во внутренних помещениях;

Крышные – монтируются на специально возведённых помостах (основаниях) или кровле зданий.
Блочно-модульные – полностью собранные агрегаты, поставляемые в мобильном контейнере;
`
    },
    secondText: {
        text: `##### Установка промышленной котельной может включать:

Подготовка помещения под установку котельной системы;

Заливка фундамента;

Монтаж конструкции и прокладка инженерных сетей;

Установка и подвод к котлу дымохода;

Монтаж трубопроводов отопления;

Монтаж промышленных котлов (газовых, водогрейных и паровых);

Монтаж промышленных горелок;

Установка насосов;

Монтаж теплообменников;

Установка регуляторов газового давления, газовых фильтров, предохранительной и запорной арматуры;

Монтаж внутренних трубопроводов;

Монтаж электрооборудования, систем автоматизации и диспетчеризации;

Пусконаладочные работы и сдача в эксплуатацию.
Вспомогательное оборудование:

Монтаж деаэраторов (химические, вакуумные, термические, атмосферного давления);

Монтаж баков-аккумуляторов;

Установка бойлеров, водоподогревателей и т.п.
Монтаж должен производиться опытными специалистами, имеющими аттестации, допуски и лицензии для работы с промышленным оборудованием.
После монтажа и пуска в эксплуатацию, мы готовы взять на себе дальнейшее обслуживание промышленной котельной – сервис, технический осмотр и ремонт.
`}};
