import firstImage from '../../images/pages/dismantling-of-machines/1.jpg';
import secondImage from '../../images/pages/dismantling-of-machines/2.jpg';
import {PageTemplates} from "../../pages/standartPages/enum";

export const dismantlingOfMachines = {
    url: '/dismantling-of-machines',
    title: 'Демонтаж станков',
    firstImage,
    secondImage,
    keywords: 'демонтаж станков,замена станков,демонтаж сверлильных станков,замена фрезерных станков',
    type: PageTemplates.TwoImagesTwoTextsBigType,
    underTitle: `ООО «СИТАМ» Выполним демонтаж станков и другого промышленного оборудования на профессиональном уровне. На все виды работ организация имеет необходимые разрешения и свидетельства.`,
    description: 'Демонтаж, перенос и последующий монтаж станков и станочных линий - сверлильный станок, фрезерный станок, сверлильно-фрезерный станок, термопластавтоматы',
    firstText: {
        text: `Выполним работы по демонтажу, переносу и последующему монтажу станков и станочных линий.

Переезд предприятия или перемещение цеха требуют качественно выполнения демонтажных работ для последующего монтажа.

В списке оборудования с которым мы работали числятся:

Сверлильный станок;

Фрезерный станок;

Сверлильно-фрезерный станок;

Трубонарезные и муфтонарезные станки;

Термопластавтоматы;

Лентопильный станок;

Горизонтально-расточной станок;

Токарный станок;

Токарно-винторезный станок;

Токарно-револьверный обрабатывающий центр;

Станок лазерной резки;

Листогибочный станок;

Прокатный стан;

Кромкогиб;

Вертикально-фрезерный обрабатывающий центр;

Расточный станок;

Координатно-расточный станок;

Кривошипный пресс;

Координатно-пробивной штамповочный пресс;

Гидравлические пресса;

Гибочные машины и гильотины;

Шлифовальный станок;

Деревообрабатывающие станки;

и др. металлообрабатывающие и металлорежущие станки.`
    },
    secondText: {
        text: `Работы по демонтажу могут включать:

Демонтаж станков на месте расположения с учетом:несущей способности полов, грузоподъемности кранов, ограниченного пространства, сохранности другого оборудования находящегося поблизости;

Транспортировка комплектующих станков по участку к месту их вывоза с учетом имеющихся ограничений в цеху;

Обесточивание станка;

Расфиксация крепежных болтов с сохранением их цельности;

Слив рабочих жидкостей;

Отключение и демонтаж электрической части оборудования и системы УЧПУ станков;

Демонтаж воздушных и гидравлических контуров станка;

Разборка оборудования на элементы, обеспечивающие возможность перемещения и последующего монтажа станков, с сохранением его функционала и работоспособности.;

Демонтаж измерительных приборов и аппаратуры станков, с сохранением их функциональности;

Демонтаж и отключение всего вспомогательного оборудования станков;

Демонтаж защитных кожухов станка;

Перемещение на новое место установки;
`
}};
