import firstImage from '../../images/pages/crystallization-department/1.jpg';
import {PageTemplates} from "../../pages/standartPages/enum";

export const crystallizationDepartment = {
    url: '/crystallization-department',
    title: 'Монтаж обладнання кристалізаційного відділу',
    firstImage,
    keywords: 'монтаж обладнання кристалізаційного,заміна кристалізаційного відділу,монтаж кристалізаторів',
    type: PageTemplates.OneImageTwoTextsSmallType,
    description: 'Роботи з монтажу обладнання та реконструкції кристалізаційного відділу цукрового заводу. Монтаж вертикальних кристалізаторів Ш1-ПКВ, ТМА-КВ, ТКВ.  Монтаж вакуум-апаратів з циркулятором DSSE, А2-ПВЕ',
    firstText: {
        text: `ТОВ «СІТАМ» Виконає монтаж та заміну/демонтаж обладнання кристалізаційного відділу та іншого промислового обладнання на професійному рівні. На всі види робіт організація має необхідні дозволи та свідоцтва.`
    },
    secondText: {
        text: `Наша компанія виконає роботи з монтажу обладнання та реконструкції кристалізаційного відділу цукрового заводу.

##### Список можливих робіт:

- Монтаж вертикальних кристалізаторів Ш1-ПКВ, ТМА-КВ, ТКВГ;

- Монтаж вакуум-апаратів з циркулятором DSSE, А2-ПВЕ;

- Монтаж змішувача утфель-вода Ш1-ПКВ;

- Монтаж насосних агрегатів перекачування утфеля;

- Реконструкція ділянки випарювання утфеля;
`}};
