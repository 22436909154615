import firstImage from '../../images/pages/bending-press/1.jpg';
import secondImage from '../../images/pages/bending-press/2.jpg';
import {PageTemplates} from "../../pages/standartPages/enum";

export const bendingPress = {
    url: '/bending-press',
    title: 'Ремонт гидравлического листогибочного пресса',
    description: 'Ремонту и техническое обслуживание гидравлического листогибочного пресса (кромкогиб) для гибки листового проката. Работаем с агрегатами следующих производителей: Riko, Yangli, Cormak, DURMD, Ermaksan, ЛГ и других, в т.ч. отечественных',
    type: PageTemplates.TwoImagesTwoTextsBigType,
    keywords: 'ремонт пресса,обслуживание пресса,диагностика пресса,ремонт гидравлического пресса',
    manufacturers: {
        text: 'ООО «СИТАМ» имеет большой опыт ремонта и обслуживания гидравлических кромкогибочных, листогибочных прессов самых разных марок и конфигураций. В списке оборудования, с которым мы работаем, числятся агрегаты следующих производителей: Riko, Yangli, Cormak, DURMD, Ermaksan, ЛГ и других производителей в т.ч. отечественных',
    },
    underTitle: 'ООО «СИТАМ» Выполним ремонт и обслуживание гидравлического листогибочного пресса и другого промышленного прессового оборудования на профессиональном уровне. На все виды работ организация имеет необходимые разрешения и свидетельства.',
    firstImage,
    secondImage,
    firstText: {
        text: `Наша компания выполнит работы по ремонту и техническому обслуживанию гидравлического листогибочного пресса (кромкогиб) для гибки листового проката. Комплекс работ по ремонту листогиба может включать в себя демонтаж и монтаж узлов, восстановительный ремонт и замену определенных узлов и механизмов.

- #### Неисправности листогибочных прессов:

- Неравномерный угол загиба металла;

- Непривычный шум при работе гидронасоса;

- Подтекания масла на соединениях и цилиндре;

- Разрывы сварных швов;

- Деформация конструкции станка;

- Нет давления в системе.

Неисправности заставляют работать систему менее эффективно, с меньшей производительностью вызывая отказы в работе которые ведут к выходу оборудования из строя, увеличению брака и затратам в связи с остановкой производственного цикла.
`
    },
    secondText: {
        text: `#### Текущий и капитальный ремонт гидравлического листогибочного пресса может включать:

- Диагностика и выявление неполадок оборудования;

- Восстановление стола, заднего упора, корпуса;

- Замена винтов крепления;

- Замена направляющих;

- Замена, ремонт цилиндров;

- Замена уплотнений цилиндра;

- Ремонт штоков (наплавка и шлифовка поврежденных мест);

- Замена шлангов гидравлической системы;

- Ремонт, замена насоса;

- Замена фильтров;

- Ремонт системы смазки;

- Чистка фильтрующих элементов;

- Доливка масла до необходимого уровня;

- Замена электрооборудования;

- Ремонт пульта управления.
`}};



