import firstImage from '../../images/pages/modernization-of-the-hydraulic-press/1.jpg';
import secondImage from '../../images/pages/modernization-of-the-hydraulic-press/2.jpg';
import {PageTemplates} from "../../pages/standartPages/enum";

export const modernizationOfTheHydraulicPress = {
    url: '/modernization-of-the-hydraulic-press',
    type: PageTemplates.TwoImagesTwoTextsType,
    keywords: 'модернизация пресса,реконструкция пресс,модернизация гидравлического пресса',
    manufacturers: {
        text: 'ООО «СИТАМ» имеет большой опыт модернизации и ремонта рамных и колонных гидропрессов самых разных марок и конфигураций. В списке оборудования, с которым мы работаем, числятся агрегаты следующих производителей: LAEIS (Лайс) HPF, ITALPRESSE, Beckwood Press, glomac, П-6332Б, ONA-PRESS, Jelsingrad Smeral, дг2434а, «БЛИСС», «Schloemann», П237, Б6234, П3236, ДО242 и других производителей в т.ч. отечественных',
    },
    title: 'Модернизация гидравлического пресса',
    underTitle: 'ООО «СИТАМ» Выполним ремонт и модернизацию пресса гидравлического и другого промышленного кузнечно-прессового оборудования на профессиональном уровне. На все виды работ организация имеет необходимые разрешения и свидетельства',
    firstImage,
    secondImage,
    description: 'Выполним ремонт и модернизацию пресса гидравлического. модернизации и ремонта рамных и колонных гидропрессов самых разных марок и конфигураций. В списке оборудования, с которым мы работаем, числятся агрегаты следующих производителей: LAEIS (Лайс) HPF, ITALPRESSE, Beckwood Press, glomac, П-6332Б, ONA-PRESS, Jelsingrad Smeral, дг2434а, «БЛИСС», «Schloemann», П237, Б6234, П3236, ДО242 и других производителей в т.ч. отечественных',
    firstText: {
        text: `##### Модернизация гидравлической части:

Диагностика гидравлической системы;

Замена насосов (насосных групп);

Замена имеющейся фильтровальной системы;

Замена гидроаппаратуры на современные клапанные группы;

Установка новых гидрораспределителей и манометров на гидростанцию;

Замена всех РТИ на новые;

Модернизация гидропривода;

Замена трубопроводов гидросистемы.

##### Модернизация электрической части (электрооборудования):

Установка эл. двигателей;

Установка системы КИПиА;

Установка комплекта датчиков;

Шкаф управления;

Модернизация, установка панели управления;

Замена существующих конечных и аварийных выключателей;

Замена релейных систем управления.
`
    },
    secondText: {
        text: `Наша компания выполнит работы по модернизации и ремонту гидравлического пресса, установленных в технологических линиях различных отраслях промышленности (металлургической, машиностроительной, деревообрабатывающей, хлопковой, коксохимической и т.д.) использующихся для:

Производства огнеупорных изделий, пластмассы;

Запрессовки колесных пар;

Листовой штамповки;

Горячей штамповки (горячей вытяжки днищ);

Пакетирования и брикетирования стружки черных металлов;

Производства графитовых электродов;

Производства алюминиевого профиля;

Шаговой формовки труб;

Производства ДВП;

Ковки, штамповки, запрессовки/выпрессовки изделий из металла и т.д.
В результате модернизации оборудование оснащается современной распределительной и силовой аппаратурой, а также системами управления, что повышает его надежность и ремонтопригодность.

Модернизация механической части:

Разделка и заварка трещин на станине;

Фрезеровка рабочей поверхности основания;

Ремонт, замена цилиндров;

Замена уплотнений цилиндров;

Ремонт штока цилиндров (шлифовка задиров, наплавка выработки);

Восстановлении посадочных отверстий цилиндров;

Изготовлении новых бронзовых втулок;

Восстановлении сопрягаемых отверстий чугунных траверс;

Заменена подвижной траверсы;

Восстановлен архитрав;

Ремонт колонны;

Термозатяжка гаек колонн;

Установка, ревизия системы смазки;

Замена винтов и гайки пресса
`}};

