import secondImage from '../../images/pages/diffusion-department/1.jpg';
import firstImage from '../../images/pages/diffusion-department/2.jpg';
import {PageTemplates} from "../../pages/standartPages/enum";

export const diffusionDepartment = {
        url: '/diffusion-department',
        title: 'Монтаж обладнання дифузійного відділення',
        firstImage,
        secondImage,
        keywords: 'монтаж обладнання дифузійного,заміна обладнання дифузійного',
        underTitle: `ТОВ "СІТАМ" Виконаємо монтаж і заміну/демонтаж обладнання дифузійного відділення та іншого промислового обладнання на професійному рівні. На всі види робіт організація має необхідні дозволи та свідоцтва.`,
        type: PageTemplates.TwoImagesOneTextType,
        description: 'Монтаж обладнання та реконструкція дифузійного відділення цукрового заводу. Монтаж нахилених дифузійних апаратів (ДС-8, DC12). Монтаж бурякорізок (свеклорізок Glass&Wolf, Putch)',
        text: `Наша компанія виконає монтаж обладнання та реконструкцію дифузійного відділення цукрового заводу.

##### До переліку робіт може входити:

- Монтаж технологічного обладнання та комунікацій;

- Монтаж нахилених дифузійних апаратів (ДС-8, DC12);

- Монтаж бурякорізок (свеклорізок Glass&Wolf, Putch);

- Ремонт підігрівача дифузійного соку, утфельного пару та кристалізації ПДУ;

- Монтаж конденсатного збирника дифузії;

- Обв'язка конденсатних збирників та трубопроводів вакуумних насосів;

- Монтаж обладнання підготовки води для живлення диф. апарату;

- Монтаж висолаживателя колонного типу;

- Монтаж ошпарювача;

- Монтаж підігрівача соку;

- Монтаж лінії подачі стружки та вивантаження жому;

- Монтаж насосного парку;

- Встановлення теплообмінного обладнання для нагріву води жомопресної;

- Встановлення ресиверів та обв'язка трубопроводами;

- Монтаж транспортного обладнання;

- Виготовлення та монтаж циклонів;

- Монтаж бункера під ПКФ.`,
    };


