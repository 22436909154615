import secondImage from '../../images/pages/assembly-of-the-conveyor/firstImage.jpg';
import firstImage from '../../images/pages/assembly-of-the-conveyor/secondImage.jpg';
import {PageTemplates} from "../../pages/standartPages/enum";

export const assemblyOfTheConveyor = {
    url: '/assembly-of-the-conveyor',
    title: 'Монтаж і заміна конвейера (транспортера)',
    type: PageTemplates.ThreeTwoImagesTwoTextsType,
    keywords: 'монтаж конвейера,заміна конвейера,монтаж транспортера,заміна транспортера',
    firstImage,
    secondImage,
    description: 'Монтаж і заміна/демонтаж конвейерів (транспортерів) та іншого промислового обладнання на професійному рівні. Роботи по монтажу і заміні/демонтажу ланцюгових скребкових і шнекових (шнекових) конвейерів (транспортерів) призначених для переміщення насипних і насипних вантажів горизонтально і під кутом (гусиний шиї)',
    firstText: {
        text: `Виконаємо монтаж і заміну/демонтаж конвейерів (транспортерів) та іншого промислового обладнання на професійному рівні. На всі види робіт організація має необхідні дозволи та свідоцтва.
        
Наша компанія виконує роботи з монтажу і заміни/демонтажу ланцюгових скребкових і шнекових (шнекових) конвейерів (транспортерів) призначених для переміщення насипних і насипних вантажів горизонтально і під кутом (гусиний шиї). Залежно від технології конвейер може знаходитися на різних висотних відмітках.

Використовуються в основному для транспортування зерна з ями, силоса, подачі лушпиння, шроту, комбікормів, насіння соняшника тощо на елеваторах, зерносховищах, олійно-екстракційних заводах (ЦДМ) так само застосовуються в хімічній, гірничодобувній, будівельній, металургійній промисловості та інших галузях.
        `
    },
    secondText: {
        text: `Під час заміни скребкових конвейерів виконуються наступні роботи:

#### Монтаж

- Виготовлення і установка площадки;

- Монтаж прохідних секцій;

- Монтаж натяжної станції з установкою ланцюга;

- Установка захисних кришок;

- Монтаж приводу конвейера (мотор-редуктора);

- Виготовлення і врізка самотечів;

- Виготовлення і установка опори конвейера;

- Регулювання натягу ланцюга;

- Футерування поуліретаном армованим;

- Виготовлення і монтаж нового укриття каналів конвейера з організацією стоку води;

- Грунтування і фарбування;

- Перевірка положення привідного вала;

- Зміщення привідної і ведучої зірочки тягового ланцюга;

- Перевірка збірки на холостому ходу;

Прибирання робочого місця.

#### Демонтаж

- Демонтаж коробів;

- Демонтаж прохідних секцій;

- Демонтаж приводу (ел. двигун, редуктор);

- Демонтаж самотечного обладнання;

- Демонтаж ланцюга конвейера;

- Демонтаж привідної групи.`
    }};