import firstImage from '../../images/pages/process-pipeline-repair/2.jpg';
import secondImage from '../../images/pages/process-pipeline-repair/1.jpg';
import {PageTemplates} from "../../pages/standartPages/enum";

export const processPipelineRepair = {
    url: '/process-pipeline-repair',
    type: PageTemplates.TwoImagesTwoTextsBigType,
    keywords: 'ремонт технологических трубопроводов,ремонт трубопроводов',
    manufacturers: {
        text: 'ООО «СИТАМ» имеет большой опыт ремонта и замены трубопровода самых разных марок и конфигураций. В списке оборудования, с которым мы работаем, числятся агрегаты следующих производителей: Е, КСВа (ЭКО), ВК, КВГ, ТВГ, КСВ и других производителей в т.ч. отечественных.',
    },
    title: 'Ремонт технологического трубопровода',
    underTitle: 'ООО «СИТАМ»  Выполним ремонт и монтаж технологического трубопровода и другого промышленного оборудования на профессиональном уровне. На все виды работ организация имеет необходимые разрешения и свидетельства.',
    firstImage,
    secondImage,
    description: 'Выполним ремонт и реконструкцию технологических трубопроводов из нержавеющей стали и арматуры для предприятий пищевой, химической, фармацевтической промышленности, нефтебаз, пивзаводов, молокозаводов, маслоэкстракционных заводов. В списке оборудования, с которым мы работаем, числятся агрегаты следующих производителей: Е, КСВа (ЭКО), ВК, КВГ, ТВГ, КСВ и других производителей в т.ч. отечественных.',
    firstText: {
        text: `Наша компания выполнит ремонт и реконструкцию технологических трубопроводов из нержавеющей стали и арматуры для предприятий пищевой, химической, фармацевтической промышленности, нефтебаз, пивзаводов, молокозаводов, маслоэкстракционных заводов и др. В работе пользуемся классическим траншейным или бестраншейными методами.

### Произведем ремонт технологических трубопроводов:

- Трубопровод водоснабжения;

- Трубопровод сжатого воздуха;

- Противопожарный водопровод;

- Трубопровод подачи пара ;

- Трубопровод горячего водоснабжения;

- Трубопровод конденсата;

- Трубопровод наружных сетей теплоснабжения (систем отопления);

- Газопровод;

- Нефтепроводов;

- Трубопровод сброса очищенных сточных вод;

- Трубопроводов с рубашкой подогрева;

- Трубопровод холодоснабжения;

- Трубопроводов наружных канализационных сетей;

- Трубопроводов амиака;

- Кислотопровод;

- Трубопровод химреагентов;

- Трубопровода жидких удобрений;

- Трубопровод преддефекации;

- Трубопровод сока;

- Вакуумный трубопровод.
`
    },
    secondText: {
        text: `### Ремонт и реконструкция технологического трубопровода может включать:

- Раскопка трубопровода;

- Вырезка технологического отверстия;

- Гидродинамическая очистка участка;

- Обследование участка;

- Выявление повреждений (места прорыва);

- Частичная замена трубопровода;

- Изготовление узлов трубопроводов межцеховых на эстакадах, кронштейнах и других спецконструкциях с фланцами и сварными стыками;

- Врезка трубопровода в действующие магистрали;

- Заварка свищей в корпусах;

- Контроль качества сварных швов;

- Очистка металлических поверхностей и обезжиривание;

- Грунтовка и покраска металлических поверхностей;

- Ревизия и ремонт вентилей стальных, задвижек, кранов, клапанов обратных и предохранительных, регуляторов давления чугунных и стальных фланцевых;

- Шабровка плоских поверхностей фланцев задвижек;

- Высверливание и удаление сломанных болтов, винтов и шпилек;

- Обратная засыпка траншеи.
`}};


