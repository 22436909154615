import firstImage from '../../images/pages/installation-of-lifting-equipment/1.jpg';
import secondImage from '../../images/pages/installation-of-lifting-equipment/2.jpg';
import {PageTemplates} from "../../pages/standartPages/enum";

export const installationOfLiftingEquipment = {
    url: '/installation-of-lifting-equipment',
    title: 'Монтаж козлового крана',
    type: PageTemplates.TwoImagesTwoTextsBigType,
    keywords: 'монтаж козлового крана,демонтаж крана,демонтаж козлового крана,монтаж двухбалочного крана',
    manufacturers: {
        text: 'ООО «СИТАМ» имеет большой опыт монтажа козловых кранов самых разных марок и конфигураций. В списке оборудования, с которым мы работаем, числятся агрегаты следующих производителей: КК, ККС, ККТ, КДКК и других производителей в т.ч. отечественных.',
    },
    description: 'Выполним монтаж и демонтаж козлового крана однобалочного, двухбалочного, ферменного. Монтажа козловых кранов: комплексный (установка подкрановых путей и сборка технического средства для подъема/перемещения грузов), частичный (без установки подкрановых путей).',
    underTitle: 'ООО «СИТАМ» Выполним монтаж козлового электрического крана и другого грузоподъемного промышленного оборудования на профессиональном уровне. На все виды работ организация имеет необходимые разрешения и свидетельства.',
    firstImage,
    secondImage,
    firstText: {
        text: `Наша компания выполнит монтаж и демонтаж козлового крана однобалочного, двухбалочного, ферменного.

Виды монтажа козловых кранов: Комплексный (установка подкрановых путей и сборка технического средства для подъема/перемещения грузов). Частичный (без установки подкрановых путей).

Цена монтажа козлового электрического крана зависит от его грузоподъемности, длины пролета, высоты подъема, типа (однобалочный, двухбалочный, ферменный), исполнения (общепромышленный, магнитный, грейферный) и места проведения работ.`
    },
    secondText: {
        text: `#### Монтаж козлового крана включает:

- Монтаж пролетной балки крана, опор и ходовых тележек;

- Подъем крана на подкрановых путях;

- Монтаж грузовой тележки (тельфера);

- Монтаж грузового барабана;

- Монтаж электродвигателей подъема и перемещения;

- Монтаж редукторов;

- Монтаж тормозов;

- Монтаж кабины крановщика;

- Электромонтаж крановой кабины и грузовой тележки;

- Монтаж токоподвода;

- Монтаж узлов электропроводов;

- Монтаж электрооборудования (крановых панелей, блоков резисторов);

- Монтаж системы управления;

- Пусконаладочные работы.`}};




