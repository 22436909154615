import firstImage from '../../images/pages/waste-paper-press/1.jpg';
import secondImage from '../../images/pages/waste-paper-press/2.jpg';
import { PageTemplates } from "../../pages/standartPages/enum";

export const wastePaperPress = {
    url: '/remont-pressa-dlya-makulatury-i-vtorisr\'ya',
    type: PageTemplates.TwoImagesTwoTextsType,
    manufacturers: {
        text: 'ТОВ «СІТАМ» має великий досвід ремонту та обслуговування вертикальних та горизонтальних пресів для макулатури та іншого вторинного сировини найрізноманітніших марок і конфігурацій. В списку обладнання, з яким ми працюємо, числяться агрегати таких виробників: STALCO HLP, Presona LP, Bollegraf, Paal Pacomat, Lindeman, MBT Mashinenhandel, Strautmann BalePress, Riko RTV, ПГП, А-125(129,127,121), БА3121, МГП-3А, БГМП-40, НРН-40, fd-30, fd-50, HSM, HPA80, ПГМТ-50, MKP та інші виробники, включаючи вітчизняних.',
    },
    keywords: 'ремонт преса, ремонт преса для макулатури, прес для макулатури, ремонт преса для вторинної сировини, ремонт гідравлічного преса',
    title: 'Ремонт преса для макулатури та вторинної сировини',
    underTitle: 'ТОВ «СІТАМ» Виконаємо ремонт та обслуговування гідравлічного преса для макулатури та вторинної сировини та іншого промислового пресового обладнання на професійному рівні. На всі види робіт організація має необхідні дозволи та свідоцтва.',
    firstImage,
    secondImage,
    description: 'Виконаємо ремонт та обслуговування вертикальних та горизонтальних пресів для макулатури та іншого вторинного сировини найрізноманітніших марок і конфігурацій. В списку обладнання, з яким ми працюємо, числяться агрегати таких виробників: STALCO HLP, Presona LP, Bollegraf, Paal Pacomat, Lindeman, MBT Mashinenhandel, Strautmann BalePress, Riko RTV, ПГП, А-125(129,127,121), БА3121, МГП-3А, БГМП-40, НРН-40, fd-30, fd-50, HSM, HPA80, ПГМТ-50, MKP та інші виробники, включаючи вітчизняних.',
    firstText: {
        text: `##### Гідравлічна частина:

- Ревізія гідравлічних систем та систем змазки обладнання;

- Заміна/ремонт маслостанції;

- Заміна розподільних блоків;

- Заміна розподільного клапана;

- Заміна, ремонт насоса;

- Заміна гідравлічного шланга (РВД);

- Заміна датчиків тиску;

- Заміна всіх РТІ преса;

Електрична частина

- Заміна електродвигуна;

- Заміна кінцевих вимикачів;

- Заміна запобіжника (аварійного вимикача);

- Заміна ламп і кнопок;

- Заміна панелі управління;

- Пусконалагодження преса.
`,
    },
    secondText: {
        text: `Наша компанія виконає роботи з ремонту вертикального та горизонтального гідравлічного преса для макулатури, картону, ПЕТ-пляшок, пластику, поліетиленової плівки, жердин та алюмінієвих банок, відходів (ТПВ) та іншого вторинного сировини. Комплекс робіт з ремонту пакетуючих пресів може включати в себе демонтаж та монтаж вузлів, відновлювальний ремонт та заміну певних вузлів та механізмів.

##### Несправності макулатурного преса:

- Перекос плити;

- Під час пресування кришка самопроизвольно піднімається;

- Витік гідравлічної оливи;

- Не опускається бегунець, заклинювання;

- Підвищений шум під час роботи гідростанції;

- Відсутність автоматичного змащування;

- Протікання на з'єднаннях;

- Немає тиску в системі.

Несправності змушують працювати систему менше ефективно, з меншою продуктивністю, викликаючи відмови в роботі, які призводять до виходу обладнання з ладу, збільшенню відходів та витрат через зупинку виробничого циклу.

##### Поточний та капітальний ремонт пакетуючого преса для макулатури може включати:

- Діагностика та складання дефектної відомості

##### Механічна частина:

- Заміна бічних накладок;

- Розпилення та зварювання тріщин на корпусі;

- Заміна/ремонт прес-плити;

- Заміна/ремонт стабілізатора прес-плити;

- Заміна болтів кріплення;

- Ремонт, заміна гідроциліндрів;

- Заміна ущільнень;

- Заміна опор циліндрів;

- Заміна/ремонт верхньої, нижньої дверей;

- Заміна/ремонт тележки для видалення тюків;

- Заміна/ремонт верхньої захисної кришки;

- Заміна/ремонт виштовхувача (основи, ніжки, засува);

- `,
    },
};
