import firstImage from '../../images/pages/norii-repair/1.png';
import secondImage from '../../images/pages/norii-repair/2.png';
import thirdImage from '../../images/pages/norii-repair/3.png';
import {PageTemplates} from "../../pages/standartPages/enum";

export const noriiRepair = {
    url: '/norii-repair',
    title: 'Ремонт и техническое обслуживание нории',
    firstImage,
    secondImage,
    thirdImage,
    keywords: 'ремонт нории,техническое обслуживание нории,демонтаж нории,установка нории',
    manufacturers: {
        text: 'ООО «СИТАМ» имеет большой опыт монтажа норий самых разных марок и конфигураций. В списке оборудования, с которым мы работаем, числятся агрегаты следующих производителей:Fratelli Pedrotti, Agrex, Mecmar, Metalmont, Frame, Bonfanti, Ravaro, Scolari, Strahl, Petcus, Araj, Chelik, Mepu, Neuero, Riela, Ozsu, ДСП и др. и других производителей в т.ч. отечественных.',
    },
    underTitle: 'ООО «СИТАМ» Установим нории и другое промышленное оборудование на профессиональном уровне. На все виды работ организация имеет необходимые разрешения и свидетельства.',
    type: PageTemplates.ThreeTwoImagesTwoTextsType,
    description: 'Монтаж норий самых разных марок и конфигураций. В списке оборудования, с которым мы работаем, числятся агрегаты следующих производителей: Fratelli Pedrotti, Agrex, Mecmar, Metalmont, Frame, Bonfanti, Ravaro, Scolari, Strahl, Petcus, Araj, Chelik, Mepu, Neuero, Riela, Ozsu, ДСП и др. и других производителей в т.ч. отечественных.',
    firstText: {
        text: `Непрерывная работа при высоких нагрузках приводит к значительному износу, снижению работоспособности и сбоям в работе оборудования нории.

##### В процессе эксплуатации могут выявляются следующие неисправности норий:

- Шум, искры, снижение производительности;

- Буксования или соскакиванием приводного ремня;

- Остановка ленты;

- Износ и деформация деталей (кожухов, башмака, труб, шеек валов, подшипников, пальцев ,обкладок и др.);

- Появление трещин в спицах барабанов;

- Смешение и износ редукторов, шестерен;

- Изгиб регулировочных винтов натяжного механизма;

- Чрезмерное натяжение ленты, повреждение кромок и их износ;

- Отсутствие значительного количества ковшей в одном месте ленты;

- Деформация ковшей и разрыв стыков.
`
    },
    secondText: {
        text: `##### Ремонт ковшевой нории включает:

- Очистка редуктора, сапуна;

- Диагностика деталей, узлов нории, редуктора, самотека;

- Диагностика подшипников редуктора;

- Ремонт редуктора с креплением и станиной. Устранение утечки масла;

- Ремонт редуктора с креплением и станиной;

- Проверка посадочного места под редуктор и шпоночного соединения;

- Проверка шпоночных соединений полумуфт и муфты обратного хода норийной ленты;

- Замена эластичных вставок тихоходной и быстроходной полумуфт;

- Ремонт защиты муфт;

- Ремонт тормозного механизма;

- Замена масла в редукторе, смазка подшипниковых узлов нории;

- Ремонт приводной станции (головка);

- Замена футеровка приводного барабана;

- Ремонт натяжной станции (башмак);

- Ремонт отжимного ролика (над башмаком);

- Ремонт проходной станции с норийной лентой;

- Замена ковшей;

- Демонтаж/монтаж верхней крышки приводного барабана;

- Демонтаж/монтаж ревизионных крышек шахты нории;

- Демонтаж/монтаж нижних крышек монтажных отверстий;

- Замена подшипников верхнего барабана;

- Замена норийной ленты;

- Прогонка резьбы норийных метиз (болт, гайка);

- Ремонт самотека схода товара с нории;

- Ремонт шиберных заслонок;

- Сварочные работы (латание потертостей, замена уголков усиления и т.д.);

- Пусконаладочные работы (Регулировка натяжения и центровка ленты и т.д.);

- Проверка работы краткосрочным пуском;

- Вывоз б/у частей нории к месту хранения / утилизации;

- Уборка рабочего места.

Демонтаж норий и разборку выполняют с целью диагностики неисправностей и ремонта узлов в различной степени в зависимости от вида ремонта. Полную разборку производят при капитальном ремонте оборудования.

### Техническое обслуживание норий

Чтобы уберечь элеваторное оборудование от неисправностей, выхода из строя и затрат на капитальный ремонт, необходимо выполнять периодическое тех. обслуживание нории.

##### К нему относится:

- Внешний осмотр;

- Чистка барабана, башмака и труб;

- Контроль зануления и заземления электрооборудования;

- Проверка натяжения ленты;

- Контроль смещения опорной конструкции;

- Проверка приводов, электродвигателя, редуктор, муфты, тормозного устройства;

- Замена пружин, подшипников и прокладок и масла;

- Устранение трения деталей и искрения;

- Проверка наличия всех включателей и смазки на подшипниках;

- Проверка степени затяжки норийных болтов;

- Проверка предохранителей, датчиков, систем контроля.

- Периодический осмотр необходим, даже если поломок не было. Даже не большой зазор между лентой и трубой способен вызвать наматывание и сбой в работе.
`
}};
