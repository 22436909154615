import firstImage from '../../images/pages/installation-of-transformers-and-power-equipment/1.jpg';
import secondImage from '../../images/pages/installation-of-transformers-and-power-equipment/2.jpg';
import {PageTemplates} from "../../pages/standartPages/enum";

export const installationOfTransformersAndPowerEquipment = {
        url: '/installation-of-transformers-and-power-equipment',
        type: PageTemplates.TwoImagesTwoTextsBigType,
        title: 'Встановлення потужних трансформаторів',
        firstImage,
        secondImage,
        keywords: 'монтаж,олійних,потужних,трансформаторів,підстанцій,маслоприймач',
        manufacturers: {
            text: 'ТОВ «СІТАМ» має великий досвід монтажу масляних потужних трансформаторів різних конфігурацій і напруги.',
        },
        description: 'Виконаємо монтаж потужних трансформаторів, трансформаторних підстанцій. Встановлення трансформаторів 35, 110, 220 кВ, потужністю понад 10000 кВ і іншої напруги',
        underTitle: 'ТОВ «СІТАМ» Виконаємо монтаж потужних трансформаторів, трансформаторних підстанцій і іншого електрообладнання на професійному рівні. На всі види робіт організація має необхідні дозволи і свідоцтва.',
        firstText: {
            text: `Встановлення трансформаторів 35, 110, 220 кВ, потужністю понад 10000 кВ і іншої напруги повинно здійснюватися з повним дотриманням діючих вимог щодо монтажу.

##### Олійні потужні трансформатори транспортуються виробником висушеними і в залежності від розмірів і маси у наступному стані:

- Повністю зібрані і залиті маслом;

- Частково розібрані і законсервовані, залиті маслом нижче кришки з заповненням інертним газом або сухим повітрям;

Частково розібрані в власному баку без масла, заповнені азотом з автоматичним живленням.
`
        },
        secondText: {
            text: `##### Порядок монтажу потужного трансформатора включає:

- Встановлення фундаменту та маслоприймача;

- Підготовка місця монтажу для проведення перевірок, прогрівання і збирання обладнання;

- Підготовка підйомного обладнання для монтажу;

- Транспортування потужних трансформаторів до місця установки;

- Монтаж комплектуючих вузлів (система охолодження, вбудовані ТТ, фільтри, газові реле, реле рівня масла, розширювач, вихлопна труба та ін.);

- Підготовка трансформаторної оливи, системи заливки, баків, індикаторного силікагелю.

- Випробування потужного трансформатора;

- Запуск потужних трансформаторів в експлуатацію.

Наявність матеріально-технічної бази і атестованих спеціалістів з багаторічним досвідом дозволяє нам професійно здійснювати встановлення і демонтаж потужних трансформаторів будь-якої потужності.
`}
    };

