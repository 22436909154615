import firstImage from '../../images/pages/waste-paper-press/1.jpg';
import secondImage from '../../images/pages/waste-paper-press/2.jpg';
import {PageTemplates} from "../../pages/standartPages/enum";

export const wastePaperPress = {
    url: '/waste-paper-press',
    type: PageTemplates.TwoImagesTwoTextsType,
    manufacturers: {
        text: 'ООО «СИТАМ» имеет большой опыт ремонта и обслуживания вертикальных и горизонтальных прессов для макулатуры и другого вторсырья самых разных марок и конфигураций. В списке оборудования, с которым мы работаем, числятся агрегаты следующих производителей: STALCO HLP, Presona LP, Bollegraf, Paal Pacomat, Lindeman, MBT Mashinenhandel, Strautmann BalePress, Riko RTV, ПГП, А-125(129,127,121), БА3121, МГП-3А, БГМП-40, НРН-40, fd-30, fd-50, HSM, HPA80, ПГМТ-50, MKP и других производителей в т.ч. отечественных',
    },
    keywords: 'ремонт пресса,ремонт пресса для макулатуры,пресс для макулатуры,ремонт пресса для вторсырья,ремонт гидравлического пресса',
    title: 'Ремонт пресса для макулатуры и вторсырья',
    underTitle: 'ООО «СИТАМ» Выполним ремонт и обслуживание гидравлического пресса для макулатуры и вторсырья и другого промышленного прессового оборудования на профессиональном уровне. На все виды работ организация имеет необходимые разрешения и свидетельства',
    firstImage,
    secondImage,
    description: 'Выполним ремонт и обслуживание вертикальных и горизонтальных прессов для макулатуры и другого вторсырья самых разных марок и конфигураций. В списке оборудования, с которым мы работаем, числятся агрегаты следующих производителей: STALCO HLP, Presona LP, Bollegraf, Paal Pacomat, Lindeman, MBT Mashinenhandel, Strautmann BalePress, Riko RTV, ПГП, А-125(129,127,121), БА3121, МГП-3А, БГМП-40, НРН-40, fd-30, fd-50, HSM, HPA80, ПГМТ-50, MKP и других производителей в т.ч. отечественных',
    firstText: {
        text: `##### Гидравлическая часть:

- Ревизия гидравлических систем и систем смазки оборудования;

- Замена/ремонт маслостанции;

- Замена распределительных блоков;

- Замена распределительного клапана;

- Замена, ремонт насоса;

- Замена гидравлического шланга (РВД);

- Замена датчиков давления;

- Замена всех РТИ пресса;

Электрическая часть

- Замена электродвигателя;

- Замена концевых выключателей;

- Замена предохранителя (аварийного выключателя);

- Замена ламп и кнопок;

- Замена панели управления;

- Пусконаладка пресса.
`
    },
    secondText: {
        text: `Наша компания выполнит работы по ремонту вертикального и горизонтального гидравлического пресса для для макулатуры, картона, пэт бутылок, пластика, полиэтиленовой пленки, жестяных и алюминиевых банок, отходов (ТБО) и другого вторсырья. Комплекс работ по ремонту пакетировочных прессов может включать в себя демонтаж и монтаж узлов, восстановительный ремонт и замену определенных узлов и механизмов.

##### Неисправности макулатурного пресса:

- Перекос плиты;

- Во время прессования крышка самопроизвольно поднимается;

- Течь гидравлического масла;

- Не опускается ползун, заклинивание;

- Повышенный шум при работе гидростанции;

- Отсутствие автоматической смазки;

- Подтекания на соединениях;

- Нет давления в системе.

Неисправности заставляют работать систему менее эффективно, с меньшей производительностью вызывая отказы в работе которые ведут к выходу оборудования из строя, увеличению брака и затратам в связи с остановкой производственного цикла.

##### Текущий и капитальный ремонт пакетировочного пресса для макулатуры может включать:

- Диагностика и составление дефектной ведомости

##### Механическая часть:

- Замена боковых накладок;

- Разделка и заварка трещин на корпусе;

- Замена/ремонт пресс-плиты;

- Замена/ремонт стабилизатора пресс-плиты;

- Замена болтов крепления;

- Ремонт, замена гидроцилиндров;

- Замена уплотнений;

- Замена опор цилиндров;

- Замена/ремонт верхней, нижней двери;

- Замена/ремонт тележки для извлечения тюков;

- Замена/ремонт верхней защитной крышки;

- Замена/ремонт выталкивателя (основы, ножки, засова);

- `}};


