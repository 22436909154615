import secondImage from '../../images/pages/repair-of-a-baler-for-scrap-metal/1.jpg';
import firstImage from '../../images/pages/repair-of-a-baler-for-scrap-metal/2.jpg';
import {PageTemplates} from "../../pages/standartPages/enum";

export const repairOfABalerForScrapMetal = {
    url: '/repair-of-a-baler-for-scrap-metal',
    type: PageTemplates.TwoImagesTwoTextsType,
    manufacturers: {
        text: 'ТОВ «СІТАМ» має великий досвід ремонту та обслуговування пакетувальних пресів для металобрухту найрізноманітніших марок і конфігурацій. У списку обладнання, з яким ми працюємо, знаходяться агрегати таких виробників: Riko, Y83, Y81, ПГПМ-100, Huahong, IMABE, ENERPAT SMB, Becker, Bramidan та інших виробників, включаючи вітчизняних',
    },
    keywords: 'ремонт пакетувального пресу,ремонт пресу,пакетувальний прес',
    title: 'Ремонт пакетувального пресу для металобрухту',
    underTitle: 'ТОВ «СІТАМ» Виконаємо ремонт і обслуговування гідравлічного пакетувального пресу для металобрухту та іншого промислового пресового обладнання на професійному рівні. На всі види робіт організація має необхідні дозволи та свідоцтва',
    firstImage,
    secondImage,
    description: 'Виконаємо ремонт і обслуговування гідравлічного пакетувального пресу для металобрухту. Заміна/ремонт маслостанції. Заміна розподільного клапана. Заміна гідравлічного шланга (РВД)',
    firstText: {
        text: `#### Гідравлічна частина

- Ревізія гідравлічних систем та систем змазки обладнання;

- Заміна/ремонт маслостанції;

- Заміна розподільних блоків;

- Заміна розподільного клапана;

- Заміна, ремонт насоса;

- Заміна гідравлічного шланга (РВД);

- Заміна датчиків тиску;

- Заміна всіх РТІ преса;

#### Електрична частина

- Заміна електродвигуна;

- Заміна кінцевих вимикачів;

- Заміна запобіжника (аварійного вимикача);

- Заміна ламп і кнопок;

- Заміна панелі керування;

- Пусконаладка преса.
`
    },
    secondText: {
        text: `Наша компанія виконає роботи з обслуговування та ремонту гідравлічного пресу для металобрухту, який використовується для пакування брухту чорних і кольорових металів, металевої стружки, автомобільного брухту на металургійних підприємствах, брухтопереробних заводах, сталеплавильній промисловості та ін. Комплекс робіт з ремонту пакетувальних пресів для металобрухту може включати в себе демонтаж і монтаж вузлів, відновлювальний ремонт і заміну певних вузлів і механізмів.

- #### Несправності пакетувального пресу:

- Плита не рухається;

- Витік гідравлічної рідини;

- Підвищений шум при роботі гідростанції;

- Відсутність автоматичного змащування;

- Витікання на з'єднаннях;

- Немає тиску в системі.

Несправності змушують працювати систему менш ефективно, з меншою продуктивністю, спричиняють відмови в роботі, які призводять до виходу обладнання з ладу, збільшення відходів та витрат в зв'язку з зупинкою виробничого циклу.

Поточний і капітальний ремонт пакетувального пресу для металобрухту може включати:

Діагностика і складання дефектної відомості

#### Механічна частина

- Заміна броньованих плит;

- Розділка і зварка тріщин на корпусі;

- Заміна/ремонт прес-плити;

- Заміна болтів кріплення;

- Ремонт, заміна гідроциліндрів;

- Заміна ущільнень;

- Заміна, ремонт направляючих;

- Заміна бронзових втулок;

`}};