import firstImage from '../../images/pages/elevator-repair/1.jpg';
import secondImage from '../../images/pages/elevator-repair/2.jpeg';
import {PageTemplates} from "../../pages/standartPages/enum";

export const elevatorRepair = {
        url: '/elevator-repair',
        title: 'Ремонт елеваторів',
        type: PageTemplates.TwoImagesTwoTextsType,
        keywords: 'ремонт елеватора,ремонт силосу',
        manufacturers: {
            text: 'ТОВ «СІТАМ» має великий досвід установки силосів та бункерів для зерна, борошна, цементу, піску тощо різних типів (конусні, з плоским дном), обсягу, габаритів та комплектуючих.',
        },
        underTitle: 'ТОВ «СІТАМ» Виконаємо ремонт та сервісне обслуговування елеваторів, силосів, бункерів та інших металевих конструкцій різних видів на професійному рівні. На всі види робіт організація має необхідні дозволи та свідоцтва.',
        firstImage,
        secondImage,
        description: 'Встановлення силосів та бункерів для зерна, борошна, цементу, піску тощо різних типів (конусні, з плоским дном), обсягу, габаритів та комплектуючих. Виконаємо ремонт елеватора відповідно до всіх технологічних та конструктивних особливостей споруди',
        firstText: {
            text: `### Обслуговування елеваторів
Конструкція сховища виконується з металу або залізобетону, в ході активної експлуатації порушується їх цілісність і з'являються руйнування, в ході яких неможливо забезпечити нормативні умови зберігання зерна та інших матеріалів. Для підтримки цілісності конструкції потрібно періодично проводити технічне обслуговування елеватора та його ремонт.

### Демонтаж елеваторів

##### Демонтаж силосних веж та бункерів може включати такі етапи:

- Виїзд на об'єкт і оцінка робіт з демонтажу;

- Підготовка необхідної дозвільної документації;

- Зняття старого покриття, Видалення ізоляційного шару

- Демонтаж та вивіз металевих конструкцій;

- Розбір кріплень, руйнування коробки.

- Підготовка металобрухту до вивозу

Виконуємо роботи в закритих приміщеннях, демонтаж з збереженням конструкції для подальшого транспортування та монтажу на новому місці. Монтажники, альпіністи та різці з атестовані для висотних робіт і з досвідом від 5 років.
`
        },
        secondText: {
            text: `### Ремонт зернових елеваторів

Виконаємо ремонт елеватора відповідно до всіх технологічних та конструктивних особливостей споруди.

##### Елеватор включає в себе комплекс споруд:

- Робоча будівля;

- Силосні корпуси;

- Механізми транспортування зерна (норії);

- Зерносушарки тощо.

##### Етапи ремонту елеваторів можуть включати:

- Штукатурку міжшовних швів;

- Герметизацію швів і з'єднань;

- Ремонт і перевірка стану болтових з'єднань збірних конструкцій;

- Ремонт залізобетонних колонн елеватора;

##### Ремонт фасаду елеватора, даху.

- Ремонт перехідних галерей;

- Заміна вікон елеватора;

- Чищення зовнішньої поверхні стін, внутрішня очистка силосів;

- Відновлення оцинкованих поверхонь;

- Фарбування елеватора, силоса та металоконструкцій.

### Реконструкція елеватора
Реконструкцію елеватора виконують у зв'язку зі змінами комплексу, обсягу виробництва, зносом окремих частин та всього корпусу.

##### Реконструкція включає в себе:

- Підсилення стінових плит силосних корпусів і фундаменту;

- Перепланування комплексу;

- Заміна окремих частин елеватора;

##### Виконаємо ремонт металевого, залізобетонного елеватора методом промислового альпінізму.
 `
        }
    };

