import firstImage from '../../images/pages/installation-and-dismantling-of-car-scales/firstImage.jpg';
import secondImage from '../../images/pages/installation-and-dismantling-of-car-scales/secondImage.jpg';
import {PageTemplates} from "../../pages/standartPages/enum";

export const installationAndDismantlingOfCarScales = {
    url: '/installation-and-dismantling-of-car-scales',
    title: 'Монтаж і демонтаж вагонних ваг',
    type: PageTemplates.TwoImagesOneTextBigType,
    keywords: 'монтаж вагонних ваг,демонтаж вагонних ваг',
    manufacturers: {
        text: 'ТОВ «СІТАМ» має великий досвід монтажу ваг найрізноманітніших марок та конфігурацій. Серед обладнання, з яким ми працюємо, вказані агрегати таких виробників: Весоізмерительні системи, Техноваги, Булат, Кода, Міка, ЕТС, ТОМ, Сведа, Елва, Укрестмаркінвест, Метровес, НПП Грамм, СоюзЦветМетАвтоматика, Пульсар, Schenck, НПВ-150т, ВВЕТ-150, ТВВ-150 та інші виробники',
    },
    description: 'Ремонтуємо промислові ваги та інше вагове обладнання на професійному рівні. Пропонуємо послуги з встановлення платформених тензометричних вагонних ваг різної конструкції під ключ',
    underTitle: `ТОВ «СІТАМ» Ремонтуємо промислові ваги та інше вагове обладнання на професійному рівні. На всі види робіт організація має необхідні дозволи та свідоцтва.
    Пропонуємо послуги з встановлення платформених тензометричних вагонних ваг різної конструкції під ключ. Також демонтаж або заміну фізично і морально застарілого вагового обладнання на вашому підприємстві. Виконаємо всі будівельні роботи або швидко встановимо на готовому фундаменті, шпалах та ін. `,
    firstImage,
    secondImage,
    text: `### Виконаємо наступні будівельно-монтажні роботи:

###### Розробка котловану під приямок;
- Облаштування основи під залізничну колію;

###### Монтаж залізничних шляхів;
- Улаштування залізобетонних фундаментів;
- Баластування шляху на залізо- бетонних шпалах;

- Установка-демонтаж опалубки;
- Облаштування вирівнюючого цементного стяжки;
- Засипка і ущільнення траншей, котлованів та ярів;

- Установка металевих конструкцій та закладних деталей;

- Монтаж контуру заземлення ваг;
- Грунтовка та фарбування металевих поверхонь;

- Гідроізоляція стін, фундамент- та;

- Вивантаження та перевезення сміття;

- Демонтаж залізничних шляхів;
- Демонтаж та утилізація бетонного покриття;

- Демонтаж асфальтового покриття;
- Демонтаж платформи ваг, вагової системи, балок;

- Демонтаж бетонних стінок фундаменту;
`};
