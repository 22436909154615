import firstImage from '../../images/pages/installation-of-presses/firstImage.jpg';
import secondImage from '../../images/pages/installation-of-presses/secondImage.jpeg';
import {PageTemplates} from "../../pages/standartPages/enum";

export const installationOfPresses = {
    url: '/installation-of-presses',
    title: 'Монтаж пресів',
    firstImage,
    secondImage,
    description: 'Виконуємо монтаж пресів та іншого промислового обладнання. Виробимо монтаж гідравлічного преса, кривошипного преса та гільйотини різної конструкції та розмірів',
    keywords: 'ремонт преса,заміна гідравлічного преса,монтаж кривошипних пресів',
    type: PageTemplates.TwoImagesTwoTextsBigType,
    underTitle: 'ТОВ «СІТАМ» Виконаємо монтаж пресів та іншого промислового обладнання на професійному рівні. На всі види робіт організація має необхідні дозволи та свідоцтва',
    firstText: {
        text: `#### Монтаж збірних пресів виконується за наступною схемою:

- Вивчення технічного завдання, виїзд на об'єкт;
- Підготовка комерційної пропозиції;
- Розробка та виготовлення ППР з урахуванням особливостей обладнання та місця встановлення;
- Транспортування на об'єкт необхідного обладнання, оснастки та інструменту;
- Оформлення необхідних документів (наряди, акти допуску на проведення робіт);
- Перевірка фундаменту, закладних елементів та обладнання;
- Виконання всіх вимог заводу-виробника щодо кріплення та стропування вантажу;
- Переміщення габаритних частин преса та їх збірка;
- Після встановлення на фундамент монтуємо інші вузли, деталі та допоміжні системи.
- Налаштування пресів Пусконалагодження та випробування преса служить для визначення робочих характеристик обладнання перед експлуатацією.

До налагодження приступають після повного закінчення монтажних робіт. Налаштування пресового обладнання проводиться в два етапи: налагодження гідроприводу, потім налагодження самого преса. Послідовність робіт з налагодження гідроприводу визначається його конструктивною схемою.

Після завершення налагодження проводять перевірку верстату згідно з свідоцтвом про приймання на відповідність технічним характеристикам, після чого складається відповідний акт про виконані ПНР. За результатами проведення пусконаладочних робіт обладнання здається замовнику в експлуатацію з відповідними паспортними характеристиками.`
    },
    secondText: {
        text: `###### Виробимо монтаж гідравлічного преса, кривошипного преса та гільйотини різної конструкції та розмірів.

Монтаж пресового обладнання та пресових ліній може включати:
- Підготовка приміщення, заливка фундаменту;
- Транспортування преса, деталей та вузлів на місце монтажу;
- Перевірка стану деталей та розпакування;
- Кантування преса та установка в жолоб або на фундамент;
- Установка інших деталей преса, пусконалагодження, налаштування обладнання в різних режимах роботи;
- Здачу в експлуатацію з підготовкою виконавчої документації.
У випадку промислового переїзду потрібен також демонтаж преса. Необхідні деталі та вузли обладнання знімаються, упаковуються, маркуються та консервуються для подальшої збірки. Спеціалісти виконають демонтажні роботи якісно та в строк.

Розміщувати на виробництві вантажопідйомне обладнання може бути економічно не вигідно. Тому роботи з монтажу, демонтажу пресового обладнання, вигідніше доручити організації зі спеціалістами, необхідною матеріально-технічною базою та досвідом виконання подібних робіт.

*Монтаж пресів виконується відповідно до технічної документації або завдання замовника.
`
}};