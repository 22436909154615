import firstImage from '../../images/pages/filtration-station/1.jpg';
import {PageTemplates} from "../../pages/standartPages/enum";

export const filtrationStation = {
    url: '/filtration-station',
    title: 'Монтаж обладнання станції фільтрування',
    firstImage,
    keywords: 'монтаж станції фільтрування,демонтаж станції фільтрування,ремонт станції фільтрування',
    type: PageTemplates.OneImageTwoTextsSmallType,
    firstText: {
        text: `Виконаємо монтаж і заміну/демонтаж обладнання станції фільтрування та іншого промислового обладнання на професійному рівні. На всі види робіт організація має необхідні дозволи та свідоцтва.`
    },
    description: 'Роботи з монтажу і модернізації станції фільтрації цукрового заводу. Монтаж прес-фільтрів Рутш, Сісмат, КФ. Монтаж свічкових фільтрів (ФСБУ-110, ФСБУ-90, ФСБУ-110). Монтаж сиропних фільтрів ФСП, ТФС. Монтаж і обвязка патронних фільтрів сиропу (ТК-ФПІ)',
    secondText: {
        text: `Наша компанія виконає роботи з монтажу і модернізації станції фільтрації цукрового заводу.

##### До переліку робіт можуть входити:

- Монтаж прес-фільтрів Рутш, Сісмат, КФ;

- Встановлення комплектів фільтрувальних елементів для фільтрів ТF;

- Монтаж свічкових фільтрів (ФСБУ-110, ФСБУ-90, ФСБУ-110);

- Монтаж і обв'язка патронних фільтрів сиропу (ТК-ФПІ);

- Монтаж сиропних фільтрів ФСП, ТФС.
`}};


