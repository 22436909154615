import firstImage from '../../images/pages/oil-cleaning-line-installation/firstImage.jpg';
import secondImage from '../../images/pages/oil-cleaning-line-installation/secondImage.jpg';
import {PageTemplates} from "../../pages/standartPages/enum";

export const oilCleaningLineInstallation = {
    url: '/oil-cleaning-line-installation',
    title: 'Монтаж лінії очищення масла',
    firstImage,
    secondImage,
    keywords: 'ремонт лінії очищення масла,лінія очищення масла',
    type: PageTemplates.TwoImagesOneTextType,
    description: 'Реконструкція маслоекстракційного заводу. Будівельно-монтажні роботи. Влаштування фундаментів під ємності та резервуари. Монтаж технологічних мереж і комунікацій.',
    text: `# Реконструкція маслоекстракційного заводу:
        
- Будівельно-монтажні роботи;

- Виготовлення та монтаж металевих конструкцій;

- Влаштування фундаментів під ємності та резервуари;

- Обшивка сендвіч-панелями приміщень та стін будівель;

- Демонтаж застарілого обладнання;

- Монтаж технологічних мереж і комунікацій;

- Реконструкція та будівництво будівель та споруд;

- Реконструкція технологічної лінії цеху екстракції;

- Реконструкція станції розвантаження автомобілів;

- Виготовлення ємностей з нержавіючої сталі;

- Виготовлення Бункерів;

- Ремонт технологічних трубопроводів;

- Ремонт норій;

- Ремонт конвейерів;

- Видовження транспортера;

- Супровід пуско-налагоджувальних робіт.`,
    };