import firstImage from '../../images/pages/washing-complex/1.jpg';
import { PageTemplates } from "../../pages/standartPages/enum";

export const washingComplex = {
    url: '/montazh-oborudovaniya-moechnogo-kompleksa-sakharnogo-zavoda',
    title: 'Монтаж обладнання мийного комплексу цукрового заводу',
    firstImage,
    type: PageTemplates.OneImageTwoTextsSmallType,
    keywords: 'монтаж, мийний комплекс цукрового заводу, обладнання мийного комплексу, монтаж мийного комплексу цукрового заводу',
    firstText: {
        text: `ТОВ «СІТАМ» Виконаємо монтаж та заміну/демонтаж обладнання мийного комплексу та іншого промислового обладнання на професійному рівні. На всі види робіт організація має необхідні дозволи та свідоцтва.`,
    },
    description: 'Виконаємо реконструкцію та монтаж обладнання тракту подачі та мийного комплексу цукрового заводу для підвищення продуктивності або технічного переоснащення (модернізації). Монтаж свекломийки з ополіскувачами та водовідділячами (Ш1-ПМД, СДК, DRW). Встановлення вертикальних відстійників-згущувачів ВОУ, Ш1-ПОС',
    secondText: {
        text: `Наша компанія виконає реконструкцію та монтаж обладнання тракту подачі та мийного комплексу цукрового заводу для підвищення продуктивності або технічного переоснащення (модернізації).

#### Монтаж та введення в експлуатацію мийного комплексу може включати:

- Монтаж свекломийки з ополіскувачами та водовідділячами (Ш1-ПМД, СДК, DRW);

- Монтаж класифікатора двовалькового;

- Встановлення вертикальних відстійників-згущувачів ВОУ, Ш1-ПОС;

- Встановлення по очищенню транспортерно-мийної води;

- Монтаж сепаратора води (барабанного);

- Монтаж водовідділяча ТМА-ВДФ;

- Монтаж улавлювача легких домішок УЛП;

- Монтаж сітчастого транспортера ТМА-КС;

- Монтаж каменеловушки та соломоботволовушки (TSA);

- Монтаж барабанної гравіеловушки ТКА;

- Монтаж розділювально-ленточного конвеєра TRB;

- Монтаж фільтра транспортерно-мийних вод SWF;

- Монтаж насосного парку;

- Виготовлення та заміна обладнання;
`}
};
