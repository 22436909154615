import firstImage from '../../images/pages/dismantling-of-machines/1.jpg';
import secondImage from '../../images/pages/dismantling-of-machines/2.jpg';
import {PageTemplates} from "../../pages/standartPages/enum";

export const dismantlingOfMachines = {
    url: '/dismantling-of-machines',
    title: 'Демонтаж верстатів',
    firstImage,
    secondImage,
    keywords: 'демонтаж верстатів,заміна верстатів,демонтаж свердлильних верстатів,заміна фрезерних верстатів',
    type: PageTemplates.TwoImagesTwoTextsBigType,
    underTitle: `ТОВ "СІТАМ" Виконає демонтаж верстатів та іншого промислового обладнання на професійному рівні. На всі види робіт організація має необхідні дозволи та свідоцтва.`,
    description: 'Демонтаж, перенесення та наступний монтаж верстатів і верстатних ліній - свердлильний верстат, фрезерний верстат, свердлильно-фрезерний верстат, термопластавтомати',
    firstText: {
        text: `Виконаємо роботи з демонтажу, перенесення та наступного монтажу верстатів і верстатних ліній.

Переїзд підприємства або переміщення цеху потребує якісно виконаних демонтажних робіт для наступного монтажу.

У списку обладнання, з яким ми працювали, знаходяться:

Свердлильний верстат;

Фрезерний верстат;

Свердлильно-фрезерний верстат;

Труборізальні та муфторізальні верстати;

Термопластавтомати;

Пилення верстат;

Горизонтально-розточувальний верстат;

Токарний верстат;

Токарно-гвинтівний верстат;

Токарно-револьверний обробний центр;

Верстат лазерного різання;

Листогибальний верстат;

Прокатний верстат;

Згинальний верстат;

Вертикально-фрезерний обробний центр;

Розточний верстат;

Координатно-розточувальний верстат;

Кривошипний прес;

Координатно-пробивний штамповочний прес;

Гідравлічні преси;

Гібочні машини та гільйотини;

Шліфувальний верстат;

Деревообробні верстати;

і ін. металообробні та металорізальні верстати.`
    },
    secondText: {
        text: `Роботи з демонтажу можуть включати:

Демонтаж верстатів на місці розташування з урахуванням: несучої здатності підлог, вантажопідйомності кранів, обмеженого простору, збереження іншого обладнання, що знаходиться поруч;

Транспортування комплектуючих верстатів по ділянці до місця їх вивезення з урахуванням наявних обмежень у цеху;

Вимкнення верстата;

Фіксація кріпильних болтів зі збереженням їх цілісності;

Злив робочих рідин;

Відключення і демонтаж електричної частини обладнання та системи УПП верстатів;

Демонтаж повітряних і гідравлічних контурів верстата;

Розбірка обладнання на елементи, що забезпечують можливість переміщення і наступного монтажу верстатів, зі збереженням його функціоналу та працездатності.;

Демонтаж вимірювальних приладів та апаратури верстатів, зі збереженням їх функціональності;

Демонтаж і відключення всього допоміжного обладнання верстатів;

Демонтаж захисних кожухів верстата;

Переміщення на нове місце встановлення;
`
    }};
