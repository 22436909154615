import s1 from './1.jpg';
import s2 from './2.jpg';
import s3 from './3.jpg';
import s4 from './4.jpg';
import s5 from './5.jpg';
import s6 from './6.jpg';
import s7 from './7.jpg';
import s8 from './8.jpg';
import s9 from './9.jpg';
import s10 from './10.jpg';
import s11 from './11.jpg';
import s12 from './12.jpg';
import s13 from './13.jpg';
import s14 from './14.jpg';
import s15 from './15.jpg';
import s16 from './16.jpg';
import s17 from './17.jpg';
import s18 from './18.jpg';
import s19 from './19.jpg';
import s20 from './20.jpg';
import s21 from './21.jpg';
import s22 from './22.jpg';
import s23 from './23.jpg';
import s24 from './24.jpg';
import s25 from './25.jpg';
import s26 from './26.jpg';
import s27 from './27.jpg';
import s28 from './28.jpg';

export const steeplejack = {
  text: 'Висотні роботи',
  thumb: s1,
  images: [
      s1,s2,s3,s4,s5,s6,s7,s8,s9,s10,s11,s12,s13,s14,s15,s16,s17,s18,s19,s20,s21,s22,s23,s24,s25,s26,s27,s28
  ]
};