import firstImage from '../../images/pages/thermal-insulation-of-the-pipeline/1.jpg';
import secondImage from '../../images/pages/thermal-insulation-of-the-pipeline/2.jpg';
import { PageTemplates } from "../../pages/standartPages/enum";

export const thermalInsulationOfThePipeline = {
    url: '/teploіzolyatsiya-truboprovodu',
    type: PageTemplates.TwoImagesTwoTextsType,
    manufacturers: {
        text: 'ТОВ «СІТАМ» має великий досвід монтажу та заміни теплоізоляції трубопровода найрізноманітніших марок і конфігурацій. У списку обладнання, з яким ми працюємо, числяться агрегати наступних виробників: Е, КСВа (ЕКО), ВК, КВГ, ТВГ, КСВ та інших виробників, включаючи вітчизняних.',
    },
    keywords: 'монтаж теплоізоляції, теплоізоляція технологічного трубопроводу, монтаж технологічного трубопроводу',
    title: 'Монтаж теплоізоляції технологічного трубопроводу',
    underTitle: 'ТОВ «СІТАМ» Виконаємо ремонт і монтаж теплоізоляції трубопроводу та іншого промислового обладнання на професійному рівні. На всі види робіт організація має необхідні дозволи та свідоцтва.',
    firstImage,
    secondImage,
    description: 'Ремонт та монтаж теплоізоляції трубопроводу. Грунтування та фарбування трубопроводу та ємностей. Монтаж покрівельного шару зі сталі оцинкованої з полімерним покриттям. Виготовлення та монтаж каркаса для кріплення ізоляції.',
    firstText: {
        text: `###### Монтаж теплової ізоляції трубопроводу включає:

Грунтування та фарбування трубопроводу та ємностей;

Монтаж теплової ізоляції (мінеральна вата, тощо);

Ізоляція фасонних частин та запірної арматури;

Монтаж покрівельного шару зі сталі оцинкованої з полімерним покриттям;

Виготовлення та монтаж каркаса для кріплення ізоляції;

Частковий ремонт (відновлення) ізоляції в окремих місцях;

Вирівнювання існуючої ізоляції;

Зняття старої ізоляції та транспортування в вказане місце;

Також можлива теплоізоляція технологічного обладнання, резервуарів та ємностей.
`
    },
    secondText: {
        text: `##### Наша компанія виконає теплоізоляцію технологічних трубопроводів  
з нержавіючої сталі та арматури для підприємств харчової, хімічної, фармацевтичної промисловості, нафтобаз, пивзаводів, молокозаводів, маслоекстракційних заводів та ін.

Для забезпечення гідроізоляції та теплоізоляції зовнішніх елементів трубопровідних систем виконується комплекс робіт, що включає в себе проектування, виробництво і монтаж, а також виробництво і монтаж покривного шару (металевого облицювання) з листкового металу, такого як: оцинковка нержавійка алюміній.

Виробимо монтаж, ремонт та заміну теплоізоляції технологічних трубопроводів:

Трубопровод водопостачання;

Трубопровод стисненого повітря;

Протипожежний водопровід;

Трубопровод подачі пари;

Трубопровод гарячого водопостачання;

Трубопровод конденсату;

Трубопровод зовнішніх мереж теплопостачання (систем опалення);

Газопровід;

Нафтопроводи;

Трубопровод скидання очищених стічних вод;

Трубопроводи з обігрівальним обладнанням;

Трубопровод холодопостачання;

Трубопроводи зовнішніх каналізаційних мереж;

Трубопровод аміаку;

Кислотопровід;

Трубопровод хімреагентів;

Трубопроводи рідких добрив;

Трубопровод переддефекації;

Трубопровод соку;

Вакуумний трубопровід.
`}};

