import firstImage from '../../images/pages/cooling-tower-repair/1.jpg';
import secondImage from '../../images/pages/cooling-tower-repair/2.jpg';
import {PageTemplates} from "../../pages/standartPages/enum";

export const coolingTowerRepair = {
    url: '/cooling-tower-repair',
    type: PageTemplates.TwoImagesTwoTextsType,
    keywords: 'ремонт градирни,ремонт вентиляторной градирни,модернизация градирни',
    manufacturers: {
        text: 'ООО «СИТАМ» имеет большой опыт ремонта градирен самых разных марок и конфигураций. В списке оборудования, с которым мы работаем, числятся агрегаты следующих производителей: Evapco, Mita, YWCT и других производителей в т.ч. отечественных.',
    },
    title: 'Ремонт и обслуживание вентиляторной градирни',
    firstImage,
    secondImage,
    description: 'Ремонт градирен самых разных марок и конфигураций. Модернизация распределительных трубопроводов. Замена стальных труб. Восстановление антикоррозионных покрытий на металлоконструкциях градирни',
    firstText: {
        text: `##### Также ремонт и модернизация градирни может включать:
- Изготовление и монтаж растяжек диффузора;

- Изготовление и монтаж двутавровых балок;

- Замена стальных труб;

- Модернизация распределительных трубопроводов;

- Установка технологических площадок;

- Установка ограничителей обратного хода вентилятора;

- Ремонт и изготовление металлоконструкций;

- Восстановление антикоррозионных покрытий на металлоконструкциях градирни;

- Установка запорной арматуры;

- Испытания на герметичность.
`
    },
    secondText: {
        text: `Наша компания предоставляет услуги по техническому обслуживанию и текущему/капитальному ремонту испарительных мокрых градирен открытого и закрытого типа и объектов водооборотных циклов.

Данный вид оборудования в основном используется на тепловых электростанциях (ТЭЦ), маслоэкстракционных заводах, фармацевтических а так же в металлургической, нефтеперерабатывающей, химической, коксохимической и пищевой промышленности.

### Среди распространенных неисправностей встречаются:

- Не включается вентилятор или заклинивание;

- Из градирни уносится большое количество воды;

- При работе градирни повышен уровень шума;

- Разрушение обшивки;

- Разрушения (коррозия) опорных конструкций;

- Нарушения герметичности теплообменников;

- Засорение теплообменных поверхностей;

- Деформация оросителей (нет эффекта охлаждения);

- Поломка насосного оборудования, протечка сальников насоса.

##### Ремонт и модернизация градирни может включать:

- Диагностика градирни;

- Ремонт насосного оборудования;

- Ремонт и замена обшивки градирни;

- Замена и очистка блоков оросителя;

- Замена и очистка блоков каплеуловителей;

- Демонтаж и установка элементов крепления;

- Ремонт и замена лопастей вентилятора;

- Расшивка защитного кожуха барабана;

`}};


