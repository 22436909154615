import secondImage from '../../images/pages/thermal-insulation-of-the-tank/1.png';
import firstImage from '../../images/pages/thermal-insulation-of-the-tank/2.jpg';
import {PageTemplates} from "../../pages/standartPages/enum";

export const thermalInsulationOfTheTank = {
    url: '/thermal-insulation-of-the-tank',
    title: 'Теплоизоляция резервуаров',
    firstImage,
    secondImage,
    keywords: 'теплоизоляция резервуаров,утепление резервуаров',
    underTitle: `ООО «СИТАМ» Выполним теплоизоляцию резервуара и другого промышленного оборудования на профессиональном уровне. На все виды работ организация имеет необходимые разрешения и свидетельства`,
    manufacturers: {
        text: 'ООО «СИТАМ» имеет большой опыт утепления резервуаров самых разных марок и конфигураций. В списке оборудования, с которым мы работаем, числятся агрегаты следующих производителей: РГС и других производителей в т.ч. отечественных',
    },
    type: PageTemplates.TwoImagesTwoTextsBigType,
    description: 'Утепление резервуаров для хранения сырья и готового продукта на различных видах промышленного производства. В списке оборудования, с которым мы работаем, числятся агрегаты следующих производителей: РГС и других производителей в т.ч. отечественных',
    firstText: {
        text: `#### Наша компания выполнит утепление резервуаров для хранения сырья и готового продукта на различных видах промышленного производства.

Чтобы снизить перепад температуры между окружающей средой и самими продуктом используется внешняя теплоизоляция стальных резервуаров.

В резервуарах хранят как правило жидкие или газообразные материалы, которые требуют строгого соблюдения температурного режима. Поддержание определенных температур внутри емкостей для нефтепродуктов является одним из основных факторов их функциональности.

##### Теплоизоляция емкостей может проводиться различными материалами:

- Стекловата;

- Пеностекло;

- Пенобетон;

- Минеральная вата;

- Пенополиуретан.

Утепление резервуаров может происходить следующим образом:

- Выбор утеплителя в зависимости от конструкции;

- Расчет толщины изоляции;

- Антикоррозийное покрытие металлических поверхностей;

- Монтаж утеплителя между опорными кольцами без зазоров;

- Крепление теплоизоляции приваренными шпильками или обвязочной проволокой;
`
    },
    secondText: {
        text: `##### При производстве теплоизоляционных работ мы учитываем: 
        
Обеспечение герметичности, чтобы между стальным корпусом и самой изоляцией не могла проникать влага, так как это приведет к многократному ускорению коррозии и снижению срока службы самого резервуара.Обычно теплоизоляционный материал обшивают оцинкованным листом.

Изоляция для резервуаров выполняться только на стенах и крыше конструкции, то есть с наружной поверхности.

Перед тем как приступить к теплоизоляционным работам, так же обращаем внимание на взаимодействия конструкции резервуара с элементами теплоизоляции (нагрузка на элементы резервуара, опоры, температурные режимы и т.д).`
    }};




