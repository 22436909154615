import firstImage from '../../images/pages/installation-of-containers/firstImage.jpg';
import secondImage from '../../images/pages/installation-of-containers/secondImage.jpg';
import thirdImage from '../../images/pages/installation-of-containers/thirdImage.jpg';
import {PageTemplates} from "../../pages/standartPages/enum";

export const installationOfContainers = {
    url: '/installation-of-containers',
    title: 'Встановлення ємностей',
    firstImage,
    secondImage,
    thirdImage,
    keywords: 'монтаж ємностей,установка склопластикових ємностей',
    type: PageTemplates.ThreeTwoImagesTwoTextsType,
    description: 'Виконаємо монтаж склопластикових і металевих ємностей різних видів та призначень: підземних/наземних ємностей, вертикальних/горизонтальних ємностей, ємностей міні-НПЗ, НПЗ, ГПЗ, НХЗ, ємностей зберігання нафти та нафтопродуктів (ГСМ, АЗС і АГЗС)',
    firstText: {
        text: `### Виконаємо монтаж склопластикових і металевих ємностей різних видів та призначень:

- Наземних ємностей;
- Підземних ємностей;
- Вертикальних ємностей;
- Горизонтальних ємностей;
- Ємностей зберігання нафти та нафтопродуктів (ГСМ, АЗС і АГЗС);
- Ємностей міні-НПЗ, НПЗ, ГПЗ, НХЗ;
- Ємностей для зберігання рідин;
- Ємностей для пожежогасіння;
- Ємностей для дренажу;
- Технологічних та харчових ємностей.

До місця установки ємностей повинні бути прокладені під'їзні дороги, а на робочій площадці виділені місця для зберігання, зборки та підйомно-транспортної техніки, підведено електропостачання.
`
        },
        secondText: {
            text: `### Монтаж ємностей залежно від розташування та виконання може включати:

Підземні:

- Підготовка будівельної площадки;
- Викопування ями;
- Укладання підстилаючого основи, підготовка гідроізоляційного шару;
- Монтаж опорної плити;
- Монтаж ємності;
- Фіксація стяжними ременями;
- Засипання ями;
- Установка технічних колодязів;
- Підключення до трубопровідної обв'язки.

Наземні:

- Будівництво фундаменту;
- Транспортування елементів ємності до місця установки;
- Монтаж конструкцій днища ємностей;
- Кріплення опор до фундаменту за допомогою анкерних болтів;
- Монтаж стінки ємності з листів або рулонних полотен;
- Зварювання з'єднань ємності або кріплення болтами;
- Монтаж даху на ємностях;
- Розміщення сходів, платформ для обслуговування;
- Підключення до трубопровідної обв'язки;
- Теплоізоляція ємностей, трубопроводів;
- Монтаж ємнісного обладнання та обв'язки;
- Антикорозійна обробка ємностей, баків-акумуляторів, металевих конструкцій, суден, апаратів;
- Випробування та здача ємностей в експлуатацію.

Усі роботи виконуються відповідно до правил і норм монтажу ємностей та вимог замовника. Роботи з установки та монтажу виконують монтажники, що мають необхідні для виконання даного виду робіт ліцензії та сертифікати.
`
        }
    };