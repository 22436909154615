import secondImage from '../../images/pages/welding-works/1.jpg';
import firstImage from '../../images/pages/welding-works/2.jpg';
import {PageTemplates} from "../../pages/standartPages/enum";

export const weldingWorks = {
    url: '/welding-works',
    title: 'Сварочные работы, сварка металлоконструкций',
    firstImage,
    secondImage,
    keywords: 'сварочные работы,сварка металлоконструкций',
    manufacturers: {
        text: 'ООО «СИТАМ» имеет большой опыт сварки и изготовление металлоконструкций самых разных конфигураций. В списке оборудования, с которым мы работаем, числятся агрегаты следующих производителей: Bock, Bitzer, Copeland, Maneurop, DVK и других производителей в т.ч. отечественных',
    },
    description: 'Выполним сварочные работы и изготовление различных металлоконструкций из черного металла и нержавеющей стали в промышленной и агропромышленной сфере. Сварка и наплавка изношенных частей оборудования. Сварка металлических конструкций (фермы, колонны, балки, закладные детали). Сварка электродами',
    underTitle: `ООО «СИТАМ» Выполним сварочные работы и изготовление металлоконструкций на профессиональном уровне. На все виды работ организация имеет необходимые разрешения и свидетельства`,
    type: PageTemplates.TwoImagesOneTextType,
    text: `##### Наша компания выполнит сварочные работы и изготовление различных металлоконструкций из черного металла и нержавеющей стали в промышленной и агропромышленной сфере, а именно:

- Сварка аргоном;

- Сварка электродами;

- Сварка на высоте;

- Сварка металлических конструкций (фермы, колонны, балки, закладные детали);

- Сварка и наплавка изношенных частей оборудования;

- Сварка технологических трубопроводов различного диаметра;

- Сварочные работы при установке гидро и теплоизоляции;

- Сварка нержавеющих емкостей и резервуаров;

- Сварка металлических лестничных маршей и технологических площадок;

- Сварка бункеров и баков различного назначения;

- Сварка металлических каркасов (Ангары, склады, навесы, цеха и.т.д);

- Резка металла, вальцовка, гибка листов, труб, швеллеров;

- Восстановление ножей бульдозеров, ковшей экскаваторов и другой спец. техники;

- Изготовление металлоконструкций любой сложности по чертежам заказчик.`,
};

