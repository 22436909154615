import secondImage from '../../images/pages/lime-burning-department/1.jpg';
import firstImage from '../../images/pages/lime-burning-department/2.jpg';
import {PageTemplates} from "../../pages/standartPages/enum";

export const limeBurningDepartment = {
    url: '/lime-burning-department',
    title: 'Монтаж оборудования известково-обжигательного отделения',
    firstImage,
    secondImage,
    keywords: 'монтаж известково-обжигательного отделения,ремонт известково-обжигательного',
    underTitle: `ООО «СИТАМ» Выполним монтаж и замену/демонтаж оборудования известково-обжигательного отделения и другого промышленного оборудования на профессиональном уровне. На все виды работ организация имеет необходимые разрешения и свидетельства.`,
    type: PageTemplates.TwoImagesOneTextType,
    description: 'Выполним работы по монтажу оборудования и реконструкции известкового отделения сахарного завода. Реконструкция известковообжигательных печей ИПШ, ВМА, ТМА-ПШИ, ТИПШ. Монтаж трактов подачи топлива и известкового камня, узлов подготовки шихты. Монтаж известковообжигательных печей',
    text: `Наша компания выполнит работы по монтажу оборудования и реконструкции известкового отделения сахарного завода

##### Список работ может включать:

- Монтаж известковообжигательных печей;

- Реконструкция известковообжигательных печей ИПШ, ВМА, ТМА-ПШИ, ТИПШ;

- Монтаж трактов подачи топлива и известкового камня, узлов подготовки шихты;

- Замена загрузочно-распределительных и выгрузочных устройств шахтной известковообжигательной печи;

- Установкой известегасильных аппаратов;

- Установка весовых дозаторов топлива;

- Монтаж Вибропитателя камня ВПИ.
`};


