import secondImage from '../../images/pages/press-compactor/1.jpg';
import firstImage from '../../images/pages/press-compactor/2.jpeg';
import {PageTemplates} from "../../pages/standartPages/enum";

export const pressCompactor = {
    url: '/press-compactor',
    type: PageTemplates.TwoImagesTwoTextsBigType,
    keywords: 'ремонт пресс компактора,пресс компактор для вторсырья',
    manufacturers: {
        text: 'ООО «СИТАМ» имеет большой опыт ремонта моноблочных, стационарных пресс-компакторов (в том числе шнековых). В списке оборудования, с которым мы работаем, числятся агрегаты следующих производителей:SPB 20 E, Ecopolymer,Europress,Ecopress,Avermann,Pressor,Ecomp,HSM,KOSPA,Husmann,Werner & Weber и других производителей в т.ч. отечественных',
    },
    title: 'Ремонт пресс компактора для вторсырья',
    underTitle: 'ООО «СИТАМ» Выполним ремонт и обслуживание пресса компактора и другого промышленного прессового оборудования на профессиональном уровне. На все виды работ организация имеет необходимые разрешения и свидетельства',
    firstImage,
    secondImage,
    description: 'Работы по ремонту и техническому обслуживанию пресс-компактора (пресс-контейнера) для прессования и транспортировки одноразовых поддонов, одноразовой деревянной тары, а также бумаги, картона, пленки, отходов из ПЭТ и различного вторсырья',
    firstText: {
        text: `Наша компания выполнит работы по ремонту и техническому обслуживанию пресс-компактора (пресс-контейнера) для прессования и транспортировки одноразовых поддонов, одноразовой деревянной тары, а также бумаги, картона, пленки, отходов из ПЭТ и различного вторсырья. Комплекс работ по ремонту пресс компактора может включать в себя демонтаж и монтаж узлов, восстановительный ремонт и замену определенных узлов и механизмов.

##### Неисправности пресс компактора:

- Пресс не запускается;

- Шум в клапанах распределителей и трубах при открывании клапанов;

- При закрытии клапанов слышны резкие удары;

- Толчки в механизмах при закрытии клапана;

- Пресс не развивает нужного усилия;

- Пресс не может вытолкнуть кипу.

Неисправности заставляют работать систему менее эффективно, с меньшей производительностью вызывая отказы в работе которые ведут к выходу оборудования из строя, увеличению брака и затратам в связи с остановкой производственного цикла.

##### Ремонт пресс компактора(контейнера) может включать:

- Диагностика и составление дефектной ведомости;

- Восстановление сварных швов;

- Ремонт, замена шнека;

- Ремонт, замена направляющих;

- Замена, ремонт гидроцилиндров;

- Ремонт, замена контроллера (блока управления);

- и других элементов.
`
    },
    secondText: {
        text: `##### В регламент технического обслуживание пресс компактора входит:

Механическая часть:

- Проверка соблюдения правил эксплуатации оборудования;

- Технический осмотр состояния оборудования – проверка работоспособности всех механических узлов;

- Проверка наличия внешних повреждений и их ремонт;

- Проверка кинетической системы и её ремонт, в случае неисправности;

- Смазка направляющих плиты пресса и запорных устройств;

- Протяжка резьбовых соединений.

Гидравлическая часть:

- Проверка и протяжка крепежных механизмов и соединений;

- Проверка герметичности и контроль утечек масла, устранение протечек;

- Контроль уровня масла и рабочего давления гидросистемы;

- Замена гидравлического масла и масляного фильтра;

- Проверка цилиндров и фитингов и других рабочих частей на предмет износа или повреждения;

- Смазка шарниров пресса.

Электрическая часть:

- Контроль исправности элементов электросистемы;

- Проверка срабатывания клапанов;

- Настройка индукционных датчиков;

- Проверка срабатывания концевых выключателей, их настройка и ремонт при необходимости;

- Проверка панели управления;

- Контроль исправности работы программы управления прессом.
`}};



