import firstImage from '../../images/pages/installation-of-industrial-equipment/firstImage.jpg';
import secondImage from '../../images/pages/installation-of-industrial-equipment/secondImage.jpg';
import {PageTemplates} from "../../pages/standartPages/enum";

export const installationOfIndustrialEquipment = {
    url: '/installation-of-industrial-equipment',
    title: 'Монтаж промышленного оборудования',
    keywords: 'монтаж промышленного,демонтаж оборудования,переезд завода',
    firstImage,
    secondImage,
    alsoSeeIndex: 5,
    type: PageTemplates.TwoImagesOneTextType,
    buttons: [{
        text: 'Промышленный переезд завода',
        url: '/'
    },{
        text: 'Монтаж технологического трубопровода',
        url: '/installation-of-a-process-pipeline'
    },{
        text: 'Монтаж станков',
        url: '/installation-of-machines'
    },{
        text: 'Монтаж прессов',
        url: '/installation-of-presses'
    },{
        text: 'Монтаж емкостного оборудования',
        url: '/installation-of-containers'
    },{
        text: 'Монтаж оборудования для обработки полимеров (ТПА)',
        url: '/'
    },{
        text: 'Монтаж полиграфического оборудования',
        url: '/'
    },{
        text: 'Монтаж трансформаторов и энергетического оборудования',
        url: '/installation-of-transformers-and-power-equipment'
    },{
        text: 'Монтаж грузоподъемного оборудования',
        url: '/installation-of-lifting-equipment'
    },{
        text: 'Монтаж конвейеров и транспортеров',
        url: '/assembly-of-the-conveyor'
    },{
        text: 'Монтаж горного оборудования',
        url: '/'
    },{
        text: 'Монтаж промышленных печей',
        url: '/'
    },{
        text: 'Монтаж металлургического оборудования',
        url: '/'
    },{
        text: 'Монтаж технологических линий',
        url: '/installation-of-technological-lines'
    },{
        text: 'Монтаж прочего оборудования',
        url: '/'
    }],
    description: 'Выполнит комплекс услуг по монтажу и демонтажу вашего промышленного оборудования при промышленном переезде, открытии новых технологических линий и цехов, приемке нового оборудования и запуск его в эксплуатацию',
    text: `Наша компания выполнит комплекс услуг по монтажу и демонтажу вашего промышленного оборудования при промышленном переезде, открытии новых технологических линий и цехов, приемке нового оборудования и запуск его в эксплуатацию.

#### При демонтаже и переезде завода производятся следующие работы:

Полное отключение оборудования от питающих линий;

Частичный или полный демонтаж оборудования;

Механический демонтаж;

Крупноузловой демонтаж;

Такелажные работы;

Перемещение и погрузка демонтируемых компонентов для дальнейшей транспортировки;

Перевозка оборудования;

#### В список работ по установке оборудования могут входить:

Разгрузка оборудования и перемещение к месту монтажа;

Изготовление фундаментов под новое оборудование и технологических приямков;

Перемещение деталей в зону монтажа;

Выверка и установка оборудования в проектное положение;

Кантование, подъем или опускание частей агрегатов в стесненных условиях;

Крупноузловой механический монтаж;

Сборка навесного оборудования;

Подвод питающих линий и инженерных сетей;

Пусконаладочные работы;

Запуск оборудования в эксплуатацию;`,
};
