import firstImage from '../../images/pages/repair-of-a-car-dumper/1.jpg';
import secondImage from '../../images/pages/repair-of-a-car-dumper/2.jpg';
import {PageTemplates} from "../../pages/standartPages/enum";

export const repairOfaCarDumper = {
    url: '/repair-of-a-car-dumper',
    type: PageTemplates.TwoImagesTwoTextsType,
    underTitle: 'ТОВ «СІТАМ» Ремонтуємо вагоноопрокидувачі та інше промислове обладнання на професійному рівні. На всі види робіт організація має необхідні дозволи та свідоцтва',
    title: 'Ремонт та обслуговування вентиляторної градирні',
    keywords: 'ремонт вентиляторної градирні,обслуговування вентиляторної градирні,обслуговування вагоноопрокидувачів,ремонт вагоноопрокидувачів',
    firstImage,
    secondImage,
    description: 'Сервіс та технічне обслуговування вагоноопрокидувачів та супутнього обладнання. Ремонт металоконструкцій обладнання вагоноопрокидувачів. Пусконалагоджувальні роботи',
    firstText: {
        text: `##### Сервіс та технічне обслуговування вагоноопрокидувачів та супутнього обладнання.
Регламент обслуговування вагоноопрокидувача: 3 роки експлуатації — середній ремонт, 6 років експлуатації – капітальний ремонт. Також варто звернути увагу на кваліфіковану діагностику технічного стану вагоноопрокидувача. Як показує досвід, про ремонт задумуються при постійних збоях або після виходу з ладу. Наслідки такого ставлення можуть бути досить дорогостоящими. Щоб уникнути цього, наша компанія може запропонувати наступні сервісні послуги:

##### Технічний аудит

- обстеження і діагностика технічного стану з видачею рекомендацій щодо ремонту або модернізації обладнання;

- приведення обладнання вагоноопрокидувачів, вагонотолкачів, дробильно-фрезерних машин у проектне положення;

- розробка рекомендацій щодо подовження терміну служби вагоноопрокидувача.

##### Пусконалагоджувальні роботи

- ревізія (дефектовка) після монтажу (ремонту), налагодження, пуск, регулювання та обкатка механічної частини обладнання;

- випробування, налагодження і регулювання електричної частини обладнання;

Модернізацію обладнання розвантажувальних комплексів

- модернізацію механічної та електричної частини вагоноопрокидувачів, дробильно-фрезерних машин, вагонотолкачів;

##### Виробництво і ремонт

- виконання капітальних ремонтів обладнання розвантажувального комплексу «під ключ».

- виготовлення і поставка запасних частин до вагоноопрокидувача, вагонотолкача, дробильно-фрезерної машини, живильника.
`
    },
    secondText: {
        text: `Вагоноопрокидувачі типу ВРС-75, ВРС-93(-110) застосовують на гірно-збагачувальних фабриках, коксохімічних заводах, електростанціях, пунктах опрокидування, металургійних комбінатах, балкерних та вугільних терміналах ТЕЦ для підвищення продуктивності розвантаження сипучих матеріалів.

Складний характер навантаження вагоноопрокидувача, порушення умов експлуатації, аварійні ситуації в роботі зубчастого з'єднання, можливі конструктивні недоліки, помилки при монтажі часто призводять до руйнування деталей, поломок і як наслідок простоїв обладнання.

##### Ремонт металоконструкцій обладнання вагоноопрокидувачів може включати наступне:

- ремонт пластинчастого живильника;

- ремонт стаціонарного стрічкового конвеєра;

- ремонт вагоноопрокидувача і течек в корпусі;

- ремонт вагонотолкачів;

- ремонт дробильно-фрезерних машин (ДФМ);

- ремонт приймальних бункерів і над бункерних решіток;

- демонтаж і різка демонтованого вагоноопрокидувача.

##### Капітальний ремонт вагоноопрокидувача роторного стаціонарного може включати:

- Демонтаж і монтаж катковротора з приведенням у проектне положення;

- Ревізія і ремонт ротора;

- Демонтаж, ремонт і монтаж вібраторів ротора;

- Заміна плит і прокладок рам вібраторів;

- Монтаж, демонтаж і виготовлення розсікателів ротора;

- Виправлення вагоноопрокидувача в проектне положення;

- Виготовлення, монтаж і посилення металоконструкцій;

- Монтаж і демонтаж роликів;

- Заміна роликів втулок в зборі;

- Ремонт роликоопор;

- Відновлення зварних швів;

- Ремонт призначальних стінок і буферних пристроїв з заміною вісей і пружин;

- Ремонт, заміна секцій бандажа і їх кріплень;

- Демонтаж, ремонт і монтаж амортизаторів (платформ, буферів, рам);

- Демонтаж старої і монтаж нової гумової футеровки з виготовленням кріплень;

- Виправлення підводячих і виводячих рейкових шляхів;

- Заміна рейс (лекальних);

- Ревізія і ремонт приводу вагоноопрокидувача;

- Виготовлення і монтаж настилу, оболонок;

- Регулювання і відновлення проектних зазорів;

- Встановлення фотодатчиків з подачі вагонів;

- Ремонт тяг платформи з заміною втулок;

- Демонтаж і монтаж відновлених тяг;

- Заміна келихів;

- Заміна вставок;

- Заміна осей траверс;

- Заміна і встановлення торцевого упору;

- Заміна обмежувачів з відновленням і регулюванням;

- Ремонт опорних балок;

- Заміна бонок;

- Ревізія з заміною ущільнень редукторів;

- Відновлення чавунних кришок редукторів;

- Заміна гальм;

- Заміна шківів;

- Заміна обойм;

- Заміна втулок;

- Заміна муфт;

- Ремонт шестерень, заміна валу шестерні;

- Ремонт рам і надійне закріплення на фундаментах;

- Змазка обладнання відповідно до картки змазування;

- Загальна виправка і наладка обладнання. Запуск в роботу вагоноопрокидувача.
`}};