import firstImage from '../../images/pages/installation-of-granulators/2.png';
import secondImage from '../../images/pages/installation-of-granulators/1.png';
import thirdImage from '../../images/pages/installation-of-granulators/3.png';
import {PageTemplates} from "../../pages/standartPages/enum";

export const installationOfGranulators = {
    url: '/installation-of-granulators',
    type: PageTemplates.ThreeTwoImagesTwoTextsType,
    title: 'Монтаж грануляторов',
    firstImage,
    secondImage,
    thirdImage,
    description: 'Демонтаж старого гранулятора и монтаж нового. Подготовка помещения, заливка фундамента. Транспортировка пресса, деталей и узлов на место монтажа. Кантовка пресса и установка в приямок или на фундамент. Установка остальных деталей пресса, пусконаладка, настройка оборудования в различных режимах работы',
    keywords: 'монтаж гранулятора,замена гранулятора,демонтаж гранулятора',
    firstText: {
        text: `#### Произведем демонтаж старого гранулятора и монтаж нового.
        
**Монтаж прессового оборудования и прессовых линий может включать:**

- Подготовка помещения, заливка фундамента;
- Транспортировка пресса, деталей и узлов на место монтажа;
- Проверка состояния деталей и распаковка;
- Кантовка пресса и установка в приямок или на фундамент;
- Установка остальных деталей пресса, пусконаладка, настройка оборудования в различных режимах работы;
- Сдачу в эксплуатацию с подготовкой исполнительной документации.

`
    },
    secondText: {
        text: `##### В случае замены гранулятора требуется также демонтаж старого пресса.
        
Необходимые детали и узлы оборудования снимаются, упаковываются, маркируются и консервируются для последующей сборки. Специалисты выполнят демонтажные работы качественно и в срок.
 
Размещать на производстве грузоподъемное оборудование может быть экономически не выгодно. Поэтому работы по монтажу, демонтажу прессового оборудования, выгоднее поручить организации со специалистами, необходимой материально-технической базой и опытом выполнения подобных работ.

*Монтаж грануляторов производится в соответствии с технической документации или заданием заказчика.*

##### Особенности установки прессов Гранулятора производится по следующей схеме:

- Изучение технического задания, выезд на объект;
- Подготовка коммерческого предложения;
- Разработка и изготовление ППР с учетом особенностей оборудования и места установки;
- Транспортировка на объект необходимого оборудования, оснастки и инструмента;
- Оформление необходимых документов (наряды, акты допуска на проведение работ);
- Проверка фундамента, закладных элементов и оборудования;
- Выполнение всех требований завода производителя по крепежу и строплению груза;
- Перемещение габаритных частей пресса и их сборка;
- После установки на фундамент монтируем остальные узлы, детали и вспомогательные системы.

`}};
