import firstImage from '../../images/pages/zhomodushilny-complex/1.jpg';
import {PageTemplates} from "../../pages/standartPages/enum";

export const zhomodushilnyComplex = {
    url: '/zhomodushilny-complex',
    title: 'Монтаж оборудования жомосушильного отделения',
    firstImage,
    keywords: 'монтаж оборудования жомосушильного отделения,оборудование жомосушильного отделения,монтаж жомосушек',
    type: PageTemplates.OneImageTwoTextsSmallType,
    maxSize: true,
    firstText: {
        text: `ООО «СИТАМ» Выполним монтаж и замену/демонтаж оборудования жомосушильного отделения и другого промышленного оборудования на профессиональном уровне. На все виды работ организация имеет необходимые разрешения и свидетельства.`
    },
    description: 'Выполним работы по строительству и монтажу оборудования жомопрессового, жомосушильного и грануляционного отделений сахарного завода. Монтаж жомосушильного комплекса (жомосушек). Монтаж шнека-распределителя над прессами глубокого отжима. Монтаж циклона жомосушильного барабана',
    secondText: {
        text: `Наша компания выполнит работы по строительству и монтажу оборудования жомопрессового, жомосушильного и грануляционного отделений сахарного завода.

##### Список возможных работ:

- Изготовление фундаментов, технологических площадок;

- Монтаж жомосушильного комплекса (жомосушек);

- Монтаж жомопрессов глубокого отжима Babbini, GH-2 Sangerhausen;

- Монтаж шнека-распределителя над прессами глубокого отжима;

- Ремонт жомоотжимных прессов;

- Монтаж жомосушильного барабана, камера выгрузки, топки, нории;

- Монтаж циклона жомосушильного барабана;

- Монтаж охладительной колонки;

- Изготовление беличьего колеса;

- Монтаж грануляторов CPM;

- Монта складов гранулированного жома;

- Монтаж транспортеров;

- Изготовление новых самотеков, бункеров;

- Изготовление циклонов и камера выгрузки;

- Монтаж схемы пастеризации жомопрессовой воды;

- Замена теплоизоляции;

- Монтажу силового электрооборудования и системы освещения жомосушильного и жомогрануляционного отделений;

- Электромонтажные и пуско-наладочных работ в отделении прессов глубокого отжима и жомосушильном отделении;
`}};

