import firstImage from '../../images/pages/sugar-factories/firstImage.jpg';
import secondImage from '../../images/pages/sugar-factories/secondImage.jpg';
import {PageTemplates} from "../../pages/standartPages/enum";

export const sugarFactories = {
    url:'/sugar-factories',
    title:'Сахарные заводы',
    firstImage,
    secondImage,
    type: PageTemplates.TwoImagesTwoTextsType,
    alsoSeeIndex: 8,
    buttons: [{
        text: 'Моечный комплекс',
        url: '/washing-complex'
    },{
        text: 'Жомосушильный комплекс',
        url: '/zhomodushilny-complex'
    },{
        text: 'Диффузионное отделение',
        url: '/diffusion-department'
    },{
        text: 'Продуктовое отделение',
        url: '/grocery-department'
    },{
        text: 'Кристализационное отделение',
        url: '/crystallization-department'
    },{
        text: 'Известково-обжигательное отделение',
        url: '/lime-burning-department'
    },{
        text: 'Сахаросушильный комплекс',
        url: '/sugar-drying-complex'
    },{
        text: 'Свеклоперерабатывающее отделение',
        url: '/beet-processing-department'
    },{
        text: 'Выпарная станция',
        url: '/evaporation-station'
    },{
        text: 'Станция фильтрования',
        url: '/filtration-station'
    },{
        text: 'Станция дефекосатурации (сокоочистное отделение)',
        url: '/juice-department'
    }],
    keywords: 'строительство сахарного завода,монтаж оборудования сахарного завода,строительство цеха',
    description: 'Выполним работы связанные со строительством завода по переработке сахара. Строительство нового цеха клеровки сахара-сырца. Монтаж компрессоров. Монтаж с трубной и транспортёрной обвязкой мешалок, бункеров',
    firstText: {
        text: `## Наша компания выполнит работы связанные со строительством завода по переработке сахара.

###### Монтаж оборудования сахарного завода может включать:

- Изготовление отводов, переходов и их монтаж;

- Монтаж запорной арматуры;

- Монтаж трехпродуктовой схемы;

- Монтаж с трубной и транспортёрной обвязкой мешалок, бункеров;

- Строительство нового цеха клеровки сахара-сырца;

- Демонтаж изношенных мешалок, бункеров, утфелераспределителей;

- Монтаж системы пожаротушения цеха бестарного хранения сахара;

- Монтаж смеситель утфеля и мелассы;

- Установка сборник сиропа после сульфитации в конденсатном хозяйстве;

- Монтаж турбогенератора;

- Монтаж компрессоров;

- Монтаж паровой турбины в комплекте с генератором;

- Строительно-монтажные работы по замене паровых коммуникаций выпарных аппаратов;

- Монтаж грабельного транспортёра;

- Монтаж насосного парка с трубной обвязкой;

- Замена технологических трубопроводов;

- Демонтаж и монтаж емкости для хранения мелассы объемом;

- Демонтаж и монтаж сахарно-охладительных установок;

- Монтаж теплообменников;

- Строительство быстровозводимых складов для хранения сахара;

- Монтаж арочных ферм склада гранулированного жома;

- Монтаж и ввод в эксплуатацию турбоагрегата;

- Монтаж системы аспирации жомосушильного отделения;

- Монтаж система вентиляции известкового отделения и газовой печи;

- Установка различного крупногабаритного технологического оборудования;

- Изготовлением и монтаж шкафов управления, пультов и их подключение;`
    },
    secondText: {
        text: `#### Реконструкция сахарного завода:

- Изготовление и монтаж технологического оборудования нестандартного типа;

- Изготовление и монтаж металлоконструкций;

- Капитальный ремонт диффузионных аппаратов (А1 ПДС 20, ДС-12, ПДС-20);

- Капитальный ремонт бурякорезок (свеклорезок);

- Ремонт Центрифуг;

- Изготовление и монтаж мешалок, бункеров, утфелераспределителей;

- Замена изношенных технологических трубопроводов;

- Замена трубок пароперегревателя;

- Капитальный ремонт БУМов;

- Изготовление и монтаж сатураторов, дефекаторов их трубная обвязка;

- Капитальный ремонт свекломойки с изготовлением соломоботволовушек;

- Реконструкция трубопроводов пара, воды и сока, трубных коммуникаций включая трубное оборудование;

- Ремонт водяных и соковых насосов;

- Ремонт и наладка вакуумных фильтров (БсхШУ);

- Ремонт тракта подачи и мойки свеклы;

- Ремонт транспортера;

- Капитальный ремонт гидротранспортера;

- Ремонт жомосушильных барабанов;

- Реконструкция тепловой схемы завода;

- Реконструкция станции теплообменников;

- Реконструкция известняково-обжигательной печи;

- Реконструкция фильтров 1-й и 2-й сатурации ФиЛС;

- Реконструкция вакуум-конденсационных установок и контуров охлаждения оборотной воды;

- Реконструкция теплообменников, конденсатора;

- Модернизация отделения сушки и охлаждения сахара;

- Модернизация охладителя с кипящим слоем сахара;

- Реконструкция выпарной станции (Замена поверхности нагрева всех корпусов);

- Модернизация жомосушильного отделения с переводом на газ двух жомосушек;

- Реконструкция вакуум-аппаратов;

- Ремонт лобового сита;

- Изготовление черпачного колеса;

- Наплавлка корыта;

- Демонтаж шнеков с заменой витков и изготовлением новых спиц;

- Реконструкция парового котла (ГМ-50/14, HURTS, ДКВР-10, STANDART-KESSEL, БКЗ-75-39, БГМ 35, ДКВР 17/13, ДЕ25, ТС-35, ОГО-50, ДЕ 25/14);

- Строительно-монтажные работы по замене коммуникаций конденсаторного хозяйства;

- Ремонт с изготовлением и заменой изношенного оборудования на станции свеклоподачи и мойке свеклы;

- Изготовление и монтаж с обвязкой технологическими трубопроводами мешалки стандарт-сиропа;

- Замена паточных весов с обвязкой трубопроводами и демонтажем мешалки;

- Замена инсталляции редукторов и насоса с обвязкой технологическими трубопроводами;

- Изготовление шкафов управления.
`
    }};
