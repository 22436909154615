import firstImage from '../../images/pages/elevator-assembly-and-replacement/firstImage.png';
import secondImage from '../../images/pages/elevator-assembly-and-replacement/secondImage.png';
import {PageTemplates} from "../../pages/standartPages/enum";

export const elevatorAssemblyAndReplacement = {
    url: '/elevator-assembly-and-replacement',
    title: 'Монтаж / демонтаж нории',
    firstImage,
    secondImage,
    alsoSeeIndex: 10,
    keywords: 'монтаж нории,демонтаж нории,замена нории',
    manufacturers: {
        text: 'компания ООО «СИТАМ»  имеет большой опыт монтажа норий самых разных марок и конфигураций. В списке оборудования, с которым мы работаем, числятся агрегаты следующих производителей:Fratelli Pedrotti, Agrex, Mecmar, Metalmont, Frame, Bonfanti, Ravaro, Scolari, Strahl, Petcus, Araj, Chelik, Mepu, Neuero, Riela, Ozsu, ДСП и др. и других производителей в т.ч. отечественных.'
    },
    type: PageTemplates.ThreeTwoImagesTwoTextsType,
    description: 'Выполним установку и демонтаж нории ленточной ковшовой элеватора шрота, линии ЦДМ. Монтаж составляющих частей нории. Вырезка настила на технологических площадках под размеры нории. Монтаж башмака нории',
    firstText: {
        text: `Компания ООО «СИТАМ» Установим нории и другое промышленное оборудование на профессиональном уровне. На все виды работ организация имеет необходимые разрешения и свидетельства.

##### Выполним установку и демонтаж нории ленточной ковшовой элеватора шрота, линии ЦДМ и т.д Произведем следующие работы по замене нории:

Монтаж составляющих частей нории:

Изготовление и заливка фундамента;

Пробитие (расширение) бетона в перекрытиях в местах существующих проходов;

Вырезка настила на технологических площадках под размеры нории;

Переустановки и усиления существующих конструкций;

Перемещение на место монтажа всех необходимых материалов и оборудования;

Укорочение транспортера и смещение рамы натяжной станции;

Монтаж башмака нории;

Монтаж промежуточных секций;

Монтаж головки нории;

Монтаж ленты, ковшей;

Реконструкция и монтаж новой рамы (станины) под привод нории;

Монтаж и выставление привода нории на раму (станину);

Монтаж редуктора и эл. двигателя;

Монтаж муфты;

Врезка воздуховодов аспирационной системы;

Врезка самотеков;

Реконструкция и монтаж нового загрузочного самотека на фланцевых соединениях от выходного патрубка транспортера до входного патрубка нории;

Реконструкция и монтаж нового загрузочного самотека на фланцевых соединениях от выходного патрубка нории к входному патрубку транспортера;

Зашивка (обустройство) настила на технологических площадках в местах проходов смонтированной нории;

Монтаж взрыворазрядника к головке нории с выходом на крышу;

Приведение рабочего места в порядок;

Обустройство прохода для нории (бетонирование);

Пусконаладочные работы.`
    },
    secondText: {
        text: `#### Демонтаж составляющих частей нории:

Отсоединение главной части нории от фундамента;

Демонтаж фундамента мотор-редуктора;

Демонтаж анкерных болтов прикрепления натяжной станции;

Отсоединение натяжной станции и ее выведение;

Демонтаж привода нории;

Демонтаж и спуск приводного редуктора и электродвигателя;

Разъединение полумуфты редуктора и приводного вала;

Демонтаж полумуфты с вала, опорных подшипников, приводного вала, звездочек;

Демонтаж воздуховодов аспирационной системы;

Демонтаж ковшей, ленты;

Демонтаж головки нории;

Демонтаж секций путем постепенного опускания шахты и отсоединения секций;

Демонтаж короба;

Демонтаж защитного кожуха нории главной части;

Разъединение цепи и выемка из башмака шахты нории;

Демонтаж башмака нории;

Демонтаж рамы (станины) под привод нории;

Демонтаж разгрузочного и загрузочного самотеков;

Демонтаж лейки нории;

Демонтаж ревизионных взрыворазрядных люков;

Демонтаж взрыворазрядника.`
    }};
