import image from '../../images/pages/oil-pressing-line-installation/image.jpg';
import {PageTemplates} from "../../pages/standartPages/enum";

export const oilPressingLineInstallation = {
    url: '/oil-pressing-line-installation',
    title: 'Ремонт и монтаж оборудования маслоэкстракционного завода',
    image,
    keywords: 'ремонт оборудования,оборудование маслоэкстракционного завода,монтаж маслоэкстракционного завода,монтаж линии',
    type: PageTemplates.OneImageOneTextType,
    manufacturers: {
        text: 'ООО «СИТАМ» имеет большой опыт монтажа и демонтажа конвейеров (транспортеров) самых разных марок и конфигураций. В списке оборудования, с которым мы работаем, числятся агрегаты следующих производителей: ТСЦм, TЦС, КСЦ, КЦ-С, ТрЦС, К4-УТФ, КС, ТКНЦ и других производителей в т.ч. отечественных.'
    },
    description: 'Монтаж и демонтаж конвейеров (транспортеров) самых разных марок и конфигураций. В списке оборудования, с которым мы работаем, числятся агрегаты следующих производителей: ТСЦм, TЦС, КСЦ, КЦ-С, ТрЦС, К4-УТФ, КС, ТКНЦ и других производителей в т.ч. отечественных.',
    text: `Реконструкция маслоэкстракционного завода:
        
- Строительно-монтажные работы;

- Изготовление и монтаж металлоконструкций;

- Возведении фундаментов под емкости и резервуары;

- Зашивка сэндвич-панелями помещений и стен зданий;

- Демонтаж устаревшего оборудования;

- Монтаж технологических сетей и коммуникаций;

- Реконструкция и строительство зданий и сооружений;

- Реконструкция технологической линии цеха экстракции;

- Реконструкция станции разгрузки автомобилей;

- Изготовление емкостей из нержавеющей стали;

- Изготовление Бункеров;

- Ремонт технологических трубопроводов;

- Ремонт норий;

- Ремонт конвейеров;

- Удлинение транспортера;

- Сопровождение пуско-наладочных работ.`,
};