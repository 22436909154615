import secondImage from '../../images/pages/sugar-drying-complex/2.png';
import firstImage from '../../images/pages/sugar-drying-complex/1.png';
import {PageTemplates} from "../../pages/standartPages/enum";

export const sugarDryingComplex = {
    url: '/sugar-drying-complex',
    title: 'Монтаж оборудования сахаросушильного отделения',
    firstImage,
    secondImage,
    keywords: 'монтаж оборудования сахаросушильного отделения,оборудование сахаросушильного отделения',
    underTitle: `ООО «СИТАМ» Выполним монтаж и замену/демонтаж оборудования сахаросушильного отделения и другого промышленного оборудования на профессиональном уровне. На все виды работ организация имеет необходимые разрешения и свидетельства.`,
    type: PageTemplates.TwoImagesOneTextType,
    description: 'Техническое переоснащение сахаросушильного отделения сахарного завода. Монтаж сахаросушки (Fives-Cail). Монтаж бункера товарного сахара',
    text: `Наша компания выполнит техническое переоснащение сахаросушильного отделения сахарного завода.

##### В список работ может входить:

- Монтаж сахаросушки (Fives-Cail);

- Монтаж технологических площадок;

- Монтаж сендвич панелей;

- Монтаж сушильниого барабана;

- Монтаж вентиляторов и калориферов воздуха;

- Монтаж барабанных просеивателей;

- Монтаж скруббера;

- Монтаж бункера товарного сахара;

- Клеровочные мешалки и подогреватели клеровки;

- Монтаж вспомогательного обоурдования: системы аспирации, элеватор, ленточные и шнековые конвейера и др.
 `};



