import firstImage from '../../images/pages/reconstruction-of-the-meal-feed-line/1.jpg';
import secondImage from '../../images/pages/reconstruction-of-the-meal-feed-line/2.jpg';
import { PageTemplates } from "../../pages/standartPages/enum";

export const reconstructionOfTheMealFeedLine = {
    url: '/reconstruction-of-the-meal-feed-line',
    title: 'Реконструкція лінії подачі шрота',
    type: PageTemplates.TwoImagesTwoTextsType,
    keywords: 'ремонт лінії подачі шрота,линія подачі шрота',
    manufacturers: {
        text: 'ООО «СИТАМ» має великий досвід реконструкції та монтажу технологічних ліній різних марок і конфігурацій. В списку обладнання, з яким ми працюємо, числяться агрегати наступних виробників: Де Смет(DE SMET), Аллоко(ALLOCCO), «СРМ, ШМИДТ-ЗЕЕГЕР(Schmidt-Seeger) та інших виробників в т.ч. вітчизняних',
    },
    underTitle: 'ООО «СИТАМ» Реконструюємо технологічні лінії та інше промислове обладнання на професійному рівні. На всі види робіт організація має необхідні дозволи і свідоцтва.',
    firstImage,
    secondImage,
    description: 'Монтаж обладнання для повернення негранульованого шрота на ділянку грануляції в цеху видобутку олії (ЦДМ) та ремонт стрічки екстрактора в цеху екстракції. В списку обладнання, з яким ми працюємо, числяться агрегати наступних виробників: Де Смет(DE SMET), Аллоко(ALLOCCO), «СРМ, ШМИДТ-ЗЕЕГЕР(Schmidt-Seeger) та інших виробників в т.ч. вітчизняних',
    firstText: {
        text: `Монтаж обладнання для повернення негранульованого шрота на ділянку грануляції в цеху видобутку олії (ЦДМ) та ремонт стрічки екстрактора в цеху екстракції.

Реконструкція ділянки заводу з підготовки до переробки насіння соняшника

### Реконструкція передбачає:

Установка силоса для накопичення негранульованого шрота

### Заміна корпусу транспортера площадки норійної вежі: 

#### Розбірні роботи:

- Розбір секцій самотека з норії лінії шрота;

- Розбір кришок секцій конвеєра;

- Від'єднання і видалення тягового ланцюга;

- Розбір приводу, ведучого і ведучого валів;

- Розбір з'єднувальних пластин секцій і від'єднання секцій від опорних лап;

- Від'єднання тягових зірочок від привідного і ведучого валів;

- Переміщення розібраних секцій і ланцюга;

#### Монтажні роботи:

- Підйом нових секцій і привідного ланцюга а також привідної і ведучих зірочок;

- Збірка секцій конвеєра, установка на опорні елементи;

- Виготовлення і установка нових лап на площадку;

- Перевірка з'єднань між секціями на наявність перепадів;

- Збірка привідного і тягового валів з новими зірочками;

- Збірка привідної і натяжної секції, установка нових опорних підшипників, монтаж приводу;

- Збірка і установка нового тягового ланцюга, регулювання натяжки;

- Установка секцій самотека з виготовленням фланцевого з'єднання;

- Виконання отвору в кришці і дні привідної секції;

- Виготовлення фланцевого з'єднання для подачі товару з конвеєра;

- Пуско-налагоджувальні роботи.

#### Продовження корпусу транспортера на площадці елеватора:

- Розбір натяжної секції конвеєра;

- Від'єднання і видалення тягового ланцюга, відключення секцій від опорних лап;

- Виготовлення і установка 3 опорних лап під нову секцію і переміщену натяжну станцію;

- Підйом на висоту і установка додаткової секції конвеєра;

- Підйом на висоту і установка нового тягового ланцюга конвеєра;

- Переміщення розібраного ланцюга;

- Вирізка отвору під самотек норії в кришці встановленої секції;

- Виготовлення і монтаж фланця під існуючий самотек;

- Пуско-налагоджувальні роботи.`,
    },
    secondText: {
        text: `Скорочення довжини корпусу транспортера в галереї грануляції:

- Розбір привідної секції конвеєра разом з приводом;

- Від'єднання і видалення тягового ланцюга трьох секцій, відсоединение 2 секцій від опорних лап і їх розбір;

- Відключення і розбір шиберної засувки привідної і проміжної секцій;

- Розбір самотека з конвеєра, відкручування конвеєра від опорних лап;

- Зміщення конвеєра в напрямку привідної станції;

- Зрізка кріплення 3-х опорних лап, переміщення і закріплення на новому місці;

- Виготовлення і установка заглушки на конвеєр;

- Виготовлення проміжного фланця привідної станції, просверлить отвори під кріплення;

- Зібрати конвеєр і з'єднати тяговий ланцюг, закріпити конвеєр;

- Розширити отвір в кришці конвеєра натяжної станції під самотек з конвеєра, отвір, який утворився в кришці перекрити листом;

- Установка самотека з конвеєра;

- Розбір самотеком з одного конвеєра на інший;

- Виконання проїму в бетонному перекритті;

- Виготовлення нового самотека;

- Використання існуючого самотека та фланця;

- Використання існуючої кришки секції конвеєра з фланцем, встановивши її на секцію;

- Виготовлення з'єднувальної пластини кришок і установка;

- Пуско-налагоджувальні роботи.

#### Продовження корпусу нахилного конвеєра типу «гусиний вузол» на площадці норійної вежі:

- Установка вантажопідйомного обладнання;

- Розбірка і розбір конвеєра за необхідності;

- Розбірка і розбір приводу конвеєра за необхідності;

- Розбір кришки привідної секції, від'єднання тягового ланцюга конвеєра, і вилучення його з шахти;

- Розбір привідної секції конвеєра;

- Розбір короткої секції встановленої за приводом;

- Розбір рами привідної секції;

- Розташування привідної секції, рами, приводу, і секції;

- Монтаж 3 секцій;

- Встановити 2 опори закріпивши секцію з шибером до двутаврової балки за допомогою кронштейнів;

- Скласти раму і привідну секцію;

- Встановити привід;

- Запустити і з'єднати тяговий ланцюг;

- Виготовити і скласти воронку транспортування товару з конвеєра на конвеєр;

- Пуско-налагоджувальні роботи.

#### Ремонт стрічки екстрактора:

- Заміна пластин стрічки екстрактора;

- Заміна роликів стрічки екстрактора;

- Заміна втулок роликів;

- Заміна штифтів роликів;

- Заміна болтів ланок стрічки екстрактора;

- Заміна трубок стрічки екстрактора;

- Натяг стрічки екстрактора;

- Центрування стрічки екстрактора.

#### Виготовлення і монтаж опорних металоконструкцій і площадок обслуговування:

- Монтаж стійок, двутаврових балок
- Виготовлення і монтаж металоконструкцій під конвеєри і транспортер
`}};

