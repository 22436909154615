import firstImage from '../../images/pages/juice-department/1.jpg';
import {PageTemplates} from "../../pages/standartPages/enum";

export const juiceDepartment = {
        url: '/juice-department',
        title: 'Встановлення обладнання станції дефекосатурації',
        firstImage,
        keywords: 'встановлення обладнання станції,встановлення станції дефекосатурації',
        type: PageTemplates.OneImageTwoTextsSmallType,
        firstText: {
            text: `Виконаємо встановлення та заміну/демонтаж обладнання станції дефекосатурації та іншого промислового обладнання на професійному рівні. На всі види робіт організація має необхідні дозволи та свідоцтва.`
        },
        description: 'Виконаємо роботи з монтажу обладнання та реконструкції станції дефекосатурації цукрового заводу. Монтаж апарата прогресивної переддефекації ТМА-ППД. Монтаж статичного, динамічного змішувача ТМА-ПСК',
        secondText: {
            text: `Наша компанія виконає роботи з монтажу обладнання та реконструкції станції дефекосатурації цукрового заводу.

##### До переліку робіт можуть входити:

- Встановлення апарата прогресивної переддефекації ТМА-ППД;

- Встановлення статичного, динамічного змішувача ТМА-ПСК;

- Встановлення апарата гарячої дефекації;

- Встановлення збирника ненапівфільтрованого соку;

- Встановлення сатураторів ТS 1 і 2 сатурації;

- Встановлення газорозподільної системи для сатуратора (‘Трубки Рихтера’);

- Встановлення апарата для проведення 1-ї ступеня основної дефекації (холодний дефекатор)ТМА-ПДХ;

- Встановлення апарата для проведення 2-ї ступеня основної дефекації (гарячий дефекатор)ТМА-ОД;

- Встановлення котлів 1 і 2-ї сатурації;

- Встановлення вугільних котлів (OR-32);

- Встановлення та заміна насосних агрегатів;
`}
    };



