import image from '../../images/pages/oil-shop-brazier-repair/1.jpg';
import {PageTemplates} from "../../pages/standartPages/enum";

export const oilShopBrazierRepair = {
    url: '/oil-shop-brazier-repair',
    title: 'Ремонт жаровни маслоцеха',
    image,
    keywords: 'ремонт жаровни,ремонт жаровни маслоцеха,ремонт чанной жаровни',
    type: PageTemplates.OneImageOneTextType,
    manufacturers: {
        text: 'ООО «СИТАМ» имеет большой опыт ремонта чанных жаровень самых разных марок и конфигураций. В списке оборудования, с которым мы работаем, числятся агрегаты следующих производителей: Ж-60/3, Ж-68, ЖМ-8, ПМШ 450, Ж-80/5,Ж-93, Ж-100/6, Ж-120/7, Ж-140, Ж-150/7, Ж-200/8, ЖЗУ и других производителей в т.ч. отечественных.'
    },
    description: 'Выполним ремонт много-чанных жаровень (кондиционер/сушилка) для обработки масличных культур в маслоцехе. Срезание деформированных и изношенных частей ножа чанов. Изготовление новых частей ножа и их установка (реставрация). Наплавка режущей кромки ножа твердоплавкими электродам',
    underTitle: 'ООО «СИТАМ» Ремонтируем жаровни и другое промышленное оборудование на профессиональном уровне. На все виды работ организация имеет необходимые разрешения и свидетельства.',
    text: `Выполним ремонт много-чанных жаровень (кондиционер/сушилка) для обработки масличных культур в маслоцехе.

#### Ремонт жаровни для обработки масличных культур может включать:

- Срезание деформированных и изношенных частей ножа чанов;

- Изготовление новых частей ножа и их установка (реставрация);

- Наплавка режущей кромки ножа твердоплавкими электродами;

- Установка дублирующих листов(перекрывание сварных швов);

- Демонтаж ножей;

- Проверка установочных зазоров;

- Правка ножей;
`,
};
