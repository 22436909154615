import firstImage from '../../images/pages/silo-cleaning/1.jpg';
import secondImage from '../../images/pages/silo-cleaning/2.jpg';
import {PageTemplates} from "../../pages/standartPages/enum";

export const siloCleaning = {
    url: '/silo-cleaning',
    title: 'Очищення силосу',
    type: PageTemplates.TwoImagesTwoTextsType,
    keywords: 'очищення силосу,очищення бункера,очищення елеватора,очищення силосних корпусів',
    manufacturers: {
        text: 'ТОВ «СІТАМ» має досвід роботи з очищення силосів, бункерів, силосних корпусів елеватора та цементних силосів в Україні. Ми пропонуємо довгострокове співробітництво в очищенні силосів та бункерів підприємств від цементу, бетонних розчинів, будівельних сумішей з застосуванням сучасних технологій очищення та безпеки до безпосередньої подачі повітря фахівцю, що виконує очищення силосу',
    },
    underTitle: 'ТОВ «СІТАМ» Встановлюємо дистилятори та інше промислове обладнання на професійному рівні. На всі види робіт організація має необхідні дозволи та свідоцтва.',
    firstImage,
    secondImage,
    description: 'Роботи з очищення силосів, бункерів, силосних корпусів елеватора та цементних силосів. Зачищення верхніх конусів металевих силосів елеватора. Очищення бункерів астероїдного типу, обрушення шрота',
    firstText: {
        text: `##### Ми також можемо виконати:

- Зачищення верхніх конусів металевих силосів елеватора;

- Зачищення від накипів аспіраційних відходів дахів виробничих приміщень;

- Очищення бункерів астероїдного типу, обрушення шрота;

- Очищення робочої вежі та обладнання елеватора.

В залежності від ситуації наші фахівці застосовують наступні види очищення:

**Механічне очищення** силосів і бункерів від затримок сипучих матеріалів особливо популярне у випадку спорожнення ємності після тривалого зберігання товару. Сипучі продукти - зерно, добрива, крупи, цемент і інші - стискаються під дією багатотонної навантаження в практично монолітну масу, що ускладнює процедуру вивантаження товару з силосу.
`
    },
    secondText: {
        text: `Для усунення подібної ситуації необхідна група промислових альпіністів, які зможуть розрушити структуру «пробки» в силосі за допомогою простих механічних пристосувань - щіток, скребків і т.п. Попередньо оглянувши стан споруди та вмісту, визначають метод і план проведення робіт.

**Гідроструминне очищення** внутрішніх поверхонь стін силосів застосовується, коли механічне очищення не дасть бажаного результату. Це обумовлено тим, що на стінах силосів можуть бути значні забруднення. Зазвичай гідроструминне очищення застосовується, коли:

- Адгезія і твердість забруднень не дозволяють видалити їх ручним інструментом;

- Забруднення представляють собою плісняві гриби;

Забруднення отруюють повітряне середовище в силосі і необхідна висока швидкість очищення.
Для гідроструминного очищення ми застосовуємо апарати високого (230 бар) та надвисокого (500 бар) тиску.

##### Переваги гідроструминного очищення силосів:

- Забезпечується високий клас очищення;

- Не пошкоджується глянцева поверхня СОГ;

- Знімаються слабо закріплені ділянки бетону і заповнювача міжсгових швів;

- З потоками води вимиваються навіть дрібні частинки забруднень і пилу;

- У процесі не утворюється іскр.

Очищення силосів необхідне як перед проведенням планових і позапланових ремонтів, так і для видалення залишків зернових культур перед завантаженням нових. Крім того, проводиться планове очищення силосів для проведення дезінфекційних заходів в процесі експлуатації. Щоб уникнути знищення, видалити залишки та утворені відкладення продукту на стінах виробнику потрібна допомога промислових альпіністів. Очищення виконується як вручну, так і за допомогою апаратів гідроструминного очищення
`}};