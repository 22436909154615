import firstImage from '../../images/pages/evaporation-station/1.jpg';
import {PageTemplates} from "../../pages/standartPages/enum";

export const evaporationStation = {
    url: '/evaporation-station',
    title: 'Монтаж оборудования выпарной станции',
    firstImage,
    keywords: 'монтаж выпарной станции,демонтаж оборудования выпарной станции,ремонт выпарной станции',
    type: PageTemplates.OneImageTwoTextsSmallType,
    firstText: {
        text: `Выполним монтаж и замену/демонтаж оборудования выпарной станции и другого промышленного оборудования на профессиональном уровне. На все виды работ организация имеет необходимые разрешения и свидетельства.`
    },
    description: 'Работы по монтажу оборудования и реконструкции выпарной станции сахарного завода. Монтаж выпарных аппаратов (станции) со сборниками конденсата (ТВП, А2-ППВ-2120). Демонтаж и монтаж выпарочных барабанов. Установка скоростных и пластинчатых подогревателей',
    secondText: {
        text: `Наша компания выполнит работы по монтажу оборудования и реконструкции выпарной станции сахарного завода.

##### В список работ могут входить:

- Монтаж выпарных аппаратов (станции) со сборниками конденсата (ТВП, А2-ППВ-2120)

- Демонтаж и монтаж выпарочных барабанов;

- Установка скоростных и пластинчатых подогревателей;

- Подготовительные демонтажные работы;

- Монтаж технологического оборудования;

- Монтаж технологических трубопроводов;

- Монтаж запорной арматуры;

- Изготовление и монтаж отводов, коллекторов, и переходов;
`}};

