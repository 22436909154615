import firstImage from '../../images/pages/installation-of-technological-lines/firstImage.jpeg';
import secondImage from '../../images/pages/installation-of-technological-lines/secondImage.jpg';
import {PageTemplates} from "../../pages/standartPages/enum";

export const installationOfTechnologicalLines = {
    url: '/installation-of-technological-lines',
    type: PageTemplates.TwoImagesTwoTextsType,
    keywords: 'монтаж технологічних ліній,монтаж збірочно-зварювальних ліній,монтаж лінії упаковки',
    manufacturers: {
        text: 'ТОВ «СІТАМ» має великий досвід монтажу виробничих ліній різних конфігурацій: агрегатно-потокові лінії, потокові технологічні лінії (Конвеєрні).',
    },
    description: 'Виконаємо монтаж технологічних ліній, конвеєрів, транспортерів, інженерних і технологічних систем. Монтаж збірочно-зварювальних ліній. Монтаж ліній по виробництву труб. Монтаж лінії упаковки',
    title: 'Монтаж технологічних ліній',
    underTitle: 'ТОВ «СІТАМ» Виконаємо монтаж технологічних ліній, конвеєрів, транспортерів, інженерних і технологічних систем, і іншого технологічного обладнання промислового виробництва на професійному рівні. На всі види робіт організація має необхідні дозволи і свідоцтва.',
    firstImage,
    secondImage,
    firstText: {
        text: `### Установка виробничих ліній
        
До переліку проектів, виконаних нашою компанією, входять:

- Монтаж збірочно-зварювальних ліній;

- Монтаж ліній по виробництву труб;

- Монтаж лінії упаковки;

- Монтаж пресових ліній;

- Монтаж технологічної лінії фарбування;

- Монтаж лінії вакуумного плавлення;

- Монтаж лінії по виробництву вафельних стаканчиків;

- Монтаж лінії обробки прутка;

- Монтаж лінії по виробництву пластикових пляшок;

- Монтаж лінії виробництва тканин;

Нам довіряють найскладніше технологічне обладнання. Всі роботи виконуються якісно і в оговорені терміни.

монтаж-технологічних-ліній монтаж-виробничих-ліній`
    },
    secondText: {
        text: `### Монтаж виробничих ліній підприємств
        
Виконаємо монтаж промислових ліній вітчизняного та імпортного виробництва, нових і вживаних.

Ми здійснюємо проекти з монтажу виробничих ліній в різних галузях промисловості:

- Харчова;

- Целюлозно-паперова;

- Будівельна;

- Гірничо-добувна;

- Медична;

- Металообробна;

- Автомобільне виробництво тощо;

Послуги з встановлення виробничих і технологічних ліній можуть включати:

- Розмітка і підготовка фундаменту, проектні роботи;

- Монтаж закладних виробів (плит, пластин, балок);

- Переміщення обладнання до місця монтажу;

- Встановлення обладнання на фундамент (перевірка, кріплення анкерами, заливка фундаменту);

- Підзбіркова збірка обладнання (зварювальні роботи);

- Механічний монтаж компонентів лінії;

Електромонтаж (встановлення електричних шаф, прокладка і розводка силових кабелів, - монтаж електрообладнання)
Прокладка і підключення обладнання до заводських мереж (стиснений повітря, вода і т.д.);

- Монтаж гідравлічних і пневматичних систем, трубопроводів;

- Проектування і монтаж технологічних металоконструкцій, площадок обслуговування, перекриттів, сходів і переходів;

- Постачання витратних матеріалів (кабелі, лотки, труби, трубопровідна арматура, метизи і т.д.);

- Пусконалагодження, налаштування і регулювання обладнання;

- Здача в експлуатацію.

- Ми використовуємо сучасну матеріально-технічну базу і спеціалістів з багаторічним успішним досвідом подібних робіт і наявністю необхідних дозволів і ліцензій.
`}};