import firstImage from '../../images/pages/installation-of-oil-winterization-freezing-line/1.jpg';
import {PageTemplates} from "../../pages/standartPages/enum";

export const installationOfOilWinterizationFreezingLine = {
    url: '/installation-of-oil-winterization-freezing-line',
    title: 'Монтаж лінії вінтеризації масла (заморожування)',
    firstImage,
    keywords: 'монтаж лінії вінтеризації масла,вінтеризація масла,заморожування масла',
    manufacturers: {
        text: 'ТОВ «СІТАМ» має великий досвід монтажу лінії вінтеризації масла (заморожування)',
    },
    maxSize: true,
    type: PageTemplates.OneImageTwoTextsSmallType,
    description: 'Спроектуємо, виготовимо і змонтуємо комплекси періодичної дії для заморожування (вінтеризації) рафінованих пресованих рослинних олій. Монтаж кристалізатора. Монтаж насосів для перекачування масла. Монтаж резервуарів',
    secondText: {
        text: `Наше підприємство проектує, виготовляє і монтує комплекси періодичної дії для заморожування (вінтеризації) рафінованих пресованих рослинних олій продуктивністю 6 - 12 тонн масла на добу.

#### Монтаж лінії вінтеризації:

- Монтаж кристалізатора;

- Монтаж насосів для перекачування масла;

- Монтаж ємностей;

- Монтаж резервуарів;

- Монтаж холодильної машини;

- Монтаж Фільтрів;

- Монтаж теплообмінників;

- Монтаж шаф управління;

- Монтаж запірної арматури;
`
    }};


