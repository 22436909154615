import firstImage from '../../images/pages/scraper-conveyor-conveyor-repair/1.jpg';
import secondImage from '../../images/pages/scraper-conveyor-conveyor-repair/2.jpg';
import {PageTemplates} from "../../pages/standartPages/enum";

export const scraperConveyorConveyorRepair = {
    url: '/scraper-conveyor-conveyor-repair',
    title: 'Ремонт скребкового конвейєра (транспортера)',
    keywords: 'ремонт скребкового конвейєра,ремонт транспортера,ремонт конвейєра',
    type: PageTemplates.ThreeTwoImagesTwoTextsType,
    manufacturers: {
        text: 'ТОВ «СІТАМ» має великий досвід ремонту транспортерів найрізноманітніших марок і конфігурацій. У списку обладнання, з яким ми працюємо, числяться агрегати наступних виробників: ТСЦм, TЦС, КСЦ, КЦ-С, ТрЦС, К4-УТФ, КС і інших виробників, в тому числі вітчизняних.',
    },
    underTitle: 'ТОВ «СІТАМ» виконає ремонт ланцюгових скребкових транспортерів та іншого промислового обладнання на професійному рівні. На всі види робіт організація має необхідні дозволи та свідоцтва',
    firstImage,
    secondImage,
    description: 'Роботи з поточного, капітального ремонту та обслуговування ланцюгових скребкових конвейерів (транспортерів), призначених для переміщення насипних і насипних вантажів горизонтально та під кутом нахилу (гусиний шия). У списку обладнання, з яким ми працюємо, числяться агрегати наступних виробників: ТСЦм, TЦС, КСЦ, КЦ-С, ТрЦС, К4-УТФ, КС і інших виробників, в тому числі вітчизняних',
    firstText: {
        text: `Наша компанія виконує роботи з поточного, капітального ремонту та обслуговування ланцюгових скребкових конвейерів (транспортерів), призначених для переміщення насипних і насипних вантажів горизонтально та під кутом нахилу (гусиний шия). Залежно від технології конвейер може знаходитися на різних висотних відмітках.

Використовується в основному для транспортування зерна зі звалювального яру, силосу, подачі лушпиння, шроту, комбікормів, насіння соняшнику та ін. на елеваторах, зернозберігальних спорудах, маслозаводах (ЦДМ), також використовуються в хімічній, гірничодобувній, будівельній, металургійній промисловості та інших галузях.

### Роботи з ремонту можуть включати:

- Діагностика і демонтаж кришок коробки транспортера, комплектація прижимними пристроями;

- Очищення редуктора, сапуна;

- Демонтаж редуктора, муфти та привідного вала транспортера;

- Ремонт редуктора з кріпленням та стійкою;

- Перевірка посадкового місця під редуктор та шпонкового з'єднання;

- Демонтаж вала та катка натяжної станції транспортера;

- Дефектовка стану опорних валів та направляючих;

- Демонтаж/монтаж секції направляючих тягового ланцюга;

- Ремонт направляючих ланцюга (різцювання, накладання дублера, заміна частин);

- Демонтаж та монтаж прохідних секцій;

- Усунення зносу бічної стінки (днища) конвейера накладенням дублера;
`
    },
    secondText: {
        text: `### Роботи з ремонту можуть включати також:

- Заміна нижньої та бічної броні;

- Заміна зношених накладок;

- Заміна болтів кріплення коробок;

- Заміна підшипників та сальників натяжного катка та монтаж на транспортер;

- Заміна з'єднувальних пальців та накладних скребків тягового ланцюга;

- Демонтаж/монтаж тягового ланцюга в коробку транспортера;

- Заміна підшипників електродвигуна;

- Заміна підшипників привідної станції;

- Заміна підшипників натяжної станції;

- Заміна підшипників редуктора;

- Огляд стану валів (привідного та натяжного) перевтулювання у разі необхідності;

- Заміна вала та ролика привідної станції;

- Заміна ролика натяжної станції;

- Заміна підтримуючих роликів ланцюга;

- Заміна Шайби тарільчастої;

- Огляд стану привідної та натяжної зірочки, заміна у разі необхідності;

- Огляд стану бронзових втулок (заміна у разі необхідності);

- Ремонт привідної станції;

- Ремонт натяжної станції;

- Ремонт прохідної станції;

- Заміна гумових втулок на муфті редуктора;

- Збірка та центрування вузла (вали, зірочки, втулки, муфта, редуктор та електродвигун);

- Заміна клинових ременів, центрування та регулювання натягу приводу;

- Монтаж захисних кришок;

- Ремонт самотіка зходу товару з транспортера;

- Ремонт шиберних засувок;

- Зварювальні роботи (латання зносів, заміна кутників підсилення та ін.);

- Заправка редуктора маслом;

- Закладка високотемпературної смазки в місцях тертя деталей;

- Відновлення функціонування системи змазування втулок, підшипників;

- Перевірка та регулювання натягу тягового ланцюга;

- Перевірка якості збірки холостим ходом;

- Вивезення вживаних частин транспортера до місця зберігання / утилізації;

- Прибирання робочого місця.
`}};

