import firstImage from '../../images/pages/design-project-approval-with-the-state-institution-of-emergency-situations/1.png';
import secondImage from '../../images/pages/design-project-approval-with-the-state-institution-of-emergency-situations/2.webp';
import thirdImage from '../../images/pages/design-project-approval-with-the-state-institution-of-emergency-situations/3.jpg';
import {PageTemplates} from "../../pages/standartPages/enum";

export const designProjectApprovalWithTheStateInstitutionOfEmergencySituations = {
    url: '/design-project-approval-with-the-state-institution-of-emergency-situations',
    type: PageTemplates.TwoImagesTwoTextsType,
    title: 'Молниезащита',
    manufacturers: {
        title: 'Наш технічний партнер',
        images: [{
            src: thirdImage,
            url: 'https://www.leolightman.com'
        }]
    },
    keywords: 'монтаж молниезащиты,монтаж заземления,ГС ЧС,установка защитного контура',
    firstImage,
    secondImage,
    description: 'Монтаж молниезащиты, молниеотвода(громоотвода), защитного контура, заземления на профессиональном уровне. ',
    firstText: {
        text: `### Заземление – його важливість та особливості

Окрема тема – заземлення в приватному будинку. Правильно спроектоване, воно гарантує електробезпеку побутової техніки та збереження життя при потраплянні блискавки. Але для чого потрібно заземлення:
- виключення появи високої напруги в грозу;
- попередження небезпечних ситуацій, коли людина доторкається до електроустановки руками;
- запобігання замикань та загорянь.

Для правильної та безпечної роботи системи необхідно виготовлення проектної частини та використання довговічних матеріалів.

Пропонуємо комплексне рішення даного питання.

1. Проектування.
2. Монтаж системи.
3. Освідетельствування та виміри сертифікованою лабораторією.
4. Узгодження з службою ГС ЧС.
`
    },
    secondText: {
        text: `##### Якщо блискавка потрапить в будівлю, результат може бути не просто печальними, а трагічними
        
Щоб такого не сталося, потрібна **молниезахист**. Це комплекс спеціальних установок та технічних рішень. Але в цілому схема встановлення молниезахисту складається з схожих компонентів:

- молниеотводу (друге найменування – громоотвод);
- захисного контура;
- заземлення.

##### Зовнішня та внутрішня молниезахист

Молниезахист буває зовнішній та внутрішній. По-перше, необхідно встановити зовнішній громоотвод. Основний його елемент – один або кілька приймачів. Зазвичай вони монтується на даху будівель. Основне призначення приймачів – брати на себе удар блискавки, не пропускати блискавку до будівель і споруд, а також прилеглої до них ділянки. Виконуються вони у вигляді мачт, тросів або стрижнів. Зона іонізації регулюється з урахуванням їх параметрів.

При розробці молниезахисту також враховуються параметри будівлі: місце, ландшафт, вогнестійкість.

При облаштуванні молниезахисту та громоотводу важливий правильний вибір матеріалів.

`}};
