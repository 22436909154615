import firstImage from '../../images/pages/disassembly-installation-of-miscella-distiller/firstImage.jpg';
import {PageTemplates} from "../../pages/standartPages/enum";

export const disassemblyInstallationOfMiscellaDistiller = {
        url: '/disassembly-installation-of-miscella-distiller',
        title: 'Монтаж / демонтаж дистиллятора місцелі',
        firstImage,
        type: PageTemplates.OneImageTwoTextsSmallType,
        keywords: 'монтаж дистиллятора місцелі,монтаж дистиллятора,заміна дистиллятора,заміна дистиллятора місцелі,кронштейн,демонтаж трубопроводу',
        description: 'Ремонт дистилляторів найрізноманітніших марок та конфігурацій. Обладнання, з яким ми працюємо: Alfa Laval, Bernardini, Andreotti, Westfalia та інших виробників, включаючи вітчизняних.',
        manufacturers: {
            text: 'Компанія ТОВ "СІТАМ" має великий досвід ремонту дистилляторів найрізноманітніших марок та конфігурацій. У списку обладнання, з яким ми працюємо, числяться агрегати наступних виробників: Alfa Laval, Bernardini, Andreotti, Westfalia та інших виробників, включаючи вітчизняних.'
        },
        firstText: {
            text: `ТОВ "СІТАМ" Встановлюємо дистиллятори та інше промислове обладнання на професійному рівні. На всі види робіт організація має необхідні дозволи та свідоцтва.

Виконаємо монтаж, демонтаж та пусконалагоджувальні роботи щодо заміни дистиллятора 1-го ступеня на ділянці олія-екстракційного цеху та інших видів промислового виробництва.`
        },
        secondText: {
            text: `Демонтаж:

- Встановлення монтажних кронштейнів;

- Роз'єднання фланцевого з'єднання та демонтаж трубопроводу парів гексана конденсатора дистиляції;

- Демонтаж трубопроводу;

- Роз'єднання фланцевого з'єднання подачі парів гексана з тостера;

- Від'єднання верхньої ємності (сепаратора) від кожухотрубного теплообмінника;

- Розбірка фланцевого з'єднання верхньої ємності (сепаратора) та кожухотрубного теплообмінника та демонтаж сепаратора;

- Демонтаж нержавіючої ізоляції дистиллятора 1-го ступеня та кожухотрубного теплообмінника;

- Демонтаж ізоляції з мінеральної вати;

- Демонтаж шини кріпильного пояса ізоляції;

- Демонтаж нижньої кришки теплообмінника;

- Від'єднання трубопроводу стоку конденсату;

- Від'єднання трубопроводу переходу парів гексана на другу ступінь дистиллятора;

- Від'єднання трубопроводу стоку місцелли;

- Встановлення двутаврової балки та вантажного пристрою;

- Від'єднання опорних лап обладнання від несучих балок та його демонтаж;

- Транспортування обладнання.

Монтаж:

- Монтаж виконується у зворотній послідовності;

- Виготовлення нової частини корпусу;

- Встановлення нового обшивки дистиллятора та перевірка зварних швів;

- Виготовлення та встановлення нових прокладок на фланцевих з'єднаннях;

- Встановлення/відновлення ізоляції на обладнанні;

- Перевірка дистиллятора на герметичність та гідравлічне випробування;

- Перевірка та налаштування всіх монтажних елементів.`
        }
    };
