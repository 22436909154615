import firstImage from '../../images/pages/design-project-approval-with-the-state-institution-of-emergency-situations/1.png';
import secondImage from '../../images/pages/design-project-approval-with-the-state-institution-of-emergency-situations/2.webp';
import thirdImage from '../../images/pages/design-project-approval-with-the-state-institution-of-emergency-situations/3.jpg';
import {PageTemplates} from "../../pages/standartPages/enum";

export const designProjectApprovalWithTheStateInstitutionOfEmergencySituations = {
    url: '/design-project-approval-with-the-state-institution-of-emergency-situations',
    type: PageTemplates.TwoImagesTwoTextsType,
    title: 'Молниезащита',
    manufacturers: {
        title: 'Наш технический партнёр',
        images: [{
            src: thirdImage,
            url: 'https://www.leolightman.com'
        }]
    },
    keywords: 'монтаж молниезащиты,монтаж заземления,ГС ЧС,установка защитного контура',
    firstImage,
    secondImage,
    description: 'Монтаж молниезащиты, молниеотвода(громоотвода), защитного контура, заземления на профессиональном уровне. ',
    firstText: {
        text: `### Заземление – его важность и особенности

Отдельная тема – заземление в частном доме. Правильно спроектированное, оно гарантирует электробезопасность бытовой техники и сохранения жизни при попадании молнии. Но для чего нужно заземление:
- исключение появления высокого напряжения в грозу;
- предупреждение опасных ситуаций, когда человек касается к электроустановки руками;
- предотвращение замыканий и возгораний.

Для правильной и безопасной работы системы необходимо изготовление проектной части и использование долговечных материалов.

Предлагаем комплексное решение данного вопроса.

1. Проектирование.
2. Монтаж системы.
3. Освидетельствование и замеры сертифицированной лабораторией.
4. Согласование со службой ГС ЧС.
`
    },
    secondText: {
        text: `##### Если молния попадет в здание, итог может оказаться не просто печальными, а трагичными
        
Чтобы такого не произошло, нужна **молниезащита**. Это комплекс специальных установок и технических решений. Но в целом схема установки молниезащиты состоит из схожих компонентов:

- молниеотвода (второе название – громоотвод);
- защитного контура;
- заземления.

##### Внешняя и внутренняя молниезащита

Молниезащита бывает наружной и внутренней. Прежде всего, необходимо установить наружный громоотвод. Основной его элемент – один либо несколько приемников. Обычно они монтируются на крыше зданий. Основное назначение приемников – брать на себя удар молнии, не пропустить молнию к зданиям и сооружениям, а также прилегающему к ним участку. Выполняются они в виде мачт, тросов или стержней. Зона ионизации регулируется с учетом их параметров.

При разработке молниезащиты также учитываются параметры здания: место, ландшафт, пожароустойчивость.

При обустройстве молниезащиты и громоотвода важен правильный выбор материалов.

`}};
