import secondImage from '../../images/pages/repair-of-a-grain-dryer/1.jpg';
import firstImage from '../../images/pages/repair-of-a-grain-dryer/2.jpeg';
import {PageTemplates} from "../../pages/standartPages/enum";

export const repairOfaGrainDryer = {
    url: '/repair-of-a-grain-dryer',
    title: 'Ремонт и обслуживание зерносушилки',
    firstImage,
    secondImage,
    keywords: 'ремонт зерносушилки,обслуживание зерносушилки,очищение зерносушилки,замена зерносушилки',
    manufacturers: {
        text: 'ООО «СИТАМ» имеет большой опыт ремонта и монтажа элеваторного оборудования, включая силосы, нории, конвейеры, зерносепараторы и зерносушилки разных производителей: Fratelli Pedrotti, Agrex, Mecmar, Metalmont, Frame, Bonfanti, Ravaro, Scolari, Strahl, Petcus, Araj, Chelik, Mepu, Neuero, Riela, Ozsu, ДСП и др.',
    },
    type: PageTemplates.TwoImagesTwoTextsType,
    firstText: {
        text: `###### Установку зерносушилки производят на улице, вследствие атмосферного воздействия и постоянных нагрузок с течением времени теряется производительность и появляются неисправности требующие ремонта.

###### При регулярном техническом обслуживании и ремонте, работа зерносушилок будет соответствовать установленным нормам изготовителя.
`,
    },
    description: 'Ремонт и монтаж элеваторного оборудования, включая силосы, нории, конвейеры, зерносепараторы и зерносушилки разных производителей: Fratelli Pedrotti, Agrex, Mecmar, Metalmont, Frame, Bonfanti, Ravaro, Scolari, Strahl, Petcus, Araj, Chelik, Mepu, Neuero, Riela, Ozsu, ДСП и др',
    secondText: {
        text: `### Ремонт зерносушилок может включать:

- Очищение от пыли, грязи и ржавчины;

- Осмотр и очистка вентиляционного оборудования;

- Ремонта вентиляторов;

- Замена прогоревших элементов топки;

- Ремонт форсунки, приводов, насосов, топливных баков и т.д;

- Ремонт системы автоматики, замена электроприборов;

- Ремонт гидравлической системы;

- Ремонт, замена шнека;

- Ремонт теплообменника;

- Замена, ремонт электродвигателя;

- Проверка герметичности теплопровода;

- Реконструкция отделения очистки и замена оборудования;

- Установка заплаток на разрушенные части шахты;

- Ремонта и замены коробов;

- Устранение деформации полок и лотков рамы;

- Покраска антикоррозийной краской;

- Проверка на холостом ходу и под нагрузкой
- Проверка исправность всех приборов;
`}};



