import secondImage from '../../images/pages/repair-of-the-dryer-cooler-of-meal/1.jpg';
import firstImage from '../../images/pages/repair-of-the-dryer-cooler-of-meal/2.jpg';
import {PageTemplates} from "../../pages/standartPages/enum";

export const repairOfTheDryerCoolerOfMeal = {
    url: '/repair-of-the-dryer-cooler-of-meal',
    title: 'Ремонт сушилки-охладителя шрота (DC)',
    firstImage,
    secondImage,
    keywords: 'сушилка-охладитель шрота,ремонт сушилки-охладителя,ремонт шрота',
    manufacturers: {
        text: 'ООО «СИТАМ» имеет большой опыт ремонта сушилок самых разных марок и конфигураций. В списке оборудования, с которым мы работаем, числятся агрегаты следующих производителей: crown и других производителей в т.ч. отечественных.',
    },
    underTitle: `ООО «СИТАМ» Ремонтируем сушилка/охладитель и другое промышленное оборудование на профессиональном уровне. На все виды работ организация имеет необходимые разрешения и свидетельства.`,
    type: PageTemplates.TwoImagesOneTextType,
    description: 'Ремонта сушилок самых разных марок и конфигураций. Ремонт сушилки/охладителя шрота. В списке оборудования, с которым мы работаем, числятся агрегаты следующих производителей: crown и других производителей в т.ч. отечественных',
    text: `Сушилка-охладитель высушивает и охлаждает шрот после экстракции растворителем. Осушитель/Охладитель может использоватся как в сочетании с десольвентайзером-тостером, так и как самостоятельное устройство, в зависимости от требований производства.

#### Ремонт сушилки/охладителя шрота может включать:

- Демонтаж изоляции с нержавеющих листов и минеральной ваты крышки емкости;

- Демонтаж переходного мостика крыши сушилки;

- Демонтаж верхнего листа сушилки с заменой листов;

- Закрепление шлюзового затвора подачи товара в емкость;

- Демонтаж самотека;

- Укрепление новой обшивки к ребрам жесткости;

- Сварка стыков новых листов;

- Проверка сварных швов на герметичность;

- Восстановление изоляции и соединение шлюзового затвора с самотеком;
`};


