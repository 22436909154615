import firstImage from '../../images/pages/installation-of-a-process-pipeline/2.jpg';
import secondImage from '../../images/pages/installation-of-a-process-pipeline/1.jpg';
import {PageTemplates} from "../../pages/standartPages/enum";

export const installationOfAProcessPipeline = {
    url: '/installation-of-a-process-pipeline',
    title: 'Монтаж технологического трубопровода',
    type: PageTemplates.TwoImagesTwoTextsBigType,
    keywords: 'ремонт технологического трубопровода,ремонт трубопровода водоснабжения',
    manufacturers: {
        text: 'ООО «СИТАМ» имеет большой опыт монтажа и изготовления трубопровода самых разных марок и конфигураций. В списке оборудования, с которым мы работаем, числятся агрегаты следующих производителей: Е, КСВа (ЭКО), ВК, КВГ, ТВГ, КСВ и других производителей в т.ч. отечественных.',
    },
    underTitle: 'ООО «СИТАМ» Выполним строительство монтаж технологического трубопровода и другого промышленного оборудования на профессиональном уровне. На все виды работ организация имеет необходимые разрешения и свидетельства.',
    firstImage,
    secondImage,
    description: 'Выполним монтаж технологических трубопроводов из нержавеющей стали и арматуры (включая сварку в среде аргона) для предприятий пищевой, химической, фармацевтической промышленности, нефтебаз, пивзаводов, молокозаводов, маслоэкстракционных заводов',
    firstText: {
        text: `Наша компания выполнит монтаж технологических трубопроводов из нержавеющей стали и арматуры (включая сварку в среде аргона) для предприятий пищевой, химической, фармацевтической промышленности, нефтебаз, пивзаводов, молокозаводов, маслоэкстракционных заводов и др.

##### Произведем прокладку технологических трубопроводов:

- Трубопровод водоснабжения;

- Трубопровод сжатого воздуха;

- Противопожарный водопровод;

- Трубопровод подачи пара;

- Трубопровод горячего водоснабжения;

- Трубопровод конденсата;

- Трубопровод наружных сетей теплоснабжения (систем отопления);

- Газопровод;

- Нефтепроводов;

- Трубопровод сброса очищенных сточных вод;

- Трубопроводов с рубашкой подогрева;

- Трубопровод холодоснабжения;

- Трубопроводов наружных канализационных сетей;

- Трубопроводов амиака;

- Кислотопровод;

- Трубопровод химреагентов;

- Трубопровода жидких удобрений;

- Трубопровод преддефекации;

- Трубопровод сока;

- Вакуумный трубопровод.
`
    },
    secondText: {
        text: `#### Работы при монтаже и изготовление технологических трубопроводов могут включать:

- Изготовление технологической площадки;

- Изготовление кронштейнов и хомутов;

- Выполнение технологических отверстий для прокладки трубопроводов;

- Изготовление и монтаж узлов трубопровода;

- Монтаж запорной арматуры, протяжка и закрепление трубы;

- Сварка металлических трубопроводов;

- Врезка нового трубопровода в существующий и соединение;

- Гидравлические испытания;

- Монтаж и обвязка технологического оборудования (насосы, теплообменники, сепараторы, пресса и пр.);

- Грунтовка и покраска антикоррозионной эмалью.

##### Дополнительные строительно-монтажные работы:

- Разработка и обратная засыпка траншей экскаватором;

- Монтаж колодца;

- Подключение системы обратного осмоса;

- Изготовление вытяжных зонтов;

- Монтаж дренажной системы.
`}};




