import secondImage from '../../images/pages/lime-burning-department/1.jpg';
import firstImage from '../../images/pages/lime-burning-department/2.jpg';
import {PageTemplates} from "../../pages/standartPages/enum";

export const limeBurningDepartment = {
    url: '/lime-burning-department',
    title: 'Встановлення обладнання вапняно-випалювального відділення',
    firstImage,
    secondImage,
    keywords: 'встановлення вапняно-випалювального відділення,ремонт вапняно-випалювального',
    underTitle: `ТОВ «СІТАМ» Виконаємо встановлення та заміну/демонтаж обладнання вапняно-випалювального відділення та іншого промислового обладнання на професійному рівні. На всі види робіт організація має необхідні дозволи та свідоцтва.`,
    type: PageTemplates.TwoImagesOneTextType,
    description: 'Виконаємо роботи з монтажу обладнання та реконструкції вапняного відділення цукрового заводу. Реконструкція вапняно-випалювальних печей ІПШ, ВМА, ТМА-ПШІ, ТІПШ. Встановлення трактів подачі палива та вапняку, вузлів підготовки шихти. Монтаж вапняно-випалювальних печей',
    text: `Наша компанія виконає роботи з монтажу обладнання та реконструкції вапняного відділення цукрового заводу

##### Список робіт може включати:

- Встановлення вапняно-випалювальних печей;

- Реконструкція вапняно-випалювальних печей ІПШ, ВМА, ТМА-ПШІ, ТІПШ;

- Встановлення трактів подачі палива та вапняку, вузлів підготовки шихти;

- Заміна завантажувально-розподільних та вивантажувальних пристроїв шахтової вапняно-випалювальної печі;

- Встановлення вапнегасильних апаратів;

- Встановлення вагових дозаторів палива;

- Монтаж Віброподавача каменю ВПІ.
`};


