import secondImage from '../../images/pages/diffusion-department/1.jpg';
import firstImage from '../../images/pages/diffusion-department/2.jpg';
import {PageTemplates} from "../../pages/standartPages/enum";

export const diffusionDepartment = {
    url: '/diffusion-department',
    title: 'Монтаж оборудования диффузионного отделения',
    firstImage,
    secondImage,
    keywords: 'монтаж оборудования диффузионного,замена оборудования диффузионного',
    underTitle: `ООО «СИТАМ» Выполним монтаж и замену/демонтаж оборудования диффузионного отделения и другого промышленного оборудования на профессиональном уровне. На все виды работ организация имеет необходимые разрешения и свидетельства.`,
    type: PageTemplates.TwoImagesOneTextType,
    description: 'Монтаж оборудования и реконструкцию диффузионного отделения сахарного завода. Монтаж наклонного диффузионного аппарата (ДС-8, DC12). Монтаж бурякорезок (свеклорезок Glass&Wolf, Putch)',
    text: `Наша компания выполнит монтаж оборудования и реконструкцию диффузионного отделения сахарного завода.

##### В список работ может входить:

- Монтаж технологического оборудования и коммуникаций;

- Монтаж наклонного диффузионного аппарата (ДС-8, DC12);

- Монтаж бурякорезок (свеклорезок Glass&Wolf, Putch);

- Ремонт подогревателя диффузионного сока, утфельного пара и кристаллизации ПДУ;

- Монтаж конденсатного сборника диффузии;

- Обвязка конденсатных сборников и трубопроводов вакуумных насосов;

- Монтаж оборудования подготовки воды для питания диф. аппарата;

- Монтаж высоладживателя колонного типа;

- Монтаж ошпаривателя;

- Монтаж подогревателя сока;

- Монтаж линии подачи стружки и выгрузка жома;

- Монтаж насосного парка;

- Установка теплообменного оборудования для нагрева жомопрессовой воды;

- Установка ресиверов и обвязка трубопроводами;

- Монтаж транспортировочного оборудования;

- Изготовление и монтаж циклонов;

- Монтаж бункера под ПКФ.`,
};


