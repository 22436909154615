import secondImage from '../../images/pages/installation-of-industrial-boilers/1.jpg';
import firstImage from '../../images/pages/installation-of-industrial-boilers/2.jpg';
import {PageTemplates} from "../../pages/standartPages/enum";

export const installationOfIndustrialBoilers = {
        url: '/installation-of-industrial-boilers',
        title: 'Монтаж промислових котлів',
        firstImage,
        secondImage,
        keywords: 'монтаж промислових котлів,демонтаж котлів,монтаж котлів',
        manufacturers: {
            text: 'ТОВ «СІТАМ» має великий досвід монтажу або демонтажу котлів найрізноманітніших марок і конфігурацій. У списку обладнання, з яким ми працюємо, числяться агрегати наступних виробників: Е, ДЕ, КЕ, ДКВр, КСВа, ВК, КВГ, ТВГ та інших виробників, включаючи вітчизняних',
        },
        description: 'Виконаємо монтаж та пусконалагодження промислових парових котлів і допоміжного котельного обладнання. У списку обладнання, з яким ми працюємо, числяться агрегати наступних виробників: Е, ДЕ, КЕ, ДКВр, КСВа, ВК, КВГ, ТВГ та інших виробників, включаючи вітчизняних',
        underTitle: `ТОВ «СІТАМ» Виконаємо монтаж котлів та іншого промислового котельного обладнання на професійному рівні. На всі види робіт організація має необхідні дозволи та свідоцтва`,
        type: PageTemplates.TwoImagesOneTextType,
        text: `Наша компанія виконає монтаж та пусконалагодження промислових парових котлів і допоміжного котельного обладнання.

Котельня може бути стаціонарною або модульною. При монтажі котельного обладнання в стаціонарній котельні всі обладнання кріпиться до спеціально підготовлених фундаментів. Блочно-модульна котельня може постачатися транспортабельними блоками. У транспортабельних котельних установках (ТКУ) обладнання вже змонтовано з максимальною заводською готовністю.

Монтаж котлів проводиться безпосередньо в котельні і включає:

Транспортування котла до місця установки;

Підготовка фундаменту (за необхідності);

Встановлення котла на фундамент або основу;

Закріплення котла на основі;

Монтаж допоміжного обладнання котла;

Підключення котла до димової труби та зовнішніх мереж (трубопровід, газопровід, електропостачання тощо);

Встановлення горілки;

Пуско-налагоджувальні роботи.`,
    };

