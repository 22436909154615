import secondImage from '../../images/pages/belt-conveyor-repair/1.jpg';
import firstImage from '../../images/pages/belt-conveyor-repair/2.jpg';
import {PageTemplates} from "../../pages/standartPages/enum";

export const beltConveyorRepair = {
        url: '/belt-conveyor-repair',
        title: 'Ремонт конвеєра стрічкового стаціонарного',
        firstImage,
        secondImage,
        description: 'Ремонт конвеєра (транспортера) стрічкового, стаціонарного. Демонтаж настилу конвеєра. Працюємо з агрегатами наступних виробників: ТСЦм, TЦС, КСЦ, КЦ-С, ТрЦС та інших, в т.ч. вітчизняних.',
        keywords: 'ремонт конвеєра,ремонт стрічкового конвеєра,ремонт стаціонарного конвеєра',
        manufacturers: {
            text: 'ТОВ «СІТАМ» має великий досвід ремонту транспортерів різних марок і конфігурацій. У списку обладнання, з яким ми працюємо, числяться агрегати наступних виробників: ТСЦм, TЦС, КСЦ, КЦ-С, ТрЦС та інших виробників, в т.ч. вітчизняних.',
        },
        underTitle: `ТОВ «СІТАМ» Виконаємо ремонт конвеєра (транспортера) стрічкового стаціонарного та іншого промислового обладнання на професійному рівні. На всі види робіт організація має необхідні дозволи та свідоцтва.`,
        type: PageTemplates.TwoImagesOneTextType,
        text: `Наша компанія виконує роботи з поточного ремонту конвеєра стрічкового стаціонарного, призначеного для переміщення зернових культур, таких матеріалів як вугілля, руда, щебень і граніт, а також їх сортування.

Використовується в основному в портах, зернових складах, зернових терміналах, майданчиках сортування а також в цементній, харчовій промисловості, сільському господарстві, металургійних заводах, теплоелектроцентралах, хімічній промисловості, гірничодобувній промисловості та інших галузях.

##### Перелік робіт і деталей, які потребують заміни або реставрації:

- Демонтаж їздової балки конвеєра під гойдковий лотковий податчик;

- Виготовлення і монтаж їздової балки;

- Демонтаж роликів;

- Виготовлення і заміна роликоопор;

- Демонтаж настилу конвеєра;

- Виготовлення і монтаж настилу;

- Демонтаж стійки;

- Виготовлення і монтаж стійки;

- Розрізання і завантаження металоконструкцій;

- Футерування розвантажувальної візка конвеєра.`,
    };

