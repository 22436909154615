import secondImage from '../../images/pages/welding-works/1.jpg';
import firstImage from '../../images/pages/welding-works/2.jpg';
import { PageTemplates } from "../../pages/standartPages/enum";

export const weldingWorks = {
    url: '/svarenye-raboty',
    title: 'Сварочные работы, сварка металлоконструкций',
    firstImage,
    secondImage,
    keywords: 'сварочные работы, сварка металлоконструкций',
    manufacturers: {
        text: 'ТОВ «СІТАМ» має великий досвід сварки та виготовлення металоконструкцій найрізноманітніших конфігурацій. В списку обладнання, з яким ми працюємо, числяться агрегати таких виробників: Bock, Bitzer, Copeland, Maneurop, DVK та інші виробники, включаючи вітчизняних.',
    },
    description: 'Виконаємо сварочні роботи та виготовлення різноманітних металоконструкцій з чорного металу та нержавіючої сталі в промисловій та агропромисловій сфері. Сварка та наплавка зношених частин обладнання. Сварка металевих конструкцій (ферми, колони, балки, закладні деталі). Сварка електродами',
    underTitle: `ТОВ «СІТАМ» Виконаємо сварочні роботи та виготовлення металоконструкцій на професійному рівні. На всі види робіт організація має необхідні дозволи та свідоцтва`,
    type: PageTemplates.TwoImagesOneTextType,
    text: `##### Наша компанія виконає сварочні роботи та виготовлення різноманітних металоконструкцій з чорного металу та нержавіючої сталі в промисловій та агропромисловій сфері, а саме:

- Сварка аргоном;

- Сварка електродами;

- Сварка на висоті;

- Сварка металевих конструкцій (ферми, колони, балки, закладні деталі);

- Сварка та наплавка зношених частин обладнання;

- Сварка технологічних трубопроводів різного діаметра;

- Сварочні роботи при встановленні гідро та теплоізоляції;

- Сварка нержавіючих ємностей та резервуарів;

- Сварка металевих сходів та технологічних площадок;

- Сварка бункерів та баків різного призначення;

- Сварка металевих каркасів (ангари, склади, навіси, цехи і.т.д);

- Різка металу, вальцювання, гнучка листів, труб, швелерів;

- Відновлення ножів бульдозерів, ковшів екскаваторів та іншої спец. техніки;

- Виготовлення металоконструкцій будь-якої складності за кресленнями замовника.`,
};
