import firstImage from '../../images/pages/grocery-department/1.jpg';
import {PageTemplates} from "../../pages/standartPages/enum";

export const groceryDepartment = {
    url: '/grocery-department',
    title: 'Монтаж обладнання продуктового відділу',
    firstImage,
    keywords: 'монтаж технологічного трубопроводу,монтаж металоконструкцій',
    type: PageTemplates.OneImageTwoTextsSmallType,
    maxSize: true,
    description: 'Монтаж і заміна/демонтаж обладнання продуктового відділу цукрового заводу. Монтаж центрифуг неперервного та періодичного дії (ВМА G1750, ВUСКАU R.WOLF, ФПІ 1321 К-01, ФПН 1251 Т-01, Зангерхаузен). Монтаж приймальної мішалки утфелю (утфелемішалка) ТМУ;',
    firstText: {
        text: `ТОВ «СІТАМ» Виконаємо монтаж та заміну/демонтаж обладнання продуктового відділу цукрового заводу та іншого промислового обладнання на професійному рівні. На всі види робіт організація має необхідні дозволи та свідоцтва.

Наша компанія виконає роботи з монтажу та реконструкції продуктового відділу цукрового заводу.`
    },
    secondText: {
        text: `### Перелік можливих робіт:

- Демонтаж та монтаж технологічного обладнання;

- Монтаж технологічного трубопроводу;

- Виготовлення та монтаж металоконструкцій, сходів з огорожами;

- Монтаж запірної арматури;

- Виготовлення та монтаж відводів, колекторів та переходів;

- Монтаж пластинчастих теплообмінників;

- Монтаж з трубною обв'язкою вакуум-апаратів ТВА;

- Монтаж утфелерозподільника;

- Монтаж центрифуг неперервного та періодичного дії (ВМА G1750, ВUСКАU R.WOLF, ФПІ 1321 К-01, ФПН 1251 Т-01, Зангерхаузен);

- Установка ополіскувача УЛП-1;

- Установка сиропних фільтрів ТФС;

- Монтаж приймальної мішалки утфелю (утфелемішалка) ТМУ;

- Монтаж шнека, віброконвеєра;

- Монтаж клеровочної мішалки ТМК;

- Монтаж підігрівача на утфельних парах ТТДС, ПДУ;

- Установка вакуум-конденсаційної установки ВКУ (конденсатори, каплеуловлювачі, модуль нагріву води для екстрактора, водорозподільні системи градірен, запірно-регулююча апаратура);

- Монтаж апарата прогресивної попередньої дефекації (попередньодефекатора) ТМА-ППД;

- Монтаж змішувача кавітаційного ТМА-ПСК;

- Монтаж апарата для проведення 1-го етапу основної дефекації (холодний дефекатор)ТМА-ПДХ;

- Монтаж апарата для проведення 2-го етапу основної дефекації (гарячий дефекатор)ТМА-ОД;

- Монтаж апарата дефекації для соку 1-го та 2-го сатурації ТМА-ПАС;

- Монтаж електросилової частини;
`}};

