import firstImage from '../../images/pages/silo-repair/1.jpg';
import secondImage from '../../images/pages/silo-repair/2.jpg';
import {PageTemplates} from "../../pages/standartPages/enum";

export const siloRepair = {
    url: '/silo-repair',
    title: 'Ремонт и обслуживание силоса',
    type: PageTemplates.TwoImagesOneTextType,
    keywords: 'ремонт силоса,обслуживание силоса',
    manufacturers: {
        text: 'ООО «СИТАМ» имеет большой опыт ремонта силосов самых разных марок и конфигураций. В списке оборудования, с которым мы работаем, числятся агрегаты следующих производителей: CIMBRIA, GSI и других производителей в т.ч. отечественных.',
    },
    underTitle: 'ООО «СИТАМ» Выполним ремонт силоса и другого промышленного оборудования на профессиональном уровне. На все виды работ организация имеет необходимые разрешения и свидетельства.',
    firstImage,
    secondImage,
    description: 'Выполним ремонт металлических силосов, реконструкций силосных корпусов для хранения зерна с применением технологии промышленного альпинизма. Ремонт жесткой кровли (крыши) силоса. Ремонт конуса силоса',
    text: `Наша компания производит работы по текущему ремонту металлических силосов, реконструкций силосных корпусов для хранения зерна с применением технологии промышленного альпинизма.
    
#### Работы по ремонту могут включать:

- Ремонт жесткой кровли (крыши) силоса;

- Демонтаж поврежденных листов крыши;

- Замена поврежденных балок крыши;

- Монтаж металлоконструкций усиления балок;

- Рихтовка поврежденных и монтаж листов крыши;

- Ремонт и герметизация швов и стыков;

- Ремонт внутренних поверхностей (стен);

- Ремонт конуса силоса;

- Замена термометрии;

- Ремонт самотеков;

- Ремонт стыков СОГов и восстановление СОГовых элеваторов;

- Монтаж металлических заплаток, опалубки на сквозные отверстия;

- Устранение пересыпаний зерновых культур, дотяжка и замена шпилек;

- Очистка вентиляционных отверстий;

- Защита от коррозии (покраска).
`};