import firstImage from '../../images/pages/installation-of-transformers-and-power-equipment/1.jpg';
import secondImage from '../../images/pages/installation-of-transformers-and-power-equipment/2.jpg';
import {PageTemplates} from "../../pages/standartPages/enum";

export const installationOfTransformersAndPowerEquipment = {
    url: '/installation-of-transformers-and-power-equipment',
    type: PageTemplates.TwoImagesTwoTextsBigType,
    title: 'Установка силовых трансформаторов',
    firstImage,
    secondImage,
    keywords: 'монтаж,масляных,силовых,трансформаторов,подстанций,маслоприемник',
    manufacturers: {
        text: 'ООО «СИТАМ» имеет большой опыт монтаж масляных силовых трансформаторов различных конфигураций и напряжения.',
    },
    description: 'Выполним монтаж силовых трансформаторов, трансформаторных подстанций. Установка трансформаторов 35, 110, 220 кВ, мощностью более 10000 кВ и другого напряжения',
    underTitle: 'ООО «СИТАМ» Выполним монтаж силовых трансформаторов, трансформаторных подстанций и другого электрооборудования на профессиональном уровне. На все виды работ организация имеет необходимые разрешения и свидетельства.',
    firstText: {
        text: `Установка трансформаторов 35, 110, 220 кВ, мощностью более 10000 кВ и другого напряжения должен осуществляться с полным соблюдением действующих требований по монтажу.

##### Масляные силовые трансформаторы транспортируются изготовителем высушенными и в зависимости от размеров и массы в следующем состоянии:

- Полностью собранные и залитые маслом;

- Частично демонтированные и законсервированные, залитые маслом ниже крышки с заполнением инертным газом или сухим воздухом;

Частично демонтированные в собственном баке без масла, заполненные азотом с автоматической подпиткой.
`
    },
    secondText: {
        text: `##### Порядок монтажа силового трансформатора включает:

- Установка фундамента и маслоприемника;

- Подготовка места монтажа, для проведения проверок, прогрева и сборки оборудования;

- Подготовка подъемного оборудования для монтажа;

- Транспортировка силовых трансформаторов к месту установки;

- Монтаж комплектующих узлов (система охлаждения, встроенные ТТ, фильтры, газовые реле, реле уровня масла, расширитель, выхлопная труба и т.д.);

- Подготовка трансформаторного масла, системы заливки, баков, индикаторного силикагеля.

- Испытание силового трансформатора;

- Запуск силовых трансформаторов в эксплуатацию.

Наличие материально-технической базы и аттестованных специалистов с многолетним опытом позволяет нам профессионально осуществлять установку и демонтаж силовых трансформаторов любых мощностей.
`}};

