import firstImage from '../../images/pages/repair-of-steel-tanks/1.jpg';
import {PageTemplates} from "../../pages/standartPages/enum";

export const repairOfSteelTanks = {
    url: '/repair-of-steel-tanks',
    title: 'Ремонт стальных резервуаров',
    firstImage,
    keywords: 'демонтаж резервуаров,монтаж резервуаров,ремонт резервуаров',
    type: PageTemplates.OneImageTwoTextsSmallType,
    manufacturers: {
        text: 'ООО «СИТАМ» имеет большой опыт монтажа и демонтажа конвейеров (транспортеров) самых разных марок и конфигураций. В списке оборудования, с которым мы работаем, числятся агрегаты следующих производителей: ТСЦм, TЦС, КСЦ, КЦ-С, ТрЦС, К4-УТФ, КС, ТКНЦ и других производителей в т.ч. отечественных.'
    },
    firstText: {
        text: `ООО «СИТАМ» Выполним ремонт резервуара и другого промышленного оборудования на профессиональном уровне. На все виды работ организация имеет необходимые разрешения и свидетельства.`
    },
    description: 'Выполним работы по ремонту резервуаров, увеличению объема, усилению и антикоррозионной защите любых резервуаров. В списке оборудования, с которым мы работаем, числятся агрегаты следующих производителей: ТСЦм, TЦС, КСЦ, КЦ-С, ТрЦС, К4-УТФ, КС, ТКНЦ и других производителей в т.ч. отечественных',
    secondText: {
        text: `Наша компания выполнит работы по ремонту резервуаров, увеличению объема, усилению и антикоррозионной защите любых резервуаров.

Резервуары для хранения нефтепродуктов, жидких удобрений и т.д требуют периодического обслуживания, а также проведения текущего и капитального ремонта. Это связано как с естественным износом оборудования, так и с негативным воздействием окружающей среды и компонентами, хранящимися внутри резервуара.

### Ремонт стальных резервуаров может включать:

- Зачистка и дегазация резервуара;

- Проверка технического состояния стенки, днища и крыши;

- Усиление фундаментов;

- Ремонт оснований;

- Замена отдельных поясов стенки;

- Замена днища, кровли;

- Замены окрайков днища;

- Проверка и ремонт сварных швов;

- Очистка внутренней и внешней поверхности от коррозии;

- Заваривание коррозионных раковин и отверстий;

- Обработка антикоррозионным покрытием;

- Осмотр и ремонт резервуарного оборудования;

- Проверка герметичности.`
    }
};