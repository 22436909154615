import firstImage from '../../images/pages/repair-of-steel-tanks/1.jpg';
import {PageTemplates} from "../../pages/standartPages/enum";

export const repairOfSteelTanks = {
    url: '/repair-of-steel-tanks',
    title: 'Ремонт сталевих резервуарів',
    firstImage,
    keywords: 'демонтаж резервуарів,монтаж резервуарів,ремонт резервуарів',
    type: PageTemplates.OneImageTwoTextsSmallType,
    manufacturers: {
        text: 'ТОВ «СІТАМ» має великий досвід монтажу та демонтажу конвеєрів (транспортерів) найрізноманітніших марок і конфігурацій. У списку обладнання, з яким ми працюємо, знаходяться агрегати наступних виробників: ТСЦм, TЦС, КСЦ, КЦ-С, ТрЦС, К4-УТФ, КС, ТКНЦ та інші виробники, включаючи вітчизняних.'
    },
    firstText: {
        text: `ТОВ «СІТАМ» Виконаємо ремонт резервуара та іншого промислового обладнання на професійному рівні. На всі види робіт організація має необхідні дозволи та свідоцтва.`
    },
    description: 'Виконаємо роботи з ремонту резервуарів, збільшення об\'єму, посилення та антикорозійного захисту будь-яких резервуарів. У списку обладнання, з яким ми працюємо, знаходяться агрегати наступних виробників: ТСЦм, TЦС, КСЦ, КЦ-С, ТрЦС, К4-УТФ, КС, ТКНЦ та інші виробники, включаючи вітчизняних.',
    secondText: {
        text: `Наша компанія виконає роботи з ремонту резервуарів, збільшення об'єму, посилення та антикорозійного захисту будь-яких резервуарів.

Резервуари для зберігання нафтопродуктів, рідких добрив та інших речовин потребують періодичного обслуговування, а також проведення поточного та капітального ремонту. Це пов'язано як з природним зносом обладнання, так і з негативним впливом навколишнього середовища та компонентів, що зберігаються всередині резервуара.

### Ремонт сталевих резервуарів може включати:

- Зачистку та дегазацію резервуара;

- Перевірку технічного стану стінки, днища та даху;

- Посилення фундаментів;

- Ремонт основ;

- Заміну окремих поясів стінки;

- Заміну днища, покрівлі;

- Заміну крайок днища;

- Перевірку та ремонт зварних швів;

- Очищення внутрішньої та зовнішньої поверхні від корозії;

- Заплавлення корозійних раковин та отворів;

- Обробку антикорозійним покриттям;

- Огляд та ремонт резервуарного обладнання;

- Перевірку герметичності.`
    }
};
