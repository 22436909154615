import secondImage from '../../images/pages/compressor-installation/1.jpg';
import firstImage from '../../images/pages/compressor-installation/2.jpg';
import {PageTemplates} from "../../pages/standartPages/enum";

export const compressorInstallation = {
    url: '/compressor-installation',
    title: 'Монтаж компрессора',
    firstImage,
    secondImage,
    keywords: 'монтаж компрессора,ремонт винтовой,замена компрессора,ремонт промышленного оборудования',
    manufacturers: {
        text: 'ООО «СИТАМ» имеет большой опыт монтажу и демонтажу компрессоров самых разных марок и конфигураций. В списке оборудования, с которым мы работаем, числятся агрегаты следующих производителей: Bock, Bitzer, Copeland, Maneurop, DVK и других производителей в т.ч. отечественных',
    },
    underTitle: `ООО «СИТАМ» Выполним монтаж компрессора и другого промышленного холодильного оборудования на профессиональном уровне. На все виды работ организация имеет необходимые разрешения и свидетельства`,
    type: PageTemplates.TwoImagesOneTextType,
    description: 'Выполним монтаж и замену (демонтаж) винтового и поршневого компрессора и сопутствующего промышленного холодильного оборудования. Оборудование с которым мы работаем: Bock, Bitzer, Copeland, Maneurop, DVK и других производителей в т.ч. отечественных',
    text: `Выполним монтаж и замену (демонтаж) винтового и поршневого компрессора и сопутствующего промышленного холодильного оборудования.

Этапы установки компрессора могут включать:

Подготовка фундамента;

Разгрузка и транспортировка компрессора к месту установки;

Монтаж и закрепление компрессора на фундаменте;

Подключение оборудования к коммуникациям (электроэнергия, сжатый воздух, вода, и канализация);

Установка воздушного фильтра, ресивера, осушителя;

Подключение линии отвода конденсата;

Контроль качества рабочих жидкостей;

Контроль герметичности пневматических и гидравлических контуров;

Контроль крепления кожухов;

Контроль наличия острых краев;

Контроль смазки в местах для ее нанесения;

Достаточный доступ к оборудованию`,
};
