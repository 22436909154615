import secondImage from '../../images/pages/belt-conveyor-repair/1.jpg';
import firstImage from '../../images/pages/belt-conveyor-repair/2.jpg';
import {PageTemplates} from "../../pages/standartPages/enum";

export const beltConveyorRepair = {
    url: '/belt-conveyor-repair',
    title: 'Ремонт конвейера ленточного стационарного',
    firstImage,
    secondImage,
    description: 'Ремонт конвейера (транспортера) ленточного, стационарного. Демонтаж настила конвейера. Работаем с агрегатами следующих производителей: ТСЦм, TЦС, КСЦ, КЦ-С, ТрЦС и других, в т.ч. отечественных.',
    keywords: 'ремонт конвейера,ремонт ленточного конвейера,ремонт стационарного конвейера',
    manufacturers: {
        text: 'ООО «СИТАМ» имеет большой опыт ремонта транспортеров самых разных марок и конфигураций. В списке оборудования, с которым мы работаем, числятся агрегаты следующих производителей: ТСЦм, TЦС, КСЦ, КЦ-С, ТрЦС и других производителей в т.ч. отечественных.',
    },
    underTitle: `ООО «СИТАМ» Выполним ремонт конвейера (транспортера) ленточного стационарного и другого промышленного оборудования на профессиональном уровне. На все виды работ организация имеет необходимые разрешения и свидетельства.`,
    type: PageTemplates.TwoImagesOneTextType,
    text: `Наша компания производит работы по текущему ремонту конвейера ленточного стационарного предназначенного для перемещения зерновых культур, таких материалов как уголь, руда, щебень и гранит а также их сортировки.

Используется в основном в портах, зерноскладах, зерновых терминалов, площадках сортировки а так же в цементной, пищевой промышленности, сельском хозяйстве, металлургических заводах, теплоэлектроцентралей, химической промышленности, горно-добывающей промышленности и других отраслях.

##### Перечень работ и деталей требующих замены или реставрации:

- Демонтаж ездовой балки конвейера под качающий лотковый питатель;

- Изготовление и монтаж ездовой балки;

- Демонтаж роликов;

- Изготовление и замена роликоопор;

- Демонтаж настила конвейера;

- Изготовление и монтаж настила;

- Демонтаж станины;

- Изготовление и монтаж станины;

- Разделка и погрузка металлоконструкций;

- Футеровка разгрузочной тележки конвейер.`,
};

