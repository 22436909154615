import firstImage from '../../images/pages/process-pipeline-repair/2.jpg';
import secondImage from '../../images/pages/process-pipeline-repair/1.jpg';
import { PageTemplates } from "../../pages/standartPages/enum";

export const processPipelineRepair = {
    url: '/process-pipeline-repair',
    type: PageTemplates.TwoImagesTwoTextsBigType,
    keywords: 'ремонт технологических трубопроводов,ремонт трубопроводов',
    manufacturers: {
        text: 'ООО «СИТАМ» имеет большой опыт ремонта и замены трубопровода самых разных марок и конфигураций. В списке оборудования, с которым мы работаем, числятся агрегаты следующих производителей: Е, КСВа (ЭКО), ВК, КВГ, ТВГ, КСВ и других производителей в т.ч. отечественных.',
    },
    title: 'Ремонт технологічного трубопроводу',
    underTitle: 'ООО «СИТАМ»  Виконаємо ремонт і монтаж технологічного трубопроводу та іншого промислового обладнання на професійному рівні. На всі види робіт організація має необхідні дозволи і свідоцтва.',
    firstImage,
    secondImage,
    description: 'Виконаємо ремонт і реконструкцію технологічних трубопроводів з нержавіючої сталі та арматури для підприємств харчової, хімічної, фармацевтичної промисловості, нафтобаз, пивоварень, молокозаводів, маслодобувних заводів. В списку обладнання, з яким ми працюємо, числяться агрегати наступних виробників: Е, КСВа (ЭКО), ВК, КВГ, ТВГ, КСВ та інших виробників в т.ч. вітчизняних.',
    firstText: {
        text: `Наша компанія виконає ремонт і реконструкцію технологічних трубопроводів з нержавіючої сталі та арматури для підприємств харчової, хімічної, фармацевтичної промисловості, нафтобаз, пивоварень, молокозаводів, маслодобувних заводів і ін. У роботі користуємося класичним траншейним або безтраншейними методами.

### Виконаємо ремонт технологічних трубопроводів:

- Трубопровід водопостачання;

- Трубопровід стиснутого повітря;

- Протипожежний водопровід;

- Трубопровід подачі пару ;

- Трубопровід гарячого водопостачання;

- Трубопровід конденсату;

- Трубопровід зовнішніх мереж теплопостачання (систем опалення);

- Газопровід;

- Нафтопроводів;

- Трубопровід скидання очищених стічних вод;

- Трубопроводи з обшивкою підігріву;

- Трубопровід охолодження;

- Трубопроводи зовнішніх каналізаційних мереж;

- Трубопроводи аміаку;

- Кислотопровід;

- Трубопровід хімреагентів;

- Трубопроводи рідких добрив;

- Трубопровід преддефекації;

- Трубопровід соку;

- Вакуумний трубопровід.
`
    },
    secondText: {
        text: `### Ремонт і реконструкція технологічного трубопроводу може включати:

- Розкопка трубопроводу;

- Вирізка технологічного отвору;

- Гідродинамічне очищення ділянки;

- Обстеження ділянки;

- Виявлення пошкоджень (місця прориву);

- Часткова заміна трубопроводу;

- Виготовлення вузлів трубопроводів міжцехових на естакадах, кронштейнах та інших спецконструкціях з фланцями та зварними з'єднаннями;

- Врізка трубопроводу в діючі магістралі;

- Заварка свищів в корпусах;

- Контроль якості зварних швів;

- Очищення металевих поверхонь та обезжирювання;

- Грунтування та фарбування металевих поверхонь;

- Ревізія та ремонт вентилів сталевих, засувок, кранів, клапанів зворотних і запобіжних, регуляторів тиску чавунних і сталевих фланцевих;

- Шабровка плоских поверхонь фланців засувок;

- Висвердлювання та видалення зламаних болтів, гвинтів і шпильок;

- Зворотна засипка траншеї.
`}};