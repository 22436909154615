import secondImage from '../../images/pages/thermal-insulation-of-the-tank/1.png';
import firstImage from '../../images/pages/thermal-insulation-of-the-tank/2.jpg';
import { PageTemplates } from "../../pages/standartPages/enum";

export const thermalInsulationOfTheTank = {
    url: '/teploіzolyatsiya-rezervuariv',
    title: 'Теплоізоляція резервуарів',
    firstImage,
    secondImage,
    keywords: 'теплоізоляція резервуарів, утеплення резервуарів',
    underTitle: `ТОВ «СІТАМ» Виконаємо теплоізоляцію резервуара та іншого промислового обладнання на професійному рівні. На всі види робіт організація має необхідні дозволи та свідоцтва.`,
    manufacturers: {
        text: 'ТОВ «СІТАМ» має великий досвід утеплення резервуарів найрізноманітніших марок і конфігурацій. У списку обладнання, з яким ми працюємо, числяться агрегати наступних виробників: РГС та інших виробників, включаючи вітчизняних.',
    },
    type: PageTemplates.TwoImagesTwoTextsBigType,
    description: 'Утеплення резервуарів для зберігання сировини та готового продукту на різних видах промислового виробництва. У списку обладнання, з яким ми працюємо, числяться агрегати наступних виробників: РГС та інших виробників, включаючи вітчизняних.',
    firstText: {
        text: `#### Наша компанія виконає утеплення резервуарів для зберігання сировини та готового продукту на різних видах промислового виробництва.

Для зниження перепаду температури між навколишнім середовищем та самим продуктом використовується зовнішня теплоізоляція сталевих резервуарів.

У резервуарах зазвичай зберігають рідкі або газоподібні матеріали, які вимагають строгого дотримання температурного режиму. Підтримання певних температур всередині ємностей для нафтопродуктів є одним із основних чинників їх функціональності.

##### Теплоізоляція ємностей може проводитися різними матеріалами:

- Скловата;

- Пінопласт;

- Пінопоробетон;

- Мінеральна вата;

- Пінополіуретан.

Утеплення резервуарів може проводитися наступним чином:

- Вибір утеплювача залежно від конструкції;

- Розрахунок товщини ізоляції;

- Антикорозійне покриття металевих поверхонь;

- Монтаж утеплювача між опорними кільцями без зазорів;

- Кріплення теплоізоляції привареними шпильками або обвязочною дротом;
`
    },
    secondText: {
        text: `##### Під час виробництва теплоізоляційних робіт ми враховуємо:

Забезпечення герметичності, щоб між сталевим корпусом та самою ізоляцією не могла проникати волога, оскільки це призведе до многократного прискорення корозії та зниження терміну служби самого резервуара. Зазвичай теплоізоляційний матеріал обшивають оцинкованим листом.

Ізоляція для резервуарів виконується тільки на стінах і даху конструкції, тобто зовнішньої поверхні.

Перед початком теплоізоляційних робіт, також звертаємо увагу на взаємодії конструкції резервуара з елементами теплоізоляції (навантаження на елементи резервуара, опори, температурні режими і т. д.).
`
    }
};
