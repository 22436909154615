import firstImage from '../../images/pages/dismantling-and-replacement-of-the-pipeline/2.jpg';
import secondImage from '../../images/pages/dismantling-and-replacement-of-the-pipeline/1.jpg';
import {PageTemplates} from "../../pages/standartPages/enum";

export const dismantlingAndReplacementOfThePipeline = {
        url: '/dismantling-and-replacement-of-the-pipeline',
        title: 'Демонтаж і заміна трубопроводу',
        firstImage,
        secondImage,
        keywords: 'демонтаж трубопроводу,заміна трубопроводу',
        type: PageTemplates.TwoImagesTwoTextsBigType,
        description: 'Заміна і демонтаж технологічних трубопроводів з нержавіючої сталі та арматури для підприємств харчової, хімічної, фармацевтичної промисловості, нафтобаз, пивоварень, молокозаводів, олійно-екстракційних заводів',
        underTitle: `ТОВ "СІТАМ" Виконає демонтаж і заміну технологічного трубопроводу та іншого промислового обладнання на професійному рівні. На всі види робіт організація має необхідні дозволи та свідоцтва.
    Наша компанія виконає заміну і демонтаж технологічних трубопроводів з нержавіючої сталі та арматури для підприємств харчової, хімічної, фармацевтичної промисловості, нафтобаз, пивоварень, молокозаводів, олійно-екстракційних заводів і ін.

При умові повторного використання металевих труб демонтажні роботи проводяться обережно зі збереженням кріплень та арматури та виявленням наявності дефектів.`,
        firstText: {
            text: `#### Виконаємо заміну і демонтаж технологічних трубопроводів:

Трубопровід водопостачання;

Трубопровід стиснутого повітря;

Протипожежний водопровід;

Трубопровід подачі пари;

Трубопровід гарячого водопостачання;

Трубопровід конденсату;

Трубопровід зовнішніх мереж теплопостачання (систем опалення);

Газопровід;

Нафтопроводи;

Трубопровід стоку очищених стічних вод;

Трубопровіди з рубашкою підігріву;

Трубопровід холодопостачання;

Трубопровіди зовнішніх каналізаційних мереж;

Трубопровід аміаку;

Кислотопровід;

Трубопровід хімреагентів;

Трубопроводи рідких добрив;

Трубопровід преддефекації;

Трубопровід соку;

Вакуумний трубопровід.`
        },
        secondText: {
            text: `#### Демонтаж і заміна трубопроводу можуть включати:

Демонтаж трубопроводу з відводами та засувками;

Демонтаж запірних пристроїв з фланцями;

Демонтаж ізоляційних матеріалів;

Розбірка або різка труб;

Зняття та ремонт арматури;

Діагностика арматури та труб;

Вивіз металобрухту та будівельного сміття;

#### Заміна:

Заміна дефектних частин трубопроводу;

Зварювання і монтаж трубопроводів;

Врізання нового трубопроводу в існуючий;

Монтаж відводів;

Монтаж фланців, кранів;

Гідравлічні випробування.`
        }
    };



