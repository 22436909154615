import r1 from './1.jpg';
import r2 from './2.jpg';
import r3 from './3.jpg';
import r4 from './4.jpg';
import r5 from './5.jpg';
import r6 from './6.jpg';
import r7 from './7.jpg';
import r8 from './8.jpg';
import r9 from './9.jpg';

export const rotor = {
    text: 'Ремонт ротора',
    thumb: r3,
    images: [
        r1,
        r2,
        r3,
        r4,
        r5,
        r6,
        r7,
        r8,
        r9,
    ]
};
