import firstImage from '../../images/pages/installation-of-machines/firstImage.jpg';
import secondImage from '../../images/pages/installation-of-machines/secondImage.jpg';
import {PageTemplates} from "../../pages/standartPages/enum";

export const installationOfMachines = {
    url: '/installation-of-machines',
    type: PageTemplates.TwoImagesTwoTextsBigType,
    manufacturers: {
        text: 'ООО «СИТАМ» має великий досвід монтажу верстатів різних марок та конфігурацій. У списку обладнання, з яким ми працюємо, знаходяться агрегати наступних виробників: CNC(YZC), Hegenscheidt MFD(RQQ), DANOBAT(TT/TTB, CL/CIH, IDS), Sirmu-MT, CORREA, ARAMIS, Kalafat, Skoda, ДИП, Haas (ST), SIMPAC (MCL), TRUMATIC, WKV, KNUTH, AMADA та інших, включаючи вітчизняних.',
    },
    keywords: 'монтаж станков,монтаж сверлильных станков,монтаж фрезерных станков,монтаж сверлильно-фрезерных станков,монтаж лентопильных станков',
    title: 'Монтаж станков',
    underTitle: 'ООО «СИТАМ» Виконаємо ремонтні та монтажні роботи обладнання маслоекстракційного заводу та іншого промислового обладнання на професійному рівні. На всі види робіт організація має необхідні дозволи та свідоцтва.',
    firstImage,
    secondImage,
    description: 'Виконаємо ремонтні та монтажні роботи обладнання маслоекстракційного заводу. У списку обладнання, з яким ми працюємо, знаходяться агрегати наступних виробників: CNC(YZC), Hegenscheidt MFD(RQQ), DANOBAT(TT/TTB, CL/CIH, IDS), Sirmu-MT, CORREA, ARAMIS, Kalafat, Skoda, ДИП, Haas (ST), SIMPAC (MCL), TRUMATIC, WKV, KNUTH, AMADA та інших, включаючи вітчизняних.',
    firstText: {
        text: `Виконаємо роботи з монтажу, демонтажу та перенесення з подальшим монтажем станків та верстатних ліній.
Переїзд підприємства або переміщення цеху потребують якісного виконання монтажних робіт.

У списку обладнання, з яким ми працювали, знаходяться:

Свердлильний верстат;

Фрезерний верстат;

Свердлильно-фрезерний верстат;

Трубонарізні та муфтонарізні верстати;

Термопластавтомати;

Лентопільний верстат;

Горизонтально-расточний верстат;

Токарний верстат;

Токарно-винторізний верстат;

Токарно-револьверний обробний центр;

Лазерний верстат з різання;

Листогібочний верстат;

Прокатний верстат;

Кромкогіб;

Вертикально-фрезерний обробний центр;

Расточний верстат;

Координатно-расточний верстат;

Кривошипний прес;

Координатно-пробивний штамповий прес;

Гідравлічні преси;

Згинальні машини та гільйотини;

Шліфувальний верстат;

Деревообробні верстати;

та інші металообробні та металерізальні верстати.`
    },
    secondText: {
        text: `Монтажні роботи:
Розвантаження верстата, вузлів верстата з використанням вантажопідйомного обладнання;

Розбірка упаковки та підготовка до монтажу;

Підготовка та заливка фундаменту;

Транспортування комплектуючих верстатів по ділянці до місця монтажу;

Монтаж верстата (станини) на місці розташування фундаментів;

Установка верстата за рівнем з паспортною точністю, з подальшим затяганням та фіксацією кріпильних болтів;

Збірка вузлів верстата;

Заливка необхідної кількості робочих рідин;

Нанесення необхідних змащень;

Герметизація повітряних та гідравлічних контурів верстата;

Підключення до джерела електроживлення;

Установка шаф електроживлення верстата, подача живлення та прокладка кабелів;

Збірка, кріплення та герметизація захисних кожухів;

Перевірка герметичних параметрів верстата;


Залежно від виду верстата та вимог замовника може бути виконаний монтаж додаткового обладнання верстата. Установка та підключення всього допоміжного обладнання:
Установка гідро та масло станцій верстатів;

Установка систем охолодження масла верстатів;

Установка системи збору конденсату парів СОЖ;

Підключення гідравлічних, пневматичних шлангів (трубопроводів) СОЖ;

Установка захисного огородження;

Установка бункерів з захисними решітками;

Установка тележки завантаження-вивантаження;

Установка маніпулятора;

Установка транспортерів;

Установка системи приготування, очищення та підтримання концентрації СОЖ;

Установка чиллера;

Прокладка та монтаж трубопроводів подачі СОЖ;

і т.д.
`}}
;
