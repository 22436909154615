import secondImage from '../../images/pages/crane-painting/1.jpg';
import firstImage from '../../images/pages/crane-painting/2.png';
import {PageTemplates} from "../../pages/standartPages/enum";

export const cranePainting = {
    url: '/crane-painting',
    title: 'Покраска грузоподъемных кранов',
    firstImage,
    secondImage,
    keywords: 'покраска грузоподъемный кранов,покраска кранов, покраска мостового крана',
    manufacturers: {
        text: 'ООО «СИТАМ» имеет большой опыт покраски козловых кранов самых разных марок и конфигураций. В списке оборудования, с которым мы работаем, числятся агрегаты следующих производителей: КК, ККС, ККТ, КДКК и других производителей в т.ч. отечественных.',
    },
    description: 'Покраска (антикоррозийную обработку) мостового, козлового крана, кран-балки, портального крана и прочей грузоподъемной техники на профессиональном уровне. Удаление загрязнений, обработка участков пораженных коррозией. Оборудование, с которым мы работаем: КК, ККС, ККТ, КДКК и других производителей в т.ч. отечественных.',
    underTitle: `ООО «СИТАМ» Выполним покраску крана и другого грузоподъемного промышленного оборудования на профессиональном уровне. На все виды работ организация имеет необходимые разрешения и свидетельства.`,
    type: PageTemplates.TwoImagesTwoTextsType,
    secondText: {
        text: `Наша компания выполнит покраску (антикоррозийную обработку) мостового, козлового крана, кран-балки, портального крана и прочей грузоподъемной техники.

Покраска крана является обязательным этапом обслуживания грузоподъемного оборудования. Покраску кранов необходимо осуществлять хотя бы один раз в году, чтобы: обеспечить защиту металлоконструкций от коррозии; придать крану красивый внешнего вида.

#### Этапы покраски грузоподъемного крана:

- Очистка механизмов, узлов и элементов оборудования от старой краски;

- Удаление загрязнений, обработка участков пораженных коррозией;

- Нанесение антикоррозионного материала;

- Высушивание, очистка от пыли обезжиривание поверхности кранов;

- Нанесение грунтовки;

- Покраска крана;

- Обработка тонирующим составом.`
    },
};