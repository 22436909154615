import firstImage from '../../images/pages/dismantling-and-replacement-of-the-pipeline/2.jpg';
import secondImage from '../../images/pages/dismantling-and-replacement-of-the-pipeline/1.jpg';
import {PageTemplates} from "../../pages/standartPages/enum";

export const dismantlingAndReplacementOfThePipeline = {
    url: '/dismantling-and-replacement-of-the-pipeline',
    title: 'Демонтаж и замена трубопровода',
    firstImage,
    secondImage,
    keywords: 'демонтаж трубопровода,замена трубопровода',
    type: PageTemplates.TwoImagesTwoTextsBigType,
    description: 'Замена и демонтаж технологических трубопроводов из нержавеющей стали и арматуры для предприятий пищевой, химической, фармацевтической промышленности, нефтебаз, пивзаводов, молокозаводов, маслоэкстракционных заводов',
    underTitle: `ООО «СИТАМ» Выполним демонтаж и замену технологического трубопровода и другого промышленного оборудования на профессиональном уровне. На все виды работ организация имеет необходимые разрешения и свидетельства.
    Наша компания выполнит замену и демонтаж технологических трубопроводов из нержавеющей стали и арматуры для предприятий пищевой, химической, фармацевтической промышленности, нефтебаз, пивзаводов, молокозаводов, маслоэкстракционных заводов и др.

При условии повторного использования металлических труб демонтажные работы производятся аккуратно с сохранением крепежей и арматуры и выявлением наличия дефектов.`,
    firstText: {
        text: `#### Выполним замену и демонтаж технологических трубопроводов:

Трубопровод водоснабжения;

Трубопровод сжатого воздуха;

Противопожарный водопровод;

Трубопровод подачи пара ;

Трубопровод горячего водоснабжения;

Трубопровод конденсата;

Трубопровод наружных сетей теплоснабжения (систем отопления);

Газопровод;

Нефтепроводов;

Трубопровод сброса очищенных сточных вод;

Трубопроводов с рубашкой подогрева;

Трубопровод холодоснабжения;

Трубопроводов наружных канализационных сетей;

Трубопроводов амиака;

Кислотопровод;

Трубопровод химреагентов;

Трубопровода жидких удобрений;

Трубопровод преддефекации;

Трубопровод сока;

Вакуумный трубопровод.`
    },
    secondText: {
        text: `#### Демонтаж и замена трубопровода могут включать:

Демонтаж трубопровода с отводами и заслонок;

Демонтаж задвижек с фланцами;

Демонтаж изоляциионных материалов;

Разборка или резка труб;

Снятие и ремонт арматуры;

Диагностика арматуры и труб;

Вывоз металлолома и строительного мусора;

#### Замена:

Замена дефектных частей трубопровода;

Сварка и монтаж трубопроводов;

Врезка нового трубопровода в существующий;

Монтаж отводов;

Монтаж фланцев, кранов;

Гидравлические испытания.`
    }
};



