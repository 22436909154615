import firstImage from '../../images/pages/scraper-conveyor-conveyor-repair/1.jpg';
import secondImage from '../../images/pages/scraper-conveyor-conveyor-repair/2.jpg';
import {PageTemplates} from "../../pages/standartPages/enum";

export const scraperConveyorConveyorRepair = {
    url: '/scraper-conveyor-conveyor-repair',
    title: 'Ремонт скребкового конвейера (транспортера)',
    keywords: 'ремонт скребкового конвейера,ремонт транспортера,ремонт конвейера',
    type: PageTemplates.ThreeTwoImagesTwoTextsType,
    manufacturers: {
        text: 'ООО «СИТАМ» имеет большой опыт ремонта транспортеров самых разных марок и конфигураций. В списке оборудования, с которым мы работаем, числятся агрегаты следующих производителей: ТСЦм, TЦС, КСЦ, КЦ-С, ТрЦС, К4-УТФ, КС и других производителей в т.ч. отечественных.',
    },
    underTitle: 'ООО «СИТАМ»  выполним ремонт цепных скребковых транспортеров и другого промышленного оборудования на профессиональном уровне. На все виды работ организация имеет необходимые разрешения и свидетельства',
    firstImage,
    secondImage,
    description: 'Работы по текущему, капитальному ремонту и обслуживанию цепных скребковых конвейеров (транспортеров) предназначенных для перемещения насыпных и навалочных грузов горизонтально и под наклоном (гусиная шея). В списке оборудования, с которым мы работаем, числятся агрегаты следующих производителей: ТСЦм, TЦС, КСЦ, КЦ-С, ТрЦС, К4-УТФ, КС и других производителей в т.ч. отечественных',
    firstText: {
        text: `Наша компания производит работы по текущему, капитальному ремонту и обслуживанию цепных скребковых конвейеров (транспортеров) предназначенных для перемещения насыпных и навалочных грузов горизонтально и под наклоном (гусиная шея). В зависимости от технологии конвейер может находиться на разных высотных отметках.

Используется в основном для транспортировки зерна из завальной ямы, силоса, подачи шелухи, шрота, комбикормов, семян подсолнечника и т.п на элеваторах, зернохранилищах, маслоэкстракционных заводах (ЦДМ) так же применяются в химической, горнодобывающей, строительной, металлургической промышленности и других отраслях.

### Работы по ремонту могут включать:

- Диагностика и демонтаж крышек короба транспортера, комплектация прижимными устройствами;

- Очистка редуктора, сапуна;

- Демонтаж редуктора, муфты и приводного вала транспортера;

- Ремонт редуктора с креплением и станиной;

- Проверка посадочного места под редуктор и шпоночного соединения;

- Демонтаж вала и катка натяжной станции транспортера;

- Дефектовка состояния опорных осей и направляющих;

- Демонтаж/монтаж секции направляющих тяговой цепи;

- Ремонт направляющих цепи (рихтовка, наложение дублера, замена части);

- Демонтаж и монтаж проходных секций;

- Устранение потертости боковой стенки (днища) конвейера наложением дублера;
`
    },
    secondText: {
        text: `### Работы по ремонту могут включать также:

- Замена нижней и боковой брони;

- Замена изношенных накладок;

- Замена болтов крепления коробов;

- Замена подшипников и сальников натяжного катка и монтаж на транспортер;

- Замена соединительных пальцев и накладных скребков тяговой цепи;

- Демонтаж/монтаж тяговой цепи в короб транспортера;

- Замена подшипников электродвигателя;

- Замена подшипников приводной станции;

- Замена подшипников натяжной станции;

- Замена подшипников редуктора;

- Осмотр состояния валов (приводной и натяжной) перевтуливание в случае необходимости;

- Замена вала и ролика приводной станции;

- Замена ролика натяжной станции;

- Замена поддерживающих роликов цепи;

- Замена Шайбы тарельчатой;

- Осмотр состояния приводной и натяжной звездочки, замена в случае необходимости;

- Осмотр состояния бронзовых втулок (замена при необходимости);

- Ремонт приводной станции;

- Ремонт натяжной станции;

- Ремонт проходной станции;

- Замена резиновых втулок на муфте редуктора;

- Сборка и центровка узла (валы, звездочки, втулки, муфта, редуктор и электродвигатель);

- Замена клиновых ремней, центровка и регулировка натяжения привода;

- Монтаж защитных крышек;

- Ремонт самотека схода товара с транспортера;

- Ремонт шиберных задвижек;

- Сварочные работы (латание потертостей, замена уголков усиления и т.д.);

- Заправка редуктора маслом;

- Закладка высокотемпературной смазки в местах трения деталей;

- Восстановление функционирования системы смазки втулок, подшипников;

- Проверка и регулировка натяжения тяговой цепи;

- Проверка качества сборки холостым ходом;

- Вывоз б/у частей транспортера к месту хранения / утилизации;

- Уборка рабочего места.
`}};




