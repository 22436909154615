import firstImage from '../../images/pages/installation-of-industrial-equipment/firstImage.jpg';
import secondImage from '../../images/pages/installation-of-industrial-equipment/secondImage.jpg';
import {PageTemplates} from "../../pages/standartPages/enum";

export const installationOfIndustrialEquipment = {
        url: '/installation-of-industrial-equipment',
        title: 'Монтаж промислового обладнання',
        keywords: 'монтаж промислового,демонтаж обладнання,переїзд заводу',
        firstImage,
        secondImage,
        alsoSeeIndex: 5,
        type: PageTemplates.TwoImagesOneTextType,
        buttons: [{
            text: 'Промисловий переїзд заводу',
            url: '/'
        },{
            text: 'Монтаж технологічного трубопроводу',
            url: '/installation-of-a-process-pipeline'
        },{
            text: 'Монтаж верстатів',
            url: '/installation-of-machines'
        },{
            text: 'Монтаж пресів',
            url: '/installation-of-presses'
        },{
            text: 'Монтаж ємнісного обладнання',
            url: '/installation-of-containers'
        },{
            text: 'Монтаж обладнання для обробки полімерів (ТПА)',
            url: '/'
        },{
            text: 'Монтаж поліграфічного обладнання',
            url: '/'
        },{
            text: 'Монтаж трансформаторів і енергетичного обладнання',
            url: '/installation-of-transformers-and-power-equipment'
        },{
            text: 'Монтаж підйомного обладнання',
            url: '/installation-of-lifting-equipment'
        },{
            text: 'Монтаж конвейерів і транспортерів',
            url: '/assembly-of-the-conveyor'
        },{
            text: 'Монтаж гірничого обладнання',
            url: '/'
        },{
            text: 'Монтаж промислових печей',
            url: '/'
        },{
            text: 'Монтаж металургійного обладнання',
            url: '/'
        },{
            text: 'Монтаж технологічних ліній',
            url: '/installation-of-technological-lines'
        },{
            text: 'Монтаж іншого обладнання',
            url: '/'
        }],
        description: 'Виконає комплекс послуг з монтажу та демонтажу вашого промислового обладнання під час промислового переїзду, відкриття нових технологічних ліній і цехів, прийому нового обладнання та запуску його в експлуатацію',
        text: `Наша компанія виконає комплекс послуг з монтажу та демонтажу вашого промислового обладнання під час промислового переїзду, відкриття нових технологічних ліній і цехів, прийому нового обладнання та запуску його в експлуатацію.

#### При демонтажі та переїзді заводу виконуються наступні роботи:

Повне відключення обладнання від живильних ліній;

Частковий або повний демонтаж обладнання;

Механічний демонтаж;

Великоблочний демонтаж;

Такелажні роботи;

Переміщення та завантаження демонтованих компонентів для подальшого транспортування;

Перевезення обладнання;

#### До переліку робіт по установці обладнання можуть входити:

Розгрузка обладнання та переміщення до місця монтажу;

Виготовлення фундаментів під нове обладнання та технологічних приямків;

Переміщення деталей в зону монтажу;

Вивірка та установка обладнання в проектне положення;

Кантування, підйом або опускання частин агрегатів в обмежених умовах;

Великоблочний механічний монтаж;

Збір навісного обладнання;

Підключення живильних ліній та інженерних мереж;

Пусконалагоджувальні роботи;

Запуск обладнання в експлуатацію;`,
    };
