import firstImage from '../../images/pages/installation-of-containers/firstImage.jpg';
import secondImage from '../../images/pages/installation-of-containers/secondImage.jpg';
import thirdImage from '../../images/pages/installation-of-containers/thirdImage.jpg';
import {PageTemplates} from "../../pages/standartPages/enum";

export const installationOfContainers = {
    url: '/installation-of-containers',
    title: 'Установка емкостей',
    firstImage,
    secondImage,
    thirdImage,
    keywords: 'монтаж емкостей,установка стеклопластиковых емкостей',
    type: PageTemplates.ThreeTwoImagesTwoTextsType,
    description: 'Выполним монтаж стеклопластиковых и металлических емкостей различных видов и назначения: подземных/наземных емкостей, вертикальных/горизонтальных емкостей, емкостей мини-НПЗ, НПЗ, ГПЗ, НХЗ, емкостей хранения нефти и нефтепродуктов (ГСМ, АЗС и АГЗС)',
    firstText: {
        text: `### Выполним монтаж стеклопластиковых и металлических емкостей различных видов и назначения:

- Наземных емкостей;

- Подземных емкостей;

- Вертикальных емкостей;

- Горизонтальных емкостей;

- Емкостей хранения нефти и нефтепродуктов (ГСМ, АЗС и АГЗС);

- Емкостей мини-НПЗ, НПЗ, ГПЗ, НХЗ;

- Емкостных парков (ДНС, УПН, УПСВ и т.д.);

- Пожарных емкостей;

- Дренажных емкости;

Технологических и пищевых емкостей.

К месту установки емкости должны быть проложены подъездные дороги, а на рабочей площадке выделены места для складирования, сборки и грузоподъемной техники, подведено электроснабжение.
        `
    },
    secondText: {
        text: `### Монтаж емкостей в зависимости от расположения и исполнения может включать:

Подземные:

- Подготовка строительной площадки;

- Рытье котлована;

- Устройство подстилающего основания, подготовка гидроизоляционного слоя;

- Монтаж опорной плиты;

- Монтаж емкости;

- Фиксация стяжными ремнями;

- Засыпка котлована;

- Установка технических колодцев;

- Подсоединение к трубопроводной обвязке.

Наземные:

- Строительство фундамента;

- Транспортировка элементов емкости к месту установки;

- Монтаж конструкций днища емкостей;

- Крепление опор к фундаменту при помощи анкерных болтов;

- Монтаж стенки емкости из листов или рулонных полотнищ;

- Сварка стыков емкости или закрепление болтами;

- Монтаж крыш на емкостях;

- Размещение лестниц, платформ для обслуживания;

- Подсоединение к трубопроводной обвязке;

- Теплоизоляция емкостей, трубопроводов;

- Монтаж емкостного оборудования и обвязки;

- Антикоррозионные обработка емкостей, баков-аккумуляторов, металлоконструкций, сосудов, аппаратов;

- Испытания и сдача емкостей в эксплуатацию.

Все работы производятся в соответствии с правилами и нормами установки емкостей и требованиями заказчика. Работы по установке и монтажу выполняют монтажники имеющие необходимые для выполнения данного вида работ лицензии и сертификаты.`
    }};