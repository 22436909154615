import firstImage from '../../images/pages/cyclone-installation/2.jpg';
import secondImage from '../../images/pages/cyclone-installation/1.jpg';
import {PageTemplates} from "../../pages/standartPages/enum";

export const cycloneInstallation = {
    url: '/cyclone-installation',
    title: 'Установка циклона, ремонт та обслуговування',
    firstImage,
    secondImage,
    keywords: 'монтаж циклона,ремонт циклона,обслуговування циклона,ремонт аспіраційних систем',
    type: PageTemplates.TwoImagesTwoTextsBigType,
    underTitle: `ТОВ «СІТАМ» Виконає ремонт, монтаж циклонів, аспіраційних систем та іншого обладнання для фільтрації на професійному рівні. На всі види робіт організація має необхідні дозволи та свідоцтва.`,
    description: 'Монтаж промислових фільтрів(пилеуловлювачі): циклонів, батарейних циклонів входять до складу аспіраційних систем та приточно-витяжної вентиляції, очищають вентильований повітря від механічних домішок (частинок пилу, сажі, копоті)',
    firstText: {
        text: `### Монтаж циклона

Виконаємо монтаж промислових фільтрів(пилеуловлювачі): циклонів, батарейних циклонів входять до складу аспіраційних систем та приточно-витяжної вентиляції, очищають вентильований повітря від механічних домішок (частинок пилу, сажі, копоті та ін.).

Використання повітроочисних циклонів потрібне на виробництвах, безпосередньо пов'язаних з високим пилостворенням:

Хімічна промисловість;

Будівництво, каменетривка, шліфування і т. д;

Металургійна промисловість;

Обробка металу та деревини;

Вугільна та добувна промисловість;

Харчова промисловість;

Перелік основних видів робіт, що виконуються при монтажі циклонів:

Підготовка місця установки, заливка фундаменту;

Транспортування циклона та його елементів до місця монтажу;

Закріплення циклона анкерними болтами та збірка;

Підключення течок, мигалок, засувок, люків, патрубків та повторна опресовка;

Пусконалагоджувальні роботи та здача в експлуатацію.
Швидкість виконання робіт, безпека та збереження обладнання залежить від кваліфікації спеціалістів. Наша компанія має великий досвід виконання подібних робіт а також всі необхідні дозволи та свідоцтва на їх виконання.`
    },
    secondText: {
        text: `### Ремонт циклона

Роботи, що проводяться при ремонті скруберів і циклонів:

Поточний ремонт:

Виправлення вм'ятин, заміна уражених корозією ділянок;

Ущільнення всіх фланцевих з'єднань, кріплень;

Зварювання отворів і тріщин в верхній трубній дошці
Ремонт пилеудаляющих пристроїв;

Регулювання вивантажувальних пристроїв (мигалок і шлюзових засувок)
Заміна зношених клапанів.
Відновлення порушеної теплоізоляції.
Очищення від пилу, бруду, накипі внутрішніх поверхонь апарата, форсунок, водо та газорозподільних пристроїв;

Капітальний ремонт:

Повна заміна всіх касет з фільтруючими наповнювачами та полотнищ в сітчастих фільтрах;

Ремонт і ущільнення трубних дощок;

Ремонт корпусу з заміною кородованих листів;

Заміна протишумних щитків на передніх вихідних трубах;

Ремонт вивантажувальних пристроїв.
Повне очищення циклона від відкладень, з витягуванням в потрібних місцях вихідних труб;

Періодичність, тривалість і труднощі ремонтів різного пилогазоочисного обладнання залежать від безлічі факторів.
### Обслуговування циклона

Для забезпечення тривалої та безперебійної роботи необхідно проводити періодичне технічне обслуговування циклона. Ми проведемо повне ТО вашого обладнання включаючи:

Перевірка рівень очищення пилесбірників;

Огляд основних робочих частин агрегату на цілісність;

Відповідність налаштуванням системи;

Тщательний огляд, чистка та заміна деталей;

Видалення з бункера великих частин сміття;

Огляд цілісності теплоізоляції, ремонт пошкоджених ділянок;

Визначення та усунення підсосу повітря в бункері.
Довіряючи проведення ТО професіоналам можливо значно продовжити строк використання установки і заощадити гроші на капітальному ремонті.`
    }};

