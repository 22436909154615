import firstImage from '../../images/pages/grocery-department/1.jpg';
import {PageTemplates} from "../../pages/standartPages/enum";

export const groceryDepartment = {
    url: '/grocery-department',
    title: 'Монтаж оборудования продуктового отделения',
    firstImage,
    keywords: 'монтаж технологического трубопровода,монтаж металлоконструкций',
    type: PageTemplates.OneImageTwoTextsSmallType,
    maxSize: true,
    description: 'Монтаж и замена/демонтаж оборудования продуктового отделения сахарного завода. Монтаж центрифуг непрерывного и периодического действия (ВМА G1750, ВUСКАU R.WOLF, ФПИ 1321 К-01, ФПН 1251 Т-01, Зангерхаузен. Монтаж приемной мешалки утфеля (утфелемешалка) ТМУ;',
    firstText: {
        text: `ООО «СИТАМ» Выполним монтаж и замену/демонтаж оборудования продуктового отделения сахарного завода и другого промышленного оборудования на профессиональном уровне. На все виды работ организация имеет необходимые разрешения и свидетельства.
        
Наша компания выполнит работы по монтажу и реконструкции продуктового отделения сахарного завода.`
    },
    secondText: {
        text: `### Список возможжных работ:

- Демонтаж и монтаж технологического оборудования;

- Монтаж технологического трубопровода;

- Изготовление и монтаж металлоконструкций, лестничных маршей с ограждениями;

- Монтаж запорной арматуры;

- Изготовление и монтаж отводов, коллекторов и переходов;

- Монтаж пластинчатых теплообменников;

- Монтаж с трубной обвязкой вакуум-аппаратов ТВА;

- Монтаж утфелераспределителя;

- Монтаж центрифуг непрерывного и периодического действия (ВМА G1750, ВUСКАU R.WOLF, ФПИ 1321 К-01, ФПН 1251 Т-01, Зангерхаузен, FCB Fives Cail, G1500, BWS-1250, G1500, ФПИ 1321 К-01, ФПН 1251 Т-01);

- Установка ополаскивателя УЛП-1;

- Установка сиропных фильтров ТФС;

- Монтаж приемной мешалки утфеля (утфелемешалка) ТМУ;

- Монтаж шнека, виброконвейера;

- Монтаж клеровочной мешалки ТМК;

- Монтаж подогревателя на утфельных парах ТТДС, ПДУ;

- установка вакуум-конденсационной установки ВКУ (конденсаторы, каплеуловители, модуль нагрева воды для экстрактора, водораспределительная системы градирен, запорно-регулирующая аппаратура);

- Монтаж аппарата прогрессивной предварительной дефекации (преддефекатора) ТМА-ППД;

- Монтаж смесителя кавитационного ТМА-ПСК;

- Монтаж аппарата для проведения 1-й ступени основной дефекации (холодный дефекатор)ТМА-ПДХ;

- Монтаж аппарата для проведения 2-й ступени основной дефекации (горячий дефекатор)ТМА-ОД;

- Монтаж аппарата дефекации для сока 1-й и 2-й сатурации ТМА-ПАС;

- Монтаж электросиловой части;
`}};

