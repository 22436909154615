import React from 'react';
import {HeaderMenu} from "./Menu";
import {HeaderImage} from "./HeaderImage";

export const Header = () => {
    return (
        <>
            <HeaderMenu />
            <HeaderImage/>
        </>
    );
};
