import firstImage from '../../images/pages/modernization-of-the-shear-press/1.jpg';
import secondImage from '../../images/pages/modernization-of-the-shear-press/2.jpg';
import {PageTemplates} from "../../pages/standartPages/enum";

export const modernizationOfTheShearPress = {
    url: '/modernization-of-the-shear-press',
    type: PageTemplates.TwoImagesTwoTextsType,
    keywords: 'модернизация пресс ножниц,реконструкция пресс ножниц,ремонт пресс ножниц,пресс-ножницы',
    manufacturers: {
        text: 'ООО «СИТАМ» имеет большой опыт ремонта и модернизации пресс ножниц и прессов для пакетирования металлолома самых разных марок и конфигураций. В списке оборудования, с которым мы работаем, числятся агрегаты следующих производителей: Akros, Riko, Metso Lindemann, SIERRA, HuaHong, Enerpat, WANSHIDA, Dongfang и других производителей в т.ч. отечественных',
    },
    title: 'Модернизация пресс ножниц',
    underTitle: 'ООО «СИТАМ»  Выполним модернизацию пресс ножниц и другого промышленного оборудования на профессиональном уровне. На все виды работ организация имеет необходимые разрешения и свидетельства',
    firstImage,
    secondImage,
    description: 'Выполним ремонт и модернизацию пресс ножниц и прессов для пакетирования металлолома самых разных марок и конфигураций. Замена устаревшего гидравлического оборудования. Установка панели визуализации температур масла, температур двигателей, давления, состояния двигателей, индикация аварий и предупрежденияя',
    firstText: {
        text: `##### Модернизация электрооборудования пресс-ножниц:

Установка эл. двигателей основных насосов;

Система плавного пуска двигателей;

Установка панели визуализации температур масла, температур двигателей, давления, состояния двигателей, индикация аварий и предупреждения;

Замена существующих конечных и аварийных выключателей (морально устаревших) на более современные и надежные с интеграцией их в систему визуализации;
`
    },
    secondText: {
        text: `Наша компания выполнит модернизацию гидравлических пресс ножниц для переработки металлолома. Комплекс работ по модернизации пресс ножниц может включать в себя демонтаж и монтаж узлов, восстановительный ремонт и замену определенных узлов и механизмов.

##### Замена устаревшего гидравлического оборудования:

Диагностика гидравлической системы;

Замена насосов основного давления;

Замена имеющейся фильтровальной системы;

Замена гидроаппаратуры на современные клапанные группы;

Ремонт градирни водяного охлаждения с заменой насосной группы системы водяного охлаждения;

Установка системы фильтрации водяного охлаждения;

Замена теплообменника;

Установка системы КИПиА для управления гидросистемой ножниц: комплект аппаратуры для контроля давления, комплект датчиков для маслобаков, комплект сигнализаторов состояния фильтров;

Замена газовой аппаратуры для наполнительного бака;

Замена газовой аппаратуры системы отвода стола (пневмокамеры);

Замена трубной разводки гидросистемы;

Замена уплотнений всех гидроцилиндров;

Ремонт с заменой комплектующих клапана наполнения реза;

Замена насосной группы системы гидравлического управления;

Ввод системы в работу и сервисное сопровождения;

`}};

