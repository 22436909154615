import firstImage from '../../images/pages/thermal-insulation-of-the-pipeline/1.jpg';
import secondImage from '../../images/pages/thermal-insulation-of-the-pipeline/2.jpg';
import {PageTemplates} from "../../pages/standartPages/enum";

export const thermalInsulationOfThePipeline = {
    url: '/thermal-insulation-of-the-pipeline',
    type: PageTemplates.TwoImagesTwoTextsType,
    manufacturers: {
        text: 'ООО «СИТАМ» имеет большой опыт монтажа и замены теплоизоляции трубопровода самых разных марок и конфигураций. В списке оборудования, с которым мы работаем, числятся агрегаты следующих производителей: Е, КСВа (ЭКО), ВК, КВГ, ТВГ, КСВ и других производителей в т.ч. отечественных',
    },
    keywords: 'монтаж теплоизоляции,теплоизоляция технологического трубопровода,монтаж технологического трубопровода',
    title: 'Монтаж теплоизоляции технологического трубопровода',
    underTitle: 'ООО «СИТАМ» Выполним ремонт и монтаж теплоизоляции трубопровода и другого промышленного оборудования на профессиональном уровне. На все виды работ организация имеет необходимые разрешения и свидетельства',
    firstImage,
    secondImage,
    description: 'Ремонт и монтаж теплоизоляции трубопровода. Грунтовка и покраска трубопровода и емкостей. Монтаж кровельного слоя из стали оцинкованной с полимерным покрытием. Изготовление и монтаж каркаса для крепления изоляции',
    firstText: {
        text: `###### Монтаж тепловой изоляции трубопровода включает:

Грунтовка и покраска трубопровода и емкостей;

Монтаж тепловой изоляции (минеральная вата, пр.);

Изоляция фасонных частей и запорной арматуры;

Монтаж кровельного слоя из стали оцинкованной с полимерным покрытием;

Изготовление и монтаж каркаса для крепления изоляции;

Частичный ремонт (восстановление) изоляции в отдельных местах;

Выравнивание существующей изоляции;

Снятие старой изоляции и транспортировка в указанное место;

Так же возможна теплоизоляция технологического оборудования, резервуаров и емкостей.
`
    },
    secondText: {
        text: `##### Наша компания выполнит теплоизоляцию технологических трубопроводов  
из нержавеющей стали и арматуры для предприятий пищевой, химической, фармацевтической промышленности, нефтебаз, пивзаводов, молокозаводов, маслоэкстракционных заводов и пр.

Для обеспечения гидроизоляции и теплозащиты наружных элементов трубопроводных систем выполняется комплекс работ, включающих в себя проектирование, производство и монтаж а так же производство и монтаж покровного слоя (металлического кожуха) из листового металла, такого как : оцинковка нержавейка алюминий

Произведем монтаж, ремонт и замену теплоизоляции технологических трубопроводов:

Трубопровод водоснабжения;

Трубопровод сжатого воздуха;

Противопожарный водопровод;

Трубопровод подачи пара ;

Трубопровод горячего водоснабжения;

Трубопровод конденсата;

Трубопровод наружных сетей теплоснабжения (систем отопления);

Газопровод;

Нефтепроводов;

Трубопровод сброса очищенных сточных вод;

Трубопроводов с рубашкой подогрева;

Трубопровод холодоснабжения;

Трубопроводов наружных канализационных сетей;

Трубопроводов амиака;

Кислотопровод;

Трубопровод химреагентов;

Трубопровода жидких удобрений;

Трубопровод преддефекации;

Трубопровод сока;

Вакуумный трубопровод
`}};

