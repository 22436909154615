import firstImage from '../../images/pages/hydraulic-press/2.jpg';
import secondImage from '../../images/pages/hydraulic-press/1.jpg';
import {PageTemplates} from "../../pages/standartPages/enum";

export const hydraulicPress = {
    url: '/hydraulic-press',
    title: 'Ремонт гидравлического пресса',
    firstImage,
    secondImage,
    keywords: 'ремонт пресса,замена гидравлического пресса,монтаж прессов',
    manufacturers: {
        text: 'Компания ООО «СИТАМ» имеет большой опыт ремонта и обслуживания рамных и колонных гидропрессов самых разных марок и конфигураций. В списке оборудования, с которым мы работаем, числятся агрегаты следующих производителей: LAEIS, HPF, ITALPRESSE, Beckwood Press, glomac, П-6332Б, ONA-PRESS, Jelsingrad Smeral, дг2434а и других производителей в т.ч. отечественных'
    },
    type: PageTemplates.TwoImagesTwoTextsBigType,
    description: 'Выполним ремонт и обслуживание пресса гидравлического и другого промышленного кузнечно-прессового оборудования. В списке оборудования, с которым мы работаем, числятся агрегаты следующих производителей: LAEIS, HPF, ITALPRESSE, Beckwood Press, glomac, П-6332Б, ONA-PRESS, Jelsingrad Smeral, дг2434а и других производителей в т.ч. отечественных',
    underTitle: `ООО «СИТАМ» Выполним ремонт и обслуживание пресса гидравлического и другого промышленного кузнечно-прессового оборудования на профессиональном уровне. На все виды работ организация имеет необходимые разрешения и свидетельства
    
    Наша компания выполнит работы по ремонту и техническому обслуживанию гидравлического пресса для ковки, штамповки, запрессовки/выпрессовки изделий из металла и пластмассы. Комплекс работ по ремонту прессов может включать в себя демонтаж и монтаж узлов, восстановительный ремонт и замену определенных узлов и механизмов.`,
    firstText: {
        text: `##### Неисправности гидравлического пресса:

Не опускается ползун;

Остановка или самопроизвольное опускание;

Утечка гидравлической жидкости;

Подтекания на соединениях;

Задиры и износ рабочих поверхностей;

Шум и постукивания при работе;

Пресс не развивает нужного усилия;

Неисправности заставляют работать систему менее эффективно, с меньшей производительностью вызывая отказы в работе которые ведут к выходу оборудования из строя, увеличению брака и затратам в связи с остановкой производственного цикла.

##### Текущий и капитальный ремонт пресса гидравлического может включать:

Диагностика и составление дефектной ведомости

##### Механическая часть

Отключение пресса от внешних сетей и слив масла;

Замена трубопроводов пресса;

Разделка и заварка трещин на станине (раме);

Демонтаж стола, фрезерование и шлифование поверхности;

Замена болтов крепления;

Демонтаж, ревизия и ремонт гидроцилиндра;

Замена уплотнений главного цилиндра;

Шлифовка задиров, наплавка выработки штока;

Замена штока;

Замена направляющих стоек;

Замена направляющих верхнего ползуна;

Демонтаж ползуна, фрезерование и шлифование поверхности;

Демонтаж плунжера, наплавка и шлифовка поверхности;

Ремонт поршней с изготовлением и заменой бронзовых втулок;
`
    },
    secondText: {
        text: `##### Гидравлическая часть

Ревизия гидравлических систем и систем смазки оборудования;

Замена гидрораспределителя;

Замена или ремонт насоса;

Замена гидравлических магистралей;

Замена гидроаккумулятора, камеры гидроаккумулятора;

Заправка гидроаккумулятора;

Замена всасывающего фильтра и фильтрующих элементов;

Замена манометров пресса;

Ревизия и замена теплообменников гидроагрегата;

Замена РВД;

Замена всех РТИ пресса;

Замена и доливка гидравлического масла;

Чистка бака;

##### Электрическая часть

Реконструкция электрооборудования пресса (электрический шкаф, электропроводка и т.д);

Замена датчиков;

Замена индуктивных концевых выключателей;

Замена электродвигателя;

##### Прочее

Монтаж узлов, сборка и транспортировка пресса по цеху;

Очистка и покраска пресса;

Пусконаладка пресса.`
}};


