import firstImage from '../../images/pages/toaster-repair-vat-evaporator/1.jpg';
import { PageTemplates } from "../../pages/standartPages/enum";

export const toasterRepairVatEvaporator = {
    url: '/remont-desolventayzer-toster',
    title: 'Ремонт десольвентайзер-тостер',
    firstImage,
    type: PageTemplates.OneImageTwoTextsSmallType,
    maxSize: true,
    keywords: 'ремонт десольвентайзер-тостер, десольвентайзер-тостер',
    manufacturers: {
        text: 'ТОВ «СІТАМ» має великий досвід ремонту десольвентайзер – тостерів найрізноманітніших марок і конфігурацій. У списку обладнання, з яким ми працюємо, числяться агрегати наступних виробників: Ж-60/3, Ж-68, ЖМ-8, ПМШ 450, Ж-80/5,Ж-93, Ж-100/6, Ж-120/7, Ж-140, Ж-150/7, Ж-200/8, ЖЗУ та інших виробників, включаючи вітчизняних.',
    },
    firstText: {
        text: `ТОВ «ІНТЕК СЕРВІС» Ремонтуємо чанні испарювачі та інше промислове обладнання на професійному рівні. На всі види робіт організація має необхідні дозволи та свідоцтва.`,
    },
    description: 'Ремонт десольвентайзер–тостерів найрізноманітніших марок і конфігурацій. У списку обладнання, з яким ми працюємо, числяться агрегати наступних виробників: Ж-60/3, Ж-68, ЖМ-8, ПМШ 450, Ж-80/5,Ж-93, Ж-100/6, Ж-120/7, Ж-140, Ж-150/7, Ж-200/8, ЖЗУ та інших виробників, включаючи вітчизняних.',
    secondText: {
        text: `Іспарювач чанний (Десольвентайзер – тостер) входить до складу екстракційної лінії підприємств жирової промисловості та призначений для вологотеплової обробки обезжиреного залишку сировини (шроту) з олійних культур з метою видалення залишків розчинника з нього.

Ремонт жарильника для обробки олійних культур може включати:

- Демонтаж ножів;

- Зрізання деформованих і зношених частин ножа;

- Демонтаж зношених ділянок дна чана;

- Демонтаж зношених дублюючих листів і бічних стінок;

- Наплавка ріжучого краю ножа твердоплавкими електродами;

- Виготовлення нових частин ножа та їх установка (реставрація);

- Очищення та установка нових дублюючих листів;

- Перевірка установочних зазорів;

- Виправлення ножів;
`
    }
};
