import firstImage from '../../images/pages/press-shears/2.jpg';
import secondImage from '../../images/pages/press-shears/1.jpg';
import {PageTemplates} from "../../pages/standartPages/enum";

export const pressShears = {
    url: '/press-shears',
    title: 'Ремонт прес-ножиць',
    firstImage,
    keywords: 'ремонт прес-ножиць, прес-ножиці',
    secondImage,
    type: PageTemplates.TwoImagesTwoTextsBigType,
    manufacturers: {
        text: 'ТОВ «СІТАМ» має великий досвід ремонту прес-ножиць і пресів для пакування металобрухту різних марок і конфігурацій. У списку обладнання, з яким ми працюємо, фігурують агрегати наступних виробників: Akros, Riko, Metso Lindemann, SIERRA, HuaHong, Enerpat, WANSHIDA, Dongfang та інші виробники, включаючи вітчизняних.',
    },
    underTitle: `ТОВ «СІТАМ» Виконаємо ремонт прес-ножиць та іншого промислового обладнання на професійному рівні. На всі види робіт організація має необхідні дозволи та свідоцтва.`,
    description: 'Виконаємо роботи з ремонту та технічного обслуговування прес-ножиць для переробки металобрухту. У списку обладнання, з яким ми працюємо, фігурують агрегати наступних виробників: Akros, Riko, Metso Lindemann, SIERRA, HuaHong, Enerpat, WANSHIDA, Dongfang та інші виробники, включаючи вітчизняних.',
    firstText: {
        text: `Наша компанія виконає роботи з ремонту та технічного обслуговування прес-ножиць для переробки металобрухту. Комплекс робіт з ремонту прес-ножиць може включати в себе демонтаж та монтаж вузлів, відновлювальний ремонт та заміну певних вузлів та механізмів.

##### Несправності прес-ножиць:

- Тріщини з'єднань на стійці;

- Вибої та знос робочих поверхонь;

- Зупинка під час різання заготовки (застрягання, заклинювання);

- В отвір не проходить прокат максимальних розмірів;

- Самовільне опускання ножової рами;

- Самовільне опускання притискача;

- Витік гідравлічної оливи;

- Протікання оливи на з'єднаннях;

- Поломка кріпильних елементів;

- Пошкодження ножів;

- Знос направляючих ножової балки. У подальшому це призводить до нерівного різу металу;

- Знос країв ножів, що також впливає на якість і рівномірність різу металу;

- Насос не видаватиме максимальний основний тиск у системі (немає робочого тиску);

- Люфт у шарнірних з'єднаннях кріплення гідроциліндрів;

- Вибої на штоку;

- Несправність датчика температури оливи, тиску;

- Мех. пошкодження РВД;

Несправності змушують працювати систему менш ефективно, з меншою продуктивністю, викликаючи відмови в роботі, які призводять до виходу агрегата з ладу, збільшення браку та витрат у зв'язку з припиненням виробничого циклу.

##### Роботи з ремонту та усунення дефектів прес-ножиць можуть включати:

- Діагностика прес-ножиць та виявлення несправностей обладнання;

##### Гідравлічний трубопровід

- Заміна ущільнень;

- Заміна колектора;

##### Стійка

- Розділка та зварка тріщин на стійці;

- Демонтаж та монтаж стійки;

- Заміна стальних наладок на стійці;

- Наплавка та механічна обробка вікна видачі лому;

- Наплавка та механічна обробка днища прес-камери;

##### Вузол різу

- Заміна ущільнень циліндра різу;

- Затягування пробки циліндра різу;

- Ремонт поршнів з виготовленням (мех. обробкою) та заміною бронзових втулок;

- Заміна направляючих втулок штока з виготовленням (мех. обробкою);

- Шліфування вибоїв, наплавка витрати штока;

- Наплавка ножової рами;

- Заміна направляючих ножової рами;

- Наплавка монтажних місць (сідла) під ножі;

- Заміна болтів кріплення наладок;

- Заміна прокладок ножів (верхніх, нижніх);

- Заміна болтів кріплення стальних наладок стійки;

- Наплавка та мех. обробка направляючих;

- Заміна ущільнювальних кілець з'єднань трубопроводів;

- Заміна футеровочних плит;

- Заміна болтів, гайок, шпильок, шайб, шпонок ножів;

- Заміна ножів;

- Заміна листів ковзання ножової рами на стійці;

- Заміна опорів листів ковзання на стійці;

- Заміна контрножа;

- Заміна болтів кріплення листів ковзання;

- Розклинювання виносу різу або притискання;
`
    },
    secondText: {
        text: `##### Притиск (трамбівка)

- Демонтаж, монтаж циліндра трамбівки;

- Заміна ущільнень циліндра трамбівки;

- Заміна ущільнювальних кілець з'єднань трубопроводів;

- Ремонт поршнів з виготовленням (мех. обробкою) та заміною бронзових втулок;

- Заміна направляючих втулок штока з виготовленням (мех. обробкою);

- Наплавка кубика трамбівки;

- Наплавка прогнилих місць з очищенням після наплавки;

- Заміна зношених плит шахти притискання (трамбівки);

- Вибурювання залишків поламаних болтів та відновлення різьби в стійці;

- Заміна болтів кріплення плит;

- Заміна болтів кріплення штока;

##### Пресуючий вузол

- Заміна ущільнень циліндрів кришок;

- Заміна циліндрів кришок;

- Ремонт поршнів з виготовленням (мех. обробкою) та заміною бронзових втулок;

- Заміна направляючих втулок штока з виготовленням (мех. обробкою);

- Заміна бронзових втулок вушок гідроциліндрів;

- Відновлення геометричних розмірів пальців циліндрів кришок;

- Наплавка та мех. обробка поверхонь кришок;

- Заміна фланців;

- Демонтаж та монтаж створки;

- Наплавка та очищення створок, бронельиста;

- Монтаж неподвижних рояльних петель;

- Встановлення бронзових втулок рухливих рояльних петель;

##### Толкатель

- Ремонт поршнів з виготовленням (мех. обробкою) та заміною бронзових втулок;

- Заміна направляючих втулок штока з виготовленням (мех. обробкою);

- Наплавка та шліфування пошкоджених місць штоків;

- Демонтаж та монтаж толкателя;

- Заміна ущільнень;

- Заміна циліндра толкателя;

- Наплавка пуансона;

- Заміна пальця кріплення толкателя;

##### Формувальний вузол

- Заміна ущільнень;

- Заміна бронзових втулок вушок гідроциліндра;

- Відновлення геометрії пальців циліндра формування;

- Заміна бронзових втулок для валів формування;

- Відновлення посадкових місць кріплення формування до пресу з заміною втулки, пальця та зупинника;

- Ревізія розподільників, очищення, промивання;

- Заміна розподільника ЦСС;

- Заміна прес-масел;

- Заміна датчика температури оливи;

- Розбірка, чистка та збірка клапанів;

- Заміна гідравлічної оливи з повним миттям гідравлічного бака;

- Заміна або ремонт гідравлічного насоса;

- Заміна РВД;

- Ремонт обручів РВД кришок;

- Заміна масляного фільтра;

- Заміна повітряних фільтрів з датчиком забруднення;

- Заміна фільтрів гідронасоса;

- Заміна, чистка фільтрів всмоктувальної лінії;

- Заміна фільтруючих елементів у системі охолодження;

- Очищення радіаторів охолодження;

- Очищення вентиляційної системи та зони входу охолоджуючого повітря;

##### Інше

- Проведення електроізмерений (заземлення, ізоляція, петля, фаза-нуль);

- Регулювання тиску в гідравлічній системі;

- Очищення та фарбування обладнання;

- Ремонт фундаменту;
`
    },
};
