import firstImage from '../../images/pages/toaster-repair-vat-evaporator/1.jpg';
import {PageTemplates} from "../../pages/standartPages/enum";

export const toasterRepairVatEvaporator = {
    url: '/toaster-repair-vat-evaporator',
    title: 'Ремонт десольвентайзер-тостера',
    firstImage,
    type: PageTemplates.OneImageTwoTextsSmallType,
    maxSize: true,
    keywords: 'ремонт десольвентайзер-тостера,десольвентайзер-тостер',
    manufacturers: {
        text: 'ООО «СИТАМ»  имеет большой опыт ремонта десольвентайзер – тостеров самых разных марок и конфигураций. В списке оборудования, с которым мы работаем, числятся агрегаты следующих производителей: Ж-60/3, Ж-68, ЖМ-8, ПМШ 450, Ж-80/5,Ж-93, Ж-100/6, Ж-120/7, Ж-140, Ж-150/7, Ж-200/8, ЖЗУ и других производителей в т.ч. отечественных.'
    },
    firstText: {
        text: `ООО «ИНТЕК СЕРВИС» Ремонтируем чанные испарители и другое промышленное оборудование на профессиональном уровне. На все виды работ организация имеет необходимые разрешения и свидетельства.`
    },
    description: 'Ремонт десольвентайзер–тостеров самых разных марок и конфигураций. В списке оборудования, с которым мы работаем, числятся агрегаты следующих производителей: Ж-60/3, Ж-68, ЖМ-8, ПМШ 450, Ж-80/5,Ж-93, Ж-100/6, Ж-120/7, Ж-140, Ж-150/7, Ж-200/8, ЖЗУ и других производителей в т.ч. отечественных',
    secondText: {
        text: `Испаритель чанный (Десольвентайзер – тостер) входит в состав экстракционной линии предприятий масложировой промышленности и предназначен для влаготепловой обработки обезжиренного остатка сырья (шрота) из масличных культур с целью удаления остатков растворителя из него.

Ремонт жаровни для обработки масличных культур может включать:

- Демонтаж ножей;

- Срезание деформированных и изношенных частей ножа;

- Демонтаж изношенных участков дна чана;

- Демонтаж изношенных дублирующих листов и боковых стенок;

- Наплавка режущей кромки ножа твердоплавкими электродами;

- Изготовление новых частей ножа и их установка (реставрация);

- Зачистка и установка новых дублирующих листов;

- Проверка установочных зазоров;

- Правка ножей;
`
}};
