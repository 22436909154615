import firstImage from '../../images/pages/installation-of-a-process-pipeline/2.jpg';
import secondImage from '../../images/pages/installation-of-a-process-pipeline/1.jpg';
import {PageTemplates} from "../../pages/standartPages/enum";

export const installationOfAProcessPipeline = {
    url: '/installation-of-a-process-pipeline',
    title: 'Монтаж технологічного трубопроводу',
    type: PageTemplates.TwoImagesTwoTextsBigType,
    keywords: 'ремонт технологічного трубопроводу,ремонт трубопроводу водопостачання',
    manufacturers: {
        text: 'ТОВ «СІТАМ» має великий досвід монтажу та виготовлення трубопроводу найрізноманітніших марок та конфігурацій. Серед обладнання, з яким ми працюємо, вказані агрегати таких виробників: Е, КСВа (ЭКО), ВК, КВГ, ТВГ, КСВ та інших виробників в т.ч. вітчизняних.',
    },
    underTitle: 'ТОВ «СІТАМ» Виконаємо будівництво монтаж технологічного трубопроводу та іншого промислового обладнання на професійному рівні. На всі види робіт організація має необхідні дозволи та свідоцтва.',
    firstImage,
    secondImage,
    description: 'Виконаємо монтаж технологічних трубопроводів з нержавіючої сталі та арматури (включаючи зварювання в середовищі аргону) для підприємств харчової, хімічної, фармацевтичної промисловості, нафтобаз, пивоварів, молокозаводів, олійних екстракційних заводів',
    firstText: {
        text: `Наша компанія виконає монтаж технологічних трубопроводів з нержавіючої сталі та арматури (включаючи зварювання в середовищі аргону) для підприємств харчової, хімічної, фармацевтичної промисловості, нафтобаз, пивоварів, молокозаводів, олійних екстракційних заводів та ін.

##### Виконаємо прокладання технологічних трубопроводів:

- Трубопровід водопостачання;

- Трубопровід стиснутого повітря;

- Протипожежний водопровід;

- Трубопровід подачі пари;

- Трубопровід гарячого водопостачання;

- Трубопровід конденсату;

- Трубопровід зовнішніх мереж теплопостачання (систем опалення);

- Газопровід;

- Нафтопроводи;

- Трубопровід відведення очищених стічних вод;

- Трубопроводи з кільцевою рубашкою нагріву;

- Трубопровід холодопостачання;

- Трубопровіди зовнішніх каналізаційних мереж;

- Трубопровіди аміаку;

- Кислотопровід;

- Трубопровіди хімреагентів;

- Трубопроводи рідких добрив;

- Трубопровід преддефекації;

- Трубопровід соку;

- Вакуумний трубопровід.
`
    },
    secondText: {
        text: `#### Роботи при монтажі та виготовленні технологічних трубопроводів можуть включати:

- Виготовлення технологічної площадки;

- Виготовлення кронштейнів та хомути;

- Виконання технологічних отворів для прокладання трубопроводів;

- Виготовлення та монтаж вузлів трубопроводу;

- Монтаж запірної арматури, протяг та кріплення труби;

- Зварювання металевих трубопроводів;

- Вирізка нового трубопроводу в існуючий та з'єднання;

- Гідравлічні випробування;

- Монтаж та обв'язка технологічного обладнання (насоси, теплообмінники, сепаратори, преси та ін.);

- Грунтовка та фарбування антикорозійним емаллю.

##### Додаткові будівельно-монтажні роботи:

- Розробка та зворотне засипання траншей екскаватором;

- Монтаж кільця;

- Підключення системи зворотного осмосу;

- Виготовлення витяжних парасоль;

- Монтаж дренажної системи.
`}};




