import firstImage from '../../images/pages/installation-of-oil-adsorption-bleaching-line/1.jpg';
import {PageTemplates} from "../../pages/standartPages/enum";

export const installationOfOilAdsorptionBleachingLine = {
    url: '/installation-of-oil-adsorption-bleaching-line',
    title: 'Монтаж лінії адсорбції (відбілювання) масла',
    firstImage,
    keywords: 'монтаж лінії адсорбції масла,монтаж лінії відбілювання масла,линія відбілювання масла',
    type: PageTemplates.OneImageTwoTextsSmallType,
    description: 'монтаж лінії адсорбції (відбілювання) масла різних культур, таких як соєве масло, арахісове масло, соняшникове масло, масло насіння рапсу, кокосове масло, пальмове масло тощо',
    secondText: {
        text: `Наша компанія виконає монтаж лінії адсорбції (відбілювання) масла різних культур, таких як соєве масло, арахісове масло, соняшникове масло, масло насіння рапсу, кокосове масло, пальмове масло тощо.

#### Монтаж лінії адсорбції (відбілювання) рослинного масла:

- Монтаж резервуарів (ємностей)
- Монтаж колон
- Монтаж насосів
- Монтаж теплообмінників
- Монтаж трубопроводу пара
- Монтаж вакуумного адсорбційного агрегату
- Монтаж циклона
- Монтаж фільтрів
- Монтаж пастки
- Монтаж повітряного компресора`
    }};

