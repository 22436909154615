import firstImage from '../../images/pages/silo-repair/1.jpg';
import secondImage from '../../images/pages/silo-repair/2.jpg';
import {PageTemplates} from "../../pages/standartPages/enum";

export const siloRepair = {
    url: '/silo-repair',
    title: 'Ремонт та обслуговування силосу',
    type: PageTemplates.TwoImagesOneTextType,
    keywords: 'ремонт силосу,обслуговування силосу',
    manufacturers: {
        text: 'ТОВ "СІТАМ" має великий досвід ремонту силосів найрізноманітніших марок і конфігурацій. У списку обладнання, з яким ми працюємо, знаходяться агрегати наступних виробників: CIMBRIA, GSI та інших виробників, включаючи вітчизняних.',
    },
    underTitle: 'ТОВ "СІТАМ" Виконаємо ремонт силосу та іншого промислового обладнання на професійному рівні. На всі види робіт організація має необхідні дозволи та свідоцтва.',
    firstImage,
    secondImage,
    description: 'Виконаємо ремонт металевих силосів, реконструкції силосних корпусів для зберігання зерна з використанням технології промислового альпінізму. Ремонт жорсткої даху (крыші) силосу. Ремонт конуса силосу',
    text: `Наша компанія виконує роботи з поточного ремонту металевих силосів, реконструкцій силосних корпусів для зберігання зерна з використанням технології промислового альпінізму.
    
#### Роботи по ремонту можуть включати:

- Ремонт жорсткої даху (крыші) силосу;

- Демонтаж пошкоджених листів криші;

- Заміна пошкоджених балок криші;

- Монтаж металоконструкцій посилення балок;

- Вирівнювання пошкоджених і монтаж листів криші;

- Ремонт і герметизація швів і з'єднань;

- Ремонт внутрішніх поверхонь (стін);

- Ремонт конуса силосу;

- Заміна термометрії;

- Ремонт самотеків;

- Ремонт з'єднань СОГів та відновлення СОГівних елеваторів;

- Монтаж металевих заплаток, опалубки на просвітні отвори;

- Усунення пересипань зернових культур, дотягання та заміна шпильок;

- Очищення вентиляційних отворів;

- Захист від корозії (фарбування).
`};
