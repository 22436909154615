import firstImage from '../../images/pages/reconstruction-of-the-meal-feed-line/1.jpg';
import secondImage from '../../images/pages/reconstruction-of-the-meal-feed-line/2.jpg';
import {PageTemplates} from "../../pages/standartPages/enum";

export const reconstructionOfTheMealFeedLine = {
    url: '/reconstruction-of-the-meal-feed-line',
    title: 'Реконструкция линии подачи шрота',
    type: PageTemplates.TwoImagesTwoTextsType,
    keywords: 'ремонт линии подачи шрота,линия подачи шрота',
    manufacturers: {
        text: 'ООО «СИТАМ» имеет большой опыт реконструкции и монтажа технологических линий разных марок и конфигураций. В списке оборудования, с которым мы работаем, числятся агрегаты следующих производителей: Де Смет(DE SMET), Аллоко(ALLOCCO), «СРМ, ШМИДТ-ЗЕЕГЕР(Schmidt-Seeger) и других производителей в т.ч. отечественных',
    },
    underTitle: 'ООО «СИТАМ» Реконструируем технологические линии и другое промышленное оборудование на профессиональном уровне. На все виды работ организация имеет необходимые разрешения и свидетельства.',
    firstImage,
    secondImage,
    description: 'Монтаж оборудования для возврата негранулированного шрота на участок грануляции в цехе добычи масла (ЦДМ) и ремонт ленты экстрактора в цеху экстракции. В списке оборудования, с которым мы работаем, числятся агрегаты следующих производителей: Де Смет(DE SMET), Аллоко(ALLOCCO), «СРМ, ШМИДТ-ЗЕЕГЕР(Schmidt-Seeger) и других производителей в т.ч. отечественных',
    firstText: {
        text: `Монтаж оборудования для возврата негранулированного шрота на участок грануляции в цехе добычи масла (ЦДМ) и ремонт ленты экстрактора в цеху экстракции

Реконструкция участка завода по подготовке к переработке семян подсолнечника

### Реконструкция предполагает:

Установка силоса для накопления негранулированного шрота

### Замена корпуса транспортера площадки норийной башни: 

#### Демонтажные работы:

- Демонтаж секций самотека с нории линии шрота;

- Демонтаж крышек секций конвейера;

- Разъединение и выемка тяговой цепи;

- Демонтаж привода, ведущего и ведомого валов;

- Демонтаж соединительных пластин секций и отсоединение секций от опорных лап;

- Отсоединение тяговых звездочек от приводного и ведомого валов;

- Перемещение демонтированных секций и цепи;

#### Монтажные работы:

- Поднятие новых секций и приводной цепи а так же приводной и ведомой звездочек;

- Сборка секций конвейера, установка на опорные элементы;

- Изготовление и установка новых лап на площадку;

- Проверка стыков между секциями на наличие перепадов;

- Сборка приводного и тягового валов с новыми звездочками;

- Сборка приводной и натяжной секции, установка новых опорных подшипников, монтаж привода;

- Сборка и установка новой тяговой цепи, регулировка натяжки;

- Установка секций самотека с изготовлением фланцевого соединения;

- Выполнение отверстия в крышке и дне приводной секции;

- Изготовление фланцевого соединения для подачи товара с конвейера;

- Пуско-наладочные работы.

#### Продление корпуса транспортера на площадке элеватора:

- Демонтаж натяжной секций конвейера;

- Разъединение и выемка тяговой цепи, отключение секций от опорных лап;

- Изготовление и установка 3 опорных лап под новую секцию и перемещенную натяжную станцию;

- Подъем на высоту и установка дополнительной секции конвейера;

- Подъем на высоту и установка новой тяговой цепи конвейера;

- Перемещение демонтированной цепи;

- Вырезка отверстия под самотек нории в крышке смонтированной секции;

- Изготовление и монтаж фланца под существующий самотек;

- Пуско-наладочные работы.`
    },
    secondText: {
        text: `Сокращение длины корпуса транспортера в галереи грануляции:

- Демонтаж приводной секций конвейера вместе с приводом;

- Разъединение и выемка тяговой цепи трех секций, отсоединение 2 секций от опорных лап и их демонтаж;

- Отключение и демонтаж шиберной задвижки приводной и промежуточной секции;

- Демонтаж самотека с конвейера, откручивание конвейера от опорных лап;

- Смещение конвейера в направлении приводной станции;

- Срезка крепления 3-х опорных лап, перемещение и, закрепление на новом месте;

- Изготовление и установка заглушки на конвейер;

- Изготовление промежуточного фланца приводной станции, просверлить отверстия под крепления;

- Собрать конвейер и соединить тяговую цепь, закрепить конвейер;

- Расширить отверстие в крышке конвейера натяжной станции под самотек с конвейера, отверстие, которое образовалось в крышке перекрыть листом;

- Установка самотека с конвейера;

- Демонтаж самотеком с одного конвейера на другой;

- Выполнение проема в бетонном перекрытию;

- Изготовление нового самотека;

- Использование существующего самотека и фланеца;

- Использование существующей крышки секции конвейера с фланцем, установив ее на секцию;

- Изготовление соединительной пластины крышек и установка;

- Пуско-наладочные работы.

#### Продление корпуса наклонного конвейера типа «гусиная шея» на площадке норийной башни:

- Установка грузоподъемного оборудования;

- Разборка и демонтаж конвейера при необходимости;

- Разборка и демонтаж привода конвейера при необходимости;

- Демонтаж крышки приводной секции, разъединение тяговой цепи конвейера, и извлечение ее из шахты;

- Демонтаж приводной секции конвейера;

- Демонтаж короткой секции установленной за приводом;

- Демонтаж рамы приводной секции;

- Размещение приводной секции, рамы, привода, и секции;

- Монтаж 3 секций;

- Установить 2 опоры закрепив секцию с шибером к двутавровой балке с помощью кронштейнов;

- Смонтировать раму и приводную секцию;

- Установить привод;

- Завести и соединить тяговая цепь;

- Изготовить и смонтировать воронку транспортировки товара с конвейера на конвейер;

- Пуско-наладочные работы.

#### Ремонт ленты экстрактора:

- Замена пластин ленты экстрактора;

- Замена роликов ленты экстрактора;

- Замена втулок роликов;

- Замена штифтов роликов;

- Замена болтов звеньев ленты экстрактора;

- Замена трубок ленты экстрактора;

- Натяжка ленты экстрактора;

- Центровка ленты экстрактора.

#### Изготовление и монтаж опорных металлоконструкций и площадок обслуживания:

- Монтаж стоек, двутавровых балок
- Изготовление и монтаж металлоконструкций под конвейера и транспортер
`}};

