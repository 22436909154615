import secondImage from '../../images/pages/guillotine-press-shears/1.jpg';
import firstImage from '../../images/pages/guillotine-press-shears/2.jpg';
import {PageTemplates} from "../../pages/standartPages/enum";

export const guillotinePressShears = {
        url: '/guillotine-press-shears',
        title: 'Ремонт гільйотинних ножиць',
        firstImage,
        secondImage,
        keywords: 'відмови гільйотини,ремонт гільйотинних ножиць,заміна гільйотинних ножиць',
        manufacturers: {
            text: 'ТОВ «СІТАМ» має великий досвід ремонту гільйотинних ножиць найрізноманітніших марок та конфігурацій. Серед обладнання, з яким ми працюємо, вказані агрегати таких виробників: Akros, Yangli QC12, NC E200 та інших виробників (в т.ч. вітчизняних)',
        },
        underTitle: `ТОВ «СІТАМ» Ремонтуємо гільйотини, прес-ножиці та гідравліку на професійному рівні. На всі види робіт організація має необхідні дозволи та свідоцтва`,
        description: 'Ремонтуємо гільйотини, прес-ножиці та гідравліку на професійному рівні. Серед обладнання, з яким ми працюємо, вказані агрегати таких виробників: Akros, Yangli QC12, NC E200 та інших виробників (в т.ч. вітчизняних)',
        type: PageTemplates.TwoImagesOneTextType,
        text: `#### Відмови гільйотинних ножиць:

- Двигун не запускається;

- Ріжучий балка не рухається;

- Дивний шум, що видається насосом;

- Не встановлюється максимальний тиск;

- Погана якість різу.

Відмови змушують працювати систему менш ефективно, з меншою продуктивністю, викликаючи відмови в роботі, які призводять до виходу агрегата з ладу, збільшенню браку та витрат в зв'язку з зупинкою виробничого циклу.

##### Ремонт гільйотини для металу включає:

В залежності від результатів діагностики перелік робіт по поточному ремонту гільйотинних ножиць може включати ремонт, відновлення або повну заміну наступних елементів гільйотинних ножиць.

- Заміна окремих вузлів або елементів системи;

- Усунення дефектів деталей наплавкою та розточуванням;

- Повний демонтаж всіх вузлів та розбірка;

- Чищення, промивання та протирання деталей;

- Обробка столу, станини, механізму різу, та ін. базових деталей;

- Виготовлення бронзових втулок та вкладень;

- Заміна підшипників кочення та ковзання;

- Заміна системи змазки;

- Виготовлення ел. шафи та розводка електрообладнання;

- Виправлення норм точності та зазору між ножами;

- Пробіжка ножиць на холостому ходу;

- Перевірка роботи на заготовці;

- Фарбування ножиць;

- Ремонт автоматики;

- Пусконалагодження та монтаж гільйотинних ножиць.

Ремонт та модернізація дозволяє отримати агрегат за своїми характеристиками повністю відповідно новому, за витратами не більше 35- 40% від вартості нового обладнання`,
    };

