import firstImage from '../../images/pages/repair-of-grab-reloader/2.jpg';
import secondImage from '../../images/pages/repair-of-grab-reloader/1.jpg';
import {PageTemplates} from "../../pages/standartPages/enum";

export const repairOfGrabReloader = {
    url: '/repair-of-grab-reloader',
    title: 'Ремонт грейфера, перегружувача',
    firstImage,
    secondImage,
    keywords: 'ремонт грейфера,ремонт перегружувач',
    type: PageTemplates.TwoImagesTwoTextsBigType,
    manufacturers: {
        test: 'ТОВ «СІТАМ» має великий досвід ремонту грейферів і перегружувачів металобрухту найрізноманітніших марок і конфігурацій. В списку обладнання, з яким ми працюємо, знаходяться агрегати наступних виробників: SMAG (S-HMG), Liebherr, Colmar, Fuchs, Велмаш-С, Atlas-Terex, ARDEN, ЄК, ПЕ, МГК та інших виробників включаючи вітчизняних.'
    },
    underTitle: `ТОВ «СІТАМ» Обслуговує грейферні перегружувачі та інше промислове обладнання на професійному рівні. На всі види робіт організація має необхідні дозволи та свідоцтва.`,
    description: 'Ремонт грейферів і перегружувачів металобрухту найрізноманітніших марок і конфігурацій. В списку обладнання, з яким ми працюємо, знаходяться агрегати наступних виробників: SMAG (S-HMG), Liebherr, Colmar, Fuchs, Велмаш-С, Atlas-Terex, ARDEN, ЄК, ПЕ, МГК та інших виробників включаючи вітчизняних',
    firstText: {
        text: `Наша компанія надає послуги з технічного обслуговування та поточного/капітального ремонту грейферних навантажувачів та перегружувачів металобрухту.

Ця техніка використовується переважно для завантаження металобрухту в преси, прес-ножиці, а також для завантаження вагонів, ломовозів і перевалки металобрухту та ТПВ на відкритих майданчиках.

##### Несправності грейфера:

- Витік робочої рідини;

- Щелепи не герметично прилягають одна до одної, зазори між зубами;

- Вигини або поламки на ножах;

- Несинхронне переміщення гідроциліндрів;

- Тріщини в напрямних і ковзунів, знос робочих поверхонь, вигин осей;

- Викривлення в тягах;

- Порушення геометрії (вм'ятини, викривлення, тріщини або зношування елементів захоплення);

- Знос посадкових поверхонь муфти електродвигун-насос;

- Руйнування оболонок кабелів;

- Перериви петель кріплення опадаючих кожухів, деформація кожухів;

- Порушення герметичності корпусів електромагнітів гідророзподільника;

- Руйнування антикорозійного покриття.

Несправності змушують працювати систему менш ефективно, з меншою продуктивністю, викликаючи відмови в роботі, які призводять до виходу техніки з ладу та зупинки робочого циклу.`
    },
    secondText: {
        text: `##### Ремонт щелепних (лепесткових) грейферних захоплень може включати:

- Діагностика грейфера;

- Розбірка грейфера;

- Відновлення отворів під пальці;

- Виготовлення пальців кріплення циліндрів та кігтів;

- Заміна наконечників кігтів грейфера;

- Наплавка тріщин і розділення;

- Усунення вигинів деталей;

- Ремонт щелеп грейферних перегружувачів;

- Заміна РТІ і РВД;

- Заміна, ремонт гідравлічних циліндрів;

- Розточування посадкових місць, заміна втулок;

- Заміна муфти двигун-насос;

- Заміна кабелів обертової платформи та грейфера;

- Відновлення кожухів грейфера та електродвигуна;

- Збірка грейфера;

- Виготовлення і наплавка упорів кігтів;

- Встановлення необхідних зазорів;

- Затягування з'єднань вузлів;

- Контроль та заміна гідравлічного масла та змащувальних матеріалів;

- Чищення та фарбування грейфера;

- Розміщення пуско-захисного устаткування.`
    }};