import secondImage from '../../images/pages/assembly-of-the-conveyor/firstImage.jpg';
import firstImage from '../../images/pages/assembly-of-the-conveyor/secondImage.jpg';
import {PageTemplates} from "../../pages/standartPages/enum";

export const assemblyOfTheConveyor = {
    url: '/assembly-of-the-conveyor',
    title: 'Монтаж и замена конвейера (транспортера)',
    type: PageTemplates.ThreeTwoImagesTwoTextsType,
    keywords: 'монтаж конвейера,замена конвейера,монтаж транспортера,замена транспортера',
    firstImage,
    secondImage,
    description: 'Монтаж и замена/демонтаж конвейеров (транспортеров) и другого промышленного оборудования на профессиональном уровне. Работы по монтажу и замене/демонтажу цепных скребковых и винтовых (шнековых) конвейеров (транспортеров) предназначенных для перемещения насыпных и навалочных грузов горизонтально и под наклоном (гусиная шея)',
    firstText: {
        text: `Выполним монтаж и замену/демонтаж конвейеров (транспортеров) и другого промышленного оборудования на профессиональном уровне. На все виды работ организация имеет необходимые разрешения и свидетельства.
        
Наша компания производит работы по монтажу и замене/демонтажу цепных скребковых и винтовых (шнековых) конвейеров (транспортеров) предназначенных для перемещения насыпных и навалочных грузов горизонтально и под наклоном (гусиная шея). В зависимости от технологии конвейер может находиться на разных высотных отметках.

Использующихся в основном для транспортировки зерна из завальной ямы, силоса, подачи шелухи, шрота, комбикормов, семян подсолнечника и т.п на элеваторах, зернохранилищах, маслоэкстракционных заводах (ЦДМ) так же применяются в химической, горнодобывающей, строительной, металлургической промышленности и других отраслях.
        `
    },
    secondText: {
        text: `В ходе замены скребковых конвейеров выполняются следующие работы:

#### Монтаж

- Изготовление и установка площадки;

- Монтаж проходных секций;

- Монтаж натяжной станции с установкой цепи;

- Установка защитных крышек;

- Монтаж привода конвейера (мотор-редуктора);

- Изготовление и врезка самотеков;

- Изготовление и установка опоры конвейера;

- Регулировка натяжения цепи;

- Футеровка поулиретаном армированным;

- Изготовление и монтаж нового укрытия каналов конвейера с организацией стока воды;

- Грунтовка и окраска;

- Проверка положения приводного вала;

- Смещение приводной и ведомой звездочки тяговой цепи;

- Проверка сборки на холостом ходу;

Уборка рабочего места.

#### Демонтаж

- Демонтаж коробов;

- Демонтаж проходных секций;

- Демонтаж привода (эл. двигатель, редуктор);

- Демонтаж самотечного оборудования;

- Демонтаж цепи конвейера;

- Демонтаж приводной группы.`
    }};