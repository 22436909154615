import secondImage from '../../images/pages/repair-of-the-seed-crusher/1.jpg';
import firstImage from '../../images/pages/repair-of-the-seed-crusher/2.jpg';
import {PageTemplates} from "../../pages/standartPages/enum";

export const repairOfTheSeedCrusher = {
    url: '/repair-of-the-seed-crusher',
    title: 'Ремонт семяобрушувальної машини (семярушки)',
    type: PageTemplates.TwoImagesTwoTextsBigType,
    firstImage,
    secondImage,
    keywords: 'семяобрушувальна машина,ремонт семяобрушувальної машини,ремонт семярушки',
    manufacturers: {
        text: 'ТОВ «СІТАМ» має великий досвід ремонту семярушок найрізноманітніших марок і конфігурацій. У списку обладнання, з яким ми працюємо, знаходяться агрегати наступних виробників: НРХ-4, НРХ-4-01, МР-70, СМ-450, МРН-50 та інших виробників, включаючи вітчизняних.',
    },
    underTitle: 'ТОВ «СІТАМ» Ремонтуємо семяобрушувальні машини та інше промислове обладнання на професійному рівні. На всі види робіт організація має необхідні дозволи та свідоцтва.',
    description: 'Ремонт семярушальної машини для обрушування шкірних олійних насіння на підприємствах олійно-жирової промисловості. У списку обладнання, з яким ми працюємо, знаходяться агрегати наступних виробників: НРХ-4, НРХ-4-01, МР-70, СМ-450, МРН-50 та інших виробників, включаючи вітчизняних.',
    firstText: {
        text: `Виконаємо ремонт семярушальної машини для обрушування шкірних олійних насіння на підприємствах олійно-жирової промисловості.

#### Порядок розборки та ремонту бичкової семярушки:

- Відключення електродвигуна;

- Демонтаж захисних кожухів, ременів барабана та живильника;

- Демонтаж лобової пластини обладнання з електродвигуном;

- Демонтаж внутрішнього кожуха барабана;

- Демонтаж корпусів підшипників живильника та самого живильника;

- Відкручування корпусів підшипників вала ротора;

- Демонтаж барабана;

- Відкручування задньої пластини секцій деки;

- Відкручування та демонтаж корпусів і гвинтів декодержателя;

- Демонтаж обертового вузла декодержателя;

- Виїмка та заміна секцій дек;

- Відрізання зношених країв фіксації секцій дек та приварювання нових;

- Демонтаж та заміна бичок ротора;

- Демонтаж шківів і підшипників ротора заміною;

- Демонтаж шківа живильника та корпусу з заміною підшипників;

- Демонтаж натягового шківа та зняття підшипників з заміною;

- Збірка обладнання в зворотному порядку;

- Регулювання положення декодержателя, натягу ременів;

- Запуск обладнання у роботу.`
    },
    secondText: {
        text: `#### Ремонт самотіків:

- Заміна самотіка з конвеєра на семяобрушувальну машину;

- Демонтаж самотіка з лотка шиберної засувки конвеєра до семярушки;

- Виготовлення нового самотіка;

- Приварювання самотіка до лотка;

- Відрізання фланця від старого самотіка та приварювання до нового;

- Покриття нового самотіка емаллю;

- Монтаж самотіка;

- Герметизація фланцевих з'єднань.

#### Ремонт лотка:

- Вирізка передньої стінки самотіка та бічної стінки лотка;

- Виготовлення передньої та бічних стінок лотка;

- Збірка лотка;

Герметизація ділянки лотка та покриття її емаллю.`
    }};