import firstImage from '../../images/pages/bunker-manufacturing-and-installation/firstImage.jpg';
import {PageTemplates} from "../../pages/standartPages/enum";

export const bunkerManufacturingAndInstallation = {
    url: '/bunker-manufacturing-and-installation',
    title: 'Изготовление и монтаж бункера',
    firstImage,
    type: PageTemplates.OneImageTwoTextsSmallType,
    keywords: 'изготовление бункера,монтаж бункер,стальной бункер',
    firstText: {
        text: `Выполняем монтаж бункера и транспортного оборудования зернохранилищ силосного типа на профессиональном уровне. На все виды работ организация имеет необходимые разрешения и свидетельства.`
    },
    description: 'Монтаж стального бункера для хранения сыпучих материалов. Работы по демонтажу бункеров отходов, шрота, зерновых и сопутствующего оборудования на высоте и любой сложности.',
    secondText: {
        text: `## Изготовим и произведем монтаж стального бункера для хранения сыпучих материалов:
зерна, муки, комбикорма, строительных смесей, различных удобрений, цемента, песка, извести, гравия, щебня, угля, руды, кокса и прочих сыпучих веществ и материалов.

При изготовлении бункеров накопителей осуществляется контроль сварных швов на прочность и герметичность. Бункеры и накопители изготавливаются по чертежам или техническому заданию заказчика.

Так же выполним работы по демонтажу бункеров отходов, шрота, зерновых и сопутствующего оборудования на высоте и любой сложности.`
    }};