import firstImage from '../../images/pages/press-shears/2.jpg';
import secondImage from '../../images/pages/press-shears/1.jpg';
import {PageTemplates} from "../../pages/standartPages/enum";

export const pressShears = {
    url: '/press-shears',
    title: 'Ремонт пресс ножниц',
    firstImage,
    keywords: 'ремонт пресс ножниц,пресс ножницы',
    secondImage,
    type: PageTemplates.TwoImagesTwoTextsBigType,
    manufacturers: {
        text: 'ООО «СИТАМ» имеет большой опыт ремонта пресс ножниц и прессов для пакетирования металлолома самых разных марок и конфигураций. В списке оборудования, с которым мы работаем, числятся агрегаты следующих производителей: Akros, Riko, Metso Lindemann, SIERRA, HuaHong, Enerpat, WANSHIDA, Dongfang и других производителей в т.ч. отечественных',
    },
    underTitle: `ООО «СИТАМ» Выполним ремонт пресс ножниц и другого промышленного оборудования на профессиональном уровне. На все виды работ организация имеет необходимые разрешения и свидетельства.`,
    description: 'Выполним работы по ремонту и техническому обслуживанию пресс ножниц для переработки металлолома. В списке оборудования, с которым мы работаем, числятся агрегаты следующих производителей: Akros, Riko, Metso Lindemann, SIERRA, HuaHong, Enerpat, WANSHIDA, Dongfang и других производителей в т.ч. отечественных',
    firstText: {
        text: `Наша компания выполнит работы по ремонту и техническому обслуживанию пресс ножниц для переработки металлолома. Комплекс работ по ремонту пресс ножниц может включать в себя демонтаж и монтаж узлов, восстановительный ремонт и замену определенных узлов и механизмов.

##### Неисправности пресс ножниц:

- Трещины стыков на станине;

- Задиры и износ рабочих поверхностей;

- Остановка во время резки заготовки (заедание, подклинивания);

- В отверстие не проходит прокат максимальных размеров;

- Самопроизвольное опускание ножевой рамы;

- Самопроизвольное опускание прижима;

- Утечка гидравлического масла;

- Подтекания масла на соединениях;

- Поломка крепежных элементов;

- Повреждения ножей;

- Износ направляющих ножевой балки. Впоследствии это ведет к неровному резу металла;

- Износ кромок ножей, что также влияет на качество и ровность реза металла;

- Насос не выдает максимальное основное давление в системе (нет рабочего давления);

- Люфт в шарнирных соединениях крепления гидроцилндров;

- Задиры на штоке;

- Неисправность датчика температуры масла, давления;

- Мех. повреждения РВД;

Неисправности заставляют работать систему менее эффективно, с меньшей производительностью вызывая отказы в работе которые ведут к выходу агрегата из строя, увеличению брака и затратам в связи с остановкой производственного цикла.

##### Работы по ремонту и устранению дефектов пресс ножниц могут включать:

- Диагностика пресс-ножниц и выявление неполадок оборудования;

##### Гидравлический трубопровод

- Замена уплотнений;

- Замена коллектора;

##### Станина

- Разделка и заварка трещин на станине;

- Демонтаж и монтаж станины;

- Замена стальных наделок на станине;

- Наплавка и механическая обработка окна выдачи лома;

- Наплавка и механическая обработка днища пресс-камеры;

##### Узел реза

- Замена уплотнений цилиндра реза;

- Утяжка пробки цилиндра реза;

- Ремонт поршней с изготовлением (мех. обработкой) и заменой бронзовых втулок;

- Замена направляющих втулок штока с изготовлением (мех. обработкой);

- Шлифовка задиров, наплавка выработки штока;

- Наплавка ножевой рамы;

- Замена направляющих ножевой рамы;

- Наплавка установочных мест (седла) под ножи;

- Замена винтов крепления наделок;

- Замена проставок ножей (верхних, нижних);

- Замена болтов крепления стальных наделок станины;

- Наплавка и мех. обработка направляющих;

- Замена уплотнительных колец стыков трубопроводов;

- Замена футеровочных плит;

- Замена болтов, гаек, шпильки, шайб, шпонок ножей;

- Замена ножей;

- Замена листов скольжения ножевой рамы на станине;

- Замена упоров листов скольжения на станине;

- Замена контрножа;

- Замена винтов крепления листов скольжения;

- Расклинивание ползуна реза или прижима;
`
    },
    secondText: {
        text: `##### Прижим (трамбовка)

- Демонтаж, монтаж цилиндра трамбовки;

- Замена уплотнений цилиндра трамбовки;

- Замена уплотнительных колец стыков трубопроводов;

- Ремонт поршней с изготовлением (мех. обработкой) и заменой бронзовых втулок;

- Замена направляющих втулок штока с изготовлением (мех. обработкой);

- Наплавка кубика трамбовки;

- Наплавка продавленных мест с зачисткой после наплавки;

- Замена изношенных плит шахты прижима (трамбовки);

- Высверливание остатков сломленных винтов и восстановление резьбы в станине;

- Замена винтов крепления плит;

- Замена болтов крепления штока;

##### Прессующий узел

- Замена уплотнений цилиндров крышек;

- Замена цилиндров крышек;

- Ремонт поршней с изготовлением (мех. обработкой) и заменой бронзовых втулок;

- Замена направляющих втулок штока с изготовлением (мех. обработкой);

- Замена бронзовых втулок проушин гидроцилиндров;

- Восстановление геометрических размеров пальцев цилиндров крышек;

- Наплавка и мех. обработка поверхностей крышек;

- Замена фланцев;

- Демонтаж и монтаж створки;

- Наплавка и зачистка створок, бронелиста;

- Монтаж неподвижных рояльных петель;

- Установка бронзовых втулок подвижных рояльных петель;

##### Толкатель

- Ремонт поршней с изготовлением (мех. обработкой) и заменой бронзовых втулок;

- Замена направляющих втулок штока с изготовлением (мех. обработкой);

- Наплавка и шлифовка поврежденных мест штоков;

- Демонтаж и монтаж толкателя;

- Замена уплотнений;

- Замена цилиндра толкателя;

- Наплавка пуансона;

- Замена пальца крепления толкателя;

##### Мульда

- Замена уплотнений;

- Замена бронзовых втулок проушин гидроцилиндра;

- Восстановление геометрии пальцев цилиндра мульды;

- Замена бронзовых втулок для осей мульды;

- Восстановление посадочных мест крепления мульды к прессу с заменой втулки, пальца и стопора;

- Ревизия распределителей, очистка, промывка;

- Замена распределителя ЦСС;

- Замена пресс-масленок;

- Замена датчика температуры масла;

- Разборка, чистка и сборка клапанов;

- Замена гидравлического масла с полной мойкой гидравлического бака;

- Замена или ремонт гидравлического насоса;

- Замена РВД;

- Ремонт бандажей РВД крышек;

- Замена масляного фильтра;

- Замена воздушных фильтров с датчиком засора;

- Замена фильтров гидронасоса;

- Замена, чистка фильтров всасывающей линии;

- Замена фильтрующих элементов в системе охлаждения;

- Очистка радиаторов охлаждения;

- Очистка вентиляционной системы и зоны входа охлаждающего воздуха;

##### Прочее

- Проведение электроизмерений (заземление, изоляция, петля, фаза-ноль);

- Регулировка давления в гидравлической системе;

- Очистка и окраска оборудования;

- Ремонт фундамента;
`
}};


