import firstImage from '../../images/pages/installation-of-technological-lines/firstImage.jpeg';
import secondImage from '../../images/pages/installation-of-technological-lines/secondImage.jpg';
import {PageTemplates} from "../../pages/standartPages/enum";

export const installationOfTechnologicalLines = {
    url: '/installation-of-technological-lines',
    type: PageTemplates.TwoImagesTwoTextsType,
    keywords: 'монтаж технологических линий,монтаж сборочно-сварочных линий,монтаж линии упаковки',
    manufacturers: {
        text: 'ООО «СИТАМ»  имеет большой опыт монтажа производственных линий различных конфигураций: агрегатно-поточные линии, поточные технологические линии(Конвейерные).',
    },
    description: 'Выполним монтаж технологических линий, конвейеров, транспортеров, инженерных и технологических систем. Монтаж сборочно-сварочных линий. Монтаж линий по производству труб. Монтаж линии упаковки',
    title: 'Монтаж технологических линий',
    underTitle: 'ООО «СИТАМ» Выполним монтаж технологических линий, конвейеров, транспортеров, инженерных и технологических систем, и другого технологического оборудования промышленного производства на профессиональном уровне. На все виды работ организация имеет необходимые разрешения и свидетельства.',
    firstImage,
    secondImage,
    firstText: {
        text: `### Установка производственных линий
        
В список проектов выполненных нашей компанией входят:

- Монтаж сборочно-сварочных линий;

- Монтаж линий по производству труб;

- Монтаж линии упаковки;

- Монтаж прессовых линий;

- Монтаж технологической линии покраски;

- Монтаж линии вакуумной плавки;

- Монтаж линии по производству вафельных стаканчиков;

- Монтаж линии обработки прутка;

- Монтаж линии по производству пластмассовых бутылок;

- Монтаж линии производства тканей;

Нам доверяют самое сложное технологическое оборудование. Все работы выполняются качественно и в оговоренные сроки.

монтаж-технологических-линий монтаж-производственных-линий`
    },
    secondText: {
        text: `### Монтаж производственных линий предприятий
        
Выполним монтаж промышленных линий отечественного и импортного производства, новых и б/у.

Мы осуществляем проекты по монтажу производственных линий в различных отраслях промышленности:

- Пищевая;

- Целлюлозно-бумажная;

- Строительная;

- Горно-добывающая;

- Медицинская;

- Металлообрабатывающая;

- Автомобильное производство и т.д;

Услуги по установки производственных и технологических линий могут включать:

- Разметка и подготовка фундамента, проектные работы;

- Монтаж закладных изделий (плит, пластин, балок);

- Перемещение оборудования к месту монтажа;

- Установка оборудования на фундамент (выверка, крепление анкерами, подливка фундамента);

- Поузловая сборка оборудования (сварочные работы);

- Механический монтаж компонентов линии;

Электромонтаж (установка электрических шкафов, прокладка и разводка силовых кабелей,-  монтаж электрооборудования)
Прокладка и подключение оборудования к заводским сетям (сжатый воздух, вода и т.д);

- Монтаж гидравлических и пневматических систем, трубопроводов;

- Проектирование и монтаж технологических металлоконструкций, площадок обслуживания, перекрытий, лестниц и переходов;

- Поставка расходных материалов (кабеля, лотки, трубы, трубопроводная арматура, метизы и т.д.);

- Пусконаладка, настройка и регулировка оборудования;

- Сдача в эксплуатацию.

- Мы используем современную материально-техническую базу и специалистов с многолетним успешным опытом подобных работ и наличием необходимых разрешений и лицензий.
`}};