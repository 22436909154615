import firstImage from '../../images/pages/beet-processing-department/1.jpg';
import {PageTemplates} from "../../pages/standartPages/enum";

export const beetProcessingDepartment = {
    url: '/beet-processing-department',
    title: 'Монтаж оборудования свеклоперерабатывающего отделения',
    firstImage,
    type: PageTemplates.OneImageTwoTextsSmallType,
    description: 'Монтаж оборудования,свеклоперерабатывающее отделение,монтаж диффузионных аппаратов. Ремонт наклонных диффузионных установок. Установка пульполовушек диффузионного сока. Монтаж ошпаривателя стружки',
    keywords: 'монтаж оборудования,свеклоперерабатывающее отделение,монтаж диффузионных аппаратов',
    firstText: {
        text: `Выполним монтаж и замену/демонтаж оборудования свеклоперерабатывающего отделения и другого промышленного оборудования на профессиональном уровне. На все виды работ организация имеет необходимые разрешения и свидетельства.`
    },
    secondText: {
        text: `Наша компания выполнит работы по монтажу и реконструкции (модернизации) свеклоперерабатывающего отделения сахарного завода.

##### Список возможных работ:

- Демонтаж и монтаж диффузионных аппаратов (КДА, ЭКА, ПДС, DDS, DC, Ж4-ПДБ-3, КД 2-А30, А1-ПД2-С20, DC-8, C-17);

- Ремонт наклонных диффузионных установок;

- Установка пульполовушек диффузионного сока и жомопрессовой воды ОВ 63-200;

- Монтаж ошпаривателя стружки Р3-ПОД, ОС-20/30М, ПНА-2;

- Установкой пеноудалителя (БМА);

- Монтаж тракт подачи свеклы;

- Ремонту электрооборудования;
`}};
