import firstImage from '../../images/pages/bending-press/1.jpg';
import secondImage from '../../images/pages/bending-press/2.jpg';
import {PageTemplates} from "../../pages/standartPages/enum";

export const bendingPress = {
    url: '/bending-press',
    title: 'Ремонт гідравлічного листогинного преса',
    description: 'Ремонт та технічне обслуговування гідравлічного листогинного преса (кромкогиба) для згинання листового прокату. Працюємо з агрегатами наступних виробників: Riko, Yangli, Cormak, DURMD, Ermaksan, ЛГ та інших, в т.ч. вітчизняних',
    type: PageTemplates.TwoImagesTwoTextsBigType,
    keywords: 'ремонт преса,обслуговування преса,діагностика преса,ремонт гідравлічного преса',
    manufacturers: {
        text: 'ТОВ «СІТАМ» має великий досвід ремонту та обслуговування гідравлічних кромкогибочних, листогибочних пресів різних марок і конфігурацій. У списку обладнання, з яким ми працюємо, числяться агрегати наступних виробників: Riko, Yangli, Cormak, DURMD, Ermaksan, ЛГ та інших виробників, в т.ч. вітчизняних',
    },
    underTitle: 'ТОВ «СІТАМ» Виконаємо ремонт та обслуговування гідравлічного листогинного преса та іншого промислового пресового обладнання на професійному рівні. На всі види робіт організація має необхідні дозволи та свідоцтва.',
    firstImage,
    secondImage,
    firstText: {
        text: `Наша компанія виконає роботи з ремонту та технічного обслуговування гідравлічного листогинного преса (кромкогиба) для згинання листового прокату. Комплекс робіт з ремонту листогина може включати в себе демонтаж і монтаж вузлів, відновлювальний ремонт та заміну певних вузлів і механізмів.

- #### Несправності листогинних пресів:

- Нерівномірний кут згину металу;

- Незвичайний шум під час роботи гідронасоса;

- Витоки масла на з'єднаннях та циліндрі;

- Розриви зварних швів;

- Деформація конструкції верстата;

- Відсутність тиску в системі.

Несправності змушують працювати систему менш ефективно, з меншою продуктивністю, що призводить до відмов у роботі, збільшення браку та витрат через зупинку виробничого циклу.
`
    },
    secondText: {
        text: `#### Поточний та капітальний ремонт гідравлічного листогинного преса може включати:

- Діагностика та виявлення несправностей обладнання;

- Відновлення столу, заднього упору, корпусу;

- Заміна болтів кріплення;

- Заміна напрямних;

- Заміна, ремонт циліндрів;

- Заміна ущільнень циліндра;

- Ремонт штоків (наплавка і шліфування пошкоджених місць);

- Заміна шлангів гідравлічної системи;

- Ремонт, заміна насоса;

- Заміна фільтрів;

- Ремонт системи змащення;

- Чистка фільтруючих елементів;

- Доливка масла до необхідного рівня;

- Заміна електрообладнання;

- Ремонт пульта керування.
`}};



