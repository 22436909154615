import firstImage from '../../images/pages/silo-installation/1.jpg';
import secondImage from '../../images/pages/silo-installation/2.jpg';
import {PageTemplates} from "../../pages/standartPages/enum";

export const siloInstallation = {
    url: '/silo-installation',
    title: 'Встановлення силосу',
    firstImage,
    secondImage,
    keywords: 'встановлення силосу,встановлення силосного бункера',
    type: PageTemplates.TwoImagesTwoTextsType,
    underTitle: `ТОВ "СІТАМ" Виконуємо встановлення силосу та транспортного обладнання зернозберігаючого силосного типу на професійному рівні. На всі види робіт організація має необхідні дозволи та свідоцтва.
    Виконаємо встановлення силосів для зберігання зерна з пласким і конусним дном. Зазвичай такі силоси використовують заводи маслорослинної та комбікормової промисловості, підприємства, що займаються обробкою сільськогосподарських культур, млини.`,
    description: 'Встановлення силосу та транспортного обладнання зернозберігаючого силосного типу. Встановлення силосного бункера. Встановлення зачисного шнекового транспортера. Встановлення ребер жорсткості вздовж стінок силосу',
    firstText: {
        text: `Вимоги до встановлення в кожному випадку індивідуальні через особливості використання. У даному випадку особлива увага приділяється якісному виконанню зварювальних робіт і болтових з'єднань, а також необхідності залучення спецтехніки для робіт з важкими конструкціями на висоті.

Виконуємо роботи в закритих приміщеннях, демонтаж зі збереженням конструкції для подальшого транспортування і встановлення на новому місці.

Монтажники, альпіністи та різці з атестовані для висотних робіт та з досвідом від 5 років.`
    },
    secondText: {
        text: `## Встановлення силосу може включати:

- Підготовка майданчика;

- Виготовлення та заливка фундаменту;

- Встановлення металевої конструкції, збірка секцій зварюванням, болтами;

- Монтаж ребер жорсткості вздовж стінок силосу;

- Встановлення силосного бункера;

- Монтаж вентиляційної системи;

- Монтаж зачисного шнекового транспортера;

- Монтаж системи термометрії;

- Встановлення системи аерації (вентилятори, дихальні клапани);

- Монтаж допоміжного обладнання (сходи, поручні, проміжні платформи, датчики заповнення, вібратори);

- Устрійство вузла з'єднання силосу з фундаментом;

- Електричне обв'язку;

- Гідроізоляція матеріалів, грунтування та фарбування.`
    }
};
