import firstImage from '../../images/pages/juice-department/1.jpg';
import {PageTemplates} from "../../pages/standartPages/enum";

export const juiceDepartment = {
    url: '/juice-department',
    title: 'Монтаж оборудования станции дефекосатурации',
    firstImage,
    keywords: 'монтаж оборудования станции,монтаж станции дефекосатурации',
    type: PageTemplates.OneImageTwoTextsSmallType,
    firstText: {
        text: `Выполним монтаж и замену/демонтаж оборудования станции дефекосатурации и другого промышленного оборудования на профессиональном уровне. На все виды работ организация имеет необходимые разрешения и свидетельства.`
    },
    description: 'Выполним работы по монтажу оборудования и реконструкции станции дефекосатурации сахарного завода. онтаж аппарата прогрессивной преддефекации ТМА-ППД. Монтаж статического, динамического смесителя ТМА-ПСК',
    secondText: {
        text: `Наша компания выполнит работы по монтажу оборудования и реконструкции станции дефекосатурации сахарного завода.

##### В список работу могут входить:

- Монтаж аппарата прогрессивной преддефекации ТМА-ППД;

- Монтаж статического, динамического смесителя ТМА-ПСК;

- Монтаж аппарата горячей дефекации;

- Монтаж сборника нефильтрованного сока;

- Монтаж cатураторов ТS 1 и 2 сатурации;

- Монтаж газораспределительной системы для сатуратора (‘Трубки Рихтера’);

- Монтаж аппарата для проведения 1-й ступени основной дефекации (холодный дефекатор)ТМА-ПДХ;

- Монтаж аппарата для проведения 2-й ступени основной дефекации (горячий дефекатор)ТМА-ОД;

- Монтаж котлов 1 и 2-й сатурации;

- Монтаж угольных котлов (OR-32);

- Монтаж и заменена насосных агрегатов;
`}};



