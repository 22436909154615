import firstImage from '../../images/pages/installation-of-lifting-equipment/1.jpg';
import secondImage from '../../images/pages/installation-of-lifting-equipment/2.jpg';
import {PageTemplates} from "../../pages/standartPages/enum";

export const installationOfLiftingEquipment = {
    url: '/installation-of-lifting-equipment',
    title: 'Монтаж козлового крана',
    type: PageTemplates.TwoImagesTwoTextsBigType,
    keywords: 'монтаж козлового крана,демонтаж крана,демонтаж козлового крана,монтаж двухбалочного крана',
    manufacturers: {
        text: 'ООО «СИТАМ» має великий досвід монтажу козлових кранів різних марок та конфігурацій. У списку обладнання, з яким ми працюємо, знаходяться агрегати наступних виробників: КК, ККС, ККТ, КДКК та інших виробників, включаючи вітчизняних.',
    },
    description: 'Виконаємо монтаж та демонтаж козлового крана однобалочного, двобалочного, ферменного. Монтаж козлових кранів: комплексний (встановлення підкранових шляхів та збірка технічного засобу для підйому/переміщення вантажів), частковий (без встановлення підкранових шляхів).',
    underTitle: 'ООО «СИТАМ» Виконаємо монтаж козлового електричного крана та іншого підйомного промислового обладнання на професійному рівні. На всі види робіт організація має необхідні дозволи та свідоцтва.',
    firstImage,
    secondImage,
    firstText: {
        text: `Наша компанія виконає монтаж та демонтаж козлового крана однобалочного, двобалочного, ферменного.

Види монтажу козлових кранів: Комплексний (встановлення підкранових шляхів та збірка технічного засобу для підйому/переміщення вантажів). Частковий (без встановлення підкранових шляхів).

Ціна монтажу козлового електричного крана залежить від його вантажопідйомності, довжини пролету, висоти підйому, типу (однобалочний, двобалочний, ферменний), виконання (загальнопромисловий, магнітний, грейферний) та місця проведення робіт.`
    },
    secondText: {
        text: `#### Монтаж козлового крана включає:

- Монтаж пролітної балки крана, опор та хідових тележок;

- Підйом крана на підкранових шляхах;

- Монтаж вантажної тележки (тельфера);

- Монтаж вантажного барабана;

- Монтаж електродвигунів підйому та переміщення;

- Монтаж редукторів;

- Монтаж гальм;

- Монтаж кабіни кранівника;

- Електромонтаж кранової кабіни та вантажної тележки;

- Монтаж токопідводу;

- Монтаж вузлів електропроводів;

- Монтаж електрообладнання (кранових панелей, блоків резисторів);

- Монтаж системи управління;

- Пусконалагоджувальні роботи.`}};




