import secondImage from '../../images/pages/crane-painting/1.jpg';
import firstImage from '../../images/pages/crane-painting/2.png';
import {PageTemplates} from "../../pages/standartPages/enum";

export const cranePainting = {
    url: '/crane-painting',
    title: 'Фарбування вантажних кранів',
    firstImage,
    secondImage,
    keywords: 'фарбування вантажних кранів,фарбування кранів, фарбування мостового крана',
    manufacturers: {
        text: 'ТОВ «СІТАМ» має великий досвід фарбування козлових кранів найрізноманітніших марок і конфігурацій. У списку обладнання, з яким ми працюємо, числяться агрегати наступних виробників: КК, ККС, ККТ, КДКК та інших виробників в т.ч. вітчизняних.',
    },
    description: 'Фарбування (антикорозійна обробка) мостового, козлового крана, кран-балки, портального крана та іншої вантажної техніки на професійному рівні. Видалення забруднень, обробка ділянок, пошкоджених корозією. Обладнання, з яким ми працюємо: КК, ККС, ККТ, КДКК та інших виробників в т.ч. вітчизняних.',
    underTitle: `ТОВ «СІТАМ» Виконає фарбування крана та іншої вантажної промислової техніки на професійному рівні. На всі види робіт організація має необхідні дозволи та свідоцтва.`,
    type: PageTemplates.TwoImagesTwoTextsType,
    secondText: {
        text: `Наша компанія виконає фарбування (антикорозійну обробку) мостового, козлового крана, кран-балки, портального крана та іншої вантажної техніки.

Фарбування крана є обов'язковим етапом обслуговування вантажного обладнання. Фарбування кранів необхідно проводити хоча б один раз на рік, щоб: забезпечити захист металоконструкцій від корозії; надати крану привабливий зовнішній вигляд.

#### Етапи фарбування вантажного крана:

- Очищення механізмів, вузлів і елементів обладнання від старої фарби;

- Видалення забруднень, обробка ділянок, пошкоджених корозією;

- Нанесення антикорозійного матеріалу;

- Висушування, очищення від пилу, знежирення поверхні кранів;

- Нанесення грунтівки;

- Фарбування крана;

- Обробка тонуючим складом.`
        },
    }
;