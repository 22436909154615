import firstImage from '../../images/pages/cooling-tower-repair/1.jpg';
import secondImage from '../../images/pages/cooling-tower-repair/2.jpg';
import {PageTemplates} from "../../pages/standartPages/enum";

export const coolingTowerRepair = {
    url: '/cooling-tower-repair',
    type: PageTemplates.TwoImagesTwoTextsType,
    keywords: 'ремонт градірні,ремонт вентиляторної градірні,модернізація градірні',
    manufacturers: {
        text: 'ТОВ «СІТАМ» має великий досвід ремонту градірень найрізноманітніших марок і конфігурацій. У списку обладнання, з яким ми працюємо, числяться агрегати наступних виробників: Evapco, Mita, YWCT та інших виробників в т.ч. вітчизняних.',
    },
    title: 'Ремонт та обслуговування вентиляторної градірні',
    firstImage,
    secondImage,
    description: 'Ремонт градірень найрізноманітніших марок і конфігурацій. Модернізація розподільних трубопроводів. Заміна сталевих труб. Відновлення антикорозійних покриттів на металевих конструкціях градірні',
    firstText: {
        text: `##### Також ремонт та модернізація градірні може включати:
- Виготовлення та монтаж розтяжок дифузора;

- Виготовлення та монтаж двотаврових балок;

- Заміна сталевих труб;

- Модернізація розподільних трубопроводів;

- Встановлення технологічних площадок;

- Встановлення обмежувачів зворотного ходу вентилятора;

- Ремонт та виготовлення металевих конструкцій;

- Відновлення антикорозійних покриттів на металевих конструкціях градірні;

- Встановлення запірної арматури;

- Випробування на герметичність.
`
    },
    secondText: {
        text: `Наша компанія надає послуги з технічного обслуговування та поточного/капітального ремонту випарникових вологих градірень відкритого та закритого типу та об'єктів водовідтокових циклів.

Даний вид обладнання в основному використовується на теплових електростанціях (ТЕС), маслоекстракційних заводах, фармацевтичних а також в металургійній, нафтопереробній, хімічній, коксохімічній та харчовій промисловості.

### Серед поширених несправностей зустрічаються:

- Не вмикається вентилятор або застрягання;

- З градірні відноситься велика кількість води;

- При роботі градірні підвищений рівень шуму;

- Руйнування обшивки;

- Руйнування (корозія) опорних конструкцій;

- Порушення герметичності теплообмінників;

- Засмічення теплообмінних поверхонь;

- Деформація оросителів (немає ефекту охолодження);

- Поломка насосного обладнання, протікання ущільнень насоса.

##### Ремонт та модернізація градірні може включати:

- Діагностика градірні;

- Ремонт насосного обладнання;

- Ремонт та заміна обшивки градірні;

- Заміна та очищення блоків оросителя;

- Заміна та очищення блоків краплеуловлювачів;

- Демонтаж та встановлення елементів кріплення;

- Ремонт та заміна лопатей вентилятора;

- Розшивка захисного кожуха барабана;

`}};


