import firstImage from '../../images/pages/painting-corrosion-protection-of-the-tank/1.jpg';
import secondImage from '../../images/pages/painting-corrosion-protection-of-the-tank/2.jpg';
import {PageTemplates} from "../../pages/standartPages/enum";

export const paintingCorrosionProtectionOfTheTank = {
    url: '/painting-corrosion-protection-of-the-tank',
    title: 'Покраска - антикоррозионная защита резервуаров',
    keywords: 'ремонт резервуаров,зачистка резервуаров,покраска резервуаров,антикоррозионная защита резервуаров',
    type: PageTemplates.TwoImagesTwoTextsType,
    manufacturers: {
        text: 'ООО «СИТАМ»  имеет большой опыт зачистки резервуаров самых разных марок и конфигураций. В списке оборудования, с которым мы работаем, числятся агрегаты следующих производителей: РГС и других производителей в т.ч. отечественных.',
    },
    underTitle: 'ООО «СИТАМ»  Выполним антикоррозионную защиту резервуара и другого промышленного оборудования на профессиональном уровне. На все виды работ организация имеет необходимые разрешения и свидетельства.',
    firstImage,
    secondImage,
    description: 'Выполним антикоррозионную защиту резервуара. Нанесение лакокрасочных покрытий. Установка катодной протекторной защиты. Комбинированное покрытие (лакокрасочное и металлизированное). Металлизация',
    firstText: {
        text: `Покраска резервуаров осуществляется посредством распыления, когда лакокрасочный материал измельчается механическими устройствами до состояния аэрозоля и равномерно распределяется по поверхности емкости.

Очистка и покраска проводятся внутри и снаружи (лестницы, ограждающие конструкции и т.д). Для проведения высотных работ используется соответствующее оборудование (снаряжение, подъемники, и пр).`
    },
    secondText: {
        text: `Наша компания выполнит покраску (антикоррозионную защиту) резервуаров и других промышленных емкостей различного объема и назначения (ГСМ, нефтепродукт, меласса, растительные масла, минеральные удобрения, химические соединения и т.д) посредством нанесения полимерно-композитного покрытия.

##### Для антикоррозионной защиты стальных резервуаров могут использоваться следующие методы:

- Нанесение лакокрасочных покрытий;

- Металлизация;

- Установка катодной протекторной защиты;

- Комбинированное покрытие (лакокрасочное и металлизированное).

Материалы антикоррозионной защиты наносятся на предварительно подготовленную поверхность.

Подготовка поверхности перед нанесением лакокрасочных материалов (ЛКМ) заключается в очистке металла от окалины и ржавчины, а также удалении остаточных загрязнений (жировые, масляные пятна, пыль).`}};

