import firstImage from '../../images/pages/boiler-room-installation-repair/1.jpeg';
import secondImage from '../../images/pages/boiler-room-installation-repair/2.jpg';
import {PageTemplates} from "../../pages/standartPages/enum";

export const boilerEoomInstallationRepair = {
    url: '/boiler-room-installation-repair',
    title: 'Монтаж та ремонт котельні',
    type: PageTemplates.TwoImagesTwoTextsType,
    keywords: 'ремонт котельні,монтаж котельні',
    manufacturers: {
        text: 'ТОВ «СІТАМ» має великий досвід роботи з монтажу різних типів промислових котельних: газових, дизельних, електричних або твердопаливних, різних марок і конфігурацій (PROTHERM, VAILLANT, BUDERUS, BOSCH, BAXI, KENTATSU FURST, VIESSMANN, NAVIEN, STROPUVA, LOOS, ICI, FERROLI, OILON, WEISHAUPT, SAACKE, BROEN, NAVAL, ADL, NEMEN, VEXVE, GRUNDFOS, WILO, KSB, KRAL, ALLWEILER, SEPARTEC, JURBY WATER TECH, HENGINEERING, НВР, BWT, SWEP, ALFA-LAVAL POTOK, ETSS, UNICAL, CIB UNIGAS, ELCO та ін.)',
    },
    underTitle: 'ТОВ «СІТАМ» Виконаємо монтаж технологічних ліній, конвеєрів, транспортерів, інженерних і технологічних систем, та іншого технологічного обладнання промислового виробництва на професійному рівні. На всі види робіт організація має необхідні дозволи та свідоцтва.',
    firstImage,
    secondImage,
    description: 'Монтаж різних типів промислових котельних: газових, дизельних, електричних або твердопаливних, різних марок і конфігурацій (PROTHERM, VAILLANT, BUDERUS, BOSCH, BAXI, KENTATSU FURST, VIESSMANN, NAVIEN, STROPUVA, LOOS, ICI, FERROLI, OILON, WEISHAUPT, SAACKE, BROEN, NAVAL, ADL, NEMEN, VEXVE, GRUNDFOS, WILO, KSB, KRAL, ALLWEILER, SEPARTEC, JURBY WATER TECH, HENGINEERING, НВР, BWT, SWEP, ALFA-LAVAL POTOK, ETSS, UNICAL, CIB UNIGAS, ELCO та ін.)',
    firstText: {
        text: `### Монтаж модульних котельних та стаціонарних
Монтаж є важливим етапом будівництва котельні. Від його якості залежить робота котельні та успішність пусконалагоджувальних робіт котельного обладнання.

Виконаємо монтаж будь-яких видів котельних: парових, водяних, комбінованих. На різних промислових об'єктах (заводи, виробничі приміщення, склади, торгові центри, об'єкти комунального господарства тощо)

За способом монтажу котельні діляться на:

Стаціонарного типу:

Окремостоячі – розташовані в окремо розташованій будівлі;

Прибудовані – з безпосереднім приляганням до об'єкту;

Вбудовані – розміщуються в середині приміщень;

На даху – монтування на спеціально виготовлених майданчиках (основах) або даху будівель.
Блочно-модульні – повністю зібрані агрегати, постачаються в мобільному контейнері;
`
    },
    secondText: {
        text: `##### Встановлення промислової котельні може включати:

Підготовка приміщення під установку котельної системи;

Заливка фундаменту;

Монтаж конструкції та прокладання інженерних мереж;

Встановлення та прокладка до котла димоходу;

Монтаж трубопроводів опалення;

Монтаж промислових котлів (газових, водогрійних та парових);

Монтаж промислових горілок;

Встановлення насосів;

Монтаж теплообмінників;

Встановлення регуляторів газового тиску, газових фільтрів, запобіжної та запірної арматури;

Монтаж внутрішніх трубопроводів;

Монтаж електрообладнання, систем автоматизації та диспетчеризації;

Пусконалагоджувальні роботи та здача в експлуатацію.
Допоміжне обладнання:

Монтаж деаераторів (хімічні, вакуумні, термічні, атмосферного тиску);

Монтаж баків-акумуляторів;

Встановлення бойлерів, водонагрівачів та інше.
Монтаж повинен здійснюватися досвідченими спеціалістами, які мають атестації, допуски та ліцензії для роботи з промисловим обладнанням.
Після монтажу та пуску в експлуатацію, ми готові взяти на себе подальше обслуговування промислової котельні – сервіс, технічний огляд та ремонт.
`}};
