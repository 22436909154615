export interface IFaqData {
    buttonText: string;
    text: string;
}

export const faqData: IFaqData[] = [{
    buttonText: 'Гарантійні зобов\'язання',
    text: 'Ми надаємо гарантію на виконані роботи та вироби строком від 3 міс. до 5 років. Протягом цього часу ремонт та діагностика здійснюються безкоштовно, однак при умові виконання всіх правил експлуатації, які вказані в технічній документації. По закінченню цього часу всі роботи виконуються в індивідуальному порядку.'
},{
    buttonText: 'Терміни виконання',
    text: 'Точні терміни виконання робіт визначаються замовником або постачальником після ознайомлення з проектом або діагностики обладнання.'
},{
    buttonText: 'Матеріали та техніка',
    text: 'Спец. техніка, матеріали та механізми можуть бути надані повністю або частково як замовником, так і постачальником.'
},{
    buttonText: 'Умови виконання робіт',
    text: 'Роботи виконуються після діагностики або огляду об\'єкта на місці, а також після підписання договору, отримання передоплати та надання / покупки необхідних матеріалів. Роботи можуть бути проведені в умовах діючого виробництва з наданням технологічної карти та врахуванням всіх нормативних вимог ОТ і ДСТУ.'
},{
    buttonText: 'Умови оплати',
    text: 'Працюємо за передоплатою від 30%, або за індивідуально узгодженими умовами.'
},{
    buttonText: 'Ліцензії та дозволи',
    text: 'Ми надаємо всі необхідні документи, вимагані законодавством та замовником, включаючи кваліфікаційні атестати осіб, що виконують роботи, та джерела походження і якості матеріалів, обладнання та пристроїв.'
}
];
