import firstImage from '../../images/pages/disassembly-installation-of-miscella-distiller/firstImage.jpg';
import {PageTemplates} from "../../pages/standartPages/enum";

export const disassemblyInstallationOfMiscellaDistiller = {
    url: '/disassembly-installation-of-miscella-distiller',
    title: 'Монтаж / демонтаж дистилятора мисцеллы',
    firstImage,
    type: PageTemplates.OneImageTwoTextsSmallType,
    keywords: 'монтаж дистилятора мисцеллы,монтаж дистилятора,замена дистиллятора,замена дистиллятора мисцелла,кронштейн,демонтаж трубопровода',
    description: 'Ремонта дистилляторов самых разных марок и конфигураций. Оборудование, с которым мы работаем: Alfa Laval, Bernardini, Andreotti, Westfalia и других производителей в т.ч. отечественных.',
    manufacturers: {
        text: 'Компания ООО «СИТАМ» имеет большой опыт ремонта дистилляторов самых разных марок и конфигураций. В списке оборудования, с которым мы работаем, числятся агрегаты следующих производителей: Alfa Laval, Bernardini, Andreotti, Westfalia и других производителей в т.ч. отечественных.'
    },
    firstText: {
        text: `ООО «СИТАМ» Устанавливаем дистилляторы и другое промышленное оборудование на профессиональном уровне. На все виды работ организация имеет необходимые разрешения и свидетельства.
        
Выполним монтаж, демонтаж и пусконаладочные работы по замене дистиллятора 1-й степени на участке масло-экстракционного цеха и других видов промышленного производства.`
    },
    secondText: {
        text: `Демонтаж:

Установка монтажных кронштейнов;

Разъединение фланцевого соединения и демонтаж трубопровода паров гексана конденсатора дистилляции;

Демонтаж трубопровода;

Разъединение фланцевого соединения подачи паров гексана с тостера;

Отсоединение верхней емкости(сепаратора) от кожухотрубного теплообменника;

Разборка фланцевого соединения верхней емкости(сепаратора) и кожухотрубного теплообменника и демонтаж сепаратора;

Демонтаж нержавеющей изоляции дистиллятора 1-й ступени и кожухотрубного теплообменника;

Демонтаж изоляции из минеральной ваты;

Демонтаж шины закрепляющего пояса изоляции;

Демонтаж нижней крышки теплообменника;

Отсоединение трубопровода схода конденсата;

Отсоединение трубопровода перехода паров гексана на вторую ступень дистиллятора;

Отсоединение трубопровода схода мисцеллы;

Установка двутавровой балки и грузоподъемного устройства;

Отсоединение опорных лап оборудования от несущих балок и его демонтаж;

Транспортировка оборудования.
Монтаж:

Монтаж производится в обратной последовательности;

Изготовление новой части корпуса;

Установка новой обшивки дистиллятора и проверка сварных швов;

Изготовление и установка новых прокладок на фланцевых соединениях;

Установка/восстановление изоляции на оборудовании;

Проверка дистиллятора на герметичность и гидравлическое испытание;

Проверка и настройка всех монтажных элементов.`
    }
};
