import firstImage from '../../images/pages/silo-installation/1.jpg';
import secondImage from '../../images/pages/silo-installation/2.jpg';
import {PageTemplates} from "../../pages/standartPages/enum";

export const siloInstallation = {
    url: '/silo-installation',
    title: 'Монтаж силоса',
    firstImage,
    secondImage,
    keywords: 'монтаж силоса,монтаж силосного бункера',
    type: PageTemplates.TwoImagesTwoTextsType,
    underTitle: `ООО «СИТАМ» Выполняем монтаж силоса и транспортного оборудования зернохранилища силосного типа на профессиональном уровне. На все виды работ организация имеет необходимые разрешения и свидетельства
    Выполним монтаж силосов для хранения зерна с плоским и конусным дном. Обычно такие силосы используют заводы масложировой и комбикормовой промышленности, предприятия занимающиеся обработкой сельскохозяйственных культур, мельницы.`,
    description: 'Монтаж силоса и транспортного оборудования зернохранилища силосного типа. Монтаж силосного бункера. Монтаж зачистного шнекового транспортера. Монтаж ребер жесткости вдоль стенок силоса',
    firstText: {
        text: `Требования к монтажу в каждом случае индивидуальны в связи с особенностями использования. В данном случае особое внимание уделяется качественному выполнению сварочных работ и болтовых соединений, а также необходимости привлечения спецтехники для работ с тяжелыми конструкциями на высоте.

Выполняем работы в закрытых помещениях, демонтаж с сохранением конструкции для последующей транспортировки и монтажа на новом месте.

Монтажники, альпинисты и резчики с аттестованы для высотных работ и с опытом от 5 лет`
    },
    secondText: {
        text: `## Установка силоса может включать:

- Подготовка площадки;

- Изготовление и заливка фундамента;

- Установка металлоконструкции, сборка секций сваркой, болтами;

- Монтаж ребер жесткости вдоль стенок силоса;

- Монтаж силосного бункера;

- Монтаж вентиляционной системы;

- Монтаж зачистного шнекового транспортера;

- Монтаж системы термометрии;

- Установка системы аэрации(вентиляторы, дыхательные клапаны);

- Монтаж вспомогательного оборудования (лестницы, перила, промежуточные платформы, датчики заполнения, вибраторы);

- Устройство узла соединения силоса с фундаментом;

- Электрическая обвязка;

- Гидроизоляции материалов, грунтовка и покраска.
`
}};