import secondImage from '../../images/pages/mold-repair/2.jpg';
import firstImage from '../../images/pages/mold-repair/1.png';
import {PageTemplates} from "../../pages/standartPages/enum";

export const moldRepair = {
    url: '/mold-repair',
    title: 'Ремонт кристаллизатора',
    firstImage,
    secondImage,
    keywords: 'ремонт кристаллизатора,монтаж кристаллизатора,демонтаж кристаллизатора',
    manufacturers: {
        text: 'ООО «СИТАМ» имеет большой опыт ремонта кристаллизаторов самых разных марок и конфигураций. В списке оборудования, с которым мы работаем, числятся агрегаты следующих производителей: Alfa Laval, Bernardini, Andreotti, Westfalia и других производителей в т.ч. отечественных.',
    },
    underTitle: `ООО «СИТАМ» Ремонтируем кристаллизаторы и другое промышленное оборудование на профессиональном уровне. На все виды работ организация имеет необходимые разрешения и свидетельства.`,
    type: PageTemplates.TwoImagesOneTextBigType,
    description: 'Ремонт кристаллизаторов маслоэкстракционных заводов и других производственных лини, линии винтеризации (вымораживания). В списке оборудования, с которым мы работаем, числятся агрегаты следующих производителей: Alfa Laval, Bernardini, Andreotti, Westfalia и других производителей в т.ч. отечественных.',
    text: `Выполним ремонт кристаллизаторов маслоэкстракционных заводов и других производственных лини, например: линии винтеризации (вымораживания).

Ремонт кристаллизатора может включать:

- Натяжение горизонтальной анкерной линии;

- Закрепление, снятие расчалок [оттяжек] на высоте;

- Демонтаж мотор-редуктора с лобовиной;

- Демонтаж/монтаж металлических перекрытий и крышек для проемов (крышек кристализаторов);

- Фиксация центрального вала;

- Очистка поверхности змеевиков;

- Демонтаж змеевиков;

- Монтаж креплений под змеевики (швеллер);

- Монтаж (сварка) змеевиков;

- Демонтаж и монтаж днища;

- Демонтаж и монтаж труб теплообменника;

- Постановка высокопрочных болтов;

- Расфиксация вала;

- Монтаж мотор-редуктора;

- Очистка внутренней поверхности аппарата;

- Футеровка внутренней поверхности апарата нержавеющим листом;

- Гидравлическое испытание трубопроводов, систем отопления, водопровода и горячего водоснабжения;
`,
};


