import firstImage from '../../images/pages/installation-of-oil-hydration-and-neutralization-line/1.jpg';
import {PageTemplates} from "../../pages/standartPages/enum";

export const installationOfOilHydrationAndNeutralizationLine = {
        url: '/installation-of-oil-hydration-and-neutralization-line',
        title: 'Монтаж лінії гідратації та нейтралізації масла',
        firstImage,
        keywords: 'монтаж лінії гідратації,монтаж лінії нейтралізації масла,гідратація масла',
        manufacturers: {
            text: 'ТОВ «СІТАМ» має великий досвід монтажу та демонтажу конвеєрів (транспортерів) найрізноманітніших марок та конфігурацій. У списку обладнання, з яким ми працюємо, знаходяться агрегати наступних виробників: ТСЦм, TЦС, КСЦ, КЦ-С, ТрЦС, К4-УТФ, КС, ТКНЦ та інших виробників, включаючи вітчизняних',
        },
        type: PageTemplates.OneImageTwoTextsSmallType,
        firstText: {
            text: `ТОВ «СІТАМ» Виконаємо монтаж та заміну/демонтаж конвеєрів (транспортерів) та іншого промислового обладнання на професійному рівні. На всі види робіт організація має необхідні дозволи та свідоцтва.`
        },
        description: 'Виконаємо монтаж та заміну/демонтаж конвеєрів (транспортерів). Монтаж лінії гідратації та нейтралізації. У списку обладнання, з яким ми працюємо, знаходяться агрегати наступних виробників: ТСЦм, TЦС, КСЦ, КЦ-С, ТрЦС, К4-УТФ, КС, ТКНЦ та інших виробників, включаючи вітчизняних',
        secondText: {
            text: `#### Монтаж лінії гідратації та нейтралізації:

- Монтаж ємностей (резервуарів);
- Монтаж теплообмінників;
- Монтаж насосів;
- Монтаж міксера;
- Монтаж сепаратора;
- Монтаж сушильного агрегату;
- Монтаж пасток;`
        }
    };

