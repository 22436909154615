import secondImage from '../../images/pages/repair-of-a-baler-for-scrap-metal/1.jpg';
import firstImage from '../../images/pages/repair-of-a-baler-for-scrap-metal/2.jpg';
import {PageTemplates} from "../../pages/standartPages/enum";

export const repairOfABalerForScrapMetal = {
    url: '/repair-of-a-baler-for-scrap-metal',
    type: PageTemplates.TwoImagesTwoTextsType,
    manufacturers: {
        text: 'ООО «СИТАМ» имеет большой опыт ремонта и обслуживания пакетировочных прессов для металлолома самых разных марок и конфигураций. В списке оборудования, с которым мы работаем, числятся агрегаты следующих производителей: Riko, Y83, Y81, ПГПМ-100, Huahong, IMABE, ENERPAT SMB, Becker, Bramidan и других производителей в т.ч. отечественных',
    },
    keywords: 'ремонт пакетировочного пресса,ремонт пресса,пакетировочный пресса',
    title: 'Ремонт пакетировочного пресса для металлолома',
    underTitle: 'ООО «СИТАМ» Выполним ремонт и обслуживание гидравлического пакетировочного пресса для металлолома и другого промышленного прессового оборудования на профессиональном уровне. На все виды работ организация имеет необходимые разрешения и свидетельства',
    firstImage,
    secondImage,
    description: 'Выполним ремонт и обслуживание гидравлического пакетировочного пресса для металлолома. Замена/ремонт маслостанции. Замена распределительного клапана. Замена гидравлического шланга (РВД)',
    firstText: {
        text: `#### Гидравлическая часть

- Ревизия гидравлических систем и систем смазки оборудования;

- Замена/ремонт маслостанции;

- Замена распределительных блоков;

- Замена распределительного клапана;

- Замена, ремонт насоса;

- Замена гидравлического шланга (РВД);

- Замена датчиков давления;

- Замена всех РТИ пресса;

#### Электрическая часть

- Замена электродвигателя;

- Замена концевых выключателей;

- Замена предохранителя (аварийного выключателя);

- Замена ламп и кнопок;

- Замена панели управления;

- Пусконаладка пресса.
`
    },
    secondText: {
        text: `Наша компания выполнит работы по обслуживанию и ремонту гидравлического пресса для металлолома применяющихся для пакетирования лома черных и цветных металлов, металлической стружки, автомобильного лома на металлургических предприятиях, ломо-перерабатывающих заводах, сталеплавильной промышленности и др. Комплекс работ по ремонту пакетировочных прессов для металлолома может включать в себя демонтаж и монтаж узлов, восстановительный ремонт и замену определенных узлов и механизмов.

- #### Неисправности пакетировочного пресса:

- Плита не движется;

- Течь гидравлического масла;

- Повышенный шум при работе гидростанции;

- Отсутствие автоматической смазки;

- Подтекания на соединениях;

- Нет давления в системе.

Неисправности заставляют работать систему менее эффективно, с меньшей производительностью вызывая отказы в работе которые ведут к выходу оборудования из строя, увеличению брака и затратам в связи с остановкой производственного цикла.

Текущий и капитальный ремонт пакетировочного пресса для металлолома может включать:

Диагностика и составление дефектной ведомости

#### Механическая часть

- Замена бронированных плит;

- Разделка и заварка трещин на корпусе;

- Замена/ремонт пресс-плиты;

- Замена болтов крепления;

- Ремонт, замена гидроцилиндров;

- Замена уплотнений;

- Замена, ремонт направляющих;

- Замена бронзовых втулок;

`}};


