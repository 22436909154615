import firstImage from '../../images/pages/norii-repair/1.png';
import secondImage from '../../images/pages/norii-repair/2.png';
import thirdImage from '../../images/pages/norii-repair/3.png';
import {PageTemplates} from "../../pages/standartPages/enum";

export const noriiRepair = {
    url: '/norii-repair',
    title: 'Ремонт и техническое обслуживание нории',
    firstImage,
    secondImage,
    thirdImage,
    keywords: 'ремонт нории,техническое обслуживание нории,демонтаж нории,установка нории',
    manufacturers: {
        text: 'ТОВ «СІТАМ» має великий досвід монтажу норій найрізноманітніших марок і конфігурацій. У списку обладнання, з яким ми працюємо, знаходяться агрегати наступних виробників: Fratelli Pedrotti, Agrex, Mecmar, Metalmont, Frame, Bonfanti, Ravaro, Scolari, Strahl, Petcus, Araj, Chelik, Mepu, Neuero, Riela, Ozsu, ДСП та інших, включаючи вітчизняних.',
    },
    underTitle: 'ТОВ «СІТАМ» Встановимо норії та інше промислове обладнання на професійному рівні. На всі види робіт організація має необхідні дозволи та свідоцтва.',
    type: PageTemplates.ThreeTwoImagesTwoTextsType,
    description: 'Монтаж норій найрізноманітніших марок і конфігурацій. У списку обладнання, з яким ми працюємо, знаходяться агрегати наступних виробників: Fratelli Pedrotti, Agrex, Mecmar, Metalmont, Frame, Bonfanti, Ravaro, Scolari, Strahl, Petcus, Araj, Chelik, Mepu, Neuero, Riela, Ozsu, ДСП та інших, включаючи вітчизняних.',
    firstText: {
        text: `Неперервна робота при великих навантаженнях призводить до значного зносу, зниження працездатності та збоїв у роботі обладнання норії.

##### В процесі експлуатації можуть виявлятися наступні несправності норій:

- Шум, іскри, зниження продуктивності;

- Проскальзування або вибух приводного ременя;

- Зупинка стрічки;

- Знос і деформація деталей (оболонок, чобота, труб, шийок валів, підшипників, пальців, обкладок тощо);

- Поява тріщин ві вилицях барабанів;

- Змішування та знос редукторів, шестерень;

- Згин регулювальних гвинтів натяжного механізму;

- Зайве натягання стрічки, пошкодження країв та їх знос;

- Відсутність значної кількості ковшів в одному місці стрічки;

- Деформація ковшів та розрив з'єднань.
`
    },
    secondText: {
        text: `##### Ремонт ковшової норії включає:

- Очищення редуктора, сапуна;

- Діагностика деталей, вузлів норії, редуктора, солодильника;

- Діагностика підшипників редуктора;

- Ремонт редуктора з кріпленням і стрійкою. Усунення витоку масла;

- Ремонт редуктора з кріпленням і стрійкою;

- Перевірка місця кріплення під редуктор та шпонкового з'єднання;

- Перевірка шпонкових з'єднань напівмуфт та муфти зворотного ходу норійної стрічки;

- Заміна еластичних вставок повільного і швидкісного напівмуфт;

- Ремонт захисту муфт;

- Ремонт гальмівного механізму;

- Заміна масла в редукторі, змащення підшипникових вузлів норії;

- Ремонт привідної станції (головка);

- Заміна футерування привідного барабана;

- Ремонт натяжної станції (чобота);

- Ремонт віджимного ролика (над чоботом);

- Ремонт прохідної станції з норійною стрічкою;

- Заміна ковшів;

- Демонтаж/монтаж верхньої кришки привідного барабана;

- Демонтаж/монтаж ревізійних кришок шахти норії;

- Демонтаж/монтаж нижніх кришок монтажних отворів;

- Заміна підшипників верхнього барабана;

- Заміна норійної стрічки;

- Прогонка різьби норійних метизів (болт, гайка);

- Ремонт солодильника відходу товару з норії;

- Ремонт шиберних заслінок;

- Зварювальні роботи (латання потирань, заміна кутників підсилення тощо);

- Пусконалагоджувальні роботи (Регулювання натяження і центрування стрічки тощо);

- Перевірка роботи короткочасним запуском;

- Вивіз вживаних частин норії до місця зберігання / утилізації;

- Прибирання робочого місця.

Демонтаж норії та розбірку виконують з метою діагностики несправностей та ремонту вузлів у різній ступені залежно від виду ремонту. Повну розбірку виконують при капітальному ремонті обладнання.

### Технічне обслуговування норії

Щоб зберегти елеваторне обладнання від несправностей, виходу з ладу та витрат на капітальний ремонт, необхідно виконувати періодичне технічне обслуговування норії.

##### До нього відноситься:

- Зовнішній огляд;

- Чищення барабана, чобота та труб;

- Контроль занулення та заземлення електрообладнання;

- Перевірка натяження стрічки;

- Контроль зміщення опорної конструкції;

- Перевірка приводів, електродвигуна, редуктора, муфти, гальмівного пристрою;

- Заміна пружин, підшипників та прокладок і масла;

- Усунення тертя деталей та іскріння;

- Перевірка наявності всіх вимикачів та змащення на підшипниках;

- Перевірка ступеня затяжки норійних болтів;

- Перевірка запобіжників, датчиків, систем контролю.

- Періодичний огляд необхідний, навіть якщо поломок не було. Навіть не великий зазор між стрічкою та трубою здатен викликати намотування та збій у роботі.
`
    }};
