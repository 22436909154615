import secondImage from '../../images/pages/repair-of-a-grain-dryer/1.jpg';
import firstImage from '../../images/pages/repair-of-a-grain-dryer/2.jpeg';
import {PageTemplates} from "../../pages/standartPages/enum";

export const repairOfaGrainDryer = {
    url: '/repair-of-a-grain-dryer',
    title: 'Ремонт і обслуговування зерносушарки',
    firstImage,
    secondImage,
    keywords: 'ремонт зерносушарки,обслуговування зерносушарки,очищення зерносушарки,заміна зерносушарки',
    manufacturers: {
        text: 'ТОВ «СІТАМ» має великий досвід ремонту та монтажу елеваторного обладнання, включаючи силоси, норії, конвеєри, зерносепаратори та зерносушарки різних виробників: Fratelli Pedrotti, Agrex, Mecmar, Metalmont, Frame, Bonfanti, Ravaro, Scolari, Strahl, Petcus, Araj, Chelik, Mepu, Neuero, Riela, Ozsu, ДСП та інші.',
    },
    type: PageTemplates.TwoImagesTwoTextsType,
    firstText: {
        text: `###### Встановлення зерносушарки виконують на вулиці, внаслідок атмосферного впливу та постійних навантажень з плином часу втрачається продуктивність та з'являються несправності, які потребують ремонту.

###### При регулярному технічному обслуговуванні та ремонті, робота зерносушарок буде відповідати установленим нормам виробника.
`,
    },
    description: 'Ремонт і монтаж елеваторного обладнання, включаючи силоси, норії, конвеєри, зерносепаратори та зерносушарки різних виробників: Fratelli Pedrotti, Agrex, Mecmar, Metalmont, Frame, Bonfanti, Ravaro, Scolari, Strahl, Petcus, Araj, Chelik, Mepu, Neuero, Riela, Ozsu, ДСП та інші.',
    secondText: {
        text: `### Ремонт зерносушарок може включати:

- Очищення від пилу, бруду та ржавчини;

- Огляд і очищення вентиляційного обладнання;

- Ремонт вентиляторів;

- Заміну прогорілих елементів топки;

- Ремонт форсунки, приводів, насосів, пальних баків тощо;

- Ремонт системи автоматики, заміна електроприладів;

- Ремонт гідравлічної системи;

- Ремонт, заміна шнека;

- Ремонт теплообмінника;

- Заміна, ремонт електродвигуна;

- Перевірка герметичності теплопроводу;

- Реконструкція відділення очищення та заміна обладнання;

- Встановлення заплаток на руйнівні частини шахти;

- Ремонт та заміна коробів;

- Усунення деформації полиць та лотків рами;

- Фарбування антикорозійним фарбою;

- Перевірка на холостому ходу та під навантаженням;

- Перевірка працездатності всіх приладів;
`}};