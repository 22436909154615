import firstImage from '../../images/pages/installation-of-machines/firstImage.jpg';
import secondImage from '../../images/pages/installation-of-machines/secondImage.jpg';
import {PageTemplates} from "../../pages/standartPages/enum";

export const installationOfMachines = {
    url: '/installation-of-machines',
    type: PageTemplates.TwoImagesTwoTextsBigType,
    manufacturers: {
        text: 'ООО «СИТАМ» имеет большой опыт монтажа станков самых разных марок и конфигураций. В списке оборудования, с которым мы работаем, числятся агрегаты следующих производителей:CNC(YZC), Hegenscheidt MFD(RQQ), DANOBAT(TT/TTB, CL/CIH, IDS), Sirmu-MT, CORREA, ARAMIS, Kalafat, Skoda, ДИП, Haas (ST), SIMPAC (MCL), TRUMATIC, WKV, KNUTH, AMADA и др. и других производителей в т.ч. отечественных.',
    },
    keywords: 'монтаж станков,монтаж сверлильных станков,монтаж фрезерных станков,монтаж сверлильно-фрезерных станков,монтаж лентопильных станков',
    title: 'Монтаж станков',
    underTitle: 'ООО «СИТАМ» Выполним ремонтные и монтажные работы оборудования маслоэкстракционного завода и другого промышленного оборудования на профессиональном уровне.\n' +
    ' На все виды работ организация имеет необходимые разрешения и свидетельства.',
    firstImage,
    secondImage,
    description: 'Выполним ремонтные и монтажные работы оборудования маслоэкстракционного завода. В списке оборудования, с которым мы работаем, числятся агрегаты следующих производителей:CNC(YZC), Hegenscheidt MFD(RQQ), DANOBAT(TT/TTB, CL/CIH, IDS), Sirmu-MT, CORREA, ARAMIS, Kalafat, Skoda, ДИП, Haas (ST), SIMPAC (MCL), TRUMATIC, WKV, KNUTH, AMADA и др. и других производителей в т.ч. отечественных.',
    firstText: {
        text: `Выполним работы по монтажу, демонтажу и переносу с последующим монтажом станков и станочных линий.
Переезд предприятия или перемещение цеха требуют качественного выполнения монтажных работ.

В списке оборудования с которым мы работали числятся:

Сверлильный станок;

Фрезерный станок;

Сверлильно-фрезерный станок;

Трубонарезные и муфтонарезные станки;

Термопластавтоматы;

Лентопильный станок;

Горизонтально-расточной станок;

Токарный станок;

Токарно-винторезный станок;

Токарно-револьверный обрабатывающий центр;

Станок лазерной резки;

Листогибочный станок;

Прокатный стан;

Кромкогиб;

Вертикально-фрезерный обрабатывающий центр;

Расточный станок;

Координатно-расточный станок;

Кривошипный пресс;

Координатно-пробивной штамповочный пресс;

Гидравлические пресса;

Гибочные машины и гильотины;

Шлифовальный станок;

Деревообрабатывающие станки;

и др. металлообрабатывающие и металлорежущие станки.`
    },
    secondText: {
        text: `Монтажные работы:
Разгрузка станка, узлов станка с использованием грузоподъемного оборудования;

Разборка упаковки и подготовка к монтажу;

Подготовка и заливка фундамента;

Транспортировка комплектующих станков по участку к месту монтажа;

Монтаж станка (станины) на месте расположения фундаментов;

Установка станка по уровню с паспортной точностью, с последующей затяжкой и фиксацией крепежных болтов;

Сборка узлов станка;

Заливка требуемого количества рабочих жидкостей;

Нанесение необходимых смазок;

Герметизация воздушных и гидравлических контуров станка;

Подключение к источнику электропитания;

Установка шкафов электропитания станка, подводка питания и прокладка кабелей;

Сборка, крепление и герметизация защитных кожухов;

Проверка герметических параметров станка;


В зависимости от вида станка и требований заказчика может быть выполнен монтаж доп. оборудования станка. Установка и подключение всего вспомогательного оборудования:
Установка гидро и масло станции станков;

Установка систем охлаждения масла станков;

Установка системы сбора конденсата паров СОЖ;

Подключение гидравлических, пневматических шлангов (трубопроводов) СОЖ;

Установка защитного ограждения;

Установка бункеров с защитными решетками;

Установка тележки загрузки-выгрузки;

Установка манипулятора;

Установка транспортеров;

Установка система приготовления, очистки и поддержания концентрации СОЖ;

Установка чиллера;

Прокладка и монтаж трубопроводов подачи СОЖ;

и т.д.
`}};
