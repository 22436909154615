import firstImage from '../../images/pages/elevator-repair/1.jpg';
import secondImage from '../../images/pages/elevator-repair/2.jpeg';
import {PageTemplates} from "../../pages/standartPages/enum";

export const elevatorRepair = {
    url: '/elevator-repair',
    title: 'Ремонт элеваторов',
    type: PageTemplates.TwoImagesTwoTextsType,
    keywords: 'ремонт элеватора,ремонт силоса',
    manufacturers: {
        text: 'ООО «СИТАМ» имеет большой опыт установки силосов и бункеров для зерна, муки, цемента, песка и т.д различных типов (конусные, с плоским дном), объема, габаритов и комплектующих.',
    },
    underTitle: 'ООО «СИТАМ» Выполним ремонт и сервисное обслуживание элеваторов, силосов, бункеров и других металлических конструкций различных видов на профессиональном уровне. На все виды работ организация имеет необходимые разрешения и свидетельства.',
    firstImage,
    secondImage,
    description: 'Установка силосов и бункеров для зерна, муки, цемента, песка и т.д различных типов (конусные, с плоским дном), объема, габаритов и комплектующих. Произведем ремонт элеватора в соответствии со всеми технологическими и конструктивными особенностями сооружения',
    firstText: {
        text: `### Обслуживание элеваторов
Конструкция хранилища выполняется из металла или железобетона, в ходе активной эксплуатации нарушается их целостность и появляются разрушения в ходе которых невозможно обеспечить нормативные условия хранения зерна и других материалов. Для поддержания целостности конструкции нужно периодически проводить техническое обслуживание элеватора и его ремонт.

### Демонтаж элеваторов

##### Демонтаж силосных башен и бункеров может включать следующие этапы:

- Выезд на объект и оценка работ по демонтажу;

- Подготовка необходимой разрешительной документации;

- Снятие старого покрытия, Удаление изоляционного слоя

- Демонтаж и вынос металлических конструкций;

- Разборка креплений, разрушение коробки.

- Подготовка металлолома к вывозу

Выполняем работы в закрытых помещениях, демонтаж с сохранением конструкции для последующей транспортировки и монтажа на новом месте. Монтажники, альпинисты и резчики с аттестованы для высотных работ и с опытом от 5 лет.
`
    },
    secondText: {
        text: `### Ремонт зерновых элеваторов

Произведем ремонт элеватора в соответствии со всеми технологическими и конструктивными особенностями сооружения.

##### Элеватор включает в себя комплекс сооружений:

- Рабочее здание;

- Силосные корпуса;

- Механизмы транспортировки зерна(нории);

- Зерносушилки и т.д.

##### Этапы ремонта элеваторов могут включать:

- Штукатурка межсоговых швов;

- Герметизацию швов и стыков;

- Починка и проверка состояния болтовых соединений сборных конструкций;

- Ремонт железобетонных колонн элеватора;

##### Ремонт фасада элеватора, кровли.

- Ремонт переходных галерей;

- Замена окон элеватора;

- Чистка наружной поверхности стен, внутренняя очистка силосов;

- Восстановление оцинкованных поверхностей;

- Покраска элеватора, силоса и металлоконструкций.

### Реконструкция элеватора
Реконструкцию элеватора производят в связи с изменениями комплекса, объема производства, износе отдельных частей и всего корпуса.

##### Реконструкция включает в себя:

- Усиление стеновых плит силосных корпусов и фундамента;

- Перепланировка комплекса;

- Замена отдельных частей элеватора;

##### Выполним ремонт металлического, железобетонного элеватора методом промышленного альпинизма.
 `}};

