import firstImage from '../../images/pages/cyclone-installation/2.jpg';
import secondImage from '../../images/pages/cyclone-installation/1.jpg';
import {PageTemplates} from "../../pages/standartPages/enum";

export const cycloneInstallation = {
    url: '/cyclone-installation',
    title: 'Установка циклона, ремонт и обслуживание',
    firstImage,
    secondImage,
    keywords: 'монтаж циклона,ремонт циклона,обслуживание циклона,ремонт аспирационных систем',
    type: PageTemplates.TwoImagesTwoTextsBigType,
    underTitle: `ООО «СИТАМ» Выполним ремонт, монтаж циклонов, аспирационных систем и другого оборудования для фильтрации на профессиональном уровне. На все виды работ организация имеет необходимые разрешения и свидетельства.`,
    description: 'Монтаж промышленных фильтров(пылеуловители): циклонов, батарейных циклонов входящих в состав аспирационных систем и приточно-вытяжной вентиляции, очищающих вентилируемый воздух от механических примесей (частиц пыли, сажи, копоти)',
    firstText: {
        text: `### Монтаж циклона

Выполним монтаж промышленных фильтров(пылеуловители): циклонов, батарейных циклонов входящих в состав аспирационных систем и приточно-вытяжной вентиляции, очищающих вентилируемый воздух от механических примесей (частиц пыли, сажи, копоти и т.д.).

Использование воздухоочистных циклонов требуется на производствах, непосредственно связанных с высоким пылеобразованием:

Химическая промышленность;

Строительство, камнеобработка, шлифовка и т.д;

Металлургическая промышленность;

Обработка металла и древесины;

Угольная и добывающая промышленность;

Пищевая промышленность;

Перечень основных видов работ, производимых при монтаже циклонов:

Подготовка места установки, заливка фундамента;

Транспортировка циклона и его элементов к месту монтажа;

Закрепление циклона анкерными болтами и сборка;

Подключение течек, мигалок, затворов, люков, патрубков и повторная опрессовка;

Пусконаладочные работы и сдача в эксплуатацию.
Скорость выполнения работ, безопасность и сохранность оборудования зависит от квалификации специалистов. Наша компания имеет большой опыт выполнения подобных работ а так же все необходимые разрешения и свидетельства на их выполнение.`
    },
    secondText: {
        text: `### Ремонт циклона
        
Работы проводимые при ремонте скрубберов и циклонов:

Текущий ремонт:

Исправление вмятин, замена пораженных коррозией участков;

Уплотнение всех фланцевых соединений, креплений;

Заварка отверстий и трещин в верхней трубной доске
Ремонт пылеудаляющих устройств;

Регулировка разгрузочных устройств (мигалок и шлюзовых затворов)
Замена изношенных клапанов.
Восстановление нарушенной теплоизоляции.
Очистка от пыли, грязи, накипи внутренних поверхностей аппарата, форсунок, водо и газораспределительных устройств;

Капитальный ремонт:

Полная замена всех кассет с фильтрующими заполнителями и полотнищ в сетчатых фильтрах;

Ремонт и уплотнение трубных досок;

Ремонт корпуса с заменой корродированных листов;

Замена противоизносных щитков на передних выхлопных трубах;

Ремонт разгрузочных устройств.
Полная очистка циклона от отложений, с выемкой в нужных местах выхлопных труб;

Периодичность, продолжительность и трудоемкость ремонтов различного пылегазоочистного оборудования зависят от множества факторов.
### Обслуживание циклона

Для обеспечения длительной и бесперебойной работы необходимо осуществлять периодическое техническое обслуживание циклона. Мы проведем полное ТО вашего оборудование включающее:

Проверка уровень очистки пылесборников;

Осмотр основных рабочих частей агрегата на целостность;

Соответствие настройкам системы;

Тщательного осмотр, чистка и замена деталей;

Удаление из бункера крупных частей мусора;

Осмотр целостности теплоизоляции, ремонт повреждённых участков;

Определение и устранение подсоса воздуха в бункере.
Доверяя проведение ТО профессионалам возможно значительно продлить срок использования установки и сэкономить деньги на капитальном ремонте.`
}};

