import firstImage from '../../images/pages/modernization-of-the-shear-press/1.jpg';
import secondImage from '../../images/pages/modernization-of-the-shear-press/2.jpg';
import {PageTemplates} from "../../pages/standartPages/enum";

export const modernizationOfTheShearPress = {
    url: '/modernization-of-the-shear-press',
    type: PageTemplates.TwoImagesTwoTextsType,
    keywords: 'модернізація прес-ножиць,реконструкція прес-ножиць,ремонт прес-ножиць,прес-ножиці',
    manufacturers: {
        text: 'ТОВ «СІТАМ» має великий досвід ремонту та модернізації прес-ножиць і пресів для пакування металобрухту найрізноманітніших марок і конфігурацій. У списку обладнання, з яким ми працюємо, знаходяться агрегати наступних виробників: Akros, Riko, Metso Lindemann, SIERRA, HuaHong, Enerpat, WANSHIDA, Dongfang та інших виробників, включаючи вітчизняних',
    },
    title: 'Модернізація прес-ножиць',
    underTitle: 'ТОВ «СІТАМ» Виконаємо модернізацію прес-ножиць та іншого промислового обладнання на професійному рівні. На всі види робіт організація має необхідні дозволи та свідоцтва',
    firstImage,
    secondImage,
    description: 'Виконаємо ремонт та модернізацію прес-ножиць і пресів для пакування металобрухту найрізноманітніших марок і конфігурацій. Заміна застарілого гідравлічного обладнання. Установка панелі візуалізації температур масла, температур двигунів, тиску, стану двигунів, індикація аварій і попередження',
    firstText: {
        text: `##### Модернізація електрообладнання прес-ножиць:

Установка ел. двигунів основних насосів;

Система плавного пуску двигунів;

Установка панелі візуалізації температур масла, температур двигунів, тиску, стану двигунів, індикація аварій і попередження;

Заміна існуючих кінцевих та аварійних вимикачів (морально застарілих) на більш сучасні та надійні з інтеграцією їх в систему візуалізації;
`
    },
    secondText: {
        text: `Наша компанія виконає модернізацію гідравлічних прес-ножиць для переробки металобрухту. Комплекс робіт з модернізації прес-ножиць може включати в себе демонтаж та монтаж вузлів, відновлювальний ремонт і заміну певних вузлів і механізмів.

##### Заміна застарілого гідравлічного обладнання:

Діагностика гідравлічної системи;

Заміна насосів основного тиску;

Заміна існуючої фільтровальної системи;

Заміна гідроапаратури на сучасні клапанні групи;

Ремонт градирні водяного охолодження зі заміною насосної групи системи водяного охолодження;

Установка системи фільтрації водяного охолодження;

Заміна теплообмінника;

Установка системи КІПіА для управління гідросистемою ножиць: комплект апаратури для контролю тиску, комплект датчиків для маслобаків, комплект сигналізаторів стану фільтрів;

Заміна газової апаратури для наповнювального бака;

Заміна газової апаратури системи відведення столу (пневмокамери);

Заміна трубної розводки гідросистеми;

Заміна ущільнень всіх гідроциліндрів;

Ремонт зі заміною комплектуючих клапана наповнення різу;

Заміна насосної групи системи гідравлічного управління;

Введення системи в роботу та сервісне супроводження;

`}}
;

