import secondImage from '../../images/pages/sugar-drying-complex/2.png';
import firstImage from '../../images/pages/sugar-drying-complex/1.png';
import {PageTemplates} from "../../pages/standartPages/enum";

export const sugarDryingComplex = {
    url: '/sugar-drying-complex',
    title: 'Монтаж обладнання цукросушильного відділення',
    firstImage,
    secondImage,
    keywords: 'монтаж обладнання цукросушильного відділення,обладнання цукросушильного відділення',
    underTitle: `ТОВ "СІТАМ" Виконаємо монтаж і заміну/демонтаж обладнання цукросушильного відділення та іншого промислового обладнання на професійному рівні. На всі види робіт організація має необхідні дозволи та свідоцтва.`,
    type: PageTemplates.TwoImagesOneTextType,
    description: 'Технічне переоснащення цукросушильного відділення цукрового заводу. Монтаж цукросуші (Fives-Cail). Монтаж бункера товарного цукру',
    text: `Наша компанія виконає технічне переоснащення цукросушильного відділення цукрового заводу.

##### До переліку робіт може входити:

- Монтаж цукросушки (Fives-Cail);

- Монтаж технологічних площадок;

- Монтаж сендвіч-панелей;

- Монтаж сушаркового барабана;

- Монтаж вентиляторів і калориферів повітря;

- Монтаж барабанних сіяльників;

- Монтаж скруббера;

- Монтаж бункера товарного цукру;

- Клеровочні мішалки та нагрівачі клеровки;

- Монтаж додаткового обладнання: системи аспірації, елеватор, стрічкові та шнекові конвеєри тощо.
 `};