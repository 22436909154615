import {PageTemplates} from "../../pages/standartPages/enum";

export const privacy = {
    url: '/privacy',
    title: 'УГОДА ПРО ПЕРЕДАЧУ ПЕРСОНАЛЬНИХ ДАНИХ',
    type: PageTemplates.SimpleText,
    text: `Фізична особа-підприємець Тіщук Віктор Акімович (найменування юридичної особи, фізичної особи – підприємця), що діє на підставі Виписки з Єдиного державного реєстру юридичних осіб, фізичних осіб-підприємців та громадських формувань від 22.06.2020р. № 20650000064685, що іменується надалі "Оператор", з однієї сторони, та особа, яка приєдналась до умов цієї Угоди, шляхом прийняття пропозиції укласти Договір публічної оферти про надання робіт/послуг на умовах, визначених Оператором, що іменується надалі "Суб\`єкт персональних даних", з іншої сторони, надалі разом__ – "Сторони", а кожен окремо__ – "Сторона", уклали цю угоду про таке:
    
######    Терміни, що використовуються у тексті Угоди:

• __Автоматизоване опрацювання персональних даних__ – опрацювання персональних даних за допомогою засобів обчислювальної техніки.

• __Оператор__ – юридична особа, яка самостійно або спільно з іншими особами організовує та/або здійснює опрацювання конфіденційної інформації в тому числі персональних даних, визначає цілі опрацювання даних, склад персональних даних, що підлягають опрацюванню, дії (операції), що здійснюються з персональними даними.

• __Блокування персональних даних__ – тимчасове припинення опрацювання персональних даних (за винятком випадків, якщо опрацювання необхідне для уточнення персональних даних).

• __Інформаційна система персональних даних__ – сукупність персональних даних, що  містяться в базах даних і забезпечують їх опрацювання інформаційних технологій і технічних засобів.

• __Конфіденційність персональних даних__ – обов'язкова для дотримання оператором або іншою особою, яка отримала доступ до персональних даних, вимога не допускати їх поширення без згоди суб'єкта персональних даних або наявності іншої законної підстави.

• __Знеособлювання персональних даних__ – дії, у результаті яких неможливо визначити без використання додаткової інформації приналежність персональних даних конкретному суб'єктові персональних даних.

• __Опрацювання персональних даних__ – будь-яка дія (операція) або сукупність дій (операцій), що здійснюються з використанням засобів автоматизації або без використання таких засобів з персональними даними, включаючи збір, запис, систематизацію, накопичення, зберігання уточнення (оновлення, зміна), витяг, використання, передачу (поширення, представлення, доступ), знеособлення, блокування, видалення, знищення персональних даних.

• __Загальнодоступні персональні дані__ – персональні дані, доступ до яких надано за згодою суб'єкта персональних даних необмеженому колу осіб або на які відповідно до законодавства кожної з держав Сторін не поширюється вимога про дотримання конфіденційності

• __Оператор інформаційної системи__ – фізична або юридична особа, яка здійснює діяльність з експлуатації інформаційної системи, у тому числі з опрацювання інформації, що міститься в її базах даних.

• __Персональні дані__ – будь-яка інформація, що належить прямо або опосередковано до визначеного або такого, що визначається, або визначається на підставі такої інформації фізичній особі (суб'єкту персональних даних), у тому числі його прізвище, ім'я, по батькові, рік, місяць, дата та місце народження, адреса, сімейний, соціальний, майновий стан, освіта, професія, доходи, інша інформація.

• __Надання персональних даних__ – дії, спрямовані на розкриття персональних даних певній особі або певного кола осіб.

• __Поширення персональних даних__ – дії спрямовані на розкриття персональних даних невизначеному колу осіб (передача персональних даних) або на ознайомлення з персональними даними необмеженого кола осіб, у тому числі оприлюднення персональних даних у засобах масової інформації, розміщення в інформаційно-телекомунікаційних мережах або надання доступу до персональних даних будь-яким способом.

• __Транскордонна передача персональних даних__ – це передача ПД оператором через державний кордон РФ органу влади іноземної держави, фізичній або юридичній особі іноземної держави.

• __Знищення персональних даних__ – дії, у результаті яких неможливо відновити зміст персональних даних в інформаційній системі персональних даних та/або в результаті яких знищуються матеріальні носії персональних даних.

#### 1. ПРЕДМЕТ УГОДИ

1.1. У рамках цієї Угоди Сторони домовилися про надання один одному взаємних гарантій, що підтверджують нерозголошення, а також дотримання встановленого цією угодою порядку передачі одержуючою Стороною, що одержує від розкриваючої Сторони комерційної та будь-якої іншої конфіденційної інформації, у тому числі персональних даних, якою Сторони будуть обмінюватися в рамках спільної співпраці.

#### 2. ЗОБОВʼЯЗАННЯ СТОРІН

2.1. Протягом терміну співпраці та протягом п'яти років після його закінчення, кожна Сторона зобов'язується не розкривати без попереднього письмової згоди будь-яку конфіденційну інформацію, отриману від розкриває Сторони. Коли будь-яка інформація розкривається третій особі з такою згодою, Сторона, яка розкриває таку інформацію третій особі, повинна гарантувати, що третя особа зв’язана положеннями цієї Угоди.

2.2. Одержуюча Сторона, яка отримала будь-яку конфіденційну інформацію, не повинна розкривати її, і зобов'язується опрацьовувати таку інформацію з тим ступенем дбайливості та обачності, яка застосовується щодо її ділових і фінансових даних того ж рівня важливості.

2.3. Здійснювати опрацювання, у тому числі передачу, персональних даних за згодою суб'єкта персональних даних або його Представника, у будь-якій формі, що дозволяє підтвердити факт його отримання, якщо інше не передбачено законом.

2.4. Кожна Сторона зобов'язана під час опрацювання персональних даних ухвалювати необхідні організаційні та технічні заходи для захисту персональних даних від несанкціонованого або випадкового доступу до них, знищення, перекручення, блокування, копіювання, поширення персональних даних, а також від інших неправомірних дій.

2.5. Не отримувати та не опрацьовувати персональні дані про расову, національну належність, політичні погляди, релігійні або філософські переконання, стан здоров'я, інтимного життя, за винятком випадків, передбачених законом.

2.6. Дозволяти доступ до конфіденційної інформації, у тому числі персональних даних суб'єктів тільки спеціально уповноваженим особам, при цьому зазначені особи повинні мати право отримувати тільки ту інформацію/персональні дані, які необхідні для виконання конкретних функцій з урахуванням розумності та в цілях, здійснення і виконання покладених законодавством на Сторону, як на оператора функцій, повноважень й обов'язків.

2.7. За необхідності транскордонної передачі персональних даних на територію іноземних держав Сторони зобов'язані переконатися в тому, що іноземною державою, на території якої здійснюється передача персональних даних, забезпечується адекватний захист прав суб'єктів персональних даних, до початку здійснення транскордонної передачі даних.

2.8. У разі відкликання суб'єктом персональних даних згоди на опрацювання його персональних даних Оператор зобов'язаний припинити їх опрацювання або забезпечити припинення такого опрацювання (якщо опрацювання персональних даних здійснюється іншою особою, яка діє за дорученням оператора) і в разі, якщо збереження персональних даних більше не потрібне для цілей опрацювання персональних даних, знищити персональні дані або забезпечити їх знищення (якщо опрацювання персональних даних здійснюється іншою особою, яка діє за дорученням оператора) у строк, що не перевищує тридцяти днів з дати надходження зазначеного відкликання, якщо інше не передбачено договором, стороною якого, вигодонабувачем або поручителем за яким є суб'єкт персональних даних, іншою угодою між оператором і суб'єктом персональних даних або якщо оператор не має права здійснювати опрацювання персональних даних без згоди суб'єкта персональних даних на підставах, передбачених чинним законодавством.

2.9. У разі виявлення недостовірних персональних даних або неправомірних дій з ними оператора у разі звернення або за запитом суб'єкта персональних даних або його законного представника або уповноваженого органу з захисту прав суб'єктів персональних даних оператор зобов'язаний здійснити блокування персональних даних, що відносяться до відповідного суб'єкту персональних даних, з моменту такого звернення або отримання такого запиту на період перевірки.

2.10. У разі досягнення мети опрацювання персональних даних оператор зобов'язаний негайно припинити опрацювання персональних даних і знищити відповідні персональні дані в строк, що не перевищує трьох робочих днів з дати досягнення мети опрацювання персональних даних, якщо інше не передбачено федеральними законами, і повідомити про це суб'єкта персональних даних або його законного представника, а в разі, якщо звернення або запит було направлено уповноваженим органом із захисту прав суб'єктів персональних даних, також зазначений орган.

#### 3. СПЕЦІАЛЬНІ УМОВИ

3.1. Інформація, отримана приймаючою Стороною від розкриваючої Сторони в письмовій формі та має відмітку про її конфіденційність, буде розглядатися як конфіденційна та ставлення до неї має бути відповідне. Конфіденційної також є усна інформація, за умови, що письмове повідомлення щодо конфіденційності такої інформації було отримано від іншої Сторони в момент її передачі.

3.2. Незважаючи на будь-які інші положення, інформація, отримана приймаючою Стороною, не розглядається як конфіденційна і відповідно приймаюча Сторона не підпадає під зобов'язання про конфіденційність щодо такої інформації, якщо інформація задовольняє одну з таких характеристик:

- інформація під час її розкриття є публічно відомою;
- інформацію представлено Стороні з письмовою вказівкою на те, що вона не є конфіденційною;
- інформація законним шляхом отримана від будь-якої третьої особи без порушення цієї Угоди;
- інформація повинна бути розкрита відповідно до відповідного Закону, судовим або адміністративним розпорядженням або постановою, за умови що Сторона, яка отримала інформацію від іншої Сторони, попередньо письмово та з підтвердженням необхідності в такому розкритті повідомить про це іншу Сторону.

3.3. Усі заходи конфіденційності під час опрацювання та передачі персональних даних поширюються на всі носії інформації як на паперові, так і на електронні.

3.4. Режим конфіденційності персональних даних знімається у випадках знеособлення або включення їх у загальнодоступні джерела персональних даних, якщо інше не визначено законом.
      
#### 4. ВІДПОВІДАЛЬНІСТЬ СТОРІН

4.1. Сторони несуть відповідальність за невиконання або неналежне виконання своїх зобов'язань передбачених цією Угодою відповідно до чинного законодавства.

4.2. Приймаюча Сторона несе відповідальність за розкриття (включаючи ненавмисне) конфіденційної інформації, отриманої від розкриваючої Сторони, а також у разі невжиття заходів, які приймаюча Сторона могла або повинна була зробити, щоб запобігти або зменшити негативні наслідки такого розкриття інформації в максимально короткий період часу.

4.3. У разі якщо таке розкриття конфіденційної інформації сталося, Сторона, яка розкрила інформацію, повинна вжити всіх можливих і необхідних заходів, щоб усунути наслідки такого розкриття.

4.4. Винна в розкритті Сторона повинна нести всі витрати, які є результатом вищезазначених дій і пов'язані з діями, спрямованими на усунення наслідків розкриття інформації.

#### 5. ВРЕГУЛЮВАННЯ СПОРІВ

5.1. Угода регулюється та розглядається відповідно до чинного законодавства України.

5.2. Усі спори, що виникають із або в зв'язку із цією Угодою, а також з його тлумаченням, підлягають дружньому врегулюванню шляхом переговорів між Сторонами. 

5.3. Якщо жодне дружнє врегулювання не буде досягнуто протягом 30 календарних днів від початку цих переговорів, то спір має розглядатись судом.

#### 6. СТРОК ДІЇ УГОДИ

6.1.  Ця Угода приєднується до умов Договору публічної оферти про надання робіт/послуг.

6.2. Угода набуває чинності в день її підписання обома Сторонами і діє до повного виконання Сторонами своїх зобов'язань, що випливають з цієї Угоди.

#### 7. ПРИКІНЦЕВІ ПОЛОЖЕННЯ

7.1. Уся інформація, представлена іншій Стороні у зв'язку з цією Угодою, за винятком персональних даних, залишається виключною власністю Сторони Розкриття.

7.2. Усі зміни та доповнення до цієї Угоди набирають чинності та розглядаються як невід'ємна частина цієї Угоди тільки в тому випадку, якщо вони зроблені в письмовій формі й підписані належним чином уповноваженими представниками Сторін.

7.3. Форма та метод інформаційного обміну в рамках цієї Угоди повинні бути визначені безпосередньо Сторонами, за умови, що всі дії повинні відбуватися чесно, розумно, точно і відповідно до чесної ділової практики.

7.4. Якщо будь-яка з умов цієї Угоди буде визнана судом або будь-яким іншим компетентним органом недійсною, незаконною або не маючою чинності, то це жодним чином не вплине на дійсність, законність і дію інших умов Угоди, і Сторони вживатимуть всіх зусиль для того, щоб найкращим чином переглянути ці умови для того, щоб вони залишилися чинними відповідно до намірів, виражених у них.

7.5. Сторони не повинні повністю або частково передавати права та обов’язки, що випливають з цієї Угоди, без попередньої письмової згоди іншої сторони.
`
};