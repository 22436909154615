export interface IFaqData {
    buttonText: string;
    text: string;
}

export const faqData: IFaqData[] = [{
    buttonText: 'Гарантийные обязательства',
    text: 'Мы предоставляем гарантию на выполненные работы и изделия сроком от 3 мес. до 5 лет. На протяжении этого срока ремонт и диагностика осуществляются бесплатно, однако при условии выполнения всех правил эксплуатации которые указанны в технической документации. По истечению этого времени все работы выполняются в индивидуальном порядке.'
},{
    buttonText: 'Сроки выполнения',
    text: 'Точные сроки выполнения работ определяются заказчиком, либо поставщиком после ознакомления с проектом или диагностики оборудования.'
},{
    buttonText: 'Материалы и техника',
    text: 'Спец. техника, материалы и механизмы могут быть предоставлены полностью или частично как заказчиком так и поставщиком.'
},{
    buttonText: 'Условия выполнения работ',
    text: 'Работы вополняются после диагностики или осмотра объекта на месте а так же подписания договора, получения предоплаты и предоставления/покупки необходимых материалов. Работы могут быть произведены в условиях действующего производства с предоставлением технологической карты и учетом всех нормативных требования ОТ и ДСТУ.'
},{
    buttonText: 'Условия оплаты',
    text: 'Работаем по предоплате от 30%, либо на индивидуально оговоренных условиях.'
},{
    buttonText: 'Лицензии и разрешения',
    text: 'Мы предостовляем все необходимые документы требуемые законодательством и заказчиком включая квалификационные аттестаты лиц выполняющих работы и источники происхождения и качества материалов, оборудования и устройств.'
}
];