import secondImage from '../../images/pages/press-compactor/1.jpg';
import firstImage from '../../images/pages/press-compactor/2.jpeg';
import {PageTemplates} from "../../pages/standartPages/enum";

export const pressCompactor = {
    url: '/press-compactor',
    type: PageTemplates.TwoImagesTwoTextsBigType,
    keywords: 'ремонт прес-компактора, прес-компактор для вторинної сировини',
    manufacturers: {
        text: 'ТОВ «СІТАМ» має великий досвід ремонту моноблочних, стаціонарних прес-компакторів (включаючи шнекові). У списку обладнання, з яким ми працюємо, фігурують агрегати наступних виробників: SPB 20 E, Ecopolymer, Europress, Ecopress, Avermann, Pressor, Ecomp, HSM, KOSPA, Husmann, Werner & Weber та інші виробники, включаючи вітчизняних.',
    },
    title: 'Ремонт прес-компактора для вторинної сировини',
    underTitle: 'ТОВ «СІТАМ» Виконаємо ремонт та обслуговування прес-компактора та іншого промислового пресового обладнання на професійному рівні. На всі види робіт організація має необхідні дозволи та свідоцтва.',
    firstImage,
    secondImage,
    description: 'Роботи з ремонту та технічного обслуговування прес-компактора (прес-контейнера) для пресування та транспортування одноразових піддонів, одноразової дерев\'яної тари, а також паперу, картону, плівки, відходів з ПЕТ та різної вторинної сировини',
    firstText: {
        text: `Наша компанія виконає роботи з ремонту та технічного обслуговування прес-компактора (прес-контейнера) для пресування та транспортування одноразових піддонів, одноразової дерев'яної тари, а також паперу, картону, плівки, відходів з ПЕТ та різної вторинної сировини. Комплекс робіт з ремонту прес-компактора може включати в себе демонтаж та монтаж вузлів, відновлювальний ремонт та заміну певних вузлів та механізмів.

##### Несправності прес-компактора:

- Прес не запускається;

- Шум у клапанах розподільників та трубах при відкриванні клапанів;

- При закриванні клапанів чуються різкі удари;

- Толчки в механізмах при закриванні клапана;

- Прес не розвиває потрібного зусилля;

- Прес не може витолкнути купу.

Несправності змушують працювати систему менш ефективно, з меншою продуктивністю, викликаючи відмови в роботі, які призводять до виходу обладнання з ладу, збільшення браку та витрат у зв'язку з припиненням виробничого циклу.

##### Ремонт прес-компактора (контейнера) може включати:

- Діагностика та складання дефектної відомості;

- Відновлення зварних швів;

- Ремонт, заміна шнека;

- Ремонт, заміна направляючих;

- Заміна, ремонт гідроциліндрів;

- Ремонт, заміна контролера (блоку управління);

- та інших елементів.
`
    },
    secondText: {
        text: `##### До регламенту технічного обслуговування прес-компактора входить:

Механічна частина:

- Перевірка дотримання правил експлуатації обладнання;

- Технічний огляд стану обладнання – перевірка працездатності всіх механічних вузлів;

- Перевірка наявності зовнішніх пошкоджень та їх ремонт;

- Перевірка кінетичної системи та її ремонт у разі несправності;

- Змащування направляючих плити преса та замкових пристроїв;

- Притягування різьбових з'єднань.

Гідравлічна частина:

- Перевірка та притягування кріпильних механізмів та з'єднань;

- Перевірка герметичності та контроль витоків масла, усунення протікань;

- Контроль рівня масла та робочого тиску гідросистеми;

- Заміна гідравлічного масла та масляного фільтра;

- Перевірка циліндрів і фітингів та інших робочих частин на знос або пошкодження;

- Змащування шарнірів преса.

Електрична частина:

- Контроль справності елементів електросистеми;

- Перевірка спрацьовування клапанів;

- Налаштування індукційних датчиків;

- Перевірка спрацьовування кінцевих перемикачів, їх налаштування та ремонт за необхідності;

- Перевірка панелі управління;

- Контроль справності роботи програми управління пресом.
`}};