import firstImage from '../../images/pages/oil-extraction-line-installation/firstImage.png';
import {PageTemplates} from "../../pages/standartPages/enum";

export const oilExtractionLineInstallation = {
    url: '/oil-extraction-line-installation',
    title: 'Монтаж линии экстракции масла',
    firstImage,
    keywords: 'монтаж линии экстракции масла,линия экстракции масла,демонтаж линии экстракции масла,демонтаж конвейеров',
    type: PageTemplates.OneImageTwoTextsSmallType,
    firstText: {
        text: `Выполним монтаж и замену/демонтаж конвейеров (транспортеров) и другого промышленного оборудования на профессиональном уровне. На все виды работ организация имеет необходимые разрешения и свидетельства.
Наша компнаия произведет монтаж оборудование для экстракции масла (отжима) масличных культур: семена подсолнечника, бобы сои, пальмовые косточки, семена хлопка, арахис, и др`
    },
    description: 'Произведем монтаж оборудования для экстракции масла (отжима) масличных культур: семена подсолнечника, бобы сои, пальмовые косточки, семена хлопка, арахис. Монтаж экстрактора, пресса, гранулятора и т.д.',
    secondText: {
        text: `### Монтаж линии экстракции (экстракционного цеха) может включать:

- Монтаж транспортера;

- Монтаж скребкового конвейера;

- Монтаж экстрактора;

- Монтаж пресса;

- Монтаж гранулятора;

- Монтаж насосов;

- Монтаж тостера (чанный испаритель);

- Монтаж дистиллятора;

- Монтаж сушилка-охладителя шрота;

- Монтаж фильтров;

- Монтаж жаровни;

- Монтаж конденсаторов;

- Монтаж резервуаров, емкостей, колонн, отстойников;

- Монтаж теплообменника, экономайзера, бойлера;

и др оборудования.`
}};