import firstImage from '../../images/pages/installation-of-presses/firstImage.jpg';
import secondImage from '../../images/pages/installation-of-presses/secondImage.jpeg';
import {PageTemplates} from "../../pages/standartPages/enum";

export const installationOfPresses = {
    url: '/installation-of-presses',
    title: 'Монтаж прессов',
    firstImage,
    secondImage,
    description: 'Выполним монтаж прессов и другого промышленного оборудования. Произведем монтаж гидравлического пресса, кривошипного пресса и гильотины различной конструкции и габаритов',
    keywords: 'ремонт пресса,замена гидравлического пресса,монтаж кривошипных прессов',
    type: PageTemplates.TwoImagesTwoTextsBigType,
    underTitle: 'ООО «СИТАМ» Выполним монтаж прессов и другого промышленного оборудования на профессиональном уровне. На все виды работ организация имеет необходимые разрешения и свидетельства',
    firstText: {
        text: `#### Монтаж сборных прессов производится по следующей схеме:

- Изучение технического задания, выезд на объект;

- Подготовка коммерческого предложения;

- Разработка и изготовление ППР с учетом особенностей оборудования и места установки;

- Транспортировка на объект необходимого оборудования, оснастки и инструмента;

- Оформление необходимых документов (наряды, акты допуска на проведение работ);

- Проверка фундамента, закладных элементов и оборудования;

- Выполнение всех требований завода производителя по крепежу и строплению груза;

- Перемещение габаритных частей пресса и их сборка;

- После установки на фундамент монтируем остальные узлы, детали и вспомогательные системы.

- Наладка прессов Пусконаладка и испытание пресса служит для определения рабочих характеристик оборудования перед эксплуатацией.

К наладке приступают после полного окончания монтажных работ. Наладка прессового оборудования проводится в два этапа: наладка гидропривода, затем наладка самого пресса. Последовательность работ по наладке гидропривода определяется его конструктивной схемой.

После завершения наладки производят проверку станка согласно свидетельству о приемке на соответствие техническим характеристикам, после чего составляется соответствующий акт о выполненных ПНР. По результатам проведения пусконаладочных работ оборудование сдается заказчику в эксплуатацию с соответствующее паспортным характеристиками.`
    },
    secondText: {
        text: `###### Произведем монтаж гидравлического пресса, кривошипного пресса и гильотины различной конструкции и габаритов.

#### Монтаж прессового оборудования и прессовых линий может включать:

- Подготовка помещения, заливка фундамента;

- Транспортировка пресса, деталей и узлов на место монтажа;

- Проверка состояния деталей и распаковка;

- Кантовка пресса и установка в приямок или на фундамент;

- Установка остальных деталей пресса, пусконаладка, настройка оборудования в различных режимах работы;

- Сдачу в эксплуатацию с подготовкой исполнительной документации.

В случае промышленного переезда требуется также демонтаж пресса. Необходимые детали и узлы оборудования снимаются, упаковываются, маркируются и консервируются для последующей сборки. Специалисты выполнят демонтажные работы качественно и в срок.

Размещать на производстве грузоподъемное оборудование может быть экономически не выгодно. Поэтому работы по монтажу, демонтажу прессового оборудования, выгоднее поручить организации со специалистами, необходимой материально-технической базой и опытом выполнения подобных работ.

*Монтаж прессов производится в соответствии с технической документации или заданием заказчика.
`
}};