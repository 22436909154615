import secondImage from '../../images/pages/repair-of-the-dryer-cooler-of-meal/1.jpg';
import firstImage from '../../images/pages/repair-of-the-dryer-cooler-of-meal/2.jpg';
import {PageTemplates} from "../../pages/standartPages/enum";

export const repairOfTheDryerCoolerOfMeal = {
    url: '/repair-of-the-dryer-cooler-of-meal',
    title: 'Ремонт сушилки-охолоджувача шроту (DC)',
    firstImage,
    secondImage,
    keywords: 'сушилка-охолоджувач шроту,ремонт сушилки-охолоджувача,ремонт шроту',
    manufacturers: {
        text: 'ТОВ «СІТАМ» має великий досвід ремонту сушилок найрізноманітніших марок і конфігурацій. У списку обладнання, з яким ми працюємо, знаходяться агрегати наступних виробників: crown та інших виробників, включаючи вітчизняних.',
    },
    underTitle: `ТОВ «СІТАМ» Ремонтуємо сушилка/охолоджувач та інше промислове обладнання на професійному рівні. На всі види робіт організація має необхідні дозволи та свідоцтва.`,
    type: PageTemplates.TwoImagesOneTextType,
    description: 'Ремонт сушилок найрізноманітніших марок і конфігурацій. Ремонт сушилки/охолоджувача шроту. У списку обладнання, з яким ми працюємо, знаходяться агрегати наступних виробників: crown та інших виробників, включаючи вітчизняних',
    text: `Сушилка-охолоджувач висушує та охолоджує шрот після екстракції розчинником. Осушувач/охолоджувач може використовуватися як у поєднанні з десольвентайзером-тостером, так і як самостійний пристрій, в залежності від вимог виробництва.

#### Ремонт сушилки/охолоджувача шроту може включати:

- Демонтаж ізоляції з нержавіючих листів та мінеральної вати кришки ємності;

- Демонтаж перехідного містка кришки сушилки;

- Демонтаж верхнього листа сушилки з заміною листів;

- Закріплення шлюзового засуву подачі товару в ємність;

- Демонтаж самотіка;

- Посилення нової обшивки до ребер жорсткості;

- Зварка стиків нових листів;

- Перевірка зварних швів на герметичність;

- Відновлення ізоляції та з'єднання шлюзового засуву з самотіком;
`};