import secondImage from '../../images/pages/installation-of-industrial-boilers/1.jpg';
import firstImage from '../../images/pages/installation-of-industrial-boilers/2.jpg';
import {PageTemplates} from "../../pages/standartPages/enum";

export const installationOfIndustrialBoilers = {
    url: '/installation-of-industrial-boilers',
    title: 'Монтаж промышленных котлов',
    firstImage,
    secondImage,
    keywords: 'монтаж промышленных котлов,демонтаж котлов,монтаж котлов',
    manufacturers: {
        text: 'ООО «СИТАМ» имеет большой опыт монтажу или демонтажу котлов самых разных марок и конфигураций. В списке оборудования, с которым мы работаем, числятся агрегаты следующих производителей: Е, ДЕ, КЕ, ДКВр, КСВа, ВК, КВГ, ТВГ и других производителей в т.ч. отечественных',
    },
    description: 'Выполним монтаж и пусконаладку промышленных паровых котлов и вспомогательного котельного оборудования. В списке оборудования, с которым мы работаем, числятся агрегаты следующих производителей: Е, ДЕ, КЕ, ДКВр, КСВа, ВК, КВГ, ТВГ и других производителей в т.ч. отечественных',
    underTitle: `ООО «СИТАМ» Выполним монтаж котлов и другого промышленного котельного оборудования на профессиональном уровне. На все виды работ организация имеет необходимые разрешения и свидетельства`,
    type: PageTemplates.TwoImagesOneTextType,
    text: `Наша компания выполнит монтаж и пусконаладку промышленных паровых котлов и вспомогательного котельного оборудования.

Котельная может быть стационарной или модульной. При монтаже котельного оборудования в стационарной котельной все оборудование крепится к специально подготовленным фундаментам. Блочно-модульная котельная может поставляться транспортабельными блоками. В транспортабельных котельных установках (ТКУ) оборудование уже смонтировано с максимальной заводской готовностью.

Монтаж котлов производится непосредственно в котельной и включает:

Транспортировка котла к месту установки;

Подготовка фундамента (при необходимости);

Установка котла на фундамент или основание;

Закрепление котла на основании;

Монтаж вспомогательного оборудования котла;

Подключение котла к дымовой трубе и внешним сетям (трубопровод, газопровод, электроснабжение и т.д);

Установка горелки;

Пуско-наладочные работы.`,
};

